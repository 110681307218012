import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';

class MeslekUnvanlari extends Component {

  state = {
    yuklendi : false,
    modal1 : false,
    btn_olustur_enb : false,
    unvan : '',
    meslek_unvanlari : [ ],
    secilen_meslek : ''
  }

  async meslek_unvanlari_getir(){

      const { hata,mesaj,data } = await Api.meslek_unvanlari_listesi(this.props.match.params.id,Co.access_token());

      if(hata){
          swal("HATA",mesaj,"error");
      }

      this.setState({
          meslek_unvanlari : data == null ? [ ] : data.unvanlar,
          yuklendi : true
      })
  }

  async meslek_unvani_sil(id){

    swal({
      title :'Ünvan Siliniyor',
      content : <div><span>Ünvanı silmek için sil butonuna basın</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'green-button'
        },
        b2 : {
          text:'Sil',
          className: 'red-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj,data } = await Api.meslek_unvani_sil(id,Co.access_token());

            if(hata){
                swal("HATA",mesaj,"error");
            }
        }
        await this.meslek_unvanlari_getir();
    });

   
  }

  componentDidMount = async () => {

    document.title = this.props.location.meslek_adi + " - Meslek Ünvanları - İyi Uzman";

    if(this.props.location.meslek_adi == null){
        this.props.history.push('/sistem/meslek-gruplari');
        return;
    }else{
        this.setState({
            secilen_meslek : this.props.location.meslek_adi
        })
    }


    const ctx = this;
    setTimeout(function(){
        ctx.meslek_unvanlari_getir()
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-3">
            <div className="text-right">
                <button onClick={ () => this.toggleModal1() } className="btn light-button2">
                    <i className="fas fa-plus mr-1"></i>
                    Meslek Ünvanı Ekle
                </button>
            </div>
            <div className="table-responsive mt-3">
                <table className="table table-borderless dash">
                    <thead>
                        <tr>
                            <th style={ { width:100 } }>No</th>
                            <th>Ünvan</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        { this.meslek_unvanlari() }
                    </tbody>

                </table>
            </div>
        </div>
      )
  }

  meslek_unvanlari(){
     return ( // Map = Foreach
        this.state.meslek_unvanlari.map( (unvan,index) => {
            return (  
                <tr>
                    <td> { index+1 } </td>
                    <td>{ unvan.unvan }</td>
                    <td>
                        <button onClick={ () => this.meslek_unvani_sil(unvan.id) } className="btn light-button2">Sil</button>
                    </td>
                </tr>
            )
        })
     )
  }

  toggleModal1(){
      this.setState({
          modal1: !this.state.modal1
      })
  }

  setUnvan(val){
      this.setState({
          unvan : val
      })
  }

  async meslek_unvani_olustur(){
      if(this.state.unvan.trim().length < 1){
          swal("HATA","Ünvan girilmedi!","error");
          return;
      }

      const { hata,mesaj,data } = await Api.meslek_unvani_olustur(this.props.match.params.id,this.state.unvan,Co.access_token());

      this.meslek_unvanlari_getir();

      this.setState({ unvan : '' })

      swal(mesaj);

    this.setState({
          modal1 : false
      })
  }

  modal_meslek_unvanlari(){
      return (
        <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
            <Modal.Body>

                <div className="text-right">
                    <i onClick={ () => this.toggleModal1()  } className="fas fa-times close-popup"></i>
                </div>

                <div className="p-3">
                    <h4>Yeni Ünvan Oluştur</h4>
                    <hr />

                    

                    <div className="form-group">
                        <label htmlFor="unvan">Ünvan</label>
                        <input onChange={ (e) => this.setUnvan(e.target.value) } value={ this.state.unvan } id="unvan" type="text" className="form-control" />
                    </div>


                    <div className="text-right">
                        <button disabled={ this.state.btn_olustur_enb } onClick={ () => this.meslek_unvani_olustur() } className="btn green-button">
                            <i className="fas fa-check mr-1"></i>
                            Oluştur
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">{ this.state.secilen_meslek }</h4>
                
                { this.content() }
              </div>

            </div>

            <Footer/>
            
            { this.modal_meslek_unvanlari() }

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MeslekUnvanlari))