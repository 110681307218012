import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';

class SidebarItem extends Component {

  render() {

    const { icon,baslik,path,onClick } = this.props;

    const active = path == this.props.location.pathname ? true : false;

    return (
        <li onMouseUp={ () => this.props.sidebar_toggle(false) } onClick={ onClick } className="list-group-item 123" data-active={ active }>
            <Link to={ path }>
                { icon }
                <span>{ baslik }</span>
            </Link>
        </li>
    );
  }
}


function mapStateToProps(state){
  return {
      giris_pop : state.giris_pop,
      sidebar   : state.sidebar,
      user      : state.user,
      fotograf  : state.fotograf
  }
}

function mapDispatchToProps(dispatch){
  return {
      giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
      sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value }),
      set_user : ( value ) => dispatch({ type : actionTypes.SET_USER_DATA, payload : value })
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SidebarItem))