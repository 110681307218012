import { ReactComponent as WhatsApp2 } from '../../assets/images/whatsapp2.svg';
import { ReactComponent as Telefon2 } from '../../assets/images/telefon3.svg';

import ReactWhatsapp from 'react-whatsapp';

export default function ActionBarMobile(){
    return (
        <div className="d-lg-none d-flex d-md-none bg-white">
            <a href="https://api.whatsapp.com/send?phone=905312667251&text=Merhaba+detayli+bilgi+almak+istiyorum." className="btn btn-whatsapp">
                <WhatsApp2/> WhatsApp 
            </a>
            <a href="tel:08503052980" className="btn btn-randevu">
                <Telefon2/> Randevu
            </a>
        </div>
    )
}