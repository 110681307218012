import React, { Component,useState } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';

import Resim1 from '../assets/images/kayit-1.png';

import { Link,withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';

import { connect } from 'react-redux';
import * as actionTypes from '../Redux/actionTypes';
import Api from '../Functions/Api';

import { Modal } from 'react-bootstrap';

class KayitOl extends Component {

    state = {
        ad          : '',
        soyad       : '',
        email       : '',
        sifre       : '',
        kod         : '',
        sozlesmeler : false,
        btn_kayit_ol: true,
        modal1      : false,
        btn_dogrula : true,
    }

  

    setAd(ad){
        this.setState({ ad })
    }

    setSoyad(soyad){
        this.setState({ soyad })
    }

    setEmail(email){
        this.setState({ email })
    }

    setSifre(sifre){
        this.setState({ sifre })
    }

    setKod(kod){
        this.setState({ kod })
    }

    componentDidMount = () => {
        this.props.giris_pop_toggle(false);
        document.title = "Ücretsiz Uzman Hesabı Oluştur - İyi Uzman"
    };
   
    async kodGonder(){

        if(!this.state.sozlesmeler){
            swal("Oops!","Üye olabilmek için kullanım şartnamesini kabul etmelisiniz!","error");
            return;
        }

        this.setState({ btn_kayit_ol : false });

        const { hata,mesaj,data } = await Api.dogrulama_kodu_gonder(this.state.email);

        this.setState({ btn_kayit_ol : true });

        if(hata){
            swal("Oops!",mesaj,"error");
            return;
        }

        this.setState({ modal1 : true });
    }

    tekrarGonder(){
        this.kodGonder();
        swal("Başarılı!","Doğrulama kodu tekrar gönderildi.","success");
        
    }

    async kayitOl(){

        this.setState({ btn_dogrula : false });

        const { hata,mesaj,data } = await Api.kayit_ol(
            this.state.ad,
            this.state.soyad,
            this.state.email,
            this.state.sifre,
            this.state.kod,
        );

        if(hata){
            swal("Oops!",mesaj,'error');
            return;
        }

        this.setState({ modal1: false,btn_dogrula : true });
        swal("Harika!","Üyeliğiniz başarıyla oluşturuldu, devam etmek için şimdi giriş yapabilirsiniz.Eğer gelen kutunuza mail düşmediyse spam kutunuzu da kontrol ediniz.","success",{
            buttons : {
                b1 : {
                    text : 'Giriş yap'
                }
            }
        }).then(x => {
            this.setState({
                ad : '',
                soyad : '',
                email : '',
                sifre : '',
            });
            this.props.giris_pop_toggle(true);
        })
    }


  render(){
    return (
        <div>
            <ActionBar/>
            <ActionBarMobile/>

            <NavBar className="bg-green"/>

            <div className="container p-5">
                <div className="row">
                    <div className="col-md-5">
                        <small className="gray">Hoşgeldiniz!</small>
                        <h4>Ücretsiz Bir Uzman Hesabı Oluşturun</h4>

                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="ad">Ad</label>
                                    <input onChange={ (e) => this.setAd(e.target.value) } value={ this.state.ad } id="ad" type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="soyad">Soyad</label>
                                    <input onChange={ (e) => this.setSoyad(e.target.value) } value={ this.state.soyad } id="soyad" type="text" className="form-control" />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="email">E-mail</label>
                                    <input onChange={ (e) => this.setEmail(e.target.value) } value={ this.state.email } id="email" type="text" className="form-control" />
                                </div>

                                <div className="form-group mb-2">
                                    <label htmlFor="sifre">Şifre</label>
                                    <input onChange={ (e) => this.setSifre(e.target.value) } value={ this.state.sifre } id="sifre" type="password" className="form-control" />
                                </div>

                                <div class="custom-control custom-checkbox">
                                    <input onClick={ (e) => this.setState({ sozlesmeler : e.target.checked }) } value={ this.state.checkbox } type="checkbox" class="custom-control-input" id="sozlesmeler" checked={ this.state.sozlesmeler }/>
                                    <label class="custom-control-label" htmlFor="sozlesmeler">Site <a href="/kullanici-sozlesmesi">Kullanım Şartnamesini</a> ve <a href="/aydinlatma-metni">Kişisel Veri Gizlilik Politikası ve Aydınlatma Metni</a> 'ni kabul ediyorum. </label>
                                </div>

                                <button disabled={ !this.state.btn_kayit_ol } onClick={ () => this.kodGonder() } className="btn green-button btn-block btn-lg mt-3">
                                    Kayıt Ol
                                </button>

                                <div className="text-center mt-3">
                                    <span className="dark">Zaten bir hesabınız var mı? </span>
                                    <span>
                                        <strong>
                                            <span className='pointer' onClick={ () => this.props.giris_pop_toggle(true) }>Giriş Yap</span>
                                        </strong>
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-7 d-none d-sm-block">
                        <div className="centered">
                            <img className="img img-fluid w-75" src={ Resim1 } alt="kayit-ol" />
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
                <Modal.Body>
                    <div className="p-4">
                        <h4>Hesabınızı doğrulayın.</h4>
                        <p className="gray">
                            Hesabınızı doğrulamanız için kayıt olduğunuz e-mail adresine doğrulama kodu gönderdik. Aşağıdaki alana e-mailinize gelen doğrulama kodunu giriniz.
                        </p>

                        <div className="form-group text-left">
                            <label htmlFor="kod">Doğrulama Kodu</label>
                            <input onChange={ (e) => this.setKod(e.target.value) } id="kod" type="text" className="form-control text-center" style={ { fontSize:25 } } />
                        </div>

                        <button onClick={ () => this.kayitOl() } className="btn green-button btn-lg btn-block border">
                            Doğrula
                        </button>

                        <div className="text-center mt-3">
                            <span class="dark">Kod gelmedi mi? </span>
                            <span role="button" onClick={() => this.tekrarGonder()} className="green">Tekrar Gönder</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Footer/>
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(KayitOl))