import BlogGorsel from '../../assets/images/bloglar-healthy.jpg';
import { ReactComponent as BlogTarih } from '../../assets/images/blog-tarih.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';

class Bloglar extends Component {

  state = {
    yuklendi : false,
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="row">
            <div className="col-md-4 mb-5">
                <div class="card">
                    <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                    <div class="card-body">
                        <small className="text-green"><BlogTarih/> 25 Ekim Pazartesi 2021 #sağlıklıbeslenme diyet</small>
                        <h5 class="card-title">
                            Lorem ipsum dolor sit amet
                        </h5>
                        <p class="card-text gray">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-5">
                <div class="card">
                    <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                    <div class="card-body">
                        <small className="text-green"><BlogTarih/> 25 Ekim Pazartesi 2021 #sağlıklıbeslenme diyet</small>
                        <h5 class="card-title">
                            Lorem ipsum dolor sit amet
                        </h5>
                        <p class="card-text gray">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-5">
                <div class="card">
                    <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                    <div class="card-body">
                        <small className="text-green"><BlogTarih/> 25 Ekim Pazartesi 2021 #sağlıklıbeslenme diyet</small>
                        <h5 class="card-title">
                            Lorem ipsum dolor sit amet
                        </h5>
                        <p class="card-text gray">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-5">
                <div class="card">
                    <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                    <div class="card-body">
                        <small className="text-green"><BlogTarih/> 25 Ekim Pazartesi 2021 #sağlıklıbeslenme diyet</small>
                        <h5 class="card-title">
                            Lorem ipsum dolor sit amet
                        </h5>
                        <p class="card-text gray">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-5">
                <div class="card">
                    <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                    <div class="card-body">
                        <small className="text-green"><BlogTarih/> 25 Ekim Pazartesi 2021 #sağlıklıbeslenme diyet</small>
                        <h5 class="card-title">
                            Lorem ipsum dolor sit amet
                        </h5>
                        <p class="card-text gray">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-5">
                <div class="card">
                    <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                    <div class="card-body">
                        <small className="text-green"><BlogTarih/> 25 Ekim Pazartesi 2021 #sağlıklıbeslenme diyet</small>
                        <h5 class="card-title">
                            Lorem ipsum dolor sit amet
                        </h5>
                        <p class="card-text gray">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                <div className="row">
                    <div className="col"><h2 className="pt-3 pb-3">Bloglar</h2></div>
                    <div className="col">
                        <div className="text-right">
                            <Link to="/uzman/yeni-blog">
                                <button className="btn green-button pl-5 pr-5 pt-3 pb-3">
                                    Blog Yükle
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Bloglar))