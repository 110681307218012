import { ReactComponent as EvdeHizmet } from '../../../assets/images/evde-hizmet.svg';
import { ReactComponent as KlinikteHizmet } from '../../../assets/images/klinikte-hizmet.svg';
import { ReactComponent as OnlineHizmet } from '../../../assets/images/online-hizmet.svg';

export default function HizmetTurleri(){
    return (
        <div className="row mt-3">

            <div className="col-md-4 mt-3">
                <div className="card ht-card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <EvdeHizmet className="mr-1"/>
                            Evde Hizmet
                        </h5>
                        <p>
                            Binlerce Evde Hizmet Veren Uzman Arasından Seçim Yapın. Evde Hizmet Seçeneği ile Uzmanınız Evinize Gelerek Ev Ortamında Size Hizmet Verir.  
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="col-md-4 mt-3">
                <div className="card ht-card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <KlinikteHizmet className="mr-1"/>
                            Klinikte Hizmet
                        </h5>
                        <p>
                            Size En Yakın Klinikten Kolayca Randevu Oluşturabilir ve Uzmanınızı Daha Gitmeden Tanıyabilirsiniz.
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card ht-card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <OnlineHizmet className="mr-1"/>
                            Online Hizmet
                        </h5>
                        <p>
                            Dünyanın Neresinde Olursanız Olun Binlerce Online Hizmet Veren Uzmanlar arasından dilediğiniz uzman ile online görüşüp danışabilirsiniz.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}