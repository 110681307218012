import { ReactComponent as Edit } from '../../../assets/images/edit.svg'
import defaultAvatar from '../../../assets/images/user-avatar.jpg'
import React, { Component } from 'react';

import Footer from '../../Components/Footer';
import NavBar from '../../Components/NavBar';
import Sidebar from '../../Components/Sidebar';
import PageLoading from '../../Components/PageLoading';

import * as actionTypes from '../../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../../Functions/Api';

import Co from '../../../Functions/Co';
import Main from '../../../Functions/Main';
import Stepper from './Stepper';

class KisiselBilgiler extends Component {

  state = {

    user : { },
    yuklendi : true,
    btn_kaydet_disabled: false,
    btn_onay : true,
    fotograf_degistirildi : false,
    yeni_fotograf : '',
    onay_bekliyor : 0,
    email_dogrulandi : 0,
    kimlik_onaylandi : 0,

    telefon_dogrulandi : 0,
    onay_bekleyen_telefon : '',

    tel_dk : '',
    btn_tel_kodu_dogrula_aktif : true,
    btn_tel_kod_gonder_aktif : true,
    
    email_dk : '',
    btn_email_kodu_dogrula_aktif : true,
    btn_email_kod_gonder_aktif : true,

    ad : "",
    soyad : "",
    telefon : "",
    email : "",
    cinsiyet : "",
    dogum_tarihi : "",
    tc : "",
    adres : "",
    sirket_adi : "",
    vergi_dairesi : "",
    vergi_no : "",
    iban : "",
    il : "",
    ilce : "",

    iller : Main.iller(),
    ilceler : [ ],

    dogrulama_kodu_gonderiliyor : false,
  }

  componentDidMount(){
    document.title = "Kişisel Bilgiler - İyi Uzman";
    this.fotograf = null;

    this.profil_bilgileri();
  };

  zorunlu_alanlar(){
    if(this.state.ad.length < 1){
      Main.message('Lütfen adınızı girin.',true);
      return;
    }

    if(this.state.soyad.length < 1){
      Main.message('Lütfen soyadınızı girin.',true);
      return;
    }

    if(this.state.email.length < 1){
      Main.message('Lütfen email adresinizi girin.',true);
      return;
    }

    if(this.state.telefon.length < 1){
      Main.message('Lütfen telefon numaranızı girin.',true);
      return;
    }

    if(this.state.cinsiyet == 'BELIRTILMEDI'){
      Main.message('Lütfen cinsiyetinizi girin.',true);
      return;
    }

    if(this.state.dogum_tarihi.length < 1){
      Main.message('Lütfen doğum tarihinizi girin.',true);
      return;
    }

    if(this.state.tc.length < 1){
      Main.message('Lütfen T.C kimlik numaranızı girin.',true);
      return;
    }

    if(this.state.adres.length < 1){
      Main.message('Lütfen adresinizi girin.',true);
      return;
    }

    if(this.state.il.length < 1){
      Main.message('Lütfen il seçin.',true);
      return;
    }

    if(this.state.ilce.length < 1){
      Main.message('Lütfen ilçe seçin.',true);
      return;
    }

    if(this.state.ad.length < 1){
      Main.message('Lütfen adınızı girin.',true);
      return;
    }

    if(this.state.iban.length < 1){
      Main.message('Lütfen IBAN adresinizi girin. Olası bir iade durumunda girmiş olduğunuz IBAN adresinize iade ödemesi geçeceğiz.',true);
      return;
    }

    return true;
  }

  async profil_bilgileri(){

    this.setState({ yuklendi : false })

    const { hata,data } = await Api.profil_bilgileri({ }, Co.access_token() );
    
    if(!hata){
      this.setState({
        yuklendi : true,
        ad : data.ad,
        soyad : data.soyad,
        telefon : data.telefon,
        email : data.email,
        cinsiyet : data.cinsiyet,
        dogum_tarihi : data.dogum_tarihi,
        tc : data.tc,
        adres : data.adres,
        sirket_adi : data.sirket_adi,
        vergi_dairesi : data.vergi_dairesi,
        vergi_no : data.vergi_no,
        iban : data.iban,
        il : data.il,
        ilce : data.ilce,
        ilceler : Main.ilceler(data.il),
        onay_bekliyor : data.onay_bekliyor,
        email_dogrulandi : data.email_dogrulandi,
        telefon_dogrulandi : data.telefon_dogrulandi,
        kimlik_onaylandi : data.kimlik_onaylandi,
        onay_bekleyen_telefon : data.onay_bekleyen_telefon,
      });

      this.setState({ user : data });

      let data2 = { ...data };

      this.props.set_user(data2);
    }
  }

  setAd(ad){
      this.setState({ ad });
  }
  setSoyad(soyad){
      this.setState({ soyad });
  }
  setEmail(email){
      this.setState({ email });
  }
  setTelefon(telefon){
      this.setState({ telefon });
  }
  setCinsiyet(cinsiyet){
      this.setState({ cinsiyet });
  }
  setDogumTarihi(dogum_tarihi){
      this.setState({ dogum_tarihi });
  }
  setTc(tc){
      this.setState({ tc });
  }
  setAdres(adres){
      this.setState({ adres });
  }
  setSirket(sirket_adi){
      this.setState({ sirket_adi });
  }
  setVergiDairesi(vergi_dairesi){
      this.setState({ vergi_dairesi });
  }
  setVergiNo(vergi_no){
      this.setState({ vergi_no });
  }
  setIban(iban){
      this.setState({ iban });
  }

  setEmailDk(email_dk){
      this.setState({ email_dk });
  }

  setIl(il){
    this.setState({ il, ilce : '', ilceler : Main.ilceler(il) });
  }

  setIlce(ilce){
      this.setState({ ilce });
  }

  setTelDk(tel_dk){
    this.setState({ tel_dk });
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ yeni_fotograf: e.target.result, fotograf_degistirildi : true });
        this.props.set_fotograf(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  fotografSec(){
    this.fotograf.click()
  }

  async profili_guncelle(){

    if(!this.zorunlu_alanlar()) return;

    this.setState({
      btn_kaydet_disabled : true
    });

    if(this.state.fotograf_degistirildi){
      
      var { hata,mesaj,data } = await Api.profil_resmi_degistir(this.state.yeni_fotograf, Co.access_token());

      if(!hata){
        this.props.set_fotograf(data.fotograf);
      }
    }

    const params = {
      ad : this.state.ad,
      soyad : this.state.soyad,
      telefon : this.state.telefon,
      email : this.state.email,
      cinsiyet : this.state.cinsiyet,
      dogum_tarihi : this.state.dogum_tarihi,
      tc : this.state.tc,
      adres : this.state.adres,
      sirket_adi : this.state.sirket_adi,
      vergi_dairesi : this.state.vergi_dairesi,
      vergi_no : this.state.vergi_no,
      iban : this.state.iban,
      il : this.state.il,
      ilce : this.state.ilce,
    }

    var { hata,mesaj,data } = await Api.profili_guncelle(params,Co.access_token());

    this.setState({
      btn_kaydet_disabled : false
    });


    if(hata){
        swal("Oops!",mesaj,'error');
        return;
    }
    
    this.profil_bilgileri();
    swal("Harika!", mesaj, 'success');
  }

  async profili_onaya_gonder(){

    this.setState({
      btn_onay : false,
    })

    var { hata,mesaj,data } = await Api.profili_onaya_gonder(Co.access_token());

    this.setState({
      btn_onay : true,
    })

    if(hata){
        swal("Oops!",mesaj,'error');
        return;
    }
    this.setState({
      onay_bekliyor: 1,
    });
    swal("Harika!", mesaj, 'success');
  }

  async email_dogrula(){
    
    this.setState({ btn_email_kod_gonder_aktif : false });

    var { hata,mesaj } = await Api.kodu_dogrula(this.state.email_dk,this.state.email,'EMAIL',Co.access_token());

    Main.message(mesaj,hata);
    
    this.setState({ email_dogrulandi : hata ? false : true })

    this.setState({ btn_email_kod_gonder_aktif : true });

    this.profil_bilgileri();
  }

  async email_dogrulama_kodu_gonder(){

    this.setState({ dogrulama_kodu_gonderiliyor : true })

    const { hata,mesaj,data } = await Api.dogrulama_kodu_gonder(this.state.email);

    Main.message(mesaj,hata);

    this.setState({ dogrulama_kodu_gonderiliyor : false })
  }

  async telefon_dogrula(){
    
    this.setState({ btn_tel_kod_gonder_aktif : false });

    var { hata,mesaj } = await Api.kodu_dogrula(this.state.tel_dk,this.state.telefon,'SMS',Co.access_token());

    Main.message(mesaj,hata);

    this.setState({ telefon_dogrulandi : hata ? false : true,btn_tel_kod_gonder_aktif : true, tel_dk : '' })

    this.profil_bilgileri();

  }

  async telefon_dogrulama_kodu_gonder(){

    this.setState({ dogrulama_kodu_gonderiliyor : true })

    const { hata,mesaj,data } = await Api.dogrulama_kodu_gonder(this.state.onay_bekleyen_telefon,'SMS');

    Main.message(mesaj,hata);

    this.setState({ dogrulama_kodu_gonderiliyor : false })
  }


  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
      
        <div id="profilimContainer" className="content-card p-5">
          
            <div className="row d-flex justify-content-center mb-2">
              <div className="box text-center">
                <img
                  className="fotograf"
                  id="target"
                  src={ Main.resimURL(this.props.fotograf,true) }/>
                <button onClick={ () => this.fotografSec() } className="btn green-button"><Edit /></button>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 bg-light-info border-radius-10 pt-4 pb-2 px-4 mb-5">
                  <i className="fa fa-info-circle float-right text-info"></i>
                  <p className='text-green2'>Kendi profil resminizi eklemeniz danışanlarınızın sizi tanımasını kolaylaştırır ve daha fazla randevu almanızı sağlar.</p>
              </div>
            </div>
            <input onChange={this.onImageChange} type="file" accept="image/png, image/gif, image/jpeg" ref={ (e) => this.fotograf = e } style={ { display: 'none' } }/>
            <div className="row">
              <div className="form-group col-md-6">
                  <label htmlFor="ad"><h5>Ad</h5></label>
                  <input onChange={ (e) => this.setAd(e.target.value) } type="text" id="ad" className="form-control" defaultValue={ this.state.ad } />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="soyad"><h5>Soyad</h5></label>
                <input onChange={ (e) => this.setSoyad(e.target.value) } type="text" id="soyad" className="form-control" defaultValue={ this.state.soyad } />
              </div>
            </div>
            
            <div className="form-group">
                <label htmlFor="email"><h5>E-mail</h5></label>
                <input onChange={ (e) => this.setEmail(e.target.value) } type="text" id="email" className="form-control" defaultValue={ this.state.email } />
            </div>

            {
              !this.state.email_dogrulandi ?
              <>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="alert alert-danger">
                    <p className="mb-0">
                      E-Mail adresinizi doğrulamanız gerekiyor!
                      <br/>
                      Sisteme yeni üye olduğunuz yada email adresi değişikliği yaptığınız için email adresinize gönderdiğimiz kodu aşağıdaki alana girip doğrulama yapmalısınız.
                    </p>

                    <br/>

                    <div className="form-group">
                      <label htmlFor="email_dk" className="text-white">E-Mail doğrulama kodu</label>
                      <input onChange={ (e) => this.setEmailDk(e.target.value) } value={ this.state.email_dk } id="email_dk" type="text" className="form-control dogrulama-kodu" />
                    </div>

                    <div className="text-right">
                      <button disabled={ this.state.dogrulama_kodu_gonderiliyor } onClick={ () => this.email_dogrulama_kodu_gonder() } className="btn light-button">
                        {
                          this.state.dogrulama_kodu_gonderiliyor ? 'Lütfen bekleyin...' : 'Kodu Tekrar Gönder'
                        }
                      </button>
                      <button onClick={ () => this.email_dogrula() } className="btn light-button ml-3">
                        { this.state.btn_email_kod_gonder_aktif ? 'Doğrula' : 'Doğrulanıyor..' }
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              </>
              :
              <>
              </>
            }

            <div className="form-group">
                <label htmlFor="telefon"><h5>Telefon</h5></label>
                <input onChange={ (e) => this.setTelefon(e.target.value) } type="text" id="telefon" className="form-control" defaultValue={ this.state.telefon } />
            </div>

            {
              !this.state.telefon_dogrulandi && this.state.onay_bekleyen_telefon.length > 0 ?
              <>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="alert alert-danger">
                    <p className="mb-0">
                      <h5 className='text-light text-center'>{this.state.onay_bekleyen_telefon} kayıtlı olan telefon numaranızı doğrulamanız gerekiyor!</h5>
                      <br/>
                      Sisteme yeni üye olduğunuz yada telefon numarası değişikliği yaptığınız için telefon numaranıza gönderdiğimiz kodu aşağıdaki alana girip doğrulama yapmalısınız.
                    </p>

                    <br/>

                    <div className="form-group">
                      <label htmlFor="tel_dk" className="text-white">Telefon doğrulama kodu</label>
                      <input onChange={ (e) => this.setTelDk(e.target.value) } value={ this.state.tel_dk } id="tel_dk" type="text" className="form-control dogrulama-kodu" />
                    </div>

                    <div className="text-right">
                      <button disabled={ this.state.dogrulama_kodu_gonderiliyor } onClick={ () => this.telefon_dogrulama_kodu_gonder() } className="btn light-button">
                        {
                          this.state.dogrulama_kodu_gonderiliyor ? 'Lütfen bekleyin...' : 'Kod Gönder'
                        }
                      </button>
                      <button onClick={ () => this.telefon_dogrula() } className="btn light-button ml-3">
                        { this.state.btn_tel_kod_gonder_aktif ? 'Doğrula' : 'Doğrulanıyor..' }
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              </>
              :
              <>
              </>
            }


            <div className="form-group">
              <label htmlFor="cinsiyet"><h5>Cinsiyet</h5></label>
              <select onChange={ (e) => this.setCinsiyet(e.target.value) } id='cinsiyet' type="text" className="form-control" defaultValue={ this.state.cinsiyet }>
                <option value="BELIRTILMEDI">Belirtilmedi</option>
                <option value="KADIN">Kadın</option>
                <option value="ERKEK">Erkek</option>
              </select>
            </div>
            <div className="form-group">
                <label htmlFor="dogum_tarihi"><h5>Doğum Tarihi</h5></label>
                <input onChange={ (e) => this.setDogumTarihi(e.target.value) } type="date" id="dogum_tarihi" className="form-control" defaultValue={ this.state.dogum_tarihi } />
            </div>
            <div className="form-group">
                <label htmlFor="tc">
                  <h5>
                    Tc Kimlik No
                  </h5>
                </label>
                <input onChange={ (e) => this.setTc(e.target.value) } type="text" id="tc" className="form-control" defaultValue={ this.state.tc } />
            </div>
            <div className="form-group">
                <label htmlFor="adres"><h5>Adres</h5></label>
                <textarea onChange={ (e) => this.setAdres(e.target.value) } id="adres" rows="8" className="form-control" defaultValue={ this.state.adres }></textarea>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                  <label htmlFor="il"><h5>İl</h5></label>
                  <select defaultValue={ this.state.il } onChange={ (e) => this.setIl(e.target.value) } type="text" id="il" className="form-control">
                    <option value='' disabeled>Seçiniz</option>
                    { this.state.iller.map(il => (
                      <option value={ il.value }>{ il.label }</option>
                    )) }
                  </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="ilce"><h5>İlçe</h5></label>
                <select defaultValue={ this.state.ilce } disabled={ this.state.ilceler.length < 1 ? true : false } onChange={ (e) => this.setIlce(e.target.value) } type="text" id="ilce" className="form-control">
                    <option value='' disabeled>Seçiniz</option>
                    { this.state.ilceler.map(ilce => (
                      <option value={ ilce.value }>{ ilce.label }</option>
                    )) }
                  </select>
              </div>
            </div>
            <h4><span>Fatura Bilgileri</span></h4>
            <div className="form-group">
                <label htmlFor="sirket_adi"><h5>Firma Adı/Ünvan</h5></label>
                <input onChange={ (e) => this.setSirket(e.target.value) } type="text" id="sirket_adi" className="form-control" defaultValue={ this.state.sirket_adi } />
            </div>
            <div className="form-group">
                <label htmlFor="vergi_dairesi"><h5>Vergi Dairesi</h5></label>
                <input onChange={ (e) => this.setVergiDairesi(e.target.value) } type="text" id="vergi_dairesi" className="form-control" defaultValue={ this.state.vergi_dairesi } />
            </div>
            <div className="form-group">
                <label htmlFor="vergi_no"><h5>Vergi Kimlik No</h5></label>
                <input onChange={ (e) => this.setVergiNo(e.target.value) } type="text" id="vergi_no" className="form-control" defaultValue={ this.state.vergi_no } />
            </div>
            <div className="form-group">
                <label htmlFor="iban"><h5>İban No</h5></label>
                <input onChange={ (e) => this.setIban(e.target.value) } type="text" id="iban" className="form-control" defaultValue={ this.state.iban } />
            </div>

            <div className="alert alert-danger">
              <h5 className="text-white">Neden IBAN adresinizi istiyoruz?</h5>
              <p>Olası bir iade durumunda burada sizden almış olduğumuz iban adresine ödemenizi geçmek için.</p>
            </div>


            <button disabled={ this.state.btn_kaydet_disabled } onClick={ () => this.profili_guncelle() } className="btn btn green-button btn-block p-3">
              {
                  !this.state.btn_kaydet_disabled ? 'Kaydet' : 'Kaydediliyor...'
              }
            </button>
        </div>
      )
  }

  render(){

    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">

                { this.state.yuklendi ? <Stepper/> : '' }

                <h4 className="mb-3">Profilim</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user,
        fotograf : state.fotograf,
    }
}

function mapDispatchToProps(dispatch){
    return {
      set_user : (value) => dispatch({ type : actionTypes.SET_USER_DATA,payload : value }),
      set_fotograf  : (value) => dispatch( { type : actionTypes.SET_FOTOGRAF, payload : value } )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(KisiselBilgiler))