import React, { Component } from 'react';

import { createBrowserHistory } from 'history';
import { Switch,Route,Router } from 'react-router-dom';

import Anasayfa from './Sayfalar/Anasayfa';
import KayitOl from './Sayfalar/KayitOl';
import Iletisim from './Sayfalar/Iletisim';
import Hakkimizda from './Sayfalar/Hakkimizda';
import Ara from './Sayfalar/Ara';
import UzmanProfili from './Sayfalar/UzmanProfili';
import SifremiUnuttum from './Sayfalar/SifremiUnuttum';
import Gorusler from './Sayfalar/Gorusler';

import Kurumsal from './Sayfalar/Kurumsal'

/* Uzman Sayfaları */
import Dashboard from './Sayfalar/Uzman/Dashboard';
import CalismaTakvimi from './Sayfalar/Uzman/CalismaTakvimi';
import OBRandevular from './Sayfalar/Uzman/OBRandevular';
import Faturalar from './Sayfalar/Uzman/Faturalar';
import FaturaDetay from './Sayfalar/Uzman/FaturaDetay';
import Degerlendirmeler from './Sayfalar/Uzman/Degerlendirmeler';
import DegerlendirmeDetay from './Sayfalar/Uzman/DegerlendirmeDetay';
import RandevuTakvimi from './Sayfalar/Uzman/RandevuTakvimi';
import Randevular from './Sayfalar/Uzman/Randevular';
import RandevuDetay from './Sayfalar/Uzman/RandevuDetay';
import Bloglar from './Sayfalar/Uzman/Bloglar';
import BlogDetay from './Sayfalar/Uzman/BlogDetay';
import YeniBlog from './Sayfalar/Uzman/YeniBlog';
import Bildirimler from './Sayfalar/Uzman/Bildirimler';
import BildirimDetay from './Sayfalar/Uzman/BildirimDetay';
import Duyurular from './Sayfalar/Uzman/Duyurular';
import DuyuruDetay from './Sayfalar/Uzman/DuyuruDetay';

import UzmanKisiselBilgiler from './Sayfalar/Uzman/ProfiliDuzenle/KisiselBilgiler';
import UzmanUzmanProfili from './Sayfalar/Uzman/ProfiliDuzenle/UzmanProfili';
import UzmanSifreDegistir from './Sayfalar/Uzman/ProfiliDuzenle/SifreDegistir';

/* Müşteri Sayfaları */
import RandevuDegerlendirme from './Sayfalar/Musteri/RandevuDegerlendirme';
import MusteriRandevuDetay from './Sayfalar/Musteri/RandevuDetay';

/* Admin Sayfaları */
import HizmetBolgeleri from './Sayfalar/Admin/HizmetBolgeleri';
import HizmetBolgesi from './Sayfalar/Admin/HizmetBolgesi';
import MeslekGruplari from './Sayfalar/Admin/MeslekGruplari';
import MeslekGrubu from './Sayfalar/Admin/MeslekGrubu';
import MeslekHizmetleri from './Sayfalar/Admin/MeslekHizmetleri';
import MeslekUnvanlari from './Sayfalar/Admin/MeslekUnvanlari';
import YeniUzman from './Sayfalar/Admin/YeniUzman';
import Uzmanlar from './Sayfalar/Admin/Uzmanlar';
import UzmanOnayIstekleri from './Sayfalar/Admin/UzmanOnayIstekleri';
import UzmanGoruntule from './Sayfalar/Admin/UzmanGoruntule';
import AdminRandevular from './Sayfalar/Admin/Randevular';
import AdminRandevuDetay from './Sayfalar/Admin/RandevuDetay';
import CagriKayitlari from './Sayfalar/Admin/CagriKayitlari';
import CagriKaydi from './Sayfalar/Admin/CagriKaydi';

import MesafeliSatisSozlesmesi from './Sayfalar/Sozlesmeler/MesafeliSatisSozlesmesi';

import Giris from './Modals/Giris';

import * as actionTypes from './Redux/actionTypes';

import { connect } from 'react-redux';

import Co from './Functions/Co';
import Api from './Functions/Api';
import PageLoading from './Sayfalar/Components/PageLoading';
import TopBarProgress from "react-topbar-progress-indicator";

import ScrollToTop from './Sayfalar/Components/ScrollToTop';
import CerezPolitikasi from './Sayfalar/Sozlesmeler/CerezPolitikasi';
import KullaniciSozlesmesi from './Sayfalar/Sozlesmeler/KullaniciSozlesmesi';
import GizlilikSozlesmesi from './Sayfalar/Sozlesmeler/GizlilikSozlesmesi';
import DegerlendirmeYayinlamaKurallari from './Sayfalar/Sozlesmeler/DegerlendirmeYayinlamaKurallari';
import AydinlatmaMetni from './Sayfalar/Sozlesmeler/AydinlatmaMetni';

const history = createBrowserHistory();

class App extends Component {

    state = {
      yuklendi  : true,
      giris     : false
    }

    handlePageChange = (activePage) => {

      history.push(activePage);
      setTimeout(() => {
        window.scrollTo(0, 0);
        alert('ok');
      }, 1000);
    }

    componentDidMount = () => {

      TopBarProgress.config({
        barColors: {
          "0": "#71d4d4",
          "1.0": "#71d4d4"
        },
        shadowBlur: 5
      });

      if(Co.auth()){
        this.profil_bilgileri();
        this.setState({ giris : true });
      }
    };

    async profil_bilgileri(){

      this.setState({ yuklendi : false })

      const { hata,data } = await Api.profil_bilgileri({ },Co.access_token() );
      
      if(!hata){
        this.setState({ yuklendi : true });

        var data2 =  { ...data }
        
        this.props.set_user_data(data2);

        this.props.set_fotograf(data.fotograf);
      }
    }
    
    render() {

      
      if(!this.state.yuklendi){
        return (
          <div>
             {
              this.state.yuklendi ? '' :
              <TopBarProgress />
            }

            <PageLoading fullscreen/>
          </div>
        )
      }

      return (
        <div className="App">

          <Router history={ history }>
            <ScrollToTop/>
            <Switch>

              <Route exact path="/">
                <Anasayfa handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/kayit-ol">
                <KayitOl handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/iletisim">
                <Iletisim handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/hakkimizda">
                <Hakkimizda handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/ara/:il-:ilce/:meslek">
                <Ara handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sifremi-unuttum">
                <SifremiUnuttum handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/kurumsal">
                <Kurumsal handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/degerlendirmeler/:id">
                <Gorusler handlePageChange={ this.handlePageChange } />
              </Route>


              {/* Uzman Sayfaları */}
              <Route exact path="/dashboard">
                <Dashboard handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/calisma-takvimi">
                <CalismaTakvimi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/onay-bekleyen-randevular">
                <OBRandevular handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/faturalar">
                <Faturalar handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/fatura-detay/:id">
                <FaturaDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/degerlendirmeler">
                <Degerlendirmeler handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/degerlendirme-detay/:id">
                <DegerlendirmeDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/randevu-takvimi">
                <RandevuTakvimi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/randevular">
                <Randevular handlePageChange={ this.handlePageChange } />
              </Route>
              
              <Route exact path="/uzman/randevu-detay/:id">
                <RandevuDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/bloglar">
                <Bloglar handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/blog-detay">
                <BlogDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/yeni-blog">
                <YeniBlog handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/duyuru-detay">
                <DuyuruDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/bildirimler">
                <Bildirimler handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/bildirim-detay">
                <BildirimDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/duyurular">
                <Duyurular handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/duyuru-detay">
                <DuyuruDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/profili-duzenle/kisisel-bilgiler">
                <UzmanKisiselBilgiler handlePageChange={ this.handlePageChange } />
              </Route>
              
              <Route exact path="/uzman/profili-duzenle/uzman-profili">
                <UzmanUzmanProfili handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/uzman/profili-duzenle/sifre-degistir">
                <UzmanSifreDegistir handlePageChange={ this.handlePageChange } />
              </Route>


              {/* Admin Sayfaları */}
              <Route exact path="/sistem/hizmet-bolgeleri">
                <HizmetBolgeleri handlePageChange={ this.handlePageChange } />
              </Route>
              
              <Route exact path="/sistem/hizmet-bolgesi/:id">
                <HizmetBolgesi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/meslek-gruplari">
                <MeslekGruplari handlePageChange={ this.handlePageChange } />
              </Route>
              
              <Route exact path="/sistem/meslek-grubu/:id">
                <MeslekGrubu handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/meslek-hizmetleri/:id">
                <MeslekHizmetleri handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/meslek-unvanlari/:id">
                <MeslekUnvanlari handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/yeni-uzman">
                <YeniUzman handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/uzmanlar">
                <Uzmanlar handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/uzman-onay-istekleri">
                <UzmanOnayIstekleri handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/uzman-goruntule/:id">
                <UzmanGoruntule handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/randevular">
                <AdminRandevular handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/randevu-detay/:id">
                <AdminRandevuDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/cagri-kayitlari">
                <CagriKayitlari handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/sistem/cagri-kaydi/:id">
                <CagriKaydi handlePageChange={ this.handlePageChange } />
              </Route>


              {/* Müşteri Sayfaları */}
              <Route exact path="/musteri/randevu-degerlendirme">
                <RandevuDegerlendirme handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/randevu/:hash_id">
                <MusteriRandevuDetay handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/randevu/:hash_id/degerlendir">
                <RandevuDegerlendirme handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/:id/:il-:meslek/:ad-:soyad">
                <UzmanProfili handlePageChange={ this.handlePageChange } />
              </Route>


              {/* Sözleşmeler */}
              <Route exact path="/mesafeli-satis-sozlesmesi">
                <MesafeliSatisSozlesmesi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/cerez-politikasi">
                <CerezPolitikasi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/kullanici-sozlesmesi">
                <KullaniciSozlesmesi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/gizlilik-sozlesmesi">
                <GizlilikSozlesmesi handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/degerlendirme-yayinlama-kurallari">
                <DegerlendirmeYayinlamaKurallari handlePageChange={ this.handlePageChange } />
              </Route>

              <Route exact path="/aydinlatma-metni">
                <AydinlatmaMetni handlePageChange={ this.handlePageChange } />
              </Route>

              

            </Switch>

            <Giris/>
            
          </Router>

      </div>
      )
    };
}

function mapStateToProps(state){
  return {
    giris_pop   : state.giris_pop,
    user        : state.user,
    fotograf    : state.fotograf,
    domain      : state.domain
  }
}

function mapDispatchToProps(dispatch){
  return {
    toggle_giris_pop : (value) => dispatch({
      type : actionTypes.GIRIS_POP_TOGGLER,
      padload : value
    }),
    set_user_data : (value) => dispatch({
      type : actionTypes.SET_USER_DATA,
      payload : value
    }),
    set_fotograf : (value) => dispatch({
      type : actionTypes.SET_FOTOGRAF,
      payload : value
    }),
    set_sidebar : (value) => dispatch({
      type : actionTypes.SIDEBAR_STATE,
      padload : value
    }),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);