import { ReactComponent as Onay } from '../../assets/images/onay.svg';
import { ReactComponent as Musteri } from '../../assets/images/musteri.svg';
import { ReactComponent as Tarih } from '../../assets/images/tarih.svg';
import { ReactComponent as Saat } from '../../assets/images/saat.svg';
import { ReactComponent as MusteriBilgileri } from '../../assets/images/user.svg';
import { ReactComponent as IlIlce } from '../../assets/images/ililce.svg';
import { ReactComponent as RandevuTuru } from '../../assets/images/randevu-turu.svg';
import { ReactComponent as AlmakIstenilenHizmet } from '../../assets/images/almak-istenilen-hizmet.svg';
import { ReactComponent as RandevuNotlari } from '../../assets/images/randevu-notlari.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import { Modal } from 'react-bootstrap';

import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';

import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

class OBRandevular extends Component {

  state = {
      modal1        : false,
      modal2        : false,
      modal3        : false,
      yuklendi      : false,
      iptal_secim   : '',
      randevular    : [ ],
      randevu       : { }
  }

  componentDidMount = () => {
      document.title = "Onay Bekleyen Randevular - İyi Uzman";
      this.randevular();
  };

  async randevular(){

    this.setState({ yuklendi : false });

    const params = {
        durum : "ONAY_BEKLIYOR"
    }

    const { hata, mesaj, data } = await Api.randevular(params);

    this.setState({ yuklendi : true });

    if(!hata){
        this.setState({ randevular : data.randevular });
    }
  }

  async onayla(randevu){
    const { hata, mesaj } = await Api.randevu_onayla(randevu.id);

    if(!hata){

        this.setState({ randevu,modal1 : true })

        this.randevular();
    }else{
        Main.message(mesaj,hata);
    }
  }

  async reddet(){

    if(this.state.iptal_secim == ''){
        Main.message('Lütfen iptal nedeninizi seçin',true);
        return;
    }

    const iptal_notu = this.state.iptal_secim == 'diger' ? this.state.iptal_notu : this.state.iptal_secim;

    const { hata, mesaj } = await Api.randevu_iptal(this.state.randevu.id,iptal_notu);

    this.setState({ modal3 : false })

    if(!hata){
        this.randevular();
    }

    Main.message(mesaj,hata);
  }

  redModal(randevu){
      this.setState({ modal3 : true,randevu,iptal_secim : '' });
  }

  content(){
      if(!this.state.yuklendi){
          return (
              <PageLoading/>
          )
      }

      return (
        <div className="row">
            <div className="col-md-10">
                <div className="content-card">

                    <div className="table-responsive">
                        <table className="table table-borderless dash mb-0">
                            <thead>
                                <tr>
                                    <th>Tarih - Saat</th>
                                    <th>Müşteri Adı</th>
                                    <th>Açıklama</th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {
                                    this.state.randevular.map( randevu => (
                                        <tr>
                                            <td>{ randevu.randevu_tarihi + ' ' + randevu.randevu_saati }</td>
                                            <td>{ randevu.musteri_adi + ' ' + randevu.musteri_soyadi }</td>
                                            <td>{ randevu.musteri_notlari }</td>
                                            <td>
                                                <Link to={ '/uzman/randevu-detay/' + randevu.id }>
                                                    <button className="btn btn-warning btn-round">Görüntüle</button>
                                                </Link>
                                                <button onClick={ () => this.onayla(randevu) } className="btn green-button ml-2">Onayla</button>
                                                <button onClick={ () => this.redModal(randevu) } className="btn red-button ml-2">Reddet</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                    </div>

                    {
                        this.state.randevular.length < 1 ?
                        <>
                            <div className="text-center p-5">
                                <p className='lead'>Onay bekleyen randevu yok.</p>
                            </div>
                        </>
                        :
                        <> </>
                    }

                </div>
            </div>
        </div>
      )
  }

  render(){


    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>
              
              <div id="content">
                <h4 className="mb-3">Onay Bekleyen Randevular</h4>

                { this.content() }

              </div>

            </div>

            <Footer/>

            <Modal show={ this.state.modal1 } dialogClassName="modal-90w" centered>
                <Modal.Body>
                    <div className="text-right mr-2 mt-2">
                        <i onClick={ () => this.setState({ modal1 : false }) } className="fas fa-times close-popup"></i>
                    </div>
                    <div className="text-center">
                        <center>
                            <Onay/>
                            <h4 className="mt-2">Randevu başarıyla onaylandı</h4>

                            <p className="gray">Randevu Detayları</p>

                            <table>
                                <tr>
                                    <td>
                                        <div className="vertical">
                                            <Tarih/>
                                            <strong className="mt-1 mb-1">Tarih</strong>
                                            <small class="gray">{ this.state.randevu.randevu_tarihi }</small>
                                            {/* <small class="gray">Pazartesi</small> */}
                                        </div>
                                    </td>

                                    <td>
                                        <div className="vertical ml-5 mr-5">
                                            <Saat/>
                                            <strong className="mt-1 mb-1">Saat</strong>
                                            <small class="gray">{ this.state.randevu.randevu_saati }</small>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="vertical">
                                            <Musteri/>
                                            <strong className="mt-1 mb-1">Müşteri</strong>
                                            <small class="gray">
                                                { this.state.randevu.musteri_adi } { this.state.randevu.musteri_soyadi }
                                            </small>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <Link onPress={ () => this.setState({ modal1 : false }) } to={ '/uzman/randevu-detay/' + this.state.randevu.id }>
                                <button className='btn light-button2'>
                                    Randevu Detaylarını Görüntüle
                                </button>
                            </Link>

                        </center>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={ this.state.modal2 } dialogClassName="modal-90w" size="lg" centered>
                <Modal.Body>
                    <div className="text-right mr-2 mt-2">
                        <i onClick={ () => this.setState({ modal2 : false }) } className="fas fa-times close-popup"></i>
                    </div>
                    <h4 className="text-center mb-2">Randevu Detayları</h4>
                    <div id="" className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-6 p-2">
                                    <MusteriBilgileri className="mr-2"/>Müşteri Bilgileri:
                                </div>
                                <div className="col-md-6 p-2 gray">
                                    Zeliha Türk, Kadın, 26Y
                                </div>
                                <div className="col-md-6 p-2">
                                    <IlIlce className="mr-2"/>Hizmet Alınmak İstenen İl/İlçe:
                                </div>
                                <div className="col-md-6 p-2 gray">
                                    Ankara/Çankaya
                                </div>
                                <div className="col-md-6 p-2">
                                    <RandevuTuru className="mr-2"/>Randevu Türü:
                                </div>
                                <div className="col-md-6 p-2 gray">
                                    Klinikte Hizmet
                                </div>
                                <div className="col-md-6 p-2">
                                    <AlmakIstenilenHizmet className="mr-2"/>Almak İstenilen Hizmet:
                                </div>
                                <div className="col-md-6 p-2 gray">
                                    Lorem İpsum
                                </div>
                                <div className="col-md-6 p-2">
                                    <RandevuNotlari className="mr-2"/>Randevu Notları:
                                </div>
                                <div className="col-md-6 p-2 gray">
                                    Lorem İpsum dolar issda
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={ this.state.modal3 } dialogClassName="modal-90w" centered>
                <Modal.Body>
                    <div className="text-right mr-2 mt-2">
                        <i onClick={ () => this.setState({ modal3 : false }) } className="fas fa-times close-popup"></i>
                    </div>
                        <center>
                            <h4 className="mt-2">Randevuyu Reddet</h4>

                            <p className="gray">Randevu Detayları</p>

                            <table>
                                <tr>
                                    <td>
                                        <div className="vertical">
                                            <Tarih/>
                                            <strong className="mt-1 mb-1">Tarih</strong>
                                            <small class="gray">{ this.state.randevu.randevu_tarihi }</small>
                                            {/* <small class="gray">Pazartesi</small> */}
                                        </div>
                                    </td>

                                    <td>
                                        <div className="vertical ml-5 mr-5">
                                            <Saat/>
                                            <strong className="mt-1 mb-1">Saat</strong>
                                            <small class="gray">{ this.state.randevu.randevu_saati }</small>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="vertical">
                                            <Musteri/>
                                            <strong className="mt-1 mb-1">Müşteri</strong>
                                            <small class="gray">
                                                { this.state.randevu.musteri_adi } { this.state.randevu.musteri_soyadi }
                                            </small>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div className='form-group'>
                                <label className='text-danger' htmlFor='iptal_notlari'>İptal Nedeniniz nedir?</label>
                                <select value={ this.state.iptal_secim } onChange={ (e) => this.setState({ iptal_secim : e.target.value }) } className='form-control'>
                                    <option value="" disabled>Seçiniz</option>
                                    <option value="hatali_islem">Hatalı İşlem</option>
                                    <option value="degisen_musaitlik">Artık bu tarih saatte müsait değilim</option>
                                    <option value="acil_durum">Acil bir durum oluştu</option>
                                    <option value="diger">Diğer</option>
                                </select>
                            </div>

                            {
                                this.state.iptal_secim == 'diger' ?
                                <>
                                <div className="form-group">
                                    <label htmlFor="iptal_notu" className="text-danger">Randevuyu neden iptal etmek istediğinizi açıklar mısınız? </label>
                                    <textarea value={ this.state.iptal_notu } onChange={ (e) => this.setState({ iptal_notu : e.target.value }) } id="iptal_notu" rows="3" className="form-control"></textarea>
                                </div>
                                </>
                                : ''
                            }

                            <div className="text-right">
                                <button onClick={ () => this.setState({ modal3 : false }) } className="btn green-button">
                                    Vazgeç
                                </button>
                                <button onClick={ () => this.reddet() } className="btn red-button ml-2">
                                    Gönder
                                </button>
                            </div>

                        </center>

                </Modal.Body>
            </Modal>

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar : state.sidebar
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(OBRandevular))