import img1 from '../../../assets/images/nasil-calisir-1.jpg';
import img2 from '../../../assets/images/nasil-calisir-2.jpg';
import ComputerSearch from '../../../assets/images/computer-search.jpg';
import YakinUzmanlar from '../../../assets/images/yakin-uzmanlar.jpg';

export default function NasilCalisir(){
    return (
        <div className="row mt-5">
            <div className="col-md-6 offset-md-3 text-center">
                <h3>Nasıl Çalışır?</h3>
                <p>Binlerce Uzman Arasından Size En Uygun Uzmandan Kolayca 4 Adımda Randevu Oluşturabilirsiniz.</p>
            </div>

            <div className="col-md-12 mb-4"></div>

            <div className="col-sm mt-3">
                <div className="card step-card">
                    <img src={ img1 } />
                    <div className="card-body">
                        <h5 className="card-title text-center">
                            Almak İstediğiniz Hizmeti Seçin
                        </h5>
                        <p>Almak İstediğiniz Hizmeti & Mesleği veya Uzmanı Arayın.</p>
                    </div>
                </div>
            </div>

            <div className="col-sm mt-3">
                <div className="card step-card">
                    <img src={ YakinUzmanlar } style={{width: '255px', height: '174px', objectFit: 'cover' }} />
                    <div className="card-body">
                        <h5 className="card-title text-center">
                            Size Yakın Uzmanları Filtreleyin
                        </h5>
                        <p>İl, İlçe  ve Hizmet Yeri Seçerek Uzmanları Filtreleyin ve Size En Yakın Uzmanı Seçin.</p>
                    </div>
                </div>
            </div>

            <div className="col-sm mt-3">
                <div className="card step-card">
                    <img src={ img2 } />
                    <div className="card-body">
                        <h5 className="card-title text-center">
                            Randevunuzu Oluşturun
                        </h5>
                        <p>Uzmanın Randevu Takviminden Müsait Bir Zaman Seçin. Randevu Bilgilerini Doldurun ve Randevu İsteğini Gönderin.</p>
                    </div>
                </div>
            </div>

            <div className="col-sm mt-3">
                <div className="card step-card">
                    <img src={ ComputerSearch } style={{width: '255px', height: '174px', objectFit: 'cover' }}/>
                    <div className="card-body">
                        <h5 className="card-title text-center">
                            Randevu Saatinizi Bekleyin
                        </h5>
                        <p>Uzmanınız Randevu İsteğini Onayladıktan Sonra Hazırsınız ve Randevu Saatinde Uzmanınızla Görüşün.</p>
                    </div>
                </div>
            </div>

        </div>
    );
}