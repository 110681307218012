import { ReactComponent as Check } from '../assets/images/check.svg';
import { ReactComponent as Medal } from '../assets/images/medal.svg';
import { ReactComponent as Hospital } from '../assets/images/hospital.svg';
import { ReactComponent as Hospital2 } from '../assets/images/hospital2.svg';
import { ReactComponent as Hediye } from '../assets/images/hediye.svg';
import { ReactComponent as Konum } from '../assets/images/konum.svg';
import { ReactComponent as Call } from '../assets/images/call3.svg';
import { ReactComponent as User } from '../assets/images/user.svg';
import { ReactComponent as Woman } from '../assets/images/woman.svg';
import { ReactComponent as BirthdayCake } from '../assets/images/birthday-cake.svg';
import { ReactComponent as RandevuTuru } from '../assets/images/randevu-turu.svg';
import { ReactComponent as Money } from '../assets/images/money.svg';
import { ReactComponent as Hakkimda } from '../assets/images/hakkimda.svg';
import { ReactComponent as Okullar } from '../assets/images/okullar.svg';
import { ReactComponent as Deneyimler } from '../assets/images/deneyimler.svg';
import { ReactComponent as Tarih } from '../assets/images/tarih-mavi.svg';
import { ReactComponent as Online } from '../assets/images/online.svg';

import { kurumsal_telefon,str_kurumsal_telefon } from './../Config'

import React, { Component } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import PageLoading from './Components/PageLoading';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import Api from '../Functions/Api';
import Main from '../Functions/Main';
import ReactTooltip from 'react-tooltip';

import Viewer from 'react-viewer';

import Tour from 'reactour'

import swal from '@sweetalert/with-react';

class UzmanProfili extends Component {

  state = {
    takvim_focus      : false,
    yuklendi          : false,
    modal1            : false,
    randevu_adim      : 1,
    uzman             : { },
    rt_yuklendi       : true,
    randevu_takvimi   : [ ],
    iller             : [ ],
    ilceler           : [ ],

    s_tarih           : "",
    s_saat            : "",
    s_hizmet          : 0,
    s_hizmet_yeri     : '',
    s_il              : '',
    s_ilce            : '',
    mahalle           : '',
    notlar            : '',
    ad                : '',
    soyad             : '',
    telefon           : '',
    dogrulama_kodu    : '',

    sis_dog_kodu      : '',

    ilk_musait_tarih  : null,
    showAvatar        : false,
    avatar            : '',

    tum_hb_goster     : false,

    sozlesmeler : false,
  }


  componentDidMount = async () => {
    this.uzman_profil_bilgileri(this.props.match.params.id)
    this.randevu_takvimi(this.props.match.params.id)
  };

  async randevu_takvimi(id){

    this.setState({ rt_yuklendi : false })

    const { hata,mesaj,data } = await Api.randevu_takvimi(id);

    if(!hata){
      this.setState({ rt_yuklendi : true,randevu_takvimi : data.randevular })

      let set_ok = false;
      data.randevular.map(takvim => {
        
        if(set_ok) return;

        takvim.saatler.map(saat => {

          if(set_ok) return;

          if(saat.musait){

            let ilk_musait_tarih = takvim.tarih2 + " " + saat.saat;

            this.setState({ ilk_musait_tarih });

            set_ok = true;

            return;
          }
        })

      })

      if(this.props.location.state != undefined){
        const { tarih,saat } = this.props.location.state;

        this.randevuAl(tarih,saat);
      }
    }
  }


  hizmetIlleri(hizmet_bolgeleri){

    let iller = [ ];

    hizmet_bolgeleri.map(x => {
      const il = x.split('-')[0];

      if( iller.filter( x=> x.value == il ).length < 1 ){
        iller.push({
          label : Main.il_adi(il),
          value : il
        })
      }

    });

    return iller;
  }

  hizmetIlceleri(il){
    let ilceler = [ ];
    this.state.uzman.hizmet_bolgeleri.map(x => {
      const x_il = x.split('-')[0];
      const x_ilce = x.split('-')[1];

      if(x_il == il && ilceler.filter(x => x.value == x_ilce).length < 1){
        ilceler.push({
          label : Main.ilce_adi(x_ilce),
          value : x_ilce
        });
      }
    });

    return ilceler;
  }

  hizmet_bolgeleri(){
    let bolgeler = this.state.uzman.hizmet_bolgeleri;

    if(bolgeler.length > 15 && !this.state.tum_hb_goster){
      bolgeler = this.state.uzman.hizmet_bolgeleri.slice(0,15);
    }

    return (
      <div className="mt-2">
        {
          bolgeler.map(bolge => (
            <span class="badge badge-pill badge-success mr-1">{ bolge.toUpperCase() }</span>
          ))
        }

        {
          this.state.uzman.hizmet_bolgeleri.length > 15 ?
          <>
            <span onClick={ () => this.setState({ tum_hb_goster : !this.state.tum_hb_goster }) } class="badge badge-pill badge-dark pointer mr-1">
              { !this.state.tum_hb_goster ? '...devamını göster' : ' daha az göster' }
            </span>
          </>
          :
          <>
          </>
        }

      </div>
    )
  }

  async uzman_profil_bilgileri(id){
    const { hata,mesaj,data } = await Api.uzman_profil_bilgileri(id);

    if(!hata){

      const iller = this.hizmetIlleri(data.hizmet_bolgeleri);

      this.setState({ yuklendi : true,uzman : data,iller });

      this.setState({ avatar : Main.resimURL(data.fotograf) });

      document.title = data.ad + ' ' + data.soyad + ' | ' + ( !data.degerlendirme_seansi_ucretli ? 'Ücretsiz Ön Görüşme | ' : '' ) + Main.il_adi(data.il) + ' - ' + data.meslek_adi + ' Hemen Randevu Al';
      document.querySelector('meta[name="description"]').setAttribute("content", Main.il_adi(data.il) + " " + data.meslek_adi + " " + data.ad + " " + data.soyad  + ( !data.degerlendirme_seansi_ucretli ? " - Ücretsiz Ön Görüşme" : "") + " - Yorumlarını İncele ve Randevu Oluştur - " + (data.evde_hizmet ? 'Evde Hizmet, ' : '') + (data.klinikte_hizmet ? 'Klinikte Hizmet, ' : '') + (data.online_hizmet ? 'Online Hizmet -' : '') + " 0850 305 29 80");
    }else{
      Main.message(mesaj,hata);
    }
  }

  verilen_hizmetler(){

    if(this.state.uzman.verilen_hizmetler.length < 1){
      return (
        <p className="lead text-center">
          { this.state.uzman.ad + ' ' + this.state.uzman.soyad } henüz hizmet listesini doldurmamış!
        </p>
      )
    }

    return (
      this.state.uzman.verilen_hizmetler.map( (hizmet,index) => (
        <p>
          <i className="fas fa-check mr-2"></i>
          { hizmet.hizmet_adi }
        </p>
      ))
    )
  }

  puan(puan,checked){
    return (
        Array.from(Array(puan)).map(x => (
            <span className={ checked ? 'fa fa-star gray checked' : 'fa fa-star gray' }></span>
        ))
    )
  }

  gorusler(){

    if(this.state.uzman.degerlendirmeler.length < 1){
      return (
        <p className="lead text-center">
          { this.state.uzman.ad + ' ' + this.state.uzman.soyad } hakkında henüz hiç kimse görüş bildirmemiş!
        </p>
      )
    }

    return (
      <div className="row">
        {
          this.state.uzman.degerlendirmeler.map(d => (
            <div className="col-md-12 border-bottom pt-4">
              <p>
                <User className="mr-2" />
                Hizmet Alan Bir Müşteri
              </p>
              <p>
                { this.puan(d.puan,true) }
                { this.puan(5 - d.puan) }
              </p>
              <p className="mb-0">{ d.yorum }</p>
              <small className="gray"> { d.olusturulma_tarihi } </small>
            </div>
          ))
        }
      </div>
    )
  }

  bloglar(){

    if(this.state.uzman.bloglar.length < 1){
      return (
        <p className="lead text-center">
          { this.state.uzman.ad + ' ' + this.state.uzman.soyad } henüz hiç blog yazmamış!
        </p>
      )
    }

    return (
      this.state.uzman.bloglar.map(blog => (
        <div className="row pt-4 border-bottom">
          <div className="col-md-3 text-center">
            <img src={ blog.kapak_resmi } className="img-fluid" />
          </div>
          <div className="col-md-9">
            <div className="col-md-12">
              <Tarih className="mb-1 mr-2"/>
              <span>{ blog.olusturulma_tarihi }</span>
            </div>
            <div className="col-md-12">
              <b>{ blog.baslik }</b>
              <br />
              <Link to={ '/blog/' + blog.id }>
                <button className="btn green-button mt-2">
                  Bloğu Oku
                </button>
              </Link>
            </div>
          </div>
        </div>
      ))
    )
  }

  randevuAl(tarih,saat){
    this.setState({ takvim_focus : false })
    this.setState({ s_tarih : tarih, s_saat : saat,modal1 : true })
  }

  randevu_saatleri(){

    return (
      <tr>
        {
          this.state.randevu_takvimi.map( (takvim) => (
          <td>
            {
              takvim.saatler.map(saat => (
                saat.musait ? <button onClick={ () => this.randevuAl(takvim.tarih,saat.saat) } className='btn light-button2 bcs'>{ saat.saat }</button> :<button className='btn light-button2 bcs button-disabled'>{ saat.saat }</button>
              ))
            }
          <br />
          </td>))
        }
      </tr>
    )
  }

  btnRandevuAl(){
    this.setState({ takvim_focus : true })
  }

  content() {

    if (!this.state.yuklendi) {
      return (
        <PageLoading />
      )
    }

    const { il,meslek,ad,soyad } = this.props.match.params;

    return (
      <div className="row">
        
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">

              <li class="breadcrumb-item">
                <Link to={ '/' }>
                  <span className="text-dark">Anasayfa</span>
                </Link>
              </li>
              
              <li class="breadcrumb-item">
                <Link to={ '/ara/' + il + '-hepsi/' + Main.slug(this.state.uzman.meslek_adi) + '?mid=' + this.state.uzman.meslek_id }>
                  <span className="text-dark">Ara</span>
                  <span className="text-dark"> - { Main.ozelYazdir(Main.il_adi(il)) }</span>
                  <span className="text-dark"> - { this.state.uzman.meslek_adi }</span>
                </Link>
              </li>

              <li class="breadcrumb-item active">
                { this.state.uzman.ad + ' ' + this.state.uzman.soyad }
              </li>


            </ol>
          </nav>
        </div>

        <div className="col-md-7">
          <div className="card border-radius-10 pt-5 pb-2 px-3">
            <div className="row">
              <div className="col-md-3">
                <img onClick={ () => this.setState({ showAvatar : true }) } className="uzman-ara-fotograf pointer" src={ Main.resimURL(this.state.uzman.fotograf) } />
              </div>
              <div className="col-md-9">
                <h5>
                  <span>{ this.state.uzman.meslek_unvani }</span>
                  <p>{ this.state.uzman.ad + ' ' + this.state.uzman.soyad } <Check /></p>
                </h5>
                <p className="gray mb-1">{ this.state.uzman.meslek_adi }</p>
                
                { this.puan(this.state.uzman.puan,true) }
                { this.puan(5-this.state.uzman.puan,false) }
                <a href={'/degerlendirmeler/' + this.state.uzman.id}><small className="gray ml-2">{ this.state.uzman.toplam_degerlendirme } Görüş</small></a>
                <p className='mt-2'>
                    {/* <span>
                    { 
                        this.state.uzman.basari_orani == -1 ? 
                        <>
                            Bu uzmandan daha önce hiç randevu alınmamış.
                        </>
                        :
                        <>%{this.state.uzman.basari_orani} başarı oranı</>
                    }
                    </span> */}
                </p>
                
                <div className="row mt-3">
                  <div className="col-md-12">
                    <Konum />
                    <span className="ml-1">
                      Hizmet Bölgeleri
                    </span>
                    { this.hizmet_bolgeleri() }
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <a href={ "tel:" + kurumsal_telefon } className="btn light-button2 btn-block">
                      { str_kurumsal_telefon }
                    </a>
                  </div>
                  <div className="col-md-6">
                    <button onClick={ () => this.btnRandevuAl() } className="btn green-button btn-block">Randevu Al</button>
                  </div>
                </div>

                <div className="row mt-3 d-none">
                  <div className="col-md-6">
                    <button className="btn light-button2 text-dark btn-block p-2"><Call /> 0850 000 00 00</button>
                  </div>
                  <div className="col-md-6">
                    <button className="btn light-button2 text-dark btn-block p-2">Sizi Arayalım</button>
                  </div>
                </div>

                {
                  this.state.ilk_musait_tarih != null ?
                  <>
                  <p className="mt-3 mb-0 text-right"> Müsait olan en yakın tarih
                  </p>
                  <p className="text-danger text-right mt-2 mb-0">{ this.state.ilk_musait_tarih }</p>
                  </>
                  : ''
                }

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-3">
              <ul className="nav nav-tabs" id="profil-tab" role="tablist">
                <li className="nav-item ml-3">
                  <a className="nav-link active" data-toggle="tab" href="#genel" role="tab" aria-controls="genel" aria-selected="true">Genel</a>
                </li>
                <li className="nav-item ml-3">
                  <a className="nav-link" data-toggle="tab" href="#hizmetler" role="tab" aria-controls="hizmetler" aria-selected="false">Hizmetler</a>
                </li>
                <li className="nav-item ml-3">
                  <a className="nav-link" data-toggle="tab" href="#ozgecmis" role="tab" aria-controls="ozgecmis" aria-selected="false">Özgeçmiş</a>
                </li>
                <li className="nav-item ml-3">
                  <a className="nav-link" data-toggle="tab" href="#gorusler" role="tab" aria-controls="gorusler" aria-selected="false">Görüşler</a>
                </li>
                <li className="nav-item ml-3">
                  <a className="nav-link" data-toggle="tab" href="#bloglar" role="tab" aria-controls="bloglar" aria-selected="false">Bloglar</a>
                </li>
              </ul>

              <div className="tab-content" id="profil-tab-content">
                <div className="tab-pane fade show active" id="genel" role="tabpanel" aria-labelledby="genel-tab">

                  {/* Genel */}
                  <div className="card mt-2">
                    <div className="card-header bg-light-green">
                      <h5 className="ml-3 mt-2">Genel</h5>
                    </div>
                    <div className="card-body px-5">
                      <ReactTooltip />
                      <p className="gray"><User className="mr-2" /> { this.state.uzman.ad + ' ' + this.state.uzman.soyad } </p>
                      <p className="gray"><Woman className="mr-2" /> { this.state.uzman.cinsiyet }</p>
                      <p className="gray">
                        <RandevuTuru className="mr-2" />
                        { this.state.uzman.evde_hizmet ? 'Evde Hizmet, ' : '' }
                        { this.state.uzman.klinikte_hizmet ? 'Klinikte Hizmet, ' : '' }
                        { this.state.uzman.online_hizmet ? 'Online Hizmet' : '' }
                      </p>
                      <p className="gray"><Konum className="mr-2" /> { Main.il_adi(this.state.uzman.il) + ' / ' + Main.ilce_adi(this.state.uzman.ilce) } </p>
                      <p className="gray"><Money className="mr-2" /> Nakit, Kredi kartı, EFT, Havale</p>

                      {
                        this.state.uzman.profilde_fiyat_goster ?
                        <>
                          {
                              this.state.uzman.evde_hizmet ?
                              <>
                              <Hospital2 width={ 24 } height={ 24 } className="mb-2"/>
                              <span className="gray ml-1 mr-1">Evde Hizmet</span>
                              <span> { this.state.uzman.evde_hizmet_taban_fiyat + '₺ - ' + this.state.uzman.evde_hizmet_tavan_fiyat + '₺' } </span>
                              <small className="gray"> / Seans</small>
                              <br/>
                              </> : ''
                          }

                          {
                              this.state.uzman.klinikte_hizmet ?
                              <>
                              <Hospital width={ 24 } height={ 24 } className="mb-2"/>
                              <span className="gray ml-1 mr-1">Klinikte Hizmet</span>
                              <span> { this.state.uzman.klinikte_hizmet_taban_fiyat + '₺ - ' + this.state.uzman.klinikte_hizmet_tavan_fiyat + '₺' } </span>
                              <small className="gray"> / Seans</small>
                              <br />
                              </> : ''
                          }

                          {
                              this.state.uzman.online_hizmet ?
                              <>
                              <Online width={ 24 } height={ 24 } className="mb-2"/>
                              <span className="gray ml-1 mr-1">Online Hizmet</span>
                              <span> { this.state.uzman.online_hizmet_taban_fiyat + '₺ - ' + this.state.uzman.online_hizmet_tavan_fiyat + '₺' } </span>
                              <small className="gray"> / Seans</small>
                              </> : ''
                          }
                          <br />
                        </>
                        :
                        <></>
                      }
                      
                      <span>
                        <Hediye className="mr-1"/>
                        { this.state.uzman.degerlendirme_seansi_ucretli ? 'Değerlendirme seansı ücretlidir.' : 'Değerlendirme seansı ücretsizdir.' }
                      </span>
                    </div>
                  </div>

                </div>

                <div className="tab-pane fade" id="hizmetler" role="tabpanel" aria-labelledby="hizmetler-tab">
                  <div className="card mt-2">
                    <div className="card-header bg-light-green">
                      <h5 className="ml-3 mt-2">Hizmetler</h5>
                    </div>
                    <div className="card-body px-5">
                      { this.verilen_hizmetler() }
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="ozgecmis" role="tabpanel" aria-labelledby="ozgecmis-tab">
                  <div className="card mt-2">
                    <div className="card-header bg-light-green">
                      <h5 className="ml-3 mt-2">Özgeçmiş</h5>
                    </div>
                    <div className="card-body px-5 py-5">
                      <div className="row">
                        <div className="col-md-1">
                          <Hakkimda />
                        </div>
                        <div className="col-md-11 pl-0">
                          <b>Hakkımda</b>
                          <p className="gray">{ this.state.uzman.ozgecmis }</p>
                        </div>
                        <div className="col-md-1">
                          <Okullar />
                        </div>
                        <div className="col-md-11 pl-0">
                          <b>Okullar / Eğitimler</b>
                          <p className="gray">{ this.state.uzman.okul }</p>
                        </div>
                        <div className="col-md-1">
                          <Deneyimler />
                        </div>
                        <div className="col-md-11 pl-0">
                          <b>Deneyimler / Tecrübeler</b>
                          <p className="gray">{ this.state.uzman.tecrubeler }</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="gorusler" role="tabpanel" aria-labelledby="gorusler-tab">
                  <div className="card mt-2">
                    <div className="card-header bg-light-green">
                      <h5 className="ml-3 mt-2">Görüşler</h5>
                    </div>
                    <div className="card-body px-5 py-4">
                      
                      { this.gorusler() }

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="bloglar" role="tabpanel" aria-labelledby="bloglar-tab">
                  <div className="card mt-2">
                    <div className="card-header bg-light-green">
                      <h5 className="ml-3 mt-2">Bloglar</h5>
                    </div>
                    <div className="card-body px-5 py-4">

                      { this.bloglar() }
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="col-md-5">
          <div id="takvim" className="card">
            <div className="card-header bg-light-green">
              <h5 className="ml-3 mt-2 text-green">Randevu Takvimi</h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {
                  this.state.randevu_takvimi.filter( x => x.saatler.length > 0 ).length > 0 ?
                  <>
                  <table className="table table-sm table-borderless text-center">
                    <thead>
                      <tr>
                        {
                          this.state.randevu_takvimi.map(takvim => (
                            <th className="th-randevu-takvimi" style={{whiteSpace:'nowrap'}}>{ takvim.tarih3 }</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      { this.randevu_saatleri() }
                    </tbody>
                  </table>
                  </>
                  :
                  <>
                    <p className='text-center'>Bu uzman şu anda randevu kabul etmiyor.</p>
                  </>
                }
              </div>
            </div>
          </div>
        </div>



        <Modal show={ this.state.modal1 } dialogClassName="modal-90w" centered>
          <Modal.Body className="px-5 py-4">

            <div className="text-right mr-2 mt-2">
                <i onClick={ () => this.setState({ modal1 : false }) } className="fas fa-times close-popup"></i>
            </div>

            <h3>Randevu Oluştur</h3>

            <br />
            
            {
              this.state.randevu_adim == 1 ? this.randevu_adim1()
              :
              this.state.randevu_adim == 2 ? this.randevu_adim2()
              :
              this.state.randevu_adim == 3 ? this.randevu_adim3()
              :
              this.randevu_adim4()
            }

          </Modal.Body>
        </Modal>




      </div>
    )
  }

  geri(){

    if(this.state.randevu_adim <= 1) return;
    
    let adim = this.state.randevu_adim;

    adim--;
    
    if(adim == 2 && this.state.s_hizmet_yeri != 'evde_hizmet'){
      adim--;
    }

    this.setState({
      randevu_adim : adim
    })
  }

  adim1_validation(){

    if(this.state.s_hizmet < 1){
      Main.message("Lütfen bir hizmet seçimi yapın!",true);
      return false;
    }

    if(this.state.s_hizmet_yeri == ''){
      Main.message("Lütfen bir hizmet yeri seçin!",true);
      return false;
    }
    return true;
  }

  adim2_validation(){
    if(this.state.s_il == ''){
      Main.message("Lütfen hizmet almak istediğiniz ili seçin!",true);
      return false;
    }

    if(this.state.s_ilce == ''){
      Main.message("Lütfen hizmet almak istediğiniz ilçeyi seçin!",true);
      return false;
    }

    if(this.state.mahalle == ''){
      Main.message("Lütfen hizmet almak istediğiniz mahalleyi girin!",true);
      return false;
    }

    return true;
  }

  async adim3_validation(){
    if(this.state.ad == ''){
      Main.message("Lütfen adınızı girin!",true);
      return false;
    }

    if(this.state.soyad == ''){
      Main.message("Lütfen soyadınızı girin!",true);
      return false;
    }

    if(this.state.telefon == ''){
      Main.message("Lütfen telefon numaranızı girin!",true);
      return false;
    }

    if(this.props.user.rol != undefined && this.props.user.rol == 'ADMIN'){
      this.setState({ sis_dog_kodu : "0101" });
      return true;
    }else{
      const { hata,mesaj,data } = await Api.randevu_dogrulama_kodu_gonder(this.state.telefon);

      if(!hata){
        this.setState({ sis_dog_kodu : data.kod });
        return true;
      }else{
        Main.message(mesaj,hata);
      }
    }

    return false;
  }

  async devam_et(){

    switch(this.state.randevu_adim){
      case 1 : {
        if(!this.adim1_validation()){
          return;
        }
        break;
      }
      case 2 : {
        if(!this.adim2_validation()){
          return;
        }
        break;
      }
      case 3 : {
        const devam = await this.adim3_validation();
        if(!devam){
          return;
        }
      }
    }

    let adim = this.state.randevu_adim;

    adim++;
    
    if(adim == 2 && this.state.s_hizmet_yeri != 'evde_hizmet'){
      adim++;
    }

    this.setState({
      randevu_adim : adim
    })
  }

  async randevu_onayla(){

    if(!this.state.sozlesmeler){
        swal("Oops!","Randevu oluşturabilmek için kullanım şartnamesini kabul etmelisiniz!","error");
        return;
    }

    let randevu_turu = 'EVDE'; // EVDE - KLINIKTE - ONLINE

    if(this.state.s_hizmet_yeri == 'evde_hizmet'){
      randevu_turu = 'EVDE';
    }else if(this.state.s_hizmet_yeri == 'klinikte_hizmet'){
      randevu_turu = 'KLINIKTE';
    }else if(this.state.s_hizmet_yeri == 'online_hizmet'){
      randevu_turu = 'ONLINE';
    }else{
      Main.message("Geçersiz hizmet yeri!",true);
      return;
    }

    const params = {
      kullanici_id    : this.state.uzman.id,
      hizmet_id       : this.state.s_hizmet,
      musteri_adi     : this.state.ad,
      musteri_soyadi  : this.state.soyad,
      musteri_telefon : this.state.telefon,
      il              : this.state.s_il,
      ilce            : this.state.s_ilce,
      mahalle         : this.state.mahalle,
      musteri_notlari : this.state.notlar,
      randevu_tarihi  : this.state.s_tarih,
      randevu_saati   : this.state.s_saat,
      randevu_turu    : randevu_turu,
      dogrulama_kodu  : this.state.dogrulama_kodu
    }

    const { hata,mesaj,data } = await Api.randevu_olustur(params);

    Main.message(mesaj,hata);

    if(!hata){

      this.randevu_takvimi(this.state.uzman.id);

      this.setState({
        randevu_adim : 1,
        modal1 : false,
        s_tarih : "",
        s_saat : "",
        s_hizmet : "",
        s_hizmet_yeri : "",
        s_il : "",
        s_ilce : "",
        mahalle : "",
        notlar : "",
        ad : "",
        soyad : "",
        telefon : "",
        dogrulama_kodu : "",
      })
    }

  }

  setHizmet(s_hizmet){
    this.setState({ s_hizmet })
  }

  setHizmetYeri(s_hizmet_yeri){
    this.setState({ s_hizmet_yeri })
  }

  hizmetAdi(){
    return this.state.uzman.verilen_hizmetler.filter(x => x.hizmet_id == this.state.s_hizmet)[0].hizmet_adi;
  }

  setIl(il){
    this.setState({ s_il : il, ilce : '', ilceler : this.hizmetIlceleri(il) });
  }

  setIlce(ilce){
    this.setState({ s_ilce : ilce });
  }

  randevu_adim1(){
    return (
      <div>
        <div className="form-group mt-3">
              <p className="lead">Almak istediğiniz hizmet</p>
              <select value={ this.state.s_hizmet } onChange={ (e) => this.setHizmet(e.target.value) } id='hizmet' type="text" className="form-control" defaultValue="0">
                <option value="0" disabled>Seçim yapın</option>
                {
                  this.state.uzman.verilen_hizmetler.map(hizmet => (
                    <option value={ hizmet.hizmet_id }> { hizmet.hizmet_adi } </option>
                  ))
                }
              </select>
            </div>

            <div>

              <p className="lead">Hizmet almak istediğiniz yer</p>
                
                {
                  this.state.uzman.evde_hizmet ?
                  <>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input onClick={ () => this.setHizmetYeri('evde_hizmet') } type="radio" name="randevu_turu" id="evde-hizmet" className="custom-control-input" checked={ this.state.s_hizmet_yeri == 'evde_hizmet' ? true : false } />
                    <label className="custom-control-label" htmlFor="evde-hizmet">Evde Hizmet</label>
                  </div>
                  </> : ''
                }
                
                {
                  this.state.uzman.klinikte_hizmet ?
                  <>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input onClick={ () => this.setHizmetYeri('klinikte_hizmet') } type="radio" name="randevu_turu" id="klinikte-hizmet" className="custom-control-input" checked={ this.state.s_hizmet_yeri == 'klinikte_hizmet' ? true : false } />
                    <label className="custom-control-label" htmlFor="klinikte-hizmet">Klinikte Hizmet</label>
                  </div>
                  </>
                  : ''
                }

                {
                  this.state.uzman.online_hizmet ?
                  <>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input onClick={ () => this.setHizmetYeri('online_hizmet') } type="radio" name="randevu_turu" id="online-hizmet" className="custom-control-input" checked={ this.state.s_hizmet_yeri == 'online_hizmet' ? true : false } />
                    <label className="custom-control-label" htmlFor="online-hizmet">Online Hizmet</label>
                  </div>
                  </>
                  : ''
                }

                {
                  this.state.s_hizmet_yeri == 'klinikte_hizmet' ?
                  <>
                  <div className='alert alert-danger mt-3'>
                    Uzmanın Klinik Adresi
                    <p className='mb-0'>
                      { this.state.uzman.klinik_adresi }
                    </p>
                  </div>
                  </> : ''
                }

            </div>

            <div className="text-right mt-3">
              <button onClick={ () => this.devam_et() } className="btn green-button" style={ { width:100 } }>
                Devam Et
              </button>
            </div>
      </div>
    )
  }

  randevu_adim2(){
    return (
      <div>

        <div className="form-group">
          <label htmlFor="il">Randevu Oluşturulacak İl</label>
          <select value={ this.state.s_il } id="il" defaultValue="" onChange={ (e) => this.setIl(e.target.value) } type="text" className="form-control">
            <option value="" disabled>Seçiniz</option>
            { this.state.iller.map(il => (
              <option value={ il.value }>{ il.label }</option>
            )) }
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="ilce">Randevu Oluşturulacak İlçe</label>
          <select defaultValue="" value={ this.state.s_ilce } disabled={ this.state.ilceler.length < 1 ? true : false } onChange={ (e) => this.setIlce(e.target.value) } type="text" id="ilce" className="form-control">
            <option value="">Seçiniz</option>
            { this.state.ilceler.map(ilce => (
              <option value={ ilce.value }>{ ilce.label }</option>
            )) }
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="mahalle">Randevu Oluşturulacak Mahalle</label>
          <input i value={ this.state.mahalle } onChange={ (e) => this.setState({ mahalle : e.target.value }) }d="mahalle" name="mahalle" type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label htmlFor="notlar">Uzmana eklemek istediğiniz notlar</label>
          <textarea value={ this.state.notlar } onChange={ (e) => this.setState({ notlar : e.target.value }) } id="notlar" name="notlar" type="text" className="form-control"/>
        </div>
        
        <div className="row">
          <div className="col">
           <button onClick={ () => this.geri() } className="btn light-button2" style={ { width:100 } }>
                Geri
            </button>
          </div>
          <div className="col text-right">
            <button onClick={ () => this.devam_et() } className="btn green-button" style={ { width:100 } }>
                Devam Et
            </button>
          </div>
        </div>

      </div>
    )
  }

  randevu_adim3(){
    return (
      <div>
        
        <div className="form-group">
          <label htmlFor="ad">Adınız</label>
          <input value={ this.state.ad } onChange={ (e) => this.setState({ ad : e.target.value }) } id="ad" name="ad" type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label htmlFor="soyad">Soyadınız</label>
          <input value={ this.state.soyad } onChange={ (e) => this.setState({ soyad : e.target.value }) } id="soyad" name="soyad" type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label htmlFor="telefon">Telefon Numaranız</label>
          <input value={ this.state.telefon } onChange={ (e) => this.setState({ telefon : e.target.value }) } id="telefon" name="telefon" type="text" className="form-control" />
        </div>

        {
          this.state.s_hizmet_yeri == 'online_hizmet' || this.state.s_hizmet_yeri == 'klinikte_hizmet' ?
          <>
            <div className="form-group">
              <label htmlFor="notlar">Uzmana eklemek istediğiniz notlar</label>
              <textarea value={ this.state.notlar } onChange={ (e) => this.setState({ notlar : e.target.value }) } id="notlar" name="notlar" type="text" className="form-control"/>
            </div>
          </>
          :
          <>
          </>
        }

        <div className="alert alert-warning">
          <strong>NOT : </strong> Sonraki adımda telefon numaranıza bir sms kodu göndereceğiz lütfen erişiminiz olan bir telefon numarası girin.
        </div>      
        
        <div className="row">
          <div className="col">
           <button onClick={ () => this.geri() } className="btn light-button2" style={ { width:100 } }>
                Geri
            </button>
          </div>
          <div className="col text-right">
            <button onClick={ () => this.devam_et() } className="btn green-button" style={ { width:100 } }>
                Devam Et
            </button>
          </div>
        </div>

      </div>
    )
  }

  randevu_adim4(){
    return (
      <div>
        
        <table>
          <tr>
            <td>Ad</td>
            <td className="px-2">:</td>
            <td>{ this.state.ad }</td>
          </tr>
          <tr>
            <td>Soyad</td>
            <td className="px-2">:</td>
            <td>{ this.state.soyad }</td>
          </tr>
          <tr>
            <td>Telefon</td>
            <td className="px-2">:</td>
            <td>{ this.state.telefon }</td>
          </tr>
          <tr>
            <td>Alınacak Hizmet</td>
            <td className="px-2">:</td>
            <td>{ this.hizmetAdi() }</td>
          </tr>
          <tr>
            <td>Hizmet Türü</td>
            <td className="px-2">:</td>
            <td>
              {
                this.state.s_hizmet_yeri == 'evde_hizmet' ? "Evde Hizmet" :
                this.state.s_hizmet_yeri == 'klinikte_hizmet' ? "Klinikte Hizmet" : "Online Hizmet"
              }
            </td>
          </tr>
          {
            this.state.s_hizmet_yeri == 'evde_hizmet' ?
            <>
            <tr>
              <td>Randevu Adresi</td>
              <td className="px-2">:</td>
              <td>
                { Main.il_adi(this.state.s_il) + ' / ' + Main.ilce_adi(this.state.s_ilce) } { this.state.mahalle }
              </td>
            </tr>
            </>
            :
            <>
            </>
          }
          <tr>
            <td>Randevu Tarihi</td>
            <td className="px-2">:</td>
            <td>{ this.state.s_tarih }</td>
          </tr>
          <tr>
            <td>Randevu Saati</td>
            <td className="px-2">:</td>
            <td>{ this.state.s_saat }</td>
          </tr>
        </table>

        <div className="form-group mt-3">
          <label htmlFor="dogrulama_kodu">
            Telefonunuza gelen doğrulama kodunu girin
            {
              (this.props.user.rol != undefined && this.props.user.rol == 'ADMIN') ?
              <>
                <br/> <strong className="text-danger"> KOD : { this.state.sis_dog_kodu }</strong>
              </>
              : ''
            }
          </label>
          <input onChange={ (e) => this.setState({ dogrulama_kodu : e.target.value }) } value={ this.state.dogrulama_kodu } type="text" className="form-control text-center dogrulama-kodu"/>
        </div>

        <div class="custom-control custom-checkbox">
            <input onClick={ (e) => this.setState({ sozlesmeler : e.target.checked }) } value={ this.state.checkbox } type="checkbox" class="custom-control-input" id="sozlesmeler" checked={ this.state.sozlesmeler }/>
            <label class="custom-control-label" htmlFor="sozlesmeler">Site <a href="#">Kullanım Şartnamesini</a> ve <a href="#">Kişisel Veri Gizlilik Politikası ve Aydınlatma Metni</a> 'ni kabul ediyorum. </label>
        </div>
        
        <div className="row">
          <div className="col">
           <button onClick={ () => this.geri() } className="btn light-button2 d-none" style={ { width:100 } }>
                Geri
            </button>
          </div>
          <div className="col text-right">
            <button onClick={ () => this.randevu_onayla() } className="btn green-button" style={ { width:200 } }>
                Randevuyu Onayla
            </button>
          </div>
        </div>

      </div>
    )
  }

  render() {

    const steps = [
      {
        selector: '#takvim',
        content: 'İşte ' + this.state.uzman.ad + ' ' + this.state.uzman.soyad + "'ın randevu takvimi, sana müsait olan bir saat belirle ve randevunu oluştur.",
      },
    ]

    return (
      <div>

        <ActionBar/>
        <ActionBarMobile/>

        <NavBar className="bg-green" />

        <div className="wrapper">
          <div id="content">
            <div className="container mt-2">

            <Tour
            showButtons={ false }
            showNavigation={ false }
            showNumber={ false }
            onRequestClose={ () => this.setState({ takvim_focus : false }) }
            isOpen={ this.state.takvim_focus }
            steps={ steps }/>
            
            <Viewer
              onClose={ () => this.setState({ showAvatar : false }) }
              disableMouseZoom={ true }
              rotatable={ false }
              scalable={ false }
              zoomable={ false }
              noFooter={ true }
              noImgDetails={ true }
              noNavbar={ true }
              drag={ false }
              noToolbar={ true }
              visible={ this.state.showAvatar }
              images={ [ {src: this.state.avatar, alt: 'avatar'} ] }
            />
            
            { this.content() }
            </div>
          </div>

        </div>

        <Footer />

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UzmanProfili))