import HakkimizdaGorsel from '../assets/images/hakkimizda-gorsel.png';
import img1 from '../assets/images/nasil-calisir-1.jpg';
import img2 from '../assets/images/nasil-calisir-2.jpg';

import React, { Component } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import PageLoading from './Components/PageLoading';

import * as actionTypes from '../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../Functions/Api';

class Hakkimizda extends Component {

  state = {
    yuklendi : false,
  }


  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-6" style={{paddingTop: '100px'}}>
                    <h2>Hakkımızda</h2>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett</p>
                </div>
                <div className="col-md-6 text-center">
                    <img className="img-fluid" src={HakkimizdaGorsel} />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="card p-5 border-radius-10">
                        <h4 className="text-green">Misyonumuz</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett...”</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-5 border-radius-10">
                        <h4 className="text-green">Vizyonumuz</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett...”</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-5 border-radius-10">
                        <h4 className="text-green">Felsefemiz</h4>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett...”</p>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-6 offset-md-3 text-center">
                    <h3>Nasıl Çalışır?</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                </div>

                <div className="col-md-12 mb-4"></div>

                <div className="col-md-3 mt-3">
                    <div className="card step-card">
                        <img src={ img1 } />
                        <div className="card-body">
                            <h5 className="card-title text-center">
                                Hizmet Almak İstediğiniz Türü Arayın
                            </h5>
                            <p>181 000'den fazla doktor ve uzman arasından seçim yapın. Diğer hastaların görüşlerini inceleyin.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mt-3">
                    <div className="card step-card">   
                        <img src={ img1 } />
                        <div className="card-body">
                            <h5 className="card-title text-center">
                                Hizmet Almak İstediğiniz Türü Arayın
                            </h5>
                            <p>181 000'den fazla doktor ve uzman arasından seçim yapın. Diğer hastaların görüşlerini inceleyin.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mt-3">
                    <div className="card step-card">
                        <img src={ img2 } />
                        <div className="card-body">
                            <h5 className="card-title text-center">
                                Takvim
                            </h5>
                            <p>181 000'den fazla doktor ve uzman arasından seçim yapın. Diğer hastaların görüşlerini inceleyin.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mt-3">
                    <div className="card step-card">
                        <img src={ img1 } />
                        <div className="card-body">
                            <h5 className="card-title text-center">
                                Hizmet Almak İstediğiniz Türü Arayın
                            </h5>
                            <p>181 000'den fazla doktor ve uzman arasından seçim yapın. Diğer hastaların görüşlerini inceleyin.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      )
  }

  bottomDiv(){
      return(
        <div className="container">
            <div className="row mt-5">
        <div className="col-md-4">
            <div className="card p-5 border-radius-10">
                <h4 className="text-green">Hastalar için</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett...”</p>
            </div>
        </div>
        <div className="col-md-4">
            <div className="card p-5 border-radius-10">
                <h4 className="text-green">Uzmanlar için</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett...”</p>
            </div>
        </div>
        <div className="col-md-4">
            <div className="card p-5 border-radius-10">
                <h4 className="text-green">Klinikler için</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore eLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore ett...”</p>
            </div>
        </div>
        </div>
        </div>
      )
  }

  render(){
    return (
        <div>

            <ActionBar/>
            <ActionBarMobile/>
            <NavBar className="bg-green"/>

            <div className="wrapper hakkimizda-div">
              <div id="content">
                <div className="container mt-5">
                  { this.content() }
                </div>
                <div className="bottom-div">
                    { this.bottomDiv() }
                </div>
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Hakkimizda))