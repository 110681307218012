import Fizyoterapi from '../assets/images/fizyoterapi.png';
import Dalga from '../assets/images/dalga.png'
import Uzman from '../assets/images/uzman.jpg'
import Uzman2 from '../assets/images/uzman2.jpg'

import React, { Component } from 'react';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import PageLoading from './Components/PageLoading';

import * as actionTypes from '../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

class Kurumsal extends Component {

  state = {
    yuklendi : false,
  }


  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-12">

            <div className="row bg-green2">
                <div className="container">
                    
                    <div className="row">

                        <div className="col-md-6 p-5 justify-content-center align-self-center">
                            <h3>Ücretsiz profil oluşturun.</h3>
                            <h3>Gerisini bize bırakın!</h3>

                            <ul className='p-3'>
                                <li>Profil oluşturmak tamamen ücretsizdir!</li>
                                <li>7/24 online olarak randevu alabilirsiniz</li>
                                <li>Sms ile randevularınızı hatırlatıyoruz</li>
                                <li>Çağrı merkezi desteği</li>
                                <li>Fizyoterapistler için özelleşmiş yazılım</li>
                            </ul>

                            <h5>Türkiye’nin en büyük Fizyoterapist platformunda yerinizi alın…</h5>

                            <button className="btn btn-green">Ücretsiz Üyelik</button>
                        </div>

                        <div className="col-md-6">
                            <img className='img-fluid' src={Fizyoterapi} />
                        </div>

                    </div>

                </div>
            </div>

            {/* Statics */}
            <div className="row bg-white">
                <div className="container mt-5">
                    
                    <div className="row">

                        <div className="col-md-4 text-center">
                            <i className="fa fa-users fa-2x text-green-kurumsal"></i> <h5>100 Bin</h5>
                            <p>Aylık Tekil Ziyaretçi</p>
                        </div>
                        <div className="col-md-4 text-center">
                            <i className="fa fa-user-plus fa-2x text-green-kurumsal"></i> <h5>1000’den Fazla</h5>
                            <p>Üye Fizyoterapist Sayısı</p>
                        </div>
                        <div className="col-md-4 text-center">
                            <i className="fas fa-comments fa-2x text-green-kurumsal"></i> <h5>1000+ Yorum</h5>
                            <p>Hasta/Danışan Değerlendirmesi</p>
                        </div>
                    
                    </div>

                </div>
            </div>

            <div className="row bg-white">
                <div className="container">
                    <hr />
                </div>
            </div>


            {/* Daha fazla hasta ulaş */}

            <div className="row bg-white">
                <div className="container mt-4">
                    
                    <div className="row">

                        <div className="col-md-12 text-center mb-4">
                            <h3>Daha Fazla Hastaya/Danışana Ulaşın</h3>
                            <p className='text-info'>100 Bin+ Ziyaretçi Potansiyelini Kullanın!</p>
                            {/* <p className='mt-4'><img src={Dalga} width={50} /><img style={{ margin: 0, padding: 0, left: 0 }} src={Dalga} width={50} /><img src={Dalga} width={50} /><img src={Dalga} width={50} /></p> */}
                        </div>

                   
             
                            <div className="col-md-3 text-center">
                                <i className="fa fa-user-circle fa-4x text-green-kurumsal mb-3"></i>
                                <h5>Özel Profil Sayfası</h5>
                                <p>Hakkınızdaki tüm bilgileri hastalarınıza veya danışanlarınıza kolayca sunun.</p>
                            </div>
                            <div className="col-md-3  text-center">
                                <i className="fa fa-phone fa-4x text-green-kurumsal mb-3"></i>
                                <h5>Çağrı Merkezi</h5>
                                <p>Gelen aramalar çağrı merkezi desteği ile yönetilsin! Bilgi ve randevu alma talepleri karşılansın.</p>
                            </div>
                            <div className="col-md-3  text-center">
                                <i className="fa fa-calendar-alt fa-4x text-green-kurumsal mb-3"></i>
                                <h5>Randevu Takvimi</h5>
                                <p>Müsait saatlerinize göre hastalarınız/danışanlarınız kolaylıkla online randevu oluşturabilsin.</p>
                            </div>
                            <div className="col-md-3  text-center">
                                <i className="fa fa-comments fa-4x text-green-kurumsal mb-3"></i>
                                <h5>Soru-Cevap</h5>
                                <p>Hastalarınız veya danışanlarınızla doğrudan iletişime geçin. Online etkileşimde bulunun.</p>
                            </div>

                            <div className="col-md-12 text-center mt-4">
                                <p className="text-info">
                                    Fizyoterapistlere sağladığımız tüm ayrıcalıklar hakkında daha fazla bilgi alın.
                                </p>
                            </div>

                            <div className="col-md-12 text-center mb-5">
                                <button className="btn btn-uzman-profili">Uzman Profili</button>
                            </div>
            
                

                        
                    
                    </div>

                </div>
            </div>

            {/* Meslektaşlar */}

            <div className="row meslektaslar-content">
                <div className="container mt-4">
                    
                    <div className="row">

                        <div className="col-md-12 text-center mb-4">
                            <h3>Meslektaşlarınız İyi Uzmanda</h3>
                            <p className='text-info'>100 Bin+ Ziyaretçi Potansiyelini Kullanın!</p>
                            {/* <p className='mt-4'><img src={Dalga} width={50} /><img style={{ margin: 0, padding: 0, left: 0 }} src={Dalga} width={50} /><img src={Dalga} width={50} /><img src={Dalga} width={50} /></p> */}
                        </div>

                   
             
                            <div className="col-md-3 text-center">
                                <img className="img-uzman" src={ Uzman } />
                                <h5>Fzt. Mahsun Kırmızıgül</h5>
                                <p>İstanbul</p>
                                <p>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                </p>
                            </div>

                            <div className="col-md-3 text-center">
                            <img className="img-uzman" src={ Uzman2 } />
                                <h5>Dr. Ayşe Çevik</h5>
                                <p>İstanbul</p>
                                <p>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star"></span>
                                </p>
                            </div>

                            <div className="col-md-3 text-center">
                                <img className="img-uzman" src={ Uzman } />
                                <h5>Fzt. Mahsun Kırmızıgül</h5>
                                <p>İstanbul</p>
                                <p>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                </p>
                            </div>

                            <div className="col-md-3 text-center">
                            <img className="img-uzman" src={ Uzman2 } />
                                <h5>Dr. Ayşe Çevik</h5>
                                <p>İstanbul</p>
                                <p>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star"></span>
                                </p>
                            </div>
                           

                            <div className="col-md-12 text-center mt-4">
                                <p className="text-info">
                                    Uzmanlara sağladığımız tüm ayrıcalıklar hakkında daha fazla bilgi alın.
                                </p>
                            </div>

                            <div className="col-md-12 text-center mb-5">
                                <button className="btn btn-warning text-light py-3 px-5">Uzmanlar</button>
                            </div>
                    
                    </div>

                </div>
            </div>

            {/* En iyi uzmanınız */}

            <div className="row bg-white">
                <div className="container mt-5">
                    
                    <div className="row">

                        <div className="col-md-12 text-center mb-4">
                            <h3>En İyi Uzmanınız ile Hemen Tanışın!</h3>
                            <p className='text-info'>Randevu planlamanızda hata yapmayın. 7 gün 24 saat, istediğiniz gün ve saatlere randevu verin. SMS’ler ile randevularınız hatırlatılsın.</p>
                            {/* <p className='mt-4'><img src={Dalga} width={50} /><img style={{ margin: 0, padding: 0, left: 0 }} src={Dalga} width={50} /><img src={Dalga} width={50} /><img src={Dalga} width={50} /></p> */}
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="far fa-clock fa-3x text-primary"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>7 gün 24 saat Kesintisiz Hizmet!</h4>
                            <p>Günün her saatinde ulaşılabilirsiniz. Hiçbir talep cevapsız bırakılmasın ve sizin için yönetilsin.</p>
                            </div>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-smile-beam fa-3x text-primary"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Kullanıcı dostu arayüz!</h4>
                            <p>Takviminiz sizin sınırlarını belirlediğiniz şekilde hastalara/danışanlara randevu versin. Kullanıcı dostu arayüz ile dilediğiniz tüm değişiklikler kolayca gerçekleşsin.</p>
                            </div>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-check-circle fa-3x text-primary"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Kusursuz Randevu yönetimi!</h4>
                            <p>Gün içinde randevu çakışması, unutulan randevu gibi aksiliklerle karşılaşmayın. Randevu planlaması ve organizasyonu sizin için kusursuz bir şekilde yönetilsin.</p>
                            </div>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fas fa-tablet-alt fa-3x text-primary"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Her yerden erişim!</h4>
                            <p>Randevusu olanların iletişim bilgilerine her an ulaşın. Takviminiz yönetilsin, hastalarla ve danışanlarla iletişiminiz güçlensin.</p>
                            </div>
                        </div>


                        <div className="col-md-12 text-center my-4">
                            <button className="btn btn-outline-primary font-weight-bold py-3 px-4">Fiyatlandırma</button>
                        </div>
            
                    </div>

                </div>
            </div>

            {/* Telefonla Randeavu */}
            <div className="row meslektaslar-content">
                <div className="container mt-4">
                    
                    <div className="row">

                        <div className="col-md-12 text-center mb-4">
                            <h3>Telefonla randevu almak isteyenlerin çağrıları yanıtsız kalmasın.</h3>
                            <p className='text-info'>Cevapsız aramalar, kaçan randevulara ve gereksiz iş yüküne sebep olur. </p>
                            {/* <p className='mt-4'><img src={Dalga} width={50} /><img style={{ margin: 0, padding: 0, left: 0 }} src={Dalga} width={50} /><img src={Dalga} width={50} /><img src={Dalga} width={50} /></p> */}
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-phone-volume fa-3x text-success"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Aramalar cevaplansın, randevular alınsın!</h4>
                            <p>Çağrı merkezi sizin yerinize tüm aramaları karşılasın ve randevuları takviminizde belirttiğiniz uygun saatlere eklesin. Yapılan her işlem sonucunda size SMS ile bilgi verilsin.</p>
                            </div>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-phone-alt fa-3x text-success"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Gereksiz aramalar sizin için filtrelensin!</h4>
                            <p>Gereksiz aramalar işinizi bölmesin. Çağrı merkezi desteği sayesinde bu aramalarla vakit kaybetmeyin. Size sadece hastalarınıza ya da danışanlarınıza odaklanmak kalsın.</p>
                            </div>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-headphones fa-3x text-success"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Mesai sonrası gelen çağrılar yanıtlansın!</h4>
                            <p>Randevu taleplerinin %35’i mesai saatleri dışında geliyor. 7 gün / 09.00-24.00 arası çalışan çağrı merkezi desteği sayesinde bu aramalar cevapsız kalmasın.</p>
                            </div>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <i className="fa fa-sms fa-3x text-success"></i>
                        </div>
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div>
                            <h4>Hastalar / danışanlar bilgilendirilsin!</h4>
                            <p>Çağrı merkezini arayan kişilerin randevuları alınsın. Randevu bilgileri size ve hastaya ya da danışana sms ile bildirilsin. </p>
                            </div>
                        </div>
             

                        <div className="col-md-12 text-center mb-5">
                            <button className="btn btn-success text-light py-3 px-5">Çağrı Merkezi Desteği</button>
                        </div>
                    
                    </div>

                </div>
            </div>

            {/* Hemen profil oluştur */}

            <div className="row bg-white">
                <div className="container my-5">
                    
                    <div className="row bg-light">

                        <div className="col-md-12 text-center my-4">
                            <h2>Hemen profil oluşturmak ister misiniz?</h2>
                            <button className="btn btn-info font-weight-bold py-2 px-3">İstiyorum</button>
                        </div>
            
                    </div>

                </div>
            </div>


        </div>
      )
  }

  render(){
    return (
        <div>
            { this.content() }
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Kurumsal))