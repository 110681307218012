import { Link } from 'react-router-dom';
import IyzicoFooter from '../../assets/images/iyzico-footer.png';
import Logo from '../../assets/images/logo.png';

export default function Footer(){
    return (
        <div id="footer" className='mt-5'>
            <div className="container">
                <div className="row">
                    
                    <div className="col-md-4">
                        <img width={ 200 } className="img img-fluid" src={ Logo }/>
                        <p className="mt-2">İyi Uzman ile Belirli Meslek Hizmeti Veren (Fizyoterapist, Psikolog, Diyetisyen v.b ) Uzmanlar veya Klinikler hakkında bilgi edinebilir, değerlendirmelerini okuyabilir ve kolayca ücretsiz randevu oluşturabilirsiniz.</p>
                        <p className='mt-2'>0850 305 29 80</p>
                        <p className='mt-2'>0545 179 29 80</p>
                        <p>iletisim@iyiuzman.com</p>
                    </div>

                    <div className="col-md-4">
                        <strong>Biz</strong>

                        <a className='white-link' href={'https://kurumsal.iyiuzman.com/about-us/'} target={ '_blank' }>Hakkımızda</a>
                        <Link className='white-link' to={ '/iletisim' }>İletişim</Link>
                        <Link className='white-link' to={ '/cerez-politikasi' }>Çerez Politikası</Link>
                        <Link className='white-link' to={ '/kullanici-sozlesmesi' }>Kullanıcı Sözleşmesi</Link>
                        <Link className='white-link' to={ '/aydinlatma-metni' }>Aydınlatma Metni</Link>
                        <Link className='white-link' to={ '/gizlilik-sozlesmesi' }>Gizlilik Sözleşmesi</Link>
                        <Link className='white-link' to={ '/mesafeli-satis-sozlesmesi' }>Mesafeli Satış Sözleşmesi</Link>
                    </div>

                    <div className="col-md-4">
                        <strong>Uzmanlar İçin</strong>
                        <Link className='white-link' to={ '/kayit-ol' }>Kayıt Ol</Link>

                        <a className='white-link' href={'https://kurumsal.iyiuzman.com/departments'} target={ '_blank' }>
                            Uzman Profili
                        </a>

                        <a className='white-link' href={'https://kurumsal.iyiuzman.com/special-offers'} target={ '_blank' }>
                            Fiyatlandırma
                        </a>

                        <a className='white-link' href={'https://kurumsal.iyiuzman.com/faq'} target={ '_blank' }>
                            Sık Sorulan Sorular
                        </a>

                    </div>


                    <div className="col-md-12 mt-5">
                        <img className="img img-fluid" src={ IyzicoFooter }/>
                        <p className='mt-5'>Copyright 2022 Fizyo - İyi Uzman Danışmanlık Hizmetleri - Tüm Hakları Saklıdır</p>
                    </div>

                </div>
            </div>
        </div>
    )
}