import React, { Component,useState } from 'react';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';

import Resim1 from '../assets/images/kayit-1.png';

import { Link,withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';

import { connect } from 'react-redux';
import * as actionTypes from '../Redux/actionTypes';
import Api from '../Functions/Api';

import queryString from 'query-string';

import { Modal } from 'react-bootstrap';

class SifremiUnuttum extends Component {

    state = {
        email                   : '',
        sifre                   : '',
        tekrar_sifre            : '',
        kod                     : '',
        btn_sifremi_yenile      : true,
        btn_sifremi_sifirla     : true,
        modal1                  : false,
    }

    setEmail(email){
        this.setState({ email })
    }

    setSifre(sifre){
        this.setState({ sifre })
    }

    setTekrarSifre(tekrar_sifre){
        this.setState({ tekrar_sifre })
    }

    componentDidMount = () => {
        const params = queryString.parse(this.props.location.search);
        
        if(params.kod != undefined){
            const { kod } = params;
            this.setState({
                kod
            });
        }

        this.props.giris_pop_toggle(false);
    };

    async sifremiUnuttum(){

        const { hata,mesaj,data } = await Api.sifremi_unuttum(
            this.state.email,
        );

        if(hata){
            swal("Oops!",mesaj,'error');
            return;
        }

        swal("Harika!","Şifre yenileme bağlantısı e-mail adresinize gönderildi.Eğer gelen kutunuza mail düşmediyse spam kutunuzu da kontrol ediniz.","success");
    }

    async sifremiSifirla(){

        const { hata,mesaj,data } = await Api.sifremi_sifirla(
            this.state.sifre,
            this.state.tekrar_sifre,
            this.state.kod,
        );

        if(hata){
            swal("Oops!",mesaj,'error');
            return;
        }

        swal("Harika!","Şifreniz başarıyla sıfırlandı. Yeni şifrenizi kullanarak giriş yapabilirsiniz.","success");
    }


  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            {
                this.state.kod.length > 0 ? this.content_yeni_sifre_olustur() : this.content_sifremi_unuttum()
            }
            
            <Footer/>
        </div>
    )
  }

  content_sifremi_unuttum(){
    return(
        <div className="container p-5">
            <div className="row">
                <div className="col-md-5">
                    <h4>Şifre Yenileme</h4>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="email">E-mail</label>
                                <input onChange={ (e) => this.setEmail(e.target.value) } value={ this.state.email } id="email" type="text" className="form-control" />
                            </div>

                            <button disabled={ !this.state.btn_sifremi_yenile } onClick={ () => this.sifremiUnuttum() } className="btn green-button btn-block btn-lg mt-3">
                                Şifremi Yenile
                            </button>
                        </div>
                    </div>

                </div>
                <div className="col-md-7 d-none d-sm-block">
                    <div className="centered">
                        <img className="img img-fluid w-75" src={ Resim1 } alt="sifremi-unuttum" />
                    </div>
                </div>
            </div>
        </div>
      )
  }

  content_yeni_sifre_olustur(){
    return(
        <div className="container p-5">
            <div className="row">

                <div className="col-md-5">

                    <h4>Yeni Şifre Oluştur</h4>

                    <div className="row">

                        <div className="col-md-12">

                            <div className="form-group">
                                <label htmlFor="sifre">Yeni Şifre</label>
                                <input onChange={ (e) => this.setSifre(e.target.value) } value={ this.state.sifre } id="sifre" type="password" className="form-control" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="tekrar_sifre">Yeni Şifrenizi Tekrar Giriniz</label>
                                <input onChange={ (e) => this.setTekrarSifre(e.target.value) } value={ this.state.tekrar_sifre } id="tekrar_sifre" type="password" className="form-control" />
                            </div>

                            <button disabled={ !this.state.btn_sifremi_sifirla } onClick={ () => this.sifremiSifirla() } className="btn green-button btn-block btn-lg mt-3">
                                Yeni Şifre Oluştur
                            </button>
                        </div>
                    </div>

                </div>

                <div className="col-md-7 d-none d-sm-block">
                    <div className="centered">
                        <img className="img img-fluid w-75" src={ Resim1 } alt="sifremi-unuttum" />
                    </div>
                </div>
            </div>
        </div>
    )  
  }

}



function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SifremiUnuttum))