import { ReactComponent as SearchIcon } from './../../assets/images/search.svg';
import React, { Component } from 'react'

export default class SearchBar extends Component {
    render() {

        const { onClick, onKeyDown, placeHolder, onChange } = this.props;
        
        return (
            <div id="searchbar">
                <input onKeyDown={ onKeyDown } type="text" className="form-control" placeholder={ placeHolder } onChange={ onChange }/>
                <button onClick={ onClick } className="btn green-button">
                    <SearchIcon /> Ara
                </button>
            </div>
        )
    }
}
