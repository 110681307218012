import BlogGorsel from '../../assets/images/bloglar-healthy.jpg';
import { ReactComponent as BlogTarih } from '../../assets/images/blog-tarih.svg';
import { ReactComponent as DiyetisyenIcon } from '../../assets/images/userBlue.svg';
import { ReactComponent as Hashtag } from '../../assets/images/hashtag.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';

class BlogDetay extends Component {

  state = {
    yuklendi : false,
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="row">
            <div class="card">
                <img class="card-img-top" src={BlogGorsel} alt="blog görsel"/>
                <div class="card-body">
                    <div className="mb-3">
                        <small className="text-green mr-4">
                        <BlogTarih/> 25 Ekim Pazartesi 2021
                        </small>
                        <small className="text-green mr-4">
                            <DiyetisyenIcon/> Zehra Türk | Diyetisyen
                        </small>
                        <small className="text-green mr-4">
                            <Hashtag/> Etiketler: sağlıklıbeslenme diyet
                        </small>
                    </div>
                    <h5 class="card-title">
                        Lorem ipsum dolor sit amet
                    </h5>
                    <p class="card-text gray">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod  tempor invidunt ut labore et.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet....
                    </p>
                </div>
            </div>
        </div>
    )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(BlogDetay))