import { ReactComponent as UploadIcon } from '../../../assets/images/upload.svg';
import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import Footer from '../../Components/Footer';
import NavBar from '../../Components/NavBar';
import Sidebar from '../../Components/Sidebar';
import PageLoading from '../../Components/PageLoading';

import * as actionTypes from '../../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../../Functions/Api';
import Co from '../../../Functions/Co';


class KisiselBilgiler extends Component {
    

  state = {
    yuklendi      : false,
    guncel_sifre  : '',
    yeni_sifre    : '',
    yeni_sifre2   : '',
    btn_onayla_enb : true,
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    document.title = "Şifre Değiştir - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  async sifre_degistir(){

    if(this.state.guncel_sifre.trim().length < 1){
      swal('Oops','Lütfen güncel şifrenizi girin.','error');
      return;
    }

    if(this.state.yeni_sifre.trim().length < 1){
      swal('Oops','Lütfen yeni şifrenizi girin.','error');
      return;
    }

    if(this.state.yeni_sifre2.trim().length < 1){
      swal('Oops','Lütfen yeni şifrenizi onaylamak için tekrar girin.','error');
      return;
    }

    if(this.state.yeni_sifre2 != this.state.yeni_sifre){
      swal('Oops','Yeni şifreleriniz eşleşmiyor! Lütfen kontrol edip tekrar deneyin.','error');
      return;
    }

    this.setState({ btn_onayla_enb : false })

    const { hata,mesaj } = await Api.parola_degistir(this.state.guncel_sifre,this.state.yeni_sifre,Co.access_token());

    this.setState({ btn_onayla_enb : true })

    if(hata){
      swal("Oops",mesaj,'error');
    }else{
      swal("Harika!",mesaj,'success');
      this.setState({
        guncel_sifre  : '',
        yeni_sifre    : '',
        yeni_sifre2   : '',
      })
    }
  }

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div id="profilimContainer" className="content-card p-5">
            <h4><span>Şifreyi Değiştir</span></h4>
            <br />
            <div className="form-group mb-2">
                <label htmlFor="guncel_sifre">Güncel Şifre</label>
                <input value={ this.state.guncel_sifre } onChange={ (e) => this.setState({ guncel_sifre : e.target.value }) } type="password" className="form-control" placeholder="Güncel şifre" id="guncel_sifre"/>
            </div>
            <div className="col-md-12 text-right">
                <Link to={'/sifremi-unuttum'}>Şifrenizi mi unuttunuz?</Link>
            </div>
            <div className="form-group mb-2">
                <label htmlFor="yeni_sifre">Yeni Şifre</label>
                <input value={ this.state.yeni_sifre } onChange={ (e) => this.setState({ yeni_sifre : e.target.value }) } type="password" className="form-control" placeholder="Yeni şifre" id="yeni_sifre" />
            </div>
            <div className="form-group mb-2">
                <label htmlFor="yeni_sifrey2">Yeni Şifreyi Onayla</label>
                <input value={ this.state.yeni_sifre2 } onChange={ (e) => this.setState({ yeni_sifre2 : e.target.value }) } type="password" className="form-control" placeholder="Yeni şifreyi onayla" id="yeni_sifrey2" />
            </div>

            <button disabled={ !this.state.btn_onayla_enb } onClick={ () => this.sifre_degistir() } className="btn btn green-button btn-block p-3">
              { this.state.btn_onayla_enb ? 'Kaydet' : 'Kaydediliyor...' }
            </button>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Profilim</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(KisiselBilgiler))