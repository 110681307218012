import Select, { components } from 'react-select';

export const aramaSonucuStyle = value =>({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#95D1CC" : state.isFocused ? '#F2F2F2' : "#FFF"
  })
});

export const AramaSonucu = props => {

    const { isSelected } = props;
  
    const { label,tur,resim } = props.data;

    return (
      <components.Option {...props}>
        <div className="arama-sonucu" data-selected={ isSelected }>

          {
            tur == 'uzman' ? <img src={ resim } className="arama-sonucu-img" />
            : tur == 'meslek' ? <i className="fas fa-user-md arama-sonucu-icon"></i>
            : <i className="fas fa-hand-holding-medical arama-sonucu-icon"></i>
          }

          <span className="arama-sonucu-label">
            { label }
          </span>
          <i className="fas fa-chevron-right i-arama-sonucu"></i>
          <span className="arama-sonucu-turu">
            {
              tur == 'meslek' ? 'Meslek' :
              tur == 'hizmet' ? 'Hizmet' : 'Uzman'
            }
          </span>
        </div>
      </components.Option>
    );
};