import React, { Component } from 'react';

import ActionBar from '../Components/ActionBar';
import ActionBarMobile from '../Components/ActionBarMobile';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import PageLoading from '../Components/PageLoading';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MesafeliSatisSozlesmesi extends Component {

  state = {
    yuklendi : false,
  }


  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-12">
          <h3 className="text-center text-danger">iyiuzman.com Mesafeli Satış Sözleşmesi</h3>

          <br />

          <h5>Madde 1- Taraflar</h5>
          <p>Adı : Ali Gökberk Saygı</p>
          <p>Vergi No: 7540517723</p>
          <p>Adresi: KURTULUŞ MAH. 64021 SK. MEHMET SÜMER APT B BLOK Kapı No:1/05 SEYHAN-ADANA</p>
          <p>Tel: 0850 305 29 80 - 0545 179 29 80</p>
          <p>Email : iletisim@iyiuzman.com</p>
          <p>Yukarıda bilgileri verilen Satıcı, işbu sözleşmenin içerisinde Satıcı, Hizmet Sağlayıcı veya Ürün Sağlayıcı olarak anılabilir.</p>

          <br />

          <h5>1.2. Alıcı (Hizmet/Ürün Alan)</h5>
          <p>Adı - soyadı/TC.No:</p>
          <p>Adresi :</p>
          <p>Telefon:</p>
          <p>E-mail :</p>
          <p>Yukarıda bilgileri verilen Alıcı, işbu sözleşmenin içerisinde Alıcı, Hizmet Alan veya Ürün Alan olarak anılabilir.</p>

          <br />

          <h5>Madde 2- Konu</h5>
          <p>İşbu mesafeli satış sözleşmesi nin konusu, ALICI’nın SATICI’ya ait https://iyiuzman.com internet sitesinden elektronik ortamda aldığı hizmet veya ürün için aşağıda nitelikleri ve satış ücreti belirtilen hizmetin/ürünün satışı ve teslimi ile ilgili olarak 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
            Alıcı, satıcının isim, unvan, açık adres, telefon ve diğer erişim bilgileri , satışa konu malın temel nitelikleri, vergiler dahil olmak üzere satış fiyatı , ödeme sekli, teslimat koşulları ve masrafları vs. satışa konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı, şikayet ve itirazlarını iletebilecekleri resmi makamlar vs. konusunda açık , anlaşılır ve internet ortamına uygun şekilde satıcı tarafından bilgilendirildiğini, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında hizmet/ürün sipariş verdiğini işbu sözleşme hükümlerince kabul ve beyan eder.
            https://iyiuzman.com sitesinde yer alan ön bilgilendirme ve alıcı tarafından verilen sipariş üzerine düzenlenen fatura işbu sözleşmenin ayrılmaz parçalarıdır.
          </p>

          <br />

          <h5>Madde 3.1- Sözleşme Konusu Ürün, Ödeme, Teslimat Bilgileri</h5>

          <p>Elektronik ortamda alınan ürün/ürünlerin cinsi ve türü, miktarı, marka/modeli, satış bedeli, ödeme şekli, teslim alacak kişi, teslimat adresi, fatura bilgileri, kargo ücreti aşağıda belirtildiği gibidir. Fatura edilecek kişi ile sözleşmeyi yapan kişi aynı olmak zorundadır. Aşağıda yer alan bilgiler doğru ve eksiksiz olmalıdır. Bu bilgilerin doğru olmadığı veya noksan olduğu durumlardan doğacak zararları tamamıyla karşılamayı alıcı kabul eder ve ayrıca bu durumdan oluşabilecek her türlü sorumluluğu alıcı kabul eder
            SATICI gerekli gördüğü durumlarda, ALICI’nın vermiş olduğu bilgiler gerçekle örtüşmediğinde, siparişi durdurma hakkını saklı tutar. SATICI siparişte sorun tespit ettiği durumlarda ALICI’nın vermiş olduğu telefon, e-posta ve posta adreslerinden ALICI’ya ulaşamadığı takdirde siparişin yürürlüğe koyulmasını 15 (onbeş) gün süreyle dondurur. ALICI’nın bu süre zarfında SATICI ile konuyla ilgili olarak iletişime geçmesi beklenir. Bu süre içerisinde ALICI’dan herhangi bir cevap alınamazsa SATICI, her iki tarafın da zarar görmemesi için siparişi iptal eder.
          </p>

          <p>Alınan Ürün /Ürünler </p>
          <p>Adı , kodu : ; … adet</p>
          <p>Toplam Satış Bedeli : …. -TL</p>
          <p>Ödeme Şekli : Kredi Kartı/Banka Havalesi (EFT)</p>
          <p>Teslim Edilecek Kişi :</p>
          <p>Telefon numarası :</p>
          <p>Teslim Edilecek Adres :</p>
          <p>Fatura Edilecek Kişi/Kurum :</p>
          <p>Fatura Adresi :</p>
          <p>Vergi Dairesi :</p>
          <p>Vergi Sicil Numarası :</p>
          <p>Kargo Ücreti : … -TL</p>

          <br />

          <h5>Madde 3.2- Sözleşme Konusu Hizmet, Ödeme, Teslimat Bilgileri</h5>
          <p>https://iyiuzman.com sitesinden hizmet alımı https://iyiuzman.com sitesinin Kullanım Şartnamesinde tanımlı Fizyoterapist & Psikolog veya Diyetisyen Üye’ler için mümkündür. Sitenin kullanım şartnamesine buradan ulaşabilirsiniz; https://ozelfizyoterapist.net/s/kullanim-sartnamesi-metni
            Sözleşme konusu hizmet; Elektronik ortamda sağlanan hizmettir. Fizyoterapist & Psikolog veya Diyetisyen Üye’nin site üzerindeki takvim eklentisi ile online randevu alabilmesinin veya telekomünikasyon vasıtasıyla randevu almasının sağlanmasıdır. Bu işlemler sırasında tarafların aynı anda hazır bulunmalarına gerek yoktur.
            Fizyoterapist & Psikolog veya Diyetisyen online randevu sistemi veya telekomünikasyon vasıtasıyla sağlanan randevu sonrasıFizyoterapist & Psikolog veya Diyetisyen için Ödeme Makbuzu oluşturulur. Ödeme makbuzunda Hizmet Bedelinin son ödeme tarihi randevu tarihinden sonraki 10. güne denk gelen gündür. Son ödeme tarihinin haftasonuna gelmesi durumunda ilk iş günü son ödeme tarihidir.
            Elektronik ortamda alınan hizmet cinsi ve türü, miktarı, satış bedeli, ödeme şekli, teslim alacak kişi, teslimat adresi, fatura bilgileri, kargo ücreti aşağıda belirtildiği gibidir. Fatura edilecek kişi ile sözleşmeyi yapan kişi aynı olmak zorundadır. Aşağıda yer alan bilgiler doğru ve eksiksiz olmalıdır. Bu bilgilerin doğru olmadığı veya noksan olduğu durumlardan doğacak zararları tamamıyla karşılamayı Hizmet Alan kabul eder ve ayrıca bu durumdan oluşabilecek her türlü sorumluluğu Hizmet Alan kabul eder.
            Hizmet Sağlayıcı gerekli gördüğü durumlarda, Hizmet Alan’ın vermiş olduğu bilgiler gerçekle örtüşmediğinde, hizmeti durdurma hakkını saklı tutar. Hizmet Sağlayıcı hizmetin verilebilmesiyle ilgili bir sorun tespit ettiği durumlarda Hizmet Alan’ın vermiş olduğu telefon, e-posta ve posta adreslerinden Hizmet Alan’a ulaşamadığı takdirde hizmetin yürürlüğe koyulmasını iptal edebilir.
          </p>

          <p>Alınan Hizmet/Hizmetler</p>
          <p>Adı , kodu : ; … adet</p>
          <p>Toplam Satış Bedeli : …. -TL</p>
          <p>Ödeme Şekli : Kredi Kartı/Banka Havalesi (EFT)</p>
          <p>Teslim Edilecek Kişi : </p>
          <p>Telefon numarası :</p>
          <p>Teslim Edilecek Adres : Online Teslimat</p>
          <p>Fatura Edilecek Kişi/Kurum :</p>
          <p>Fatura Adresi :</p>
          <p>Vergi Dairesi :</p>
          <p>Vergi Sicil Numarası :</p>
          <p>Kargo Ücreti : Fatura Gönderimi için Dosya Gönderim Bedeli -TL</p>


          <br />

          <h5>Madde 4.1- Ürün Alımı için Sözleşme Tarihi ve Mücbir Nedenler</h5>
          <p>Sözleşme tarihi, alıcı tarafından siparişin verildiği tarih olan ../../…. tarihidir. 
            Sözleşmenin imzalandığı tarihte mevcut olmayan veya öngörülmeyen, tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine getirmelerini olanaksızlaştıran durumlar, mücbir sebep (Doğal afet, savaş, terör, ayaklanma, değişen mevzuat hükümleri, el koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli ölçüde arıza vb.) olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen taraf, diğer tarafa durumu derhal ve yazılı olarak bildirecektir. 
            Mücbir sebebin devamı esnasında tarafların edimlerini yerine getirememelerinden dolayı herhangi bir sorumlulukları doğmayacaktır. İşbu mücbir sebep durumu 30 (otuz ) gün süreyle devam ederse, taraflardan her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
          </p>

          <br />
          <h5>Madde 4.2- Hizmet Alımı için Sözleşme Tarihi ve Mücbir Nedenler</h5>
          <p>Sözleşme tarihi, hizmet alımında Fizyoterapist & Psikolog veya Diyetisyen Üye için randevunun oluşma tarihidir. Son ödeme tarihi ise; randevudan sonraki gelen 10. gündür. 10. Günün haftasonuna denk gelmesi durumunda son ödeme tarihi o haftasonundan sonraki ilk iş günüdür.
            Sözleşmenin oluştuğu tarihte mevcut olmayan veya öngörülmeyen, tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine getirmelerini olanaksızlaştıran durumlar, mücbir sebep (Doğal afet, savaş, terör, ayaklanma, değişen mevzuat hükümleri, el koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli ölçüde arıza vb.) olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen taraf, diğer tarafa durumu derhal ve yazılı olarak bildirecektir. 
            Mücbir sebebin devamı esnasında tarafların edimlerini yerine getirememelerinden dolayı herhangi bir sorumlulukları doğmayacaktır. İşbu mücbir sebep durumu 30 (otuz ) gün süreyle devam ederse, taraflardan her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
          </p>

          <h5>Madde 5- Satıcının Hak ve Yükümlülükleri</h5>
          <p> <strong>5.1.</strong> Satıcı, 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri uyarınca sözleşmede kendisine yüklenen edimleri mücbir haller dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder.</p>

          <p> <strong>5.2.</strong> 18 (on sekiz) yaşından küçük kişiler https://iyiuzman.com'dan alışveriş yapamaz. Satıcı, alıcının sözleşmede belirttiği yaşının doğru olduğunu esas alacaktır. Ancak alıcının yaşını yanlış yazmasından dolayı satıcıya hiçbir şekilde sorumluluk yüklenemeyecektir.</p>

          <p> <strong>5.3.</strong> Sistem hatalarından meydana gelen fiyat yanlışlıklarından https://iyiuzman.com sorumlu değildir. Buna istinaden satıcı, internet sitesindeki sistemden, dizayndan veya yasadışı yollarla internet sitesine yapılabilecek müdahaleler sebebiyle ortaya çıkabilecek tanıtım, fiyat hatalarından sorumlu değildir. Sistem hatalarına dayalı olarak alıcı satıcıdan hak iddiasında bulunamaz.</p>

          <p> <strong>5.4.</strong> https://iyiuzman.com dan kredi kartı (Visa, MasterCard , vs. ) ya da banka havalesi ile alışveriş yapılabilir. Sipariş tarihinden itibaren bir hafta içinde havalesi yapılmayan siparişler iptal edilir. Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’nin) banka hesaplarına ulaştığı belirlenen andır. Ödemeli gönderi ya da posta çeki gibi müşteri hizmetleri ile görüşülmeden gerçekleştirilen ödeme yöntemleri kabul edilmez.</p>


          <br />

          <h5>Madde 6- Alıcının Hak ve Yükümlülükleri</h5>
          <p> <strong>6.1.</strong> Alıcı, sözleşmede kendisine yüklenen edimleri mücbir sebepler dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder.</p>
          <p> <strong>6.2.</strong> Alıcı, sipariş vermekle veya hizmet almakla birlikte iş sözleşme hükümlerini kabul etmiş sayıldığını ve sözleşmede belirtilen ödeme şekline uygun ödemeyi yapacağını kabul ve taahhüt eder.</p>
          <p> <strong>6.3.</strong> Alıcı, https://iyiuzman.com internet sitesinden satıcının isim, unvan, açık adres, telefon ve diğer erişim bilgileri , satışa konu malın temel nitelikleri, vergiler dahil olmak üzere satış fiyatı , ödeme şekli, teslimat koşulları ve masrafları vs. satışa konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı , şikayet ve itirazlarını iletebilecekleri resmi makamlar vs. konusunda açık , anlaşılır ve internet ortamına uygun şekilde bilgi sahibi olduğunu bu ön bilgileri elektronik ortamda teyit ettiğini kabul ve beyan eder. </p>
          <p> <strong>6.4.</strong> Bir önceki maddeye bağlı olarak Alıcı, ürün sipariş, hizmet alımı ve ödeme koşullarının, ürün kullanım talimatlarının, olası durumlara karşı alınan tedbirlerin ve yapılan uyarıların olduğu https://iyiuzman.com hizmet alımı/sipariş/ödeme/kullanım prosedürü bilgilerini okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.</p>
          <p> <strong>6.5.</strong> Alıcı, aldığı ürünü iade etmek istemesi durumunda ne surette olursa olsun ürüne ve ambalajına zarar vermemeyi, iade anında fatura aslını ve irsaliyesini iade etmeyi kabul ve taahhüt eder.</p>

          <br />
          <h5>Madde 7- Hizmet/Sipariş Ödeme Prosedürü</h5>
          <h5>Sipariş:</h5>

          <p>Alışveriş sepetine eklenen ürünlerin KDV dahil TL tutarı (Taksitli işlemlerde toplam taksit tutarları) alıcı tarafından onaylandıktan sonra, ilgili banka kartının posu üzerinden işleme alınır. Bu nedenle siparişler, sevk edilmeden önce müşteriye sipariş onay maili gönderilir. Sipariş Onay maili gönderilmeden sevkiyat yapılmaz.</p>
          <p>Süreçteki herhangi bir aksama durumu ya da kredi kartı ile ilgili ortaya çıkabilecek problemler alıcıya sözleşmede belirttiği telefon/faks/e-mail yollarından biri veya bir kaçı kullanılmak sureti ile bildirilir. Gerekirse alıcıdan bankası ile görüşmesi istenebilir. Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’ nin) satıcı hesaplarına ulaştığının belirlendiği andır.</p>
          <p>İstisnai olarak haklı bir nedenle sözleşme konusu malın tedarik edilemeyeceğinin anlaşılması ve/veya stok problemi ile karşılaşılması durumunda alıcı hemen açık ve anlaşılır bir şekilde bilgilendirilip onay vermesi durumunda alıcıya eşit kalitede ve fiyatta başka bir mal gönderilebilir ya da alıcının arzusu ve seçimi doğrultusunda ; yeni başka bir ürün gönderilebilir, ürünün stoklara girmesi ya da teslime engel diğer engelin ortadan kalkması beklenebilir ve/veya sipariş iptal edilebilir.</p>
          <p>Sözleşme konusu malın teslim yükümlülüğünün yerine getirilmesinin imkânsızlaştığı hâllerde alıcı bu durumdan haberdar edilerek ödemiş olduğu toplam bedel ve varsa onu borç altına sokan her türlü belge en geç on gün içinde kendisine iade edilerek sözleşme iptal edilir. Böyle bir durumda alıcının satıcıdan ilave herhangi bir maddi ve manevi zarar talebi olmayacaktır. </p>

          <br />
          <h5>Sipariş Ödemesi:</h5>
          <p>https://iyiuzman.com ‘da, internet ortamında kredi kartı bilgilerini kullanmak istemeyen alıcılara nakit havale ile sipariş imkanları sunulmuştur. Havale ile ödemede alıcı kendisine en uygun bankayı seçip havalesini yapabilir. Eğer EFT yapılmışsa hesaba geçme tarihi dikkate alınacaktır. Havale ve/veya EFT yaparken “Gönderen Bilgileri”nin Fatura Bilgileri ile aynı olması ve sipariş numarasının yazılması gereklidir. </p>
          <p>Ürünün tesliminden sonra Alıcı’ya ait kredi kartının Alıcı’nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşun ürün bedelini Satıcı’ya ödememesi halinde, Alıcı’nın kendisine teslim edilmiş ürünü 10 gün içinde Satıcı’ya göndermesi zorunludur. Bu tür durumlarda nakliye giderleri Alıcı’ya aittir.</p>
          <p>Alıcı kredi kartı ile ödeme yapmayı tercih etmiş ise ALICI, ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve ALICI arasındaki “Kredi Kartı Sözleşmesi” kapsamında uygulanacağını kabul, beyan ve taahhüt eder.</p>

          <br />
          <h5>Hizmet:</h5>
          <p>https://iyiuzman.com sitesinden hizmet alımı https://iyiuzman.com sitesinin Kullanım Şartnamesinde tanımlı Fizyoterapist & Psikolog veya Diyetisyen Üye’ler için mümkündür. Sitenin kullanım şartnamesine buradan ulaşabilirsiniz; https://ozelfizyoterapist.net/kullanim-sartnamesi</p>
          <p>Sözleşme konusu hizmet; Elektronik ortamda sağlanan hizmettir.Fizyoterapist & Psikolog veya Diyetisyen Üye’nin site üzerindeki takvim eklentisi ile online randevu alabilmesinin veya telekomünikasyon vasıtasıyla randevu almasının sağlanmasıdır. Bu işlemler sırasında tarafların aynı anda hazır bulunmalarına gerek yoktur.</p>

          <br />
          <h5>Hizmet Ödemesi:</h5>
          <p>https://iyiuzman.com‘da, internet ortamında kredi kartı bilgilerini kullanmak istemeyen alıcılara nakit havale ile Hizmet Alım imkanları sunulmuştur. Havale ile ödemede alıcı kendisine en uygun bankayı seçip havalesini yapabilir. Eğer EFT yapılmışsa hesaba geçme tarihi dikkate alınacaktır. Havale ve/veya EFT yaparken “Gönderen Bilgileri”nin Fatura Bilgileri ile aynı olması gereklidir.</p>
          <p>Fizyoterapist & Psikolog veya Diyetisyen Üye’ye online randevu sistemi veya telekomünikasyon vasıtasıyla sağlanan randevu sonrası Fizyoterapist & Psikolog veya Diyetisyen Üye için Ödeme Makbuzu oluşturulur. Ödeme makbuzunda Hizmet Bedelinin son ödeme tarihi randevu tarihinden sonraki 10. güne denk gelen gündür. Son ödeme tarihinin haftasonuna gelmesi durumunda ilk iş günü son ödeme tarihidir.</p>
          <p>Ödemenin vaktinde yapılmaması durumunda Hizmet Sağlayıcı, Hizmet Alanın üyeliğini durdurma, askıya alma veya tamamiyle silme hakkını saklı tutar.</p>
          <p>Fizyoterapist & Psikolog veya Diyetisyen Üye, randevu tarihi ve saatinden 2 saat önceye kadar veya sonrasında Hizmet Alımı iptal etse de Hizmet Alım bedelinin %25 ücretini ödemek zorundadır.Fizyoterapist & Psikolog veya Diyetisyen Üye, hizmet alımı ile bu şartı kabul ettiğini beyan eder. Son ödeme tarihinde bir değişiklik yapılmaz. Randevuya 2 Saatten fazla kalan bir süre önce iptal edilen Hizmet Alımları için ise; Fizyoterapist & Psikolog veya Diyetisyen Üye herhangi bir ücret ödemez.</p>

          <br />
          <h5>Madde 8- Sevkiyat/Teslimat Prosedürü</h5>
          <h5>Sevkiyat:</h5>
          <p>Sipariş onayı mailinin gönderilmesiyle birlikte, ürün/ürünler satıcının anlaşmalı olduğu kargo Şirketine verilir.</p>

          <br />
          <h5>Teslimat:</h5>
          <p>Ürün/ürünler satıcının anlaşmalı olduğu kargo ile alıcının adresine teslim edilecektir. Teslimat süresi, Sipariş onayı mailinin gönderilmesinden ve sözleşmenin kurulmasından itibaren 30 gündür. Alıcıya önceden yazılı olarak veya bir sürekli veri taşıyıcısıyla bildirilmek koşuluyla bu süre en fazla on gün uzatılabilir. </p>
          <p>Ürünler, Kargo şirketlerinin adres teslimatı yapmadığı bölgelere telefon ihbarlı olarak gönderilir.</p>
          <p>Kargo Şirketinin haftada bir gün teslimat yaptığı bölgelerde, sevk bilgilerindeki yanlışlık ve eksiklik olduğu hallerde, bazı sosyal olaylar ve doğal afetler gibi durumlarda belirtilen gün süresinde sarkma olabilir. Bu sarkmalardan dolayı alıcı satıcıya herhangi bir sorumluluk yükleyemez. Ürün, Alıcı’dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden, sevk bilgilerindeki yanlışlık ve/veya Alıcının yerinde olmamasından doğabilecek ekstra kargo bedellerinden satıcı sorumlu değildir. Belirtilen günler içeriğinde ürün/ürünler müşteriye ulaşmadıysa teslimat problemleri müşteri hizmetlerine iletisim@iyiuzman.com e-mail adresi kullanılmak sureti ile derhal bildirilmelidir.</p>
          <p>Zarar görmüş paket durumunda; Zarar görmüş paketler teslim alınmayarak Kargo Şirketi yetkilisine tutanak tutturulmalıdır. Eğer Kargo Şirketi yetkilisi paketin hasarlı olmadığı görüşünde ise, paketin orada açılarak ürünlerin hasarsız teslim edildiğini kontrol ettirme ve durumun yine bir tutanakla tespit edilmesini isteme hakkı alıcıda vardır. Paket Alıcı tarafından teslim alındıktan sonra Kargo Şirketinin görevini tam olarak yaptığı kabul edilmiş olur. Paket kabul edilmemiş ve tutanak tutulmuş ise, durum, tutanağın Alıcı’da kalan kopyasıyla birlikte en kısa zamanda satıcı Müşteri Hizmetlerine bildirilmelidir. </p>

          <br />
          <h5>Madde 9- Ürün İade ve Cayma Hakkına İlişkin Prosedürü</h5>
          <h5>Ürün İade:</h5>

          <p>Alıcı malı teslim aldıktan sonra yedi gün içerisinde herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin sözleşmeden cayma hakkına sahiptir. 385 sayılı vergi usul kanunu genel tebliği uyarınca iade işlemlerinin yapılabilmesi için alıcının  mal ile birlikte teslim edilen satıcıya ait 2 adet faturanın alt kısmındaki iade bölümlerini eksiksiz ve doğru şekilde doldurduktan sonra imzalayarak bir nüshasını ürün ile birlikte satıcıya göndermesi diğer nüshasını da uhdesinde tutması gerekmektedir.Cayma hakkı süresi alıcıya malın teslim edildiği günden itibaren başlar. İade edilen ürün veya ürünlerin geri gönderim bedeli alıcı tarafından karşılanmalıdır.
            Alıcının istekleri ve/veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan mallar için cayma hakkı söz konusu değildir.
            Alıcının cayma hakkını kullanması halinde satıcı, cayma bildirimini içeren faturanın ürünle birlikte kendisine ulaşmasından itibaren en geç on gün içerisinde almış olduğu toplam bedeli ve varsa tüketiciyi borç altına sokan her türlü belgeyi tüketiciye hiçbir masraf yüklemeden iade edecektir.
            Teslim alınmış olan malın değerinin azalması veya iadeyi imkânsız kılan bir nedenin varlığı cayma hakkının kullanılmasına engel değildir. Ancak değer azalması veya iadenin imkânsızlaşması tüketicinin kusurundan kaynaklanıyorsa satıcıya malın değerini veya değerindeki azalmayı tazmin etmesi gerekir. 
            Sehven alınan her ürün için de genel iade süresi 7 gündür. Bu süre içerisinde, Ambalajı açılmış, kullanılmış, tahrip edilmiş vesaire şekildeki ürünlerin iadesi kabul edilmez. İade, orijinal ambalaj ile yapılmalıdır.
            Sehven alınan üründe ve ambalajında herhangi bir açılma, bozulma, kırılma, tahrip, yırtılma, kullanılma ve sair durumlar tespit edildiği hallerde ve ürünün alıcıya teslim edildiği andaki hali ile iade edilememesi durumunda ürün iade alınmaz ve bedeli iade edilmez. 
            Ürün iadesi için, durum öncelikli olarak müşteri hizmetlerine iletilmelidir. Ürünün iade olarak gönderilme bilgisi, satıcı tarafından müşteriye iletilir. Bu görüşmeden sonra ürün iade ile ilgili bilgileri içeren fatura ile birlikte alıcı adresine teslimatı yapan Kargo şirketi kanalıyla satıcıya ulaştırmalıdır. Satıcıya ulaşan iade ürün iş bu sözleşmede belirtilen koşulları sağladığı takdirde iade olarak kabul edilir, geri ödemesi de alıcı kredi kartına/hesabına yapılır. Ürün iade edilmeden bedel iadesi yapılmaz. Kredi Kartına yapılan iadelerin kredi kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır. 
            Alışveriş kredi kartı ile ve taksitli olarak yapılmışsa, kredi kartına iade prosedürü şu şekilde uygulanacaktır: Alıcı ürünü kaç taksit ile satın alma talebini iletmiş ise, Banka alıcıya geri ödemesini taksitle yapmaktadır. Satıcı,bankaya ürün bedelinin tamamını tek seferde ödedikten sonra, Banka poslarından yapılan taksitli harcamaların alıcının kredi kartına iadesi durumundakonuya müdahil tarafların mağdur duruma düşmemesi için talep edilen iade tutarları,yine taksitli olarak hamil taraf hesaplarına Banka tarafından aktarılır.Alıcının satış iptaline kadar ödemiş olduğu taksit tutarları, eğer iade tarihi ile kartın hesap kesim tarihleri çakışmazsa her ay karta 1(bir) iade yansıyacak ve alıcı iade öncesinde ödemiş olduğu taksitleri satışın taksitleri bittikten sonra, iade öncesinde ödemiş olduğu taksit sayısı kadar ay daha alacak ve mevcut borçlarından düşmüş olacaktır.
            Kart ile alınmış mal ve hizmetin iadesi durumunda satıcı, Banka ile yapmış olduğu sözleşme gereği alıcıya nakit para ile ödeme yapamaz. Üye işyeri yani satıcı, bir iade işlemi söz konusu olduğunda ilgili yazılım aracılığı ile iadesini yapacak olup, üye işyeri yani satıcı ilgili tutarı Bankaya nakden veya mahsuben ödemekle yükümlü olduğundan yukarıda detayları belirtilen prosedür gereğince alıcıya nakit olarak ödeme yapılamamaktadır. Kredi kartına iade, alıcının Bankaya bedeli tek seferde ödemesinden sonra, Banka tarafından yukarıdaki prosedür gereğince yapılacaktır.
          </p>

          <br />
          <h5>Madde 10-Garanti</h5>
          <p>Kullanma talimatına uygun şekilde kullanılan ve temizliği yapılan ürünler her türlü üretim hatasına karşı aşağıda belirtilen şartlar dahilinde 2 yıl garantilidir:  Satıcının garanti sorumluluğu yalnızca 4077 sayılı kanun kapsamına giren tüketiciler için geçerlidir. Ticari nitelikteki işler için Türk Ticaret Kanununu hükümleri geçerli olacaktır.</p>

          <br />
          <h5>Madde 11- Gizlilik</h5>
          <p>Alıcı tarafından iş bu sözleşmede belirtilen bilgiler ile ödeme yapmak amacı ile satıcıya bildirdiği bilgiler satıcı tarafından 3. şahıslarla paylaşılmayacaktır.</p>
          <p>Satıcı bu bilgileri sadece idari/ yasal zorunluluğun mevcudiyeti çerçevesinde açıklayabilecektir. Araştırma ehliyeti belgelenmiş her türlü adli soruşturma dahilinde satıcı kendisinden istenen bilgiyi elinde bulunduruyorsa ilgili makama sağlayabilir.</p>
          <p>Kredi Kartı bilgileri kesinlikle saklanmaz,Kredi Kartı bilgileri sadece tahsilat işlemi sırasında ilgili bankalara güvenli bir şekilde iletilerek provizyon alınması için kullanılır ve provizyon sonrası sistemden silinir.</p>
          <p>Alıcıya ait e-posta adresi, posta adresi ve telefon gibi bilgiler yalnızca satıcı tarafından standart ürün teslim ve bilgilendirme prosedürleri için kullanılır. Bazı dönemlerde kampanya bilgileri, yeni ürünler hakkında bilgiler, promosyon bilgileri alıcıya onayı sonrasında gönderilebilir. </p>

          <br />
          <h5>Madde 12- Uyuşmazlık Durumunda Yetkili Mahkeme ve İcra Daireleri</h5>
          <p>İşbu sözleşmenin uygulanmasından kaynaklanan uyuşmazlık halinde, Sanayi ve Ticaret Bakanlığınca her yıl Aralık ayında ilan edilen değere kadar Tüketici Hakem Heyetleri ile Alıcı’nın veya Satıcı’nın yerleşim yerindeki Tüketici Mahkemeleri yetkilidir.</p>
          <p>Siparişin gerçekleşmesi durumunda Alıcı işbu sözleşmenin tüm koşullarını kabul etmiş sayılır. </p>



        </div>
    )
  }

  render(){
    return (
        <div>

            <ActionBar/>
            <ActionBarMobile/>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              <div id="content">
                <div className="container mt-5">
                  { this.content() }
                </div>
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MesafeliSatisSozlesmesi))
