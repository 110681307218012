import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import Main from '../../Functions/Main';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';

import Moment from 'react-moment';

class FaturaDetay extends Component {

  state = {
    yuklendi    : false,
    fatura      : { },
    randevu     : { },
    hizmet      : { },
  }

  async fatura_detaylari(){
    const { id } = this.props.match.params;
    
    const { hata,mesaj,data } = await Api.fatura(id);

    if(!hata){
        this.setState({ yuklendi : true, fatura : data.fatura, randevu : data.randevu, hizmet : data.hizmet_data });
    }else{
        Main.message(mesaj,hata);
    }
  }


  componentDidMount = async () => {
    document.title = "Fatura Detayı - İyi Uzman";
    this.fatura_detaylari();
  };


  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-5 col-md-10">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Fatura Oluşturma Tarihi:
                </div>
                <div className="col-md-7 gray mb-3">
                  <Moment format="DD.MM.YYYY HH:mm">{ this.state.fatura.olusturulma_tarihi }</Moment>
                </div>
                <div className="col-md-2"></div>
                {
                  this.state.fatura.durum == 'ODENDI' ?
                  <>
                    <div className="col-md-3">
                        Ödeme Tarihi:
                    </div>
                    <div className="col-md-7 gray mb-3">
                        <Moment format="DD.MM.YYYY HH:mm">{ this.state.fatura.odeme_tarihi }</Moment>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-7 gray">
                    </div>
                  </>
                }
                
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Son Ödeme Tarihi:
                </div>
                <div className="col-md-7 gray mb-3">
                  <Moment format="DD.MM.YYYY">{ this.state.fatura.son_odeme_tarihi }</Moment>
                </div>


                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Fatura Tutarı:
                </div>
                <div className="col-md-7 gray mb-3">
                    { this.state.fatura.tutar } ₺
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Faturanın Oluşturulma Nedeni:
                </div>
                <div className="col-md-7 gray mb-3">
                    { this.state.fatura.sistem_notu }
                </div>
                <div className="col-md-12">
                  <hr />
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Randevu Tarihi:
                </div>
                <div className="col-md-7 gray mb-3">
                  <Moment format="DD.MM.YYYY">{ this.state.randevu.randevu_tarihi }</Moment>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Randevu Saati:
                </div>
                <div className="col-md-7 gray mb-3">
                    { this.state.randevu.randevu_saati }
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Randevu Türü:
                </div>
                <div className="col-md-7 gray mb-3">
                    { Main.randevu_turu(this.state.randevu.randevu_turu) }
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Hizmet Konusu:
                </div>
                <div className="col-md-7 gray mb-3">
                  { this.state.hizmet != null ? this.state.hizmet.hizmet_adi : 'Hizmet bulunamadı' }
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    Randevu Durumu:
                </div>
                <div className="col-md-7 gray mb-3">
                  { Main.randevu_durum(this.state.randevu.durum) }
                </div>
               
            </div>

            <div className="row">
              <div className="col-md-10 offset-md-2">

                  <div className="alert alert-danger">
                    <p>Bilgilendirme</p>
                    <p>3 adet ödenmemiş yada toplamda 500 ₺ ve üzeri ödenmemiş faturası bulunan kullanıcılar için sistem yeni müşteri yönlendirmez, randevu oluşturmaz.</p>
                  </div>

              </div>
            </div>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Fatura Detayı</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(FaturaDetay))