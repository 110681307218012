class Co {

    setItem(key,data){
        localStorage.setItem(key, JSON.stringify(data));
    }

    getItem(key){
        const data = localStorage.getItem(key);
        return JSON.parse(data);
    }

    auth(){
        if(this.getItem('login')){
            return true;
        }else{
            return false;
        }
    }

    access_token(){
        if(this.auth()){
            const token_data = this.getItem('auth');

            return token_data.access_token;
        }
        return '';
    }

    logout(){
        localStorage.clear();
    }

}

export default new Co();