import Degerlendirme from '../../assets/images/degerlendirme.png';
import { ReactComponent as Musteri } from '../../assets/images/musteri.svg';
import { ReactComponent as Tarih } from '../../assets/images/tarih.svg';
import { ReactComponent as RandevuTuru } from '../../assets/images/randevu-turu.svg';

import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';

import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

import Moment from 'react-moment';

class RandevuDegerlendirme extends Component {

  state = {
    yuklendi : false,
    hash_id : '',
    yorum : '',
    puan : 1,
    randevu : { },
  }

  setRate(rating){
      this.setState({
        puan : rating
      });
  }
  
  setYorum(yorum){
      this.setState({
        yorum
      });
  }

  async randevu_detaylari(){
    const { hash_id } = this.props.match.params;
    
    const { hata,mesaj,data } = await Api.randevu_detaylari(hash_id);

    if(hata){
        swal("Oops!",mesaj,'error');
    }
    else{
        this.setState({ yuklendi : true,randevu : data, hash_id });
    }
  }

  async randevuDegerlendir(){

    const { hata,mesaj,data } = await Api.randevu_degerlendir(
        this.state.hash_id,
        this.state.puan,
        this.state.yorum,
    );

    if(hata){
        swal("Oops!",mesaj,'error');
        return;
    }

    swal("Harika!",mesaj,"success");

  };

  componentDidMount = async () => {
    document.title = "Randevu Değerlendirme - İyi Uzman";
    this.randevu_detaylari();
  };


  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="col-md-12 mb-3">
                    <div className="content-card px-5 pt-4">
                        <h5 className="mb-3">Randevu Detayları</h5>
                        <div className="row">
                            <div className="col-md-6">
                                <Musteri className="mr-1" /> Uzman Bilgileri
                            </div>
                            <div className="col-md-6 mb-3">
                                <p className="gray"> { this.state.randevu.uzman_data.ad_soyad }</p>
                            </div>
                            <div className="col-md-6">
                                <Tarih className="mr-1" /> Randevu Tarih - Saat:
                            </div>
                            <div className="col-md-6 mb-3">
                                <p className="gray"><Moment format="DD.MM.YYYY HH:mm">{ this.state.randevu.tam_randevu_tarihi }</Moment></p>
                            </div>
                            <div className="col-md-6">
                                <RandevuTuru className="mr-1" /> Randevu Türü:
                            </div>
                            <div className="col-md-6 mb-3">
                                <p className="gray">{  Main.randevu_turu(this.state.randevu.randevu_turu) }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-card">
                        <div className="card-header px-5">
                            <div className="row">
                                <div className="col-md-8">
                                    <b>{ this.state.puan }</b>
                                    <br />
                                    <span className="gray">Puan</span>
                                </div>
                                
                                <div className="col-md-4 mt-2">
                                <ReactStars
                                    value={1}
                                    count={5}
                                    onChange={(e) => this.setRate(e)}
                                    size={24}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#ffd700"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-5">
                            <div className="form-group">
                                <textarea rows="10" className="form-control" onChange={ (e) => this.setYorum(e.target.value)  } placeholder="Aldığınız hizmete ait yorumunuzu giriniz."></textarea>
                            </div>
                            <div className="text-center">
                            <button className="btn green-button btn-block" onClick={ () => this.randevuDegerlendir() }>
                                Değerlendir
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 justify-content-center align-self-center">
                <img className="card-img-top" src={Degerlendirme} alt="blog görsel"/>
            </div>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">

              <div id="content">
                <div className="container">
                    <h2 className="mb-3">Randevunuzu Değerlendirin</h2>
                    { this.content() }
                </div>

                
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(RandevuDegerlendirme))