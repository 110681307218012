
import swal from 'sweetalert';
import defaultAvatar from '../assets/images/user-avatar.jpg';

import sehirler from '../assets/sehirler.json';


const domain = "https://api.iyiuzman.com";

class Main {

    resimURL(resim,yumurta = false){


        if(resim == null || resim == undefined) return defaultAvatar;

        if(!resim) return defaultAvatar;

        if(resim.includes('http')) return resim;

        if(resim.includes('base64')) return resim;

        if(resim.length < 1) {

            if(yumurta){
                return defaultAvatar;
            }

            return false;
        }

        return domain + resim;
    }

    sehirListesi(){
        let liste = [ ];

        sehirler.map(sehir => {
            sehir.ilceler.map(ilce => {
                liste.push({
                    label : sehir.il_adi + ' / ' + ilce.ilce_adi,
                    value : ilce.bolge
                })
            })
        });

        return liste;
    }

    hizmetBolgeleri(bolgeler){
        return this.sehirListesi().filter(x => bolgeler.includes(x.value))
    }

    iller(){
        let liste = [ ];

        sehirler.map(sehir => {
            liste.push({
                label : sehir.il_adi,
                value : sehir.il
            })
        });

        return liste;
    }

    ilceler(il,tumu = false){
        let liste = [ ];


        sehirler.map(sehir => {

            if(!tumu && sehir.il != il){
                return;
            }

            sehir.ilceler.map(ilce => {
                liste.push({
                    label : ilce.ilce_adi,
                    value : ilce.ilce
                })
            })
        });

        return liste;
    }

    il_adi(il){
        const il_data = this.iller().filter(x => x.value == il);

        return il_data.length > 0 ? il_data[0].label : il;
    }

    ilce_adi(ilce){
        const ilce_data = this.ilceler('',true).filter(x => x.value == ilce);

        return ilce_data.length > 0 ? ilce_data[0].label : ilce;
    }

    randevu_durum(durum){
        switch(durum){
            case "OZEL_RANDEVU" : {
                return "Özel Randevu";
            }
            case "ONAY_BEKLIYOR" : {
                return "Onay Bekliyor";
            }
            case "RANDEVU_SAATI_BEKLENIYOR" : {
                return "Randevu Saati Bekleniyor";
            }
            case "MUSTERI_IPTAL" : {
                return "Müşteri Tarafından İptal Edildi";
            }
            case "UZMAN_IPTAL" : {
                return "Uzman Tarafından İptal Edildi";
            }
            case "SISTEM_IPTAL" : {
                return "Sistem Tarafından İptal Edildi";
            }
            case "OZEL_RANDEVU_IPTAL" : {
                return "Özel Randevu İptal Edildi";
            }
            case "TAMAMLANDI" : {
                return "Tamamlandı";
            }
            case "OZEL_RANDEVU_TAMAMLANDI" : {
                return "Özel Randevu Tamamlandı";
            }
            case "TAMAMLANMAYI_BEKLIYOR" : {
                return "Tamamlanmayı Bekliyor";
            }
            default : {
                return durum;
            }
        }
    }

    hizmet_yeri(hizmet_yeri){
        switch(hizmet_yeri){
            case "EVDE" : {
                return "Evde";
            }
            case "KLINIKTE" : {
                return "Klinikte";
            }
            case "ONLINE" : {
                return "Online";
            }
            default : {
                return hizmet_yeri;
            }
        }
    }

    uzman_url(uzman){
        let id = uzman.id;
        let ad = uzman.ad.toLowerCase();
        let soyad = uzman.soyad.toLowerCase();
        let il = uzman.il.toLowerCase();
        let meslek_adi = uzman.meslek_adi.toLowerCase();

        return this.slugify('/' + id + '/' + il + '-' + meslek_adi + '/' + ad + '-' + soyad);
    }

    slug(str){
        return this.slugify(str);
    }

    slug_ad_soyad(ad,soyad){
        ad = ad.toLowerCase();
        soyad = soyad.toLowerCase();
        
        return ad + '-' + soyad;
    }

    message(baslik,hata = false){
        swal(
            hata ? 'Oops' : 'Harika!',
            baslik,
            hata ? 'error' : 'success');
    }

    slugify = function(text) {
        var trMap = {
            'çÇ':'c',
            'ğĞ':'g',
            'şŞ':'s',
            'üÜ':'u',
            'ıİ':'i',
            'öÖ':'o'
        };
        for(var key in trMap) {
            text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
        }
        return  text.replace(/[^-a-zA-Z0-9\/\s]+/ig, '') // remove non-alphanumeric chars
                    .replace(/\s/gi, "-") // convert spaces to dashes
                    .replace(/[-]+/gi, "-") // trim repeated dashes
                    .toLowerCase();
    
    }

    iptal_notlari(not){
        const secenekler = [
            {
                key     : "hatali_islem",
                value   : "Hatalı İşlem"
            },
            {
                key     : "degisen_musaitlik",
                value   : "Artık bu tarih saatte müsait değilim"
            },
            {
                key     : "acil_durum",
                value   : "Acil bir durum oluştu"
            },
            {
                key     : "hatali_tarih_saat",
                value   : "Hatalı Tarih Saat Seçimi",
            },
            {
                key     : "hatali_uzman_secimi",
                value   : "Hatalı Uzman Seçimi",
            },
            {
                key     : "hatali_hizmet_secimi",
                value   : "Hatalı Hizmet Seçimi",
            },
            {
                key     : "uzman_talebi_uzerine_iptal",
                value   : "Uzman talebi üzerine sistem tarafından iptal edildi.",
            },
            {
                key     : "musteri_talebi_uzerine_iptal",
                value   : "Müşteri talebi üzerine sistem tarafından iptal edildi.",
            }
        ];

        const secenek = secenekler.filter(x => x.key == not);

        return secenek.length > 0 ? secenek[0].value : not;
    }

    ozelYazdir(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    fatura_durum(durum){
        switch(durum){
            case "BEKLEMEDE" : {
                return "Beklemede";
            }
            case "ODENDI" : {
                return "Ödendi";
            }
            case "ODENMEDI" : {
                return "Ödenmedi";
            }
            default : {
                return durum;
            }
        }
    }

    randevu_turu(tur){
        switch(tur){
            case "EVDE" : {
                return "Evde Hizmet";
            }
            case "KLINIKTE" : {
                return "Klinikte Hizmet";
            }
            case "ONLINE" : {
                return "Online Hizmet";
            }
            default : {
                return tur;
            }
        }
    }

}

export default new Main()