import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';

class HizmetBolgeleri extends Component {

    // const iller = [
    //     { value: 'adana', label: 'Adana' },
    //     { value: 'izmir', label: 'İzmir' },
    //     { value: 'van', label: 'Van' }
    // ]
      
    // const ilceler = [
    //     { value: 'adana', label: 'Akhisar' },
    //     { value: 'izmir', label: 'Menemen' },
    //     { value: 'van', label: 'Arabacıbozköy' }
    // ]

  state = {
    yuklendi : false,
    modal1 : false,
    btn_olustur_enb : false,
    il_adi : '',
    iller : [ ]
  }

  async illeri_getir(){

      const { hata,mesaj,data } = await Api.il_listesi(Co.access_token());

      if(hata){
          swal("HATA",mesaj,"error");
          return;
      }

      this.setState({
          iller : data,
          yuklendi : true
      })
  }

  async il_sil(id){

    swal({
      title :'İl Siliniyor',
      content : <div><span>İli silmek için sil butonuna basın</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'green-button'
        },
        b2 : {
          text:'Sil',
          className: 'red-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj,data } = await Api.il_sil(id,Co.access_token());

            if(hata){
                swal("HATA",mesaj,"error");
                return;
            }
        }
        await this.illeri_getir();
    });

   
  }

  componentDidMount = async () => {
    document.title = "Hizmet Bölgeleri - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
        ctx.illeri_getir()
    },500);
  };

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

  return (
    <div className="content-card p-3">
        <div className="text-right">
            <button onClick={ () => this.toggleModal1() } className="btn light-button2">
                <i className="fas fa-plus mr-1"></i>
                Hizmet Bölgesi Ekle
            </button>
        </div>
        <div className="table-responsive mt-3">
            <table className="table table-borderless dash">
                <thead>
                    <tr>
                        <th style={ { width:100 } }>No</th>
                        <th>İl Adı</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    { this.iller() }
                </tbody>
            </table>
        </div>
    </div>
    )
  }

  

  iller(){
     return ( // Map = Foreach
        this.state.iller.map( (il,index) => {
            return (
                <tr>
                    <td> { index+1 } </td>
                    <td>{ il.il_adi }</td>
                    <td>
                        <Link
                            to={{
                                pathname : '/sistem/hizmet-bolgesi/' + il.id,
                                il : il
                            } }>
                                
                            <button className="btn light-button2">İlçelere Git</button>
                        
                        </Link>

                        <button onClick={ () => this.il_sil(il.id) } className="btn light-button2 ml-2">Sil</button>
                    </td>
                </tr>
            )
        })
     )
  }

  toggleModal1(){
      this.setState({
          modal1: !this.state.modal1
      })
  }

  setIlAdi(val){
      this.setState({ il_adi : val });
  }

  async il_olustur(){
      if(this.state.il_adi.trim().length < 1){
          swal("HATA","İl adı girilmedi!","error");
          return;
      }

      const { hata,mesaj,data } = await Api.il_olustur(this.state.il_adi,Co.access_token());

      this.illeri_getir();

      this.setState({ il_adi : '' })

      swal(mesaj);

    this.setState({
          modal1 : false
      })
  }

  modal_hizmet_bolgesi(){
      return (
        <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
            <Modal.Body>

                <div className="text-right">
                    <i onClick={ () => this.toggleModal1()  } className="fas fa-times close-popup"></i>
                </div>

                <div className="p-3">
                    <h4>Yeni Hizmet Bölgesi Oluştur</h4>
                    <hr />

                    

                    <div className="form-group">
                        <label htmlFor="il_adi">İl Adı</label>
                        <input onChange={ (e) => this.setIlAdi(e.target.value) } value={ this.state.il_adi } id="il_adi" type="text" className="form-control" />
                    </div>

                    <div className="text-right">
                        <button disabled={ this.state.btn_olustur_enb } onClick={ () => this.il_olustur() } className="btn green-button">
                            <i className="fas fa-check mr-1"></i>
                            Oluştur
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Hizmet Bölgeleri</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
            { this.modal_hizmet_bolgesi() }

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(HizmetBolgeleri))