import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';
import SearchBar from '../Components/SearchBar';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';

import Pagination from '../Components/Pagination';

class UzmanOnayIstekleri extends Component {

  state = {
    yuklendi : false,
    uzmanlar : [],
    ara : '',
    sayfa: 1,
    toplam_sayfa : 0
  }

  async uzmanlari_getir(){
      
      const { hata,mesaj,data } = await Api.uzman_listesi({ ara : this.state.ara, onay_bekliyor: 1 , sayfa : this.state.sayfa },Co.access_token());

      if(hata){
          swal("HATA",mesaj,"error");
      }
      
      this.setState({
          uzmanlar          : data == null ? [ ] : data.uzmanlar,
          toplam_sayfa      : data == null ? 0 : data.toplam_sayfa,
          yuklendi          : true
      })
  }


  ara(e){
    this.uzmanlari_getir();
  }

  onKeyDown(e){
      if(e.key == 'Enter'){
        this.uzmanlari_getir();
      }
  }


  componentDidMount = async () => {
    document.title = "Uzman Onay İstekleri - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
        ctx.uzmanlari_getir()
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-3">
            <div className="col-md-12 bg-light p-3">
                <SearchBar placeHolder="Uzman ara..." onKeyDown={ (e) => this.onKeyDown(e) } onClick={ (e) => this.ara(e) } onChange={ (e) => this.setState( { ara: e.target.value } ) } />
            </div>
            <div className="table-responsive mt-3">
                <table className="table table-borderless dash">
                    <thead>
                        <tr>
                            <th style={ { width:100 } }>No</th>
                            <th>Ad</th>
                            <th>Soyad</th>
                            <th>E-posta</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        { this.uzmanlar() }
                    </tbody>

                </table>
            </div>

            {
                this.state.uzmanlar.length < 1
                ?
                <>
                <h5 className="text-center gray p-5">Hiç kayıt bulunamadı.</h5>
                </> : <> </>
            }

            <div className="text-center">
                <Pagination
                toplam_sayfa={ this.state.toplam_sayfa }
                sayfa={ this.state.sayfa }
                ileriClick={ () => this.ileri() }
                geriClick={ () => this.geri() }
                />
            </div>
            
        </div>
      )
  }

  ileri(son = false){

      if(!son && this.state.sayfa >= this.state.toplam_sayfa) return;

      this.setState({
          sayfa : son ? this.state.toplam_sayfa : this.state.sayfa + 1
      })

      this.uzmanlari_getir()
  }

  geri(ilk = false){

    if(!ilk && this.state.sayfa <= 1) return;

    this.setState({
            sayfa : ilk ? 1 : this.state.sayfa - 1
    })

    this.uzmanlari_getir()
  }
  

  uzmanlar(){
     return ( // Map = Foreach
        this.state.uzmanlar.map( (uzman,index) => {
            return (
                <tr>
                    <td> { index+1 } </td>
                    <td>{ uzman.ad }</td>
                    <td>{ uzman.soyad }</td>
                    <td>{ uzman.email }</td>
                    <td>
                        <Link to={ {
                            pathname: '/sistem/uzman-goruntule/' + uzman.id,
                        } }>
                        <button className="btn light-button2">Görüntüle</button>
                        </Link>
                    </td>
                </tr>
            )
        })
     )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Uzman Onay İstekleri</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(UzmanOnayIstekleri))