import React, { Component } from 'react';
import SearchBar from '../Components/SearchBar';
import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';

class YeniUzman extends Component {

  state = {
    yuklendi : false,
    btn_uzman_ekle: false,
    ad : '',
    soyad : '',
    email : '',
    password : '',
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    document.title = "Yeni Uzman - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  setAd(ad){
      this.setState({
        ad
      });
  }
  setSoyad(soyad){
      this.setState({
        soyad
      });
  }
  setEmail(email){
      this.setState({
        email
      });
  }
  setPassword(password){
      this.setState({
        password
      });
  }

  async kayitOl(){
    this.setState({
        btn_uzman_ekle : true
    });
    const { hata,mesaj } = await Api.uzman_olustur(
        this.state.ad,
        this.state.soyad,
        this.state.email,
        this.state.password,
        Co.access_token(),
    );
    this.setState({
        btn_uzman_ekle : false
    });
    if(hata){
        swal("Oops!",mesaj,'error');
        return;
    }

    this.setState({
        ad: '',
        soyad: '',
        email: '',
        password : '',
    });
    swal("Harika!",mesaj,"success");
  }

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card col-md-6 p-3">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="ad">Ad</label>
                        <input onChange={ (e) => this.setAd(e.target.value) } type="text" value={ this.state.ad } className="form-control" id="ad"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="soyad">Soyad</label>
                        <input onChange={ (e) => this.setSoyad(e.target.value) } type="text" value={ this.state.soyad } className="form-control" id="soyad"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input onChange={ (e) => this.setEmail(e.target.value) } type="text" value={ this.state.email } className="form-control" id="email"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="password">Şifre</label>
                        <input onChange={ (e) => this.setPassword(e.target.value) } type="password" value={ this.state.password } className="form-control" id="password"/>
                    </div>
                </div>
                <div className="col-md-12">
                    <button disabled={ this.state.btn_uzman_ekle } onClick={ () => this.kayitOl() } className="btn green-button btn-block py-2">
                        {
                            !this.state.btn_uzman_ekle ? 'Kaydet' : 'Kayıt ediliyor...'
                        }
                    </button>
                </div>
            </div>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Yeni Uzman</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(YeniUzman))