import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from 'react-router-dom';
import { SET_USER_DATA } from '../../../Redux/actionTypes';

import Api from '../../../Functions/Api';
import Co from '../../../Functions/Co';
import Main from '../../../Functions/Main';

class Stepper extends Component {

  state = {
    btn_onay        : true,
    kisisel_profil  : false,
    uzman_profili   : false
  }

  componentDidMount = () => {
    
    if(this.props.user.meslek_id != null && this.props.user.meslek_unvani != null){
      const kisisel_profil = (
        ( this.props.kimlik_onaylandi != null && !this.props.user.kimlik_onaylandi)
        || ( this.props.email_dogrulandi != null && !this.props.user.email_dogrulandi)
        || ( this.props.telefon_dogrulandi != null && !this.props.user.telefon_dogrulandi)
      ) ? false : true;
  
      const uzman_profili =
      (
        (this.props.user.meslek_id != null && this.props.user.meslek_id < 1)
        || (this.props.user.meslek_unvani != null && this.props.user.meslek_unvani.length < 1)
        || (this.props.user.verilen_hizmetler != null && this.props.user.verilen_hizmetler.length < 1)
        || (this.props.user.diploma != null && this.props.user.diploma.length < 1)
      ) ? false : true;
  
      this.setState({ kisisel_profil, uzman_profili })

    }

  };
  

  async profili_onaya_gonder(){

    this.setState({
      btn_onay: false,
    });

    var { hata,mesaj,data } = await Api.profili_onaya_gonder(Co.access_token());

    this.setState({
      btn_onay: true,
    });

    if(hata){
        Main.message(mesaj,true);
        return;
    }
    this.setState({
      onay_bekliyor: 1,
    });

    let user_data = this.props.user;
    user_data.onay_bekliyor = true;

    let data2 = { ...user_data };

    this.props.set_user(data2);

    Main.message(mesaj);
  }

  render(){

    if(this.props.user.onaylandi != null && this.props.user.onaylandi) return null;

    let step1 = this.state.kisisel_profil ? 'true' : 'false';
    
    let step2 = this.state.uzman_profili ? 'true' : 'false';
      
    let step3 = this.props.user.onay_bekliyor ? 'true' : 'false';

    return (
      <div className="row">
        <div className="col"></div>
        <div className="content-card col-md-9 mb-3 pt-4">
          
          <p className="text-center">
            { this.props.user.onay_bekliyor ? 'Profilinizin şuanda onay aşamasında' : 'Profilinizin doğrulanması için gerekli adımlar'  }
          </p>
          
          <div className="card-body">
            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">

              <div className="step" completed={ step1 }>
                <div className="step-icon-wrap">
                  <Link to={ "/uzman/profili-duzenle/kisisel-bilgiler" }><div className="step-icon">1</div></Link>
                </div>
                <h4 className="step-title">Kişisel Bilgiler</h4>
              </div>

              <div className="step" completed={ step2 }>
                <div className="step-icon-wrap">
                  <Link to={ "/uzman/profili-duzenle/uzman-profili" }><div className="step-icon">2</div></Link>
                </div>
                <h4 className="step-title">Uzman Profili</h4>
              </div>

              <div className="step" completed={ step3 }>
                <div className="step-icon-wrap">
                  <div className="step-icon">3</div>
                </div>
                <h4 className="step-title">Onay Bekliyor</h4>
              </div>
              
            </div>

              {/* Onaya göndermeye uygun */}
              {
                (this.state.kisisel_profil && this.state.uzman_profili && !this.props.user.onay_bekliyor) ?
                <>
                <div className="text-center">
                    <button onClick={ () => this.profili_onaya_gonder() } className="btn red-button p-3 m-3" style={{minWidth: '250px'}}>
                      {
                          this.state.btn_onay ?
                          <>
                            Profilimi onaya gönder
                          </>
                          :
                          <>
                              <div class="spinner-border mr-3" role="status"></div>
                              Gönderiliyor...
                          </>
                      }
                    </button>
                </div>
                </>
                :
                (this.props.user.onay_bekliyor) ? '' :
                <>
                <div className="alert alert-warning mt-3">
                  Profiliniz şuanda onaya göndermek için uygun değildir!
                  <br />
                  Lütfen kişisel bilgiler sayfasında; <strong>telefon, email ve T.C kimlik</strong> doğrulamalarınızı yaptığınızdan emin olun.
                  <hr />

                  Uzman profili sayfasında; <strong>Meslek seçimi, ünvan seçimi, vereceğiniz hizmetlerin seçimi, hizmet bölgeleri seçimi ve diploma </strong> belgenizi yüklemeniz gerekmektedir.
                </div>
                </>
              }
              
              {
                ( this.props.user.son_onay_red != null && !this.props.user.onay_bekliyor ) ?
                <>
                  <div class="alert alert-danger p-4" role="alert">
                    <p className="lead">
                    Profilinizi onaylama isteğiniz { this.props.user.son_onay_red.tarih } tarihinde yönetici tarafından kabul edilmedi. Bilgilerinizi kontrol ederek tekrar onay isteği gönderebilirsiniz.
                    </p>
                    <h5 className="text-secondary">Reddedilme nedenleri</h5>
                    <p className="lead">
                      { this.props.user.son_onay_red.aciklama }
                    </p>
                  </div>
                </>
                :
                ''
              }

          </div>

        </div>
        <div className="col"></div>
      </div>
    )
  }
}


function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        set_user : (value) => dispatch({ type : SET_USER_DATA,payload : value })
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Stepper))