import { ReactComponent as LinkedIn } from '../../assets/images/in.svg';
import { ReactComponent as FaceBook } from '../../assets/images/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';

import { ReactComponent as WhatsApp } from '../../assets/images/whatsapp2.svg';
import { ReactComponent as Telefon } from '../../assets/images/telefon3.svg';

import ReactWhatsapp from 'react-whatsapp';

export default function ActionBar(){
    return (
        <div id="action-bar">
            
            <div className="container">
                <div className="sosyal-medya">
                    <a className="social" href="#">
                        <LinkedIn/>
                    </a>
                    
                    <a className="social" href="#">
                        <FaceBook/>
                    </a>
                    
                    <a className="social" href="#">
                        <Instagram/>
                    </a>    
                </div>

                <div className="d-flex bg-white">
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=905451792980&text=Merhaba+detayli+bilgi+almak+istiyorum." className="btn text-white bg-green btn-sm mr-2">
                        <WhatsApp/> WhatsApp
                    </a>
                    <a href="tel:08503052980" className="btn btn-dark btn-sm">
                        <Telefon/> Randevu: 0850 305 29 80
                    </a>
                </div>

            </div>

        </div>
    )
}