import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';

import * as actionTypes from '../Redux/actionTypes';

import { connect } from 'react-redux';

import Api from '../Functions/Api';
import Co from '../Functions/Co';
import Main from '../Functions/Main';

class Giris extends Component {

  state = {
      email     : '',
      sifre     : '',
      hata      : '',
      btn_giris : true
  }

  setEmail(email){
      this.setState({ email })
  }

  setSifre(sifre){
    this.setState({ sifre })
  }

  close(){
    this.props.giris_pop_toggle(false);

    this.setState({
        email   : '',
        sifre   : '',
        hata    : ''
    })
  }

  async girisYap(){
    this.setState({ hata : '', btn_giris : false });
    
    let { hata,mesaj,data } = await Api.token_al(this.state.email,this.state.sifre);

    if(hata){
        this.setState({ hata : mesaj, btn_giris : true });
        return;
    }else{
        data.token_data.expiry = (new Date().getTime() / 1000) + data.token_data.expires_in;
        Co.setItem('login',true);
        Co.setItem('auth',data.token_data);
        
        this.props.set_fotograf( Main.resimURL(data.user_data.fotograf) );
        this.props.set_user(data.user_data);
        this.props.giris_pop_toggle(false);
        this.props.history.push('/dashboard');
    }

    this.setState({ hata : '', btn_giris : true });
  }

  render() {

    return (
        <Modal id="giris-modal" show={ this.props.giris_pop } dialogClassName="modal-90w" centered>
            <Modal.Body>
                <div className="text-right mr-2 mt-2">
                    <i onClick={ () => this.close() } className="fas fa-times close-popup"></i>
                </div>
                <div className="row">
                    
                    <div className="col-md-10 offset-md-1 col-10 offset-1 pt-3 pb-5">
                        <h4 class="text-center">Hesabınıza Giriş Yapın</h4>

                        <div className="form-group mt-4">
                            <label htmlFor="email">E-posta</label>
                            <input value={ this.state.email } onChange={ (e) => this.setEmail(e.target.value) } id="email" type="text" className="form-control" placeholder='E-posta adresinizi giriniz'/>
                        </div>

                        <div className="form-group mt-2 mb-2">
                            <label htmlFor="sifre">Şifre</label>
                            <input value={ this.state.sifre } onChange={ (e) => this.setSifre(e.target.value) } id="sifre" type="password" className="form-control" placeholder='Şifrenizi giriniz'/>
                        </div>

                        <div className="text-right">
                            <Link to={ '/sifremi-unuttum' }>
                                <span>Şifrenizi mi unuttunuz?</span>
                            </Link>
                        </div>

                        {
                            this.state.hata.length > 0 ?
                            <>
                            <div className="alert alert-danger mt-3">
                                { this.state.hata }
                            </div>
                            </>
                            :
                            <>
                            </>
                        }

                        <button disabled={ !this.state.btn_giris } onClick={ () => this.girisYap()  } className="btn green-button btn-block btn-lg mt-2">
                            {
                                this.state.btn_giris ?
                                <>
                                    <span className="ml-3 dark">Giriş Yap</span>   
                                </>
                                :
                                <>
                                    <div class="spinner-border text-dark" role="status"></div>
                                    <span className="ml-3 dark">Giriş Yapılıyor...</span>
                                </>
                            }
                        </button>

                        <div className="text-center mt-3">
                            <span className="dark">Hesabın yok mu? </span>
                            <Link onClick={ () => this.props.giris_pop_toggle(false) } to={ '/kayit-ol' }>Kayıt Ol</Link>
                        </div>
                    </div>
                    
                </div>

            </Modal.Body>

        </Modal>
    );
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : (value) => dispatch( { type: actionTypes.GIRIS_POP_TOGGLER, payload : value } ),
        set_user : ( value ) => dispatch({ type : actionTypes.SET_USER_DATA, payload : value }),
        set_fotograf : ( value ) => dispatch({ type : actionTypes.SET_FOTOGRAF, payload : value }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Giris));