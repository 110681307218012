import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import { ReactComponent as KisiselBilgilerim } from './../../assets/images/uzman-menu/user.svg';
import { ReactComponent as UzmanProfilim } from './../../assets/images/uzman-menu/uzman-profilim.svg';
import { ReactComponent as Password } from './../../assets/images/uzman-menu/password.svg';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';

class SidebarItem extends Component {

  render() {

    const { icon,baslik } = this.props;
    var active = false;
    if('/uzman/profili-duzenle/kisisel-bilgiler' == this.props.location.pathname || '/uzman/profili-duzenle/uzman-profili' == this.props.location.pathname || '/uzman/profili-duzenle/sifre-degistir' == this.props.location.pathname){
         active = true;    
    }

    return (
        <div>
            <li className="list-group-item" data-active={ active }>
                <a data-toggle="collapse" href="#profil" role="button" aria-expanded="false" aria-controls="profil">
                    { icon }
                    <span>{ baslik }</span>
                </a>
            </li>
            <ul className="list-group collapse pl-3" id="profil">
                <li onMouseUp={ () => this.props.sidebar_toggle(false) } className="list-group-item">
                    <Link to='/uzman/profili-duzenle/kisisel-bilgiler'>
                        <KisiselBilgilerim />
                        <span>Kişisel Bilgilerim</span>
                    </Link>
                </li>
                <li onMouseUp={ () => this.props.sidebar_toggle(false) } className="list-group-item">
                    <Link to='/uzman/profili-duzenle/uzman-profili'>
                        <UzmanProfilim />
                        <span>Uzman Profilim</span>
                    </Link>
                </li>
                <li onMouseUp={ () => this.props.sidebar_toggle(false) } className="list-group-item">
                    <Link to='/uzman/profili-duzenle/sifre-degistir'>
                          <Password />
                        <span>Şifreyi Değiştir</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar   : state.sidebar,
        user      : state.user,
        fotograf  : state.fotograf
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value }),
        set_user : ( value ) => dispatch({ type : actionTypes.SET_USER_DATA, payload : value })
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SidebarItem))