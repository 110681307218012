import { ReactComponent as Musteri } from '../../assets/images/musteri.svg';
import { ReactComponent as Tarih } from '../../assets/images/tarih.svg';
import { ReactComponent as IlIlce } from '../../assets/images/ililce.svg';
import { ReactComponent as Adres } from '../../assets/images/adres.svg';
import { ReactComponent as RandevuTuru } from '../../assets/images/randevu-turu.svg';
import { ReactComponent as HizmetKonusu } from '../../assets/images/almak-istenilen-hizmet.svg';
import { ReactComponent as MusteriNotlari } from '../../assets/images/randevu-notlari.svg';
import { ReactComponent as RandevuDurumu } from '../../assets/images/randevu-durumu.svg';
import { ReactComponent as Call } from '../../assets/images/call.svg';
import { ReactComponent as Not } from '../../assets/images/randevu-notlari.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';

import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

import Moment from 'react-moment';

class RandevuDegerlendirme extends Component {

    state = {
        yuklendi    : false,
        modal       : false,
        randevu     : { },
        iptal_notu  : '',
        iptal_secim : '',
      }
    
      componentDidMount() {
        document.title = "Randevu Detayları - İyi Uzman";
        document.querySelector('meta[name="description"]').setAttribute("content", "Randevu Detayları, İyi Uzman, 0850 305 29 80");
        this.randevu_detaylari();
      };
    
      async randevu_detaylari(){
        const { hash_id } = this.props.match.params;
        
        const { hata,mesaj,data } = await Api.randevu_detaylari(hash_id);
    
        if(!hata){
            this.setState({ yuklendi : true,randevu : data });
        }else{
            Main.message(mesaj,hata);
        }
      }
    
    async iptal_et(){

      if(this.state.iptal_secim == ''){
          Main.message('Lütfen iptal nedeninizi seçin',true);
          return;
      }

      const iptal_notu = this.state.iptal_secim == 'diger' ? this.state.iptal_notu : this.state.iptal_secim;

      const { hata, mesaj } = await Api.musteri_randevu_iptal(this.state.randevu.hash_id,iptal_notu);
      this.setState({ modal : false })
      Main.message(mesaj,hata);

      if(!hata){
        this.randevu_detaylari();
      }
    }

    async aramaTalebi(){

        swal({
            title : 'Bir dakika...',
            text : '0850 305 29 80 Numaralı telefondan aranacaksınız telefonunuzu hazır bulundurun. Görüşmelerde kişisel telefon numarası paylaşılması yasaktır. Görüşmelerimiz kalite standartları gereği kayıt altındadır.',
            buttons : {
                iptal : {
                    text : 'Vazgeç',
                    className : 'red-button'
                },
                devam : {
                    text : 'Onaylıyorum',
                    className : "green-button"
                }
            },
        }).then( async (btn) => {
    
            if(btn == 'devam'){
                const { hata, mesaj } = await Api.randevuya_arama_talebi_olustur(this.state.randevu.hash_id,"Müşteri tarafından oluşturulmuştur.");
                Main.message(mesaj,hata);
            }
    
        })
      }

    content(){

        if(!this.state.yuklendi){
        return (
            <PageLoading/>
        )
        }

        return (
            <div className="content-card p-5 col-md-10">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-12">
                        <div className="table-responsive">
                            
                            <table id="tablo-randevu-detaylari">
                                <tr>
                                    <td> <Tarih className="mr-2" /> </td>
                                    <td> Randevu Tarih - Saat </td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { this.state.randevu.tam_randevu_tarihi }
                                    </td>
                                </tr>

                                <tr>
                                    <td> <Musteri className="mr-2" /> </td>
                                    <td> Uzman </td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { this.state.randevu.uzman_data.ad_soyad }
                                    </td>
                                </tr>

                                <tr>
                                    <td> <Musteri className="mr-2" /> </td>
                                    <td> Müşteri </td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { this.state.randevu.musteri_adi + ' ' + this.state.randevu.musteri_soyadi }
                                    </td>
                                </tr>

                                {
                                    this.state.randevu.randevu_turu == 'EVDE' ?
                                    <>
                                    <tr>
                                        <td> <IlIlce className="mr-2" /> </td>
                                        <td> Randevu İl ve ilçesi </td>
                                        <td className='px-3'> : </td>
                                        <td>
                                            { Main.il_adi(this.state.randevu.il) + ' / ' + Main.ilce_adi(this.state.randevu.ilce)  }
                                        </td>
                                    </tr>
                                    </> : <></>
                                }

                                {
                                this.state.randevu.randevu_turu != 'ONLINE' ?
                                <>
                                <tr>
                                    <td> <Adres className="mr-2" /> </td>
                                    <td> { this.state.randevu.randevu_turu == 'KLINIKTE' ? 'Klinik Adresi' : 'Adres' } </td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { this.state.randevu.randevu_turu == 'EVDE' ? this.state.randevu.mahalle : this.state.randevu.klinik_adresi }
                                    </td>
                                </tr>
                                </>
                                :
                                <></>
                                }
                                <tr>
                                    <td> <RandevuTuru className="mr-2" /> </td>
                                    <td> Hizmet Yeri </td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { Main.hizmet_yeri(this.state.randevu.randevu_turu) } Hizmet
                                    </td>
                                </tr>

                                <tr>
                                    <td> <HizmetKonusu className="mr-2" /> </td>
                                    <td> Hizmet Konusu</td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { this.state.randevu.hizmet_data.hizmet.hizmet_adi }
                                    </td>
                                </tr>

                                <tr>
                                    <td> <MusteriNotlari className="mr-2" /> </td>
                                    <td> Müşteri Notları</td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { this.state.randevu.musteri_notlari }
                                    </td>
                                </tr>
                                
                                {
                                this.state.randevu.durum == 'RANDEVU_SAATI_BEKLENIYOR' && this.state.randevu.randevu_turu == 'ONLINE' ? 
                                <>
                                <tr>
                                    <td> <MusteriNotlari className="mr-2" /> </td>
                                    <td> Uzman Notları</td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { 
                                        this.state.randevu.uzman_notlari == undefined || this.state.randevu.uzman_notlari == "" ?
                                        <>
                                            Uzmanın notları randevuya 2 saat kala gösterilecektir.
                                        </>
                                        :
                                        <>
                                            {this.state.randevu.uzman_notlari}
                                        </>
                                        }
                                    </td>
                                </tr>
                                </>
                                :
                                <></>
                                }
                                <tr>
                                    <td> <RandevuDurumu className="mr-2" /> </td>
                                    <td> Randevu Durumu</td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        <span className="text-warning">
                                            { Main.randevu_durum(this.state.randevu.durum) }
                                        </span>
                                    </td>
                                </tr>

                                {
                                    (this.state.randevu.durum == 'UZMAN_IPTAL' || this.state.randevu.durum == 'MUSTERI_IPTAL' || this.state.randevu.durum == 'SISTEM_IPTAL') ?
                                    <>
                                        <tr>
                                            <td> <Not className="mr-2" /> </td>
                                            <td> İptal Notu</td>
                                            <td className='px-3'> : </td>
                                            <td>
                                                <span className="text-danger">
                                                    { Main.iptal_notlari(this.state.randevu.iptal_notu) }
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <Tarih className="mr-2" /> </td>
                                            <td> İptal Edilme Tarihi - Saati</td>
                                            <td className='px-3'> : </td>
                                            <td>
                                                <Moment format="DD.MM.YYYY HH:mm">{ this.state.randevu.iptal_tarihi }</Moment>
                                            </td>
                                        </tr>
                                    </>
                                    :
                                    <></>
                                }



                            </table>

                            {
                                this.state.randevu.durum == 'RANDEVU_SAATI_BEKLENIYOR' ?
                                <>
                                    <button onClick={ () => this.aramaTalebi() } className="btn green-button btn-block mt-3">
                                        <Call /> Uzmanı Ara
                                    </button>
                                </>
                                :
                                <></>
                            }
                            
                            {
                                this.state.randevu.durum == 'RANDEVU_SAATI_BEKLENIYOR' || this.state.randevu.durum == 'ONAY_BEKLIYOR' ? 
                                <>
                                    <button onClick={ () => this.setState({ modal : true }) } className="btn red-button btn-block">
                                        Randevuyu İptal et
                                    </button>
                                </>
                                :
                                <></>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">

              <div id="content">
                <div className="container">
                    <h2 className="mb-3">Randevu Detayları</h2>
                    { this.content() }
                </div>

              </div>

            </div>

            <Footer/>


            <Modal show={ this.state.modal } dialogClassName="modal-90w" centered>
                <Modal.Body>
                    <div className="text-right mr-2 mt-2">
                        <i onClick={ () => this.setState({ modal : false }) } className="fas fa-times close-popup"></i>
                    </div>

                    <div className='form-group'>
                        <label className='text-danger' htmlFor='iptal_notlari'>İptal Nedeniniz nedir?</label>
                        <select value={ this.state.iptal_secim } onChange={ (e) => this.setState({ iptal_secim : e.target.value }) } defaultValue='' className='form-control'>
                            <option value="" disabled>Seçiniz</option>
                            <option value="hatali_tarih_saat">Hatalı Tarih Saat Seçimi</option>
                            <option value="hatali_uzman_secimi">Hatalı Uzman Seçimi</option>
                            <option value="hatali_hizmet_secimi">Hatalı Hizmet Seçimi</option>
                            <option value="degisen_musaitlik">Artık bu tarih saatte müsait değilim</option>
                            <option value="acil_durum">Acil bir durum oluştu</option>
                            <option value="diger">Diğer</option>
                        </select>
                    </div>

                    {
                        this.state.iptal_secim == 'diger' ?
                        <>
                        <div className="form-group">
                            <label htmlFor="iptal_notu" className="text-danger">Randevuyu neden iptal etmek istediğinizi açıklar mısınız? </label>
                            <textarea value={ this.state.iptal_notu } onChange={ (e) => this.setState({ iptal_notu : e.target.value }) } id="iptal_notu" rows="3" className="form-control"></textarea>
                        </div>
                        </>
                        : ''
                    }
                    
                    <div className="text-right">
                        <button onClick={ () => this.setState({ modal : false }) } className="btn green-button">
                            Vazgeç
                        </button>
                        <button onClick={ () => this.iptal_et() } className="btn red-button ml-2">
                            Gönder
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(RandevuDegerlendirme))