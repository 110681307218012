import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';
import Main from '../../Functions/Main';

class MeslekGruplari extends Component {

  state = {
    yuklendi : false,
    modal1: false,
    btn_olustur_enb : false,
    yeni_meslek_adi : '',
    yeni_evde_komisyon_bedeli : 0,
    yeni_klinikte_komisyon_bedeli : 0,
    yeni_online_komisyon_bedeli : 0,
    alt_meslek_gruplari :  [ ],
    secilen_meslek : '',
    evde_komisyon_bedeli : 0,
    klinikte_komisyon_bedeli : 0,
    online_komisyon_bedeli : 0,
    guncel_meslek_adi : '',
  }

  async alt_meslekleri_getir(){

      const { hata,mesaj,data } = await Api.alt_meslek_grubu_listesi(this.props.match.params.id,Co.access_token());
    
      if(hata){
          swal("HATA",mesaj,"error");
      }

      const { meslek_adi,evde_komisyon_bedeli, klinikte_komisyon_bedeli, online_komisyon_bedeli } = data[0].ana_kategori[0];

      this.setState({
          alt_meslek_gruplari : data == null ? [ ] : data[0].alt_kategoriler,
          yuklendi : true,
          evde_komisyon_bedeli,
          klinikte_komisyon_bedeli,
          online_komisyon_bedeli,
          guncel_meslek_adi : meslek_adi
      })
      document.title = meslek_adi + " - Meslek Grubu - İyi Uzman";
  }

  async alt_meslek_grubu_sil(id){

    swal({
      title :'Meslek Siliniyor',
      content : <div><span>Mesleği silmek için sil butonuna basın.</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'green-button'
        },
        b2 : {
          text:'Sil',
          className: 'red-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj,data } = await Api.alt_meslek_grubu_sil(id,Co.access_token());

            if(hata){
                swal("HATA",mesaj,"error");
            }
        }
        await this.alt_meslekleri_getir();
    });

   
  }


  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
      
    if(this.props.location.meslek_adi == null){
        this.props.history.push('/sistem/meslek-gruplari');
        return;
    }else{
        this.setState({
            secilen_meslek : this.props.location.meslek_adi
        })
    }
    
    const ctx = this;
    setTimeout( async () => {
        await ctx.alt_meslekleri_getir();
    },500);
  };

  async meslek_grubu_duzenle(){

    const { id } = this.props.match.params;
    
    const params = {
        meslek_grubu_id             : id,
        meslek_adi                  : this.state.guncel_meslek_adi,
        evde_komisyon_bedeli        : this.state.evde_komisyon_bedeli,
        klinikte_komisyon_bedeli    : this.state.klinikte_komisyon_bedeli,
        online_komisyon_bedeli      : this.state.online_komisyon_bedeli,
    };
    
    const { hata,mesaj,data } = await Api.meslek_grubu_duzenle(params);

    Main.message(mesaj,hata);
  }

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="row">
            <div className="col-md-4">
                <div className="content-card p-3">
                    <p className="lead text-primary">Hızlı Düzenle</p>
                    <table>
                        <tr>
                            <td>Meslek Adı</td>
                            <td className='px-2'>:</td>
                            <td>
                                <input onChange={ (e) => this.setState({ guncel_meslek_adi : e.target.value }) } value={ this.state.guncel_meslek_adi } type="text" className="form-control mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={ 3 }>
                                <p className="lead text-danger my-3">
                                    Komisyon Oranları
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Evde Hizmet</td>
                            <td className='px-2'>:</td>
                            <td>
                                <input onChange={ (e) => this.setState({ evde_komisyon_bedeli : e.target.value }) } value={ this.state.evde_komisyon_bedeli } type="text" className="form-control mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td>Klinikte Hizmet</td>
                            <td className='px-2'>:</td>
                            <td>
                                <input onChange={ (e) => this.setState({ klinikte_komisyon_bedeli : e.target.value }) } value={ this.state.klinikte_komisyon_bedeli } type="text" className="form-control mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td>Online Hizmet</td>
                            <td className='px-2'>:</td>
                            <td>
                                <input onChange={ (e) => this.setState({ online_komisyon_bedeli : e.target.value }) } value={ this.state.online_komisyon_bedeli } type="text" className="form-control mt-2" />
                            </td>
                        </tr>
                    </table>
                    
                    <div className="text-right mt-3">
                        <button onClick={ () => this.meslek_grubu_duzenle() } className="btn green-button">
                            <i className="fas fa-check mr-1"></i>
                            Güncelle
                        </button>
                    </div>

                </div>
            </div>
            <div className="col-md-12">
                <div className="content-card p-3 mt-3">
                    <div className="text-right">
                            <button onClick={ () => this.toggleModal1() } className="btn light-button2">
                                <i className="fas fa-plus mr-1"></i>
                                Meslek Alt Grubu Ekle
                            </button>
                    </div>
                    <div className="table-responsive mt-3">
                            <table className="table table-borderless dash">
                                <thead>
                                    <tr>
                                        <th style={ { width:100 } }>No</th>
                                        <th>Meslek Adı</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    { this.alt_meslek_gruplari() }
                                </tbody>

                            </table>
                    </div>
                </div>
            </div>
        </div>
      )
  }

  alt_meslek_gruplari(){
     return ( // Map = Foreach
        this.state.alt_meslek_gruplari.map( (meslek_grubu,index) => {
            return (
                <tr>
                    <td> { index+1 } </td>
                    <td>{ meslek_grubu.meslek_adi }</td>
                    <td>
                        <Link to={ {
                            pathname : '/sistem/meslek-hizmetleri/' + meslek_grubu.id,
                            meslek_adi : meslek_grubu.meslek_adi
                        } }>
                            <button className="btn light-button2">Meslek Hizmetleri</button>
                        </Link>
                        <Link to={ {
                            pathname : '/sistem/meslek-unvanlari/' + meslek_grubu.id,
                            meslek_adi : meslek_grubu.meslek_adi
                        } }>
                            <button className="btn light-button2">Meslek Ünvanları</button>
                        </Link>
                        <button onClick={ () => this.alt_meslek_grubu_sil(meslek_grubu.id) } className="btn light-button2">Sil</button>
                    </td>
                </tr>
            )
        })
     )
  }

  modal_meslek_grubu(){
      return (
        <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
            <Modal.Body>

                <div className="text-right">
                    <i onClick={ () => this.toggleModal1()  } className="fas fa-times close-popup"></i>
                </div>

                <div className="p-3">
                    <h4>Yeni Meslek Grubu</h4>
                    <hr />

                    

                    <div className="form-group">
                        <label htmlFor="yeni_meslek_adi">Meslek Adı</label>
                        <input onChange={ (e) => this.setYeniMeslekAdi(e.target.value) } value={ this.state.yeni_meslek_adi } id="yeni_meslek_adi" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="yeni_evde_komisyon_bedeli">Evde Komisyon Bedeli</label>
                        <input onChange={ (e) => this.setYeniEvdeKomisyonBedeli(e.target.value) } value={ this.state.yeni_evde_komisyon_bedeli } id="yeni_evde_komisyon_bedeli" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="yeni_klinikte_komisyon_bedeli">Klinikte Komisyon Bedeli</label>
                        <input onChange={ (e) => this.setYeniKlinikteKomisyonBedeli(e.target.value) } value={ this.state.yeni_klinikte_komisyon_bedeli } id="yeni_klinikte_komisyon_bedeli" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="yeni_online_komisyon_bedeli">Online Komisyon Bedeli</label>
                        <input onChange={ (e) => this.setYeniOnlineKomisyonBedeli(e.target.value) } value={ this.state.yeni_online_komisyon_bedeli } id="yeni_online_komisyon_bedeli" type="text" className="form-control" />
                    </div>

                    <div className="text-right">
                        <button disabled={ this.state.btn_olustur_enb } onClick={ () => this.alt_meslek_grubu_olustur() } className="btn green-button">
                            <i className="fas fa-check mr-1"></i>
                            Oluştur
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      )
  }

  toggleModal1(){
      this.setState({
          modal1: !this.state.modal1
      })
  }

  setYeniMeslekAdi(val){
      this.setState({ yeni_meslek_adi : val });
  }

  setYeniEvdeKomisyonBedeli(val){
      this.setState({ yeni_evde_komisyon_bedeli : val });
  }

  setYeniKlinikteKomisyonBedeli(val){
      this.setState({ yeni_klinikte_komisyon_bedeli : val });
  }

  setYeniOnlineKomisyonBedeli(val){
      this.setState({ yeni_online_komisyon_bedeli : val });
  }

  async alt_meslek_grubu_olustur(){
      if(this.state.yeni_meslek_adi.trim().length < 1){
          swal("HATA","Meslek adı girilmedi!","error");
          return;
      }

      const { hata,mesaj,data } = await Api.alt_meslek_grubu_olustur(this.props.match.params.id,this.state.yeni_meslek_adi,this.state.yeni_evde_komisyon_bedeli,this.state.yeni_klinikte_komisyon_bedeli,this.state.yeni_online_komisyon_bedeli,Co.access_token());

      this.alt_meslekleri_getir();

      this.setState({ 
          yeni_meslek_adi : '' ,
          yeni_evde_komisyon_bedeli : 0 ,
          yeni_klinikte_komisyon_bedeli : 0 ,
          yeni_online_komisyon_bedeli : 0 ,
        })

      swal(mesaj);

    this.setState({
          modal1 : false
      })
  }



  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">{ this.state.secilen_meslek }</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            { this.modal_meslek_grubu() }
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MeslekGruplari))