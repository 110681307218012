import { ReactComponent as Telefon } from '../../assets/images/telefon2.svg';

import { isMobile } from 'react-device-detect';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';
import { Link,withRouter } from 'react-router-dom';

import Co from '../../Functions/Co';
import swal from '@sweetalert/with-react';

import Logo from '../../assets/images/logo.png';

class NavBar extends Component {

  giris(){
    if(Co.auth()){
        this.props.history.push('/dashboard');
    }else{
        this.props.giris_pop_toggle(true)
    }
  }

  logout(){

    const ctx = this;

    swal({
      title :'Çıkış Yapılıyor',
      content : <div><span>Çıkış yapmak için çıkış butonuna basın</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'red-button'
        },
        b2 : {
          text:'Çıkış Yap',
          className: 'green-button',
        }
      }
    }).then(function(result){
      if(result == "b2"){
        ctx.props.set_user( {  } );
        Co.logout();
        ctx.props.history.push('/');
      }
    })
  
  }

  render() {

    return (
        <nav className="navbar navbar-expand-lg bg-green shadow-sm">
            <div className="container">
                <Link className="navbar-brand" to={ '/' }>
                    <img width={ 200 } className="img img-fluid" src={ Logo }/>
                </Link>

                

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars text-white"></i>
                    </button>
                    
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                        </ul>

                        <ul id="navbar" className="navbar-nav mr-auto">
                            <li className="nav-item d-none">
                                <a className="nav-link" href="#">Blog</a>
                            </li>
                            
                            <li className="nav-item">
                                <Link className="nav-link" to={'/iletisim'}>Yardım & İletişim</Link>
                            </li>
                            
                            <li id="li-uzman" className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="uzman" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Uzman mısınız?
                                </a>
                                <div className="dropdown-menu bg-light p-2 mb-2" aria-labelledby="uzman">
                                    <a className="dropdown-item text-green" href="https://kurumsal.iyiuzman.com/" target={'_blank'}>Nasıl Hizmet Verebilirim?</a>
                                    <Link className="dropdown-item" to={ '/kayit-ol' }>Kayıt Ol</Link> 
                                </div>
                            </li>
                        </ul>
                        
                        <div className="nav-buttons ml-2">
                            {
                                Co.auth() ?
                                <>
                                <Link to={ '/dashboard' }>
                                    <button className={ 'btn light-button mr-3' + (isMobile ? ' mt-3' : '') }>
                                        Dashboard
                                    </button>
                                </Link>

                                <button onClick={ () => this.logout() } className={ 'btn light-button mr-3' + (isMobile ? ' mt-3' : '') }>
                                    Çıkış Yap
                                </button>


                                </>
                                :
                                <>
                                    <button onClick={ () => this.giris() } className={ 'btn light-button mr-3' + (isMobile ? ' mt-3' : '') }>
                                        Giriş Yap
                                    </button>
                                    <a href="tel:08503052980" className={ 'btn light-button' + (isMobile ? ' mt-3' : '') }>
                                        <Telefon style={ { marginRight:4,marginBottom:2 } } />
                                        Randevu Al - 0850 305 29 80
                                    </a>
                                </>
                            }
                        </div>
                    </div>
                    
                
                
            </div>
        </nav>
    );
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : (value) => dispatch( { type : actionTypes.GIRIS_POP_TOGGLER, payload : value } ),
        set_user : ( value ) => dispatch({ type : actionTypes.SET_USER_DATA, payload : value })
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NavBar));