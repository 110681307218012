import React, { Component } from 'react';

import Loader from "react-loader-spinner";

export default class PageLoading extends Component {
  render() {

    const { fullscreen } = this.props;

    if(fullscreen){
      return(
        <div className="full-screen">
          <div className="loading-box">
            Yükleniyor...
          </div>
        </div>
      )
    }

    return (
        <div className='page-loading'>
            <Loader type="TailSpin" color="#71D4D4" height={ 80 } width={ 80 } />
        </div>
    );
  }
}
