import { ReactComponent as Onay } from '../../assets/images/onay.svg';
import { ReactComponent as Saat } from '../../assets/images/saat.svg';
import { ReactComponent as Musteri } from '../../assets/images/musteri.svg';
import { ReactComponent as Tarih } from '../../assets/images/tarih.svg';
import { ReactComponent as IlIlce } from '../../assets/images/ililce.svg';
import { ReactComponent as Adres } from '../../assets/images/adres.svg';
import { ReactComponent as RandevuTuru } from '../../assets/images/randevu-turu.svg';
import { ReactComponent as HizmetKonusu } from '../../assets/images/almak-istenilen-hizmet.svg';
import { ReactComponent as MusteriNotlari } from '../../assets/images/randevu-notlari.svg';
import { ReactComponent as RandevuDurumu } from '../../assets/images/randevu-durumu.svg';
import { ReactComponent as Call } from '../../assets/images/call.svg';
import { ReactComponent as Not } from '../../assets/images/randevu-notlari.svg';
import { ReactComponent as Commision } from '../../assets/images/commision.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

import Moment from 'react-moment';

import ReactAudioPlayer from 'react-audio-player';

class CagriKaydi extends Component {

  state = {
    yuklendi            : false,
    cagri_kaydi         : { },
  }

  componentDidMount() {
    document.title = "Çağrı Kaydı - İyi Uzman";
    this.cagri_kaydi();
  };

  async cagri_kaydi(){

    this.setState({ yuklendi : true });
    return;

    const { id } = this.props.match.params;
    
    let cagri_kaydi_id = id;

    const { hata,mesaj,data } = await Api.cagri_kaydi(cagri_kaydi_id);

    if(!hata){
        this.setState({ yuklendi : true, cagri_kaydi : data });
    }else{
        Main.message(mesaj,hata);
    }
  }

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-5 col-md-10">
            <div className="row">
                <div className="col-md-4 offset-md-4 col-12">
                    <div className="table-responsive">
                        
                        <table id="tablo-randevu-detaylari">
                    

                            <tr>
                                <td> <Musteri className="mr-2" /> </td>
                                <td> Uzman</td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.cagri_kaydi.uzman } Emre Beyoğlu
                                </td>
                            </tr>

                            <tr>
                                <td> <Musteri className="mr-2" /> </td>
                                <td> Müşteri </td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.cagri_kaydi.musteri } Umut Berhan
                                </td>
                            </tr>

                            <tr>
                                <td> <Tarih className="mr-2" /> </td>
                                <td> Çağrı Tarihi </td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.cagri_kaydi.tarih } 30.09.2022
                                </td>
                            </tr>

                            <tr>
                                <td> <Saat className="mr-2" /> </td>
                                <td> Çağrı Saati </td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.cagri_kaydi.tarih } 13:42
                                </td>
                            </tr>


            
                        </table>

                    </div>

                    <hr />

                    <div className="col-md-12 text-center">
                    <ReactAudioPlayer
                        src={ 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3' }
                        autoPlay
                        controls
                    />
                    </div>
                </div>
            </div>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Çağrı Kaydı</h4>
                { this.content() }
              </div>

            </div>

            <Footer/>

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CagriKaydi))