import { ReactComponent as IkiliOkSol } from '../../assets/images/ikili-ok-sol.svg';
import { ReactComponent as OkSol } from '../../assets/images/ok-sol.svg';
import { ReactComponent as IkiliOkSag } from '../../assets/images/ikili-ok-sag.svg';
import { ReactComponent as OkSag } from '../../assets/images/ok-sag.svg';

import { ReactComponent as Onay } from '../../assets/images/onay.svg';
import { ReactComponent as Musteri } from '../../assets/images/musteri.svg';
import { ReactComponent as Tarih } from '../../assets/images/tarih.svg';
import { ReactComponent as Saat } from '../../assets/images/saat.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import { Modal } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';
import swal from '@sweetalert/with-react';
import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

import Moment from 'react-moment';

class Faturalar extends Component {

  state = {
      yuklendi : false,
      duruma_gore : null,
      tarihe_gore   : null,
      sayfa         : 1,
      toplam_sayfa  : 1,
      faturalar     : [],
  }

  componentDidMount = () => {
    document.title = "Faturalar - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
       ctx.faturalari_getir();
    },500);
  };

  sayfalandirma(){

    let sayfalar = [ ];

    const { sayfa,toplam_sayfa } = this.state;

    sayfalar.push({ label : 'ilk',sayfa : 'ilk' });
    
    if(sayfa > 1){
        sayfalar.push({ label : 'geri',sayfa : sayfa - 1 });
    }

    let baslangic = (sayfa - 1);
    let bitis = (baslangic - 3);
    
    for(var i = baslangic; i > bitis; i--){

        let x = ((baslangic + bitis) - i)+1;

        if(x < 1) continue;

        sayfalar.push({ label : x, sayfa : x });
    }

    sayfalar.push({ label : sayfa, sayfa });

    baslangic = (sayfa + 1);
    bitis = (baslangic + 3);

    for(i = baslangic; i < bitis; i++){
        
        if(i > toplam_sayfa) break;

        sayfalar.push({ label : i, sayfa : i });
    }

    if(sayfa < toplam_sayfa){
        sayfalar.push({ label : 'sonraki',sayfa : sayfa + 1 });
    }
    sayfalar.push({ label : 'son',sayfa : 'son' });

    const click = (sayfa) => {
        let git = sayfa.sayfa;

        if(git == 'son'){
            git = this.state.toplam_sayfa;
        }else if (git == 'ilk'){
            git = 1;
        }

        this.setState({ sayfa : git });
        const ctx = this;
        setTimeout(() => {
            ctx.faturalari_getir();
        }, 500);
    }

      return(
        sayfalar.map(x => (
            <button onClick={ () =>  click(x) } className="btn page-button" data-active={ this.state.sayfa == x.sayfa ? true : false }>
               {
                   x.label == 'geri' ? <OkSol/> :
                   x.label == 'ilk' ? <IkiliOkSol/> :
                   x.label == 'sonraki' ? <OkSag/> :
                   x.label == 'son' ? <IkiliOkSag/> : x.label
               }
            </button>
        ))
      )
  }

    async faturalari_getir(){
        
        var duruma_gore = this.state.duruma_gore;
        var tarihe_gore = this.state.tarihe_gore;

        const { hata,mesaj,data } = await Api.faturalar_listesi(duruma_gore,tarihe_gore,this.state.sayfa);

        if(hata){
            swal("HATA",mesaj,"error");
        }

        this.setState({
        faturalar : data == null ? [ ] : data.faturalar,
        yuklendi : true,
        sayfa : data.sayfa,
        toplam_sayfa : data.toplam_sayfa,
        })
  
    }

setDurum(durum) {
    this.setState({ duruma_gore : durum } )
    const ctx = this;
    setTimeout(() => {
        ctx.faturalari_getir();
    }, 500);
}

setTarih(tarih) {
    this.setState({ tarihe_gore : tarih })
    const ctx = this;
    setTimeout(() => {
        ctx.faturalari_getir();
    }, 500);
}

  filtreler(){
      return (
          <div id="filtre-faturalar" className="pl-3" style={ { width:250 } }>
            
            <strong className="dark">Fatura durumuna göre</strong>
            <div className="pl-3 mt-2 mb-4 gray">

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input onClick={ (e) => this.setDurum(null) } type="radio" name="durum" id="tum-faturalar" className="custom-control-input" defaultChecked={ true }/>
                    <label className="custom-control-label" htmlFor="tum-faturalar">Tüm Faturalar</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input onClick={ (e) => this.setDurum(e.target.value) } type="radio" name="durum" id="beklemede" className="custom-control-input" value="BEKLEMEDE"/>
                    <label className="custom-control-label" htmlFor="beklemede">Beklemede</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input onClick={ (e) => this.setDurum(e.target.value) } type="radio" name="durum" id="odendi" className="custom-control-input" value="ODENDI"/>
                    <label className="custom-control-label" htmlFor="odendi">Ödendi</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input onClick={ (e) => this.setDurum(e.target.value) } type="radio" name="durum" id="ödenmedi" className="custom-control-input" value="ODENMEDI"/>
                    <label className="custom-control-label" htmlFor="ödenmedi">Ödenmedi</label>
                </div>

            </div>

            <strong className="dark">Tarihe göre</strong>

            <div className="pl-3 mt-1 gray">
                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" name="tarih" id="son24saat" value="son24saat" onClick={(e) => this.setTarih(e.target.value)} className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="son24saat">Son 24 saat</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" name="tarih" id="son1hafta" value="son1hafta" onClick={(e) => this.setTarih(e.target.value)} className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="son1hafta">Son bir hafta içinde</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" name="tarih" id="son1ay" value="son1ay" onClick={(e) => this.setTarih(e.target.value)} className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="son1ay">Son 1 ay içinde</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" name="tarih" id="son3ay" value="son3ay" onClick={(e) => this.setTarih(e.target.value)} className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="son3ay">Son 3 ay içinde</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" name="tarih" id="son6ay" value="son6ay" onClick={(e) => this.setTarih(e.target.value)} className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="son6ay">Son 6 ay içinde</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" name="tarih" id="son1yil" value="son1yil" onClick={(e) => this.setTarih(e.target.value)} className="custom-control-input"/>
                    <label className="custom-control-label" htmlFor="son1yil">Son 1 yıl içinde</label>
                </div>
                
            </div>

          </div>
      );
  }

  detaylar(id){
      this.props.history.push('/uzman/fatura-detay/' + id);
  }

  content(){
      if(!this.state.yuklendi){
          return (
              <PageLoading/>
          )
      }

      return (
        <div>
            {
                this.state.faturalar.length > 0 ? 
                <>
                    <div className="row">
                        <div className="col-md-10">
                            <div id="content-faturalar" className="content-card d-flex">
                                { this.filtreler() }
                                <div className="table-responsive">
                                    <table id="tablo-faturalar" className="table table-borderless dash mb-0">
                                        <thead>
                                            <tr>
                                                <th>Son Ödeme Tarihi</th>
                                                <th>Miktar</th>
                                                <th>Durum</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            this.state.faturalar.map( (fatura) => (
                                            <tr>
                                                <td><Moment format="DD.MM.YYYY">{ fatura.son_odeme_tarihi }</Moment></td>
                                                <td>{ fatura.tutar }₺</td>
                                                <td> <span className="text-success">{ Main.fatura_durum(fatura.durum) }</span> </td>
                                                <td>
                                                    <button onClick={ () => this.detaylar(fatura.id) } className="btn light-button2">
                                                        Detayları Görüntüle
                                                    </button>
                                                </td>
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="text-center">
                                { this.sayfalandirma() }
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="row">
                        <div className="col-md-10">
                            <div id="content-faturalar" className="content-card d-flex">
                                { this.filtreler() }
                                <div className="table-responsive py-5">
                                    <h5 className="text-center">Hiç fatura bulunamadı.</h5>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
      )
  }

  render(){


    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>
              
              <div id="content">
                <h4 className="mb-3">Faturalar</h4>

                { this.content() }

              </div>

            </div>

            <Footer/>
            

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar : state.sidebar
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Faturalar))