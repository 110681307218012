import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';

class HizmetBolgesi extends Component {

  state = {
    yuklendi        : false,
    il              : { },
    ilceler         : [ ],
    modal1          : false,
    btn_olustur_enb : false,
    ilce_adi        : '',
  }

  componentDidMount = async () => {

    if(this.props.location.il == null){
      this.props.history.push('/sistem/hizmet-bolgeleri');
      return;
    }else{
        this.setState({
          il : this.props.location.il
        })
    }

    const ctx = this;
    setTimeout(function(){
        ctx.ilceleri_getir()
    },500);
  };

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-3">
            <div className="text-right">
                <button onClick={ () => this.toggleModal1() } className="btn light-button2">
                    <i className="fas fa-plus mr-1"></i>
                    İlçe Ekle
                </button>
            </div>
            <div className="table-responsive mt-3">
                <table className="table table-borderless dash">
                    <thead>
                        <tr>
                            <th style={ { width:100 } }>No</th>
                            <th>İlçe Adı</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        { this.ilceler() }
                    </tbody>

                </table>
            </div>
        </div>
      )
  }

  toggleModal1(){
    this.setState({
        modal1: !this.state.modal1
    })
  }

  modal_ilce_ekle(){
    return (
      <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
          <Modal.Body>

              <div className="text-right">
                  <i onClick={ () => this.toggleModal1()  } className="fas fa-times close-popup"></i>
              </div>

              <div className="p-3">
                  <h4>Yeni İlçe Oluştur</h4>
                  <hr />

                  <div className="form-group">
                      <label htmlFor="ilce_adi">İlçe Adı</label>
                      <input onChange={ (e) => this.setIlceAdi(e.target.value) } value={ this.state.il_adi } id="ilce_adi" type="text" className="form-control" />
                  </div>

                  <div className="text-right">
                      <button disabled={ this.state.btn_olustur_enb } onClick={ () => this.ilce_olustur() } className="btn green-button">
                          <i className="fas fa-check mr-1"></i>
                          Oluştur
                      </button>
                  </div>
              </div>
          </Modal.Body>
      </Modal>
    )
}

  async ilceleri_getir(){

    const { hata,mesaj,data } = await Api.ilce_listesi(this.state.il.id,Co.access_token());

    if(hata){
        swal("HATA",mesaj,"error");
        return;
    }

    this.setState({
        ilceler : data.ilceler,
        yuklendi : true
    })

    document.title = this.state.il.il_adi + " - Hizmet Bölgeleri - İyi Uzman";
  }

  ilceler(){
    return (
       this.state.ilceler.map( (ilce,index) => {
           return (
               <tr>
                   <td> { index + 1 } </td>
                   <td>{ ilce.ilce_adi }</td>
                   <td>
                       <button onClick={ () => this.ilce_sil(ilce.id) } className="btn light-button2 ml-2">Sil</button>
                   </td>
               </tr>
           )
       })
    )
  }

  setIlceAdi(val){
    this.setState({ ilce_adi : val });
  }
  
  async ilce_olustur(){
    if(this.state.ilce_adi.trim().length < 1){
        swal("HATA","İlçe adı girilmedi!","error");
        return;
    }

    const { hata,mesaj,data } = await Api.ilce_olustur(this.state.ilce_adi,this.state.il.id,Co.access_token());

    this.ilceleri_getir();

    this.setState({ ilce_adi : '' })

    swal(mesaj);

    this.setState({
        modal1 : false
    })
  }

  async ilce_sil(id){

    swal({
      title :'İlçe Siliniyor',
      content : <div><span>İlçeyi silmek için sil butonuna basın</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'green-button'
        },
        b2 : {
          text:'Sil',
          className: 'red-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj } = await Api.ilce_sil(id,Co.access_token());

            if(hata){
                swal("HATA",mesaj,"error");
                return;
            }
        }
        await this.ilceleri_getir();
    });

   
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">{ this.state.il.il_adi }</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>

            { this.modal_ilce_ekle() }
            
        </div>
    )
  }

}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(HizmetBolgesi))