import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';

class MeslekGruplari extends Component {

  state = {
    yuklendi : false,
    modal1: false,
    btn_olustur_enb : false,
    yeni_meslek_adi : '',
    yeni_evde_komisyon_bedeli : 0,
    yeni_klinikte_komisyon_bedeli : 0,
    yeni_online_komisyon_bedeli : 0,
    meslek_gruplari : [ ]
  }

  async meslekleri_getir(){

      const { hata,mesaj,data } = await Api.meslek_grubu_listesi(Co.access_token());

      if(hata){
          swal("HATA",mesaj,"error");
      }

      this.setState({
          meslek_gruplari : data == null ? [ ] : data,
          yuklendi : true
      })
  }

  async meslek_grubu_sil(id){

    swal({
      title :'Meslek Siliniyor',
      content : <div><span>Mesleği silmek için sil butonuna basın.</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'green-button'
        },
        b2 : {
          text:'Sil',
          className: 'red-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj,data } = await Api.meslek_grubu_sil(id,Co.access_token());

            if(hata){
                swal("HATA",mesaj,"error");
            }
        }
        await this.meslekleri_getir();
    });

   
  }


  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    document.title = "Meslek Grupları - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
        ctx.meslekleri_getir()
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-3">
           <div className="text-right">
                <button onClick={ () => this.toggleModal1() } className="btn light-button2">
                    <i className="fas fa-plus mr-1"></i>
                    Meslek Grubu Ekle
                </button>
            </div>
            <div className="table-responsive mt-3">
                <table className="table table-borderless dash">
                    <thead>
                        <tr>
                            <th style={ { width:100 } }>No</th>
                            <th>Meslek Adı</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        { this.meslek_gruplari() }
                    </tbody>

                </table>
            </div>
        </div>
      )
  }

  meslek_gruplari(){
     return ( // Map = Foreach
        this.state.meslek_gruplari.map( (meslek_grubu,index) => {
            return (
                <tr>
                    <td> { index+1 } </td>
                    <td>{ meslek_grubu.ana_kategori.meslek_adi }</td>
                    <td>
                        <Link to={ {
                            pathname : '/sistem/meslek-grubu/' + meslek_grubu.ana_kategori.id,
                            meslek_adi : meslek_grubu.ana_kategori.meslek_adi
                        } }>
                            <button className="btn light-button2">Görüntüle</button>
                        </Link>
                        <Link to={ {
                            pathname: '/sistem/meslek-hizmetleri/' + meslek_grubu.ana_kategori.id,
                            meslek_adi: meslek_grubu.ana_kategori.meslek_adi
                        } }>
                            <button className="btn light-button2">Meslek Hizmetleri</button>
                        </Link>
                        <Link to={ {
                            pathname : '/sistem/meslek-unvanlari/' + meslek_grubu.ana_kategori.id,
                            meslek_adi : meslek_grubu.ana_kategori.meslek_adi
                        } }>
                            <button className="btn light-button2">Meslek Ünvanları</button>
                        </Link>
                    </td>
                    <td>
                        <button onClick={ () => this.meslek_grubu_sil(meslek_grubu.ana_kategori.id) } className="btn light-button2">Sil</button>
                    </td>
                </tr>
            )
        })
     )
  }

  modal_meslek_grubu(){
      return (
        <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
            <Modal.Body>

                <div className="text-right">
                    <i onClick={ () => this.toggleModal1()  } className="fas fa-times close-popup"></i>
                </div>

                <div className="p-3">
                    <h4>Yeni Meslek Grubu</h4>
                    <hr />

                    

                    <div className="form-group">
                        <label htmlFor="yeni_meslek_adi">Meslek Adı</label>
                        <input onChange={ (e) => this.setYeniMeslekAdi(e.target.value) } value={ this.state.yeni_meslek_adi } id="yeni_meslek_adi" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="yeni_evde_komisyon_bedeli">Evde Komisyon Bedeli</label>
                        <input onChange={ (e) => this.setYeniEvdeKomisyonBedeli(e.target.value) } value={ this.state.yeni_evde_komisyon_bedeli } id="yeni_evde_komisyon_bedeli" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="yeni_klinikte_komisyon_bedeli">Klinikte Komisyon Bedeli</label>
                        <input onChange={ (e) => this.setYeniKlinikteKomisyonBedeli(e.target.value) } value={ this.state.yeni_klinikte_komisyon_bedeli } id="yeni_klinikte_komisyon_bedeli" type="text" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="yeni_online_komisyon_bedeli">Online Komisyon Bedeli</label>
                        <input onChange={ (e) => this.setYeniOnlineKomisyonBedeli(e.target.value) } value={ this.state.yeni_online_komisyon_bedeli } id="yeni_online_komisyon_bedeli" type="text" className="form-control" />
                    </div>

                    <div className="text-right">
                        <button disabled={ this.state.btn_olustur_enb } onClick={ () => this.meslek_grubu_olustur() } className="btn green-button">
                            <i className="fas fa-check mr-1"></i>
                            Oluştur
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      )
  }

  toggleModal1(){
      this.setState({
          modal1: !this.state.modal1
      })
  }

  setYeniMeslekAdi(val){
      this.setState({ yeni_meslek_adi : val });
  }

  setYeniEvdeKomisyonBedeli(val){
      this.setState({ yeni_evde_komisyon_bedeli : val });
  }

  setYeniKlinikteKomisyonBedeli(val){
      this.setState({ yeni_klinikte_komisyon_bedeli : val });
  }

  setYeniOnlineKomisyonBedeli(val){
      this.setState({ yeni_online_komisyon_bedeli : val });
  }

  async meslek_grubu_olustur(){
      if(this.state.yeni_meslek_adi.trim().length < 1){
          swal("HATA","Meslek adı girilmedi!","error");
          return;
      }

      const { hata,mesaj,data } = await Api.meslek_grubu_olustur(this.state.yeni_meslek_adi,this.state.yeni_evde_komisyon_bedeli,this.state.yeni_klinikte_komisyon_bedeli,this.state.yeni_online_komisyon_bedeli,Co.access_token());

      this.meslekleri_getir();

      this.setState({ 
          yeni_meslek_adi : '' ,
          yeni_evde_komisyon_bedeli : 0 ,
          yeni_klinikte_komisyon_bedeli : 0 ,
          yeni_online_komisyon_bedeli : 0 ,
        })

      swal(mesaj);

    this.setState({
          modal1 : false
      })
  }



  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Meslek Grupları</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            { this.modal_meslek_grubu() }
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MeslekGruplari))