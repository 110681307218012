import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';
import Main from '../../Functions/Main';
import { domain,api_domain } from '../../Config';
import Select from 'react-select';

class UzmanGoruntule extends Component {

  state = {
    yuklendi : false,
    modal1 : false,
    btn_olustur_enb : false,
    uzman_data : { },
    hizmet_bolgeleri : { },
  }

  async uzman_bilgileri_getir(){

      const { hata,mesaj,data } = await Api.profil_bilgileri({ kullanici_id: this.props.match.params.id },Co.access_token());

      if(hata){
          swal("HATA",mesaj,"error");
      }

      let hizmet_bolgeleri = Main.hizmetBolgeleri(data.hizmet_bolgeleri);

      this.setState({
          uzman_data : data == null ? { } : data,
          hizmet_bolgeleri,
          yuklendi : true
      })

      document.title = data.ad + " " + data.soyad + " - Uzman Bilgileri - İyi Uzman";
      
  }

  verilen_hizmetler(){
     return (
        this.state.uzman_data.verilen_hizmetler.map( (hizmet,index) => {
            return (
                <span class="badge badge-pill badge-green text-white p-3 m-1">{ hizmet.hizmet_adi }</span>
            )
        })
     )
  }

  hizmet_verilecek_bolgeler(){
    return ( // Map = Foreach
       this.state.hizmet_bolgeleri.map( (bolge,index) => {
           return (
            <span class="badge badge-pill badge-green text-white p-3 m-1">{ bolge.label }</span>
           )
       })
        )
    }

  async uzmani_onayla(id){

    swal({
      title :'Uzman İsteği Onaylanıyor',
      content : <div><span>Uzmanı onaylamak için onayla butonuna tıklayınız</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'red-button'
        },
        b2 : {
          text:'Onayla',
          className: 'green-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj,data } = await Api.uzmani_onayla(id,1,'',Co.access_token());

            if(hata){
                swal("HATA",mesaj,"error");
                return;
            }
            swal("Harika!",mesaj,"success");
        }
    });

   
  }

  async uzmani_reddet(){
   
    const { hata,mesaj,data } = await Api.uzmani_onayla(this.state.uzman_data.id,0,this.state.aciklama,Co.access_token());

    if(hata){
        swal("HATA",mesaj,"error");
        return;
    }
    swal("Harika!", mesaj, 'success');

  }

  modal_uzman_reddet(){
      return (
        <Modal size="md" show={ this.state.modal1 } dialogClassName="modal-90w" centered>
            <Modal.Body>

                <div className="text-right">
                    <i onClick={ () => this.toggleModal1()  } className="fas fa-times close-popup"></i>
                </div>

                <div className="p-3">
                    <h4>Uzman Reddedilme Sebebi</h4>

                    <div className="form-group">
                        <textarea onChange={ (e) => this.setAciklama(e.target.value) } className="form-control" rows="8"></textarea>
                    </div>

                    <div className="text-right">
                        <button onClick={ () => this.uzmani_reddet() } className="btn red-button">
                            <i className="fas fa-check mr-1"></i>
                            Reddet
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      )
  }

  setAciklama(val){
    this.setState({
        aciklama : val
    });
  }
  

  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.uzman_bilgileri_getir()
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (

        
        
            
        <div id="profilimContainer" className="content-card p-5">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#kisisel-bilgiler">Kişisel Bilgiler</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#uzman-profili">Uzman Profili</a>
                </li>
            </ul>

            <div class="tab-content">

                <div id="kisisel-bilgiler" class="tab-pane active"><br/>
                    <div className="row d-flex justify-content-center mb-5">
                        <div className="box">
                            <img
                            className="fotograf"
                            src={ Main.resimURL(this.state.uzman_data.fotograf,true) }/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="ad"><h5>Ad</h5></label>
                            <input type="text" id="ad" className="form-control" defaultValue={ this.state.uzman_data.ad } readOnly />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="soyad"><h5>Soyad</h5></label>
                            <input type="text" id="soyad" className="form-control" defaultValue={  this.state.uzman_data.soyad } readOnly />
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="email"><h5>E-mail</h5></label>
                        <input type="text" id="email" className="form-control" defaultValue={ this.state.uzman_data.email } readOnly />
                    </div>

                    <div className="form-group">
                        <label htmlFor="telefon"><h5>Telefon</h5></label>
                        <input type="text" id="telefon" className="form-control" defaultValue={ this.state.uzman_data.telefon } readOnly />
                    </div>

                    <div className="form-group">
                    <label htmlFor="cinsiyet"><h5>Cinsiyet</h5></label>
                    <select id='cinsiyet' type="text" className="form-control" defaultValue={ this.state.uzman_data.cinsiyet } disabled >
                        <option value="BELIRTILMEDI">Belirtilmedi</option>
                        <option value="KADIN">Kadın</option>
                        <option value="ERKEK">Erkek</option>
                    </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="dogum_tarihi"><h5>Doğum Tarihi</h5></label>
                        <input onChange={ (e) => this.setDogumTarihi(e.target.value) } type="date" id="dogum_tarihi" className="form-control" defaultValue={ this.state.uzman_data.dogum_tarihi } readOnly />
                    </div>

                    <div className="form-group">
                        <label htmlFor="tc">
                        <h5>
                            Tc Kimlik No
                        </h5>
                        </label>
                        <input type="text" id="tc" className="form-control" defaultValue={ this.state.uzman_data.tc } readOnly/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="adres"><h5>Adres</h5></label>
                        <textarea id="adres" rows="8" className="form-control" defaultValue={ this.state.uzman_data.adres } readOnly></textarea>
                    </div>

                    <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="il"><h5>İl</h5></label>
                        <input type="text" className="form-control" defaultValue={ this.state.uzman_data.il } readOnly />
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="ilce"><h5>İlçe</h5></label>
                        <input type="text" className="form-control" defaultValue={ this.state.uzman_data.ilce } readOnly />
                    </div>

                    </div>

                    <h4><span>Fatura Bilgileri</span></h4>

                    <div className="form-group">
                        <label htmlFor="sirket_adi"><h5>Firma Adı/Ünvan</h5></label>
                        <input type="text" id="sirket_adi" className="form-control" defaultValue={ this.state.uzman_data.sirket_adi } readOnly />
                    </div>

                    <div className="form-group">
                        <label htmlFor="vergi_dairesi"><h5>Vergi Dairesi</h5></label>
                        <input type="text" id="vergi_dairesi" className="form-control" defaultValue={ this.state.uzman_data.vergi_dairesi } readOnly />
                    </div>

                    <div className="form-group">
                        <label htmlFor="vergi_no"><h5>Vergi Kimlik No</h5></label>
                        <input type="text" id="vergi_no" className="form-control" defaultValue={ this.state.uzman_data.vergi_no } readOnly />
                    </div>

                    <div className="form-group">
                        <label htmlFor="iban"><h5>İban No</h5></label>
                        <input type="text" id="iban" className="form-control" defaultValue={ this.state.uzman_data.iban } readOnly />
                    </div>
                </div>

                <div id="uzman-profili" class="tab-pane fade"><br/>

                    <div className="form-group mt-3">
                        <label><h5>Meslek</h5></label>
                        <input type="text" className="form-control" defaultValue={ this.state.uzman_data.meslek_data[0] != null ? this.state.uzman_data.meslek_data[0].meslek_adi : '' } readOnly />
                    </div>

                    {
                        this.state.uzman_data.meslek_data.length > 1 ?
                        <>
                        <div className="form-group mt-3">
                            <label><h5>Alt Dal</h5></label>
                            <input type="text" className="form-control" defaultValue={ this.state.uzman_data.meslek_data[1] != null ? this.state.uzman_data.meslek_data[1].meslek_adi : '' } readOnly />
                        </div>
                        </>
                        : ''
                    }

                    <div className="form-group">
                        <label htmlFor="unvan"><h5>Unvan</h5></label>
                        <input type="text" className="form-control" defaultValue={ this.state.uzman_data.meslek_unvani } readOnly />
                    </div>

                    <div className="form-group mt-4">
                        <label htmlFor="diploma"><h5>Diploma Belgesi</h5></label>
                        {
                        this.state.uzman_data.diploma.length > 0 ?

                            this.state.uzman_data.diploma.toLowerCase().endsWith('.pdf') ?
                            <>
                                <br />
                                <a target="_blank" href={ api_domain + this.state.uzman_data.diploma } className="btn green-button">Diplomayı Görüntüle</a>
                                <br /> <br />
                            </>
                            :
                            <>
                            <br />
                            <a target="_blank" href={ api_domain + this.state.uzman_data.diploma }>
                                <img className="diploma" src={ api_domain + this.state.uzman_data.diploma } alt="diploma" />
                            </a>
                            <br /> <br />
                            </>
                        :
                        <>
                            <br />
                            <span className="text-danger">
                            Yüklenmiş bir diploma yok
                            </span>
                            <br /><br />
                        </>
                        }
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h5>Verilecek Hizmetler</h5>
                            { this.verilen_hizmetler() }
                        </div>
                        <div className="col-md-6">
                            <h5>Hizmet Verilecek Bölgeler</h5>
                            { this.hizmet_verilecek_bolgeler() }
                        </div>
                    </div>
                    
                    <div className="form-group mb-5">
                        <label htmlFor="ozgecmis"><h5>Özgeçmiş</h5></label>
                        <textarea defaultValue={ this.state.uzman_data.ozgecmis } id="ozgecmis" rows="8" className="form-control" readOnly></textarea>
                    </div>

                    <h5>Hizmet Verme Seçenekleri</h5>
                    <div className="d-flex">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="evde" checked={ this.state.uzman_data.evde_hizmet }/>
                            <label className="custom-control-label" htmlFor="evde">Evde Hizmet</label>
                        </div>
                        <div className="custom-control custom-checkbox ml-3">
                            <input type="checkbox" className="custom-control-input" id="klinikte" checked={ this.state.uzman_data.klinikte_hizmet }/>
                            <label className="custom-control-label" htmlFor="klinikte">Klinikte Hizmet</label>
                        </div>
                        <div className="custom-control custom-checkbox ml-3">
                            <input type="checkbox" className="custom-control-input" id="online" checked={ this.state.uzman_data.online_hizmet }/>
                            <label className="custom-control-label" htmlFor="online">Online Hizmet</label>
                        </div>
                    </div>

                    {
                    this.state.uzman_data.klinikte_hizmet ?
                    <>
                    <div className="form-group">
                        <label htmlFor="klinik_adresi">Klinik Adresi</label>
                        <textarea defaultValue={ this.state.uzman_data.klinik_adresi } id="klinik_adresi" rows="2" className="form-control" readOnly ></textarea>
                    </div>
                    </>
                    : ''
                    }

                    {
                    this.state.uzman_data.evde_hizmet ?
                    <>
                    <h5>Evde Hizmet Ücret Aralığı</h5>
                    <div className="row">
                        
                        <div className="form-group col mb-2">
                        <label htmlFor="evde_hizmet_taban_fiyat">Taban Fiyat</label>
                        <input defaultValue={ this.state.uzman_data.evde_hizmet_taban_fiyat } id="evde_hizmet_taban_fiyat" type="text" className="form-control" readOnly />
                        </div>

                        <div className="form-group col mb-2">
                        <label htmlFor="evde_hizmet_tavan_fiyat">Tavan Fiyat</label>
                        <input defaultValue={ this.state.uzman_data.evde_hizmet_tavan_fiyat } id="evde_hizmet_tavan_fiyat" type="text" className="form-control" readOnly />
                        </div>
                    </div>
                    <br/>
                    </>
                    : ''
                    }

                    {
                    this.state.uzman_data.klinikte_hizmet ?
                    <>
                    <h5>Klinikte Hizmet Ücret Aralığı</h5>
                    <div className="row">
                        
                        <div className="form-group col mb-2">
                        <label htmlFor="klinikte_hizmet_taban_fiyat">Taban Fiyat</label>
                        <input defaultValue={ this.state.uzman_data.klinikte_hizmet_taban_fiyat } id="klinikte_hizmet_taban_fiyat" type="text" className="form-control" readOnly />
                        </div>

                        <div className="form-group col mb-2">
                        <label htmlFor="klinikte_hizmet_tavan_fiyat">Tavan Fiyat</label>
                        <input defaultValue={ this.state.uzman_data.klinikte_hizmet_tavan_fiyat } id="klinikte_hizmet_tavan_fiyat" type="text" className="form-control" readOnly />
                        </div>
                    </div>
                    <br />
                    </>
                    : ''
                    }

                    {
                    this.state.uzman_data.online_hizmet ?
                    <>
                    <h5>Online Hizmet Ücret Aralığı</h5>
                    <div className="row">
                        
                        <div className="form-group col mb-2">
                        <label htmlFor="online_hizmet_taban_fiyat">Taban Fiyat</label>
                        <input defaultValue={ this.state.uzman_data.online_hizmet_taban_fiyat } id="online_hizmet_taban_fiyat" type="text" className="form-control" readOnly />
                        </div>

                        <div className="form-group col mb-2">
                        <label htmlFor="online_hizmet_tavan_fiyat">Tavan Fiyat</label>
                        <input defaultValue={ this.state.uzman_data.online_hizmet_tavan_fiyat } id="online_hizmet_tavan_fiyat" type="text" className="form-control" readOnly />
                        </div>
                    </div>
                    </>
                    : ''
                    }


                    {
                    (!this.state.uzman_data.evde_hizmet && !this.state.uzman_data.klinikte_hizmet && !this.state.uzman_data.online_hizmet) ? '' :
                    <>
                    <div className="custom-control custom-checkbox col-md-12 mb-5">
                        <input type="checkbox" className="custom-control-input" id="profilde_fiyat_goster" checked={ this.state.uzman_data.profilde_fiyat_goster }/>
                        <label className="custom-control-label gray" htmlFor="profilde_fiyat_goster">Fiyatlandırma profilimde gözüksün</label>
                    </div>
                    </>
                    }

                    <h5>Kabul Edilen Ödeme Yöntemi</h5>
                    <div className="col-md-12">
                        <div className="row my-3">
                            <div className="custom-control custom-checkbox col-md-2">
                                <input type="checkbox" className="custom-control-input" id="nakit" checked={ this.state.uzman_data.nakit_odeme }/>
                                <label className="custom-control-label" htmlFor="nakit">Nakit</label>
                            </div>
                            <div className="custom-control custom-checkbox col-md-2">
                                <input type="checkbox" className="custom-control-input" id="kart" checked={ this.state.uzman_data.kart_ile_odeme }/>
                                <label className="custom-control-label" htmlFor="kart">Kredi Kartı</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-5">
                        <label htmlFor="okullar"><h5>Okullar/Eğitimler</h5></label>
                        <textarea defaultValue={ this.state.uzman_data.okul } id="okullar" rows="8" className="form-control" readOnly></textarea>
                    </div>

                    <div className="form-group mb-5">
                        <label htmlFor="tecrubeler"><h5>Deneyimler/Tecrübeler</h5></label>
                        <textarea defaultValue={ this.state.uzman_data.tecrubeler } id="tecrubeler" rows="8" className="form-control" readOnly></textarea>
                    </div>

                </div>

            </div>

            

            {
                this.state.uzman_data.onay_bekliyor ? 
                <>
                <div className="row">
                    <div className="col-md-6">
                        <button onClick={ () => this.toggleModal1() } className="btn red-button btn-block p-3">Reddet</button>
                    </div>
                    <div className="col-md-6">
                        <button onClick={ () => this.uzmani_onayla(this.state.uzman_data.id) } className="btn green-button btn-block p-3">Onayla</button>
                    </div>
                </div>
                </>
                :
                <>
                </>
            }
        </div>
        
      )
  }


  toggleModal1(){
      this.setState({
          modal1: !this.state.modal1
      })
  }


  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">

                { this.content() }
              
              </div>

            </div>

            <Footer/>
            
            { this.modal_uzman_reddet() }

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: '#EFEFEF',
      minHeight: '150px',
      padding:8,
      // height: '150px',
      boxShadow: state.isFocused ? null : null,
      alignItems:'flex-start'
    }),
  
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 6px'
    }),
  
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: '150px',
      padding:8,
    }),
  };


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(UzmanGoruntule))