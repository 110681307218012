import { ReactComponent as Check } from '../assets/images/check.svg';
import { ReactComponent as Medal } from '../assets/images/medal.svg';
import { ReactComponent as Hospital } from '../assets/images/hospital.svg';
import { ReactComponent as Hospital2 } from '../assets/images/hospital2.svg';
import { ReactComponent as Hediye } from '../assets/images/hediye.svg';
import { ReactComponent as Konum } from '../assets/images/konum.svg';
import { ReactComponent as Call } from '../assets/images/call3.svg';
import { ReactComponent as User } from '../assets/images/user.svg';
import { ReactComponent as Woman } from '../assets/images/woman.svg';
import { ReactComponent as BirthdayCake } from '../assets/images/birthday-cake.svg';
import { ReactComponent as RandevuTuru } from '../assets/images/randevu-turu.svg';
import { ReactComponent as Money } from '../assets/images/money.svg';
import { ReactComponent as Hakkimda } from '../assets/images/hakkimda.svg';
import { ReactComponent as Okullar } from '../assets/images/okullar.svg';
import { ReactComponent as Deneyimler } from '../assets/images/deneyimler.svg';
import { ReactComponent as Tarih } from '../assets/images/tarih-mavi.svg';
import { ReactComponent as Online } from '../assets/images/online.svg';

import { kurumsal_telefon,str_kurumsal_telefon } from '../Config'

import React, { Component } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import PageLoading from './Components/PageLoading';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import Api from '../Functions/Api';
import Main from '../Functions/Main';
import ReactTooltip from 'react-tooltip';

import Viewer from 'react-viewer';

import Tour from 'reactour'

class Gorusler extends Component {

  state = {
    yuklendi          : false,
    gorusler          : { },
    uzman          : { },
  }


  componentDidMount = async () => {
    this.gorusleri_getir(this.props.match.params.id)
  };


  async gorusleri_getir(id){
    const { hata,mesaj,data } = await Api.gorusler(id);
    if(!hata){

      this.setState({ yuklendi : true,gorusler : data.degerlendirmeler, uzman : data.uzman });


      document.title = data.uzman.ad + ' ' + data.uzman.soyad + ' | Uzman Görüşleri';
      
      document.querySelector('meta[name="description"]').setAttribute("content", data.uzman.ad + " " + data.uzman.soyad + ", Uzman Görüşleri, İyi Uzman, 0850 305 29 80");
    }else{
      Main.message(mesaj,hata);
    }
  }

  puan(puan,checked){
    return (
        Array.from(Array(puan)).map(x => (
            <span className={ checked ? 'fa fa-star gray checked' : 'fa fa-star gray' }></span>
        ))
    )
  }
  degerlendirmeler(){

    if(this.state.gorusler.length < 1){
      return (
        <p className="lead text-center">
          { this.state.uzman.ad + ' ' + this.state.uzman.soyad } hakkında henüz hiç kimse görüş bildirmemiş!
        </p>
      )
    }

    return (
      <div className="row bg-light">
        <div className="col-md-12 mt-4">
          <h5 className='text-center'>{ this.state.uzman.ad + ' ' + this.state.uzman.soyad } Adlı Uzmanın Değerlendirmeleri</h5>  
        </div>
        {
          this.state.gorusler.map(d => (
            <div className="col-md-12 border-bottom p-4">
              <p>
                <User className="mr-2" />
                Hizmet Alan Bir Müşteri
              </p>
              <p>
                { this.puan(d.puan,true) }
                { this.puan(5 - d.puan) }
              </p>
              <p className="mb-0">{ d.yorum }</p>
              <small className="gray"> { d.olusturulma_tarihi } </small>
            </div>
          ))
        }
      </div>
    )
  }


  content() {

    if (!this.state.yuklendi) {
      return (
        <PageLoading />
      )
    }

    return (
      <div>
        { this.degerlendirmeler() }
      </div>
    )
  }


  render() {

    return (
      <div>

        <ActionBar/>
        <ActionBarMobile/>

        <NavBar className="bg-green" />

        <div className="wrapper">
          <div id="content">
            <div className="container mt-2">
            { this.content() }
            </div>
          </div>

        </div>

        <Footer />

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gorusler))