import { ReactComponent as CalismaTakvimi } from './../../assets/images/uzman-menu/calisma-takvimi.svg';
import { ReactComponent as RandevuTakvimi } from './../../assets/images/uzman-menu/randevu-takvimi.svg';
import { ReactComponent as Randevular } from './../../assets/images/uzman-menu/randevular.svg';
import { ReactComponent as OBRandevular } from './../../assets/images/uzman-menu/ob-randevular.svg';
import { ReactComponent as Faturalar } from './../../assets/images/uzman-menu/faturalar.svg';
import { ReactComponent as Degerlendirmeler } from './../../assets/images/uzman-menu/degerlendirmeler.svg';
import { ReactComponent as Bloglar } from './../../assets/images/uzman-menu/bloglar.svg';
import { ReactComponent as Bildirimler } from './../../assets/images/uzman-menu/bildirimler.svg';
import { ReactComponent as Duyurular } from './../../assets/images/uzman-menu/duyurular.svg';
import { ReactComponent as ProfiliDuzenle } from './../../assets/images/uzman-menu/profili-duzenle.svg';
import { ReactComponent as CikisYap } from './../../assets/images/uzman-menu/cikis-yap.svg';

import SidebarItem from './SidebarItem';

import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';

import Co from '../../Functions/Co';

import swal from '@sweetalert/with-react';

import SidebarItemDropdownProfiliDuzenle from './SidebarItemDropdownProfiliDuzenle';
import SidebarItemDropdownUzman from './SidebarItemDropdownUzman';
import Main from '../../Functions/Main';

class Sidebar extends Component {
  componentDidMount = () => {
    this.aktif()
  };

  logout(){

    const ctx = this;

    swal({
      title :'Çıkış Yapılıyor',
      content : <div><span>Çıkış yapmak için çıkış butonuna basın</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'red-button'
        },
        b2 : {
          text:'Çıkış Yap',
          className: 'green-button',
        }
      }
    }).then(function(result){
      if(result == "b2"){
        ctx.props.set_user( {  } );
        Co.logout();
        ctx.props.history.push('/');
      }
    })
  
  }

  toggleSidebar(){
    this.props.sidebar_toggle( !this.props.sidebar );
  }

  aktif(pathname){
    return pathname == this.props.location.pathname ? ' active' : '';
  }

  uzman(){
    return (
      <ul className="list-group">

        <SidebarItem path={ '/dashboard' } icon={ <CalismaTakvimi/> } baslik="Dashboard"/>
        <SidebarItem path={ '/uzman/calisma-takvimi' } icon={ <CalismaTakvimi/> } baslik="Çalışma Takvimi"/>
        <SidebarItem path={ '/uzman/randevu-takvimi' } icon={ <RandevuTakvimi/> } baslik="Randevu Takvimi"/>
        <SidebarItem path={ '/uzman/randevular' } icon={ <Randevular/> } baslik="Randevular"/>
        <SidebarItem path={ '/uzman/onay-bekleyen-randevular' } icon={ <OBRandevular/> } baslik="Onay Bekleyen Randevular"/>
        <SidebarItem path={ '/uzman/faturalar' } icon={ <Faturalar/> } baslik="Faturalar"/>
        <SidebarItem path={ '/uzman/degerlendirmeler' } icon={ <Degerlendirmeler/> } baslik="Değerlendirmeler"/>
        
        {/* <SidebarItem path={ '/uzman/bloglar' } icon={ <Bloglar/> } baslik="Bloglar"/> */}

        <SidebarItem path={ '/uzman/bildirimler' } icon={ <Bildirimler/> } baslik="Bildirimler"/>
        <SidebarItem path={ '/uzman/duyurular' } icon={ <Duyurular/> } baslik="Duyurular"/>
        <SidebarItemDropdownProfiliDuzenle icon={ <ProfiliDuzenle/> } baslik="Profili Düzenle"/>
        <SidebarItem onClick={ () => this.logout() } path={ '#' } icon={ <CikisYap/> } baslik="Çıkış Yap"/>
              
      </ul>
    )
  }

  admin(){
    return (
      <ul className="list-group">
        
        <SidebarItemDropdownUzman icon={ <CalismaTakvimi/> } baslik="Uzman"/>
        <SidebarItem path={ '/sistem/uzman-onay-istekleri' } icon={ <CalismaTakvimi/> } baslik="Uzman Onay İstekleri"/>
        <SidebarItem path={ '/sistem/hizmet-bolgeleri' } icon={ <CalismaTakvimi/> } baslik="Hizmet Bölgeleri"/>
        <SidebarItem path={ '/sistem/meslek-gruplari' } icon={ <CalismaTakvimi/> } baslik="Meslek Grupları"/>
        <SidebarItem path={ '/sistem/randevular' } icon={ <CalismaTakvimi/> } baslik="Randevular"/>
        <SidebarItem path={ '/sistem/cagri-kayitlari' } icon={ <CalismaTakvimi/> } baslik="Çağrı Kayıtları"/>
        <SidebarItem onClick={ () => this.logout() } path={ '#' } icon={ <CikisYap/> } baslik="Çıkış Yap"/>
              
      </ul>
    )
  }

  alt_bilgi(){
    if(this.props.user == null){
      return (
        <p>Yükleniyor...</p>
      )
  }

    if(this.props.user.rol == 'ADMIN'){
      return(
        <div className="sidebar-user-info text-center">
          <p>{ this.props.user != null ? this.props.user.ad + ' ' + this.props.user.soyad : '' }</p>
          <p>
            <span className="badge badge-danger"> <i className="fas fa-bolt"></i> Admin</span>
          </p>
        </div>
      )
    }else{
      return(
        <div className="sidebar-user-info">
          <p>{ this.props.user != null ? this.props.user.ad + ' ' + this.props.user.soyad : '' }</p>
          <p>{ this.props.user != null ? this.props.user.meslek_adi : '' }</p>
        </div>
      )
    }

  }

  render() {

    return (
        <nav id="sidebar" className={ this.props.sidebar ? 'active' : '' }>
          <button onClick={ () => this.toggleSidebar() } id="side-bar-toggler" className="btn light-button">
              <i className="fas fa-bars"></i>
          </button>
          <div className="uzman-header">
              <Link to={ '/dashboard' }>
              <img src={ Main.resimURL(this.props.fotograf,true) } className="uzman-avatar" />
              { this.alt_bilgi() }
              </Link>
          </div>

          {
            (this.props.user != null && this.props.user.rol == 'ADMIN') ?
              <>
                { this.admin() }
              </>
            :
            <>
            { this.uzman() }
          </>
          }
          
        </nav>
    );
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar   : state.sidebar,
        user      : state.user,
        fotograf  : state.fotograf
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value }),
        set_user : ( value ) => dispatch({ type : actionTypes.SET_USER_DATA, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Sidebar))