import React, { Component } from 'react';

import ActionBar from '../Components/ActionBar';
import ActionBarMobile from '../Components/ActionBarMobile';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import PageLoading from '../Components/PageLoading';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class KullaniciSozlesmesi extends Component {

  state = {
    yuklendi : false,
  }


  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-12">
          <h3 className="text-center text-danger">Kullanıcı Sözleşmesi</h3>

          <p>Sayın Kullanıcı; www.iyiuzman.com sitesi üyesi olmak ve sitenin sağladığı hizmetlerden faydalanmak için aşağıda sorumluluk ve kuralların düzenlendiği maddeleri okumanız ve kabul etmeniz gerekmektedir. Eğer bu koşulları kabul etmiyorsanız siteyi kullanmaktan vazgeçiniz.</p>

          <h5>1- TARAFLAR ve TANIMLAR</h5>

          <table>
              <tbody>
                  
                  <tr>
                      <td>Site</td>
                      <td>:</td>
                      <td>www.iyiuzman.com</td>
                  </tr>

                  <tr>
                      <td>Kullanıcı</td>
                      <td>:</td>
                      <td>Siteyi ziyaret eden kişileri,</td>
                  </tr>

                  <tr>
                      <td>Hasta/Danışan</td>
                      <td>:</td>
                      <td>Herhangi Bir Uzman’dan Randevu Almak isteyen kişileri,</td>
                  </tr>

                  <tr>
                      <td>Uzman</td>
                      <td>:</td>
                      <td>Fizyoterapist & Diyetisyen  ya da Psikolog uzmanlığı sıfatına haiz olduğunu beyan eden kişileri ifade eder.</td>
                  </tr>

              </tbody>
          </table>

          <p>İşbu sözleşme, SİTE’nin işleteni FİZYO-İYİ UZMAN DANIŞMANLIK HİZMETLERİ ile kullanıcı sözleşmesini kabul eden HASTA/DANIŞAN & UZMAN arasında elektronik ortamda onaylanmıştır.</p>

          <h5>2- SİTE’NİN HİZMETLERİ VE SORUMLULUK HALLERİ</h5>

          <p>SİTE, Hastaları/Danışanları ve Uzmanları bir araya getirerek, Hasta/Danışanlara genel sağlık bilgilendirmesi amacı ile kurulmuş olup tedavi ve teşhis amacı taşımaz. SİTE bir bilgilendirme platformudur. SİTE’de yer alan bilgilerin ve/veya uzman tavsiyelerinin hiçbiri hasta muayenesi ve hastalık teşhisi anlamına gelmeyip, birebir hasta doktor muayenesinin yerini tutmaz. UZMAN’ların verdikleri cevaplar, hasta görüşmesi yapılmadan ve muayene edilmeden verilen cevaplar oldukları için sadece bilgilendirme ve fikir verme amacı taşırlar. SİTE’de yer alan bilgilere göre tedavinin durdurulması ve değiştirilmesi kesinlikle önerilmez.</p>

          <p>SİTE, Sunmuş olduğu hizmetlerinin içeriğini ve özelliklerini değiştirme/kısıtlama hakkını her zaman saklı tutar.</p>
          <p>SİTE, Uzmanların vermiş olduğu görüşlerin doğruluğunu teyit etmez içeriğini denetlemez. Uzmanların vermiş olduğu görüş ve bilgiler nedeni ile sorumlu tutulamaz.</p>
          <p>SİTE, Uzmanların şahıs bazında ve/veya kurumsal olarak düzenli olarak site içerisinde faaliyette bulunacaklarının garantisini veremez. SİTE’de bulunan UZMAN’lar, SİTE’nin işleteni olan şirketin kadrolu UZMAN’ları değillerdir. Makale yazma, soru cevaplama ve SİTE’de yer alan diğer tüm faaliyetleri tamamen gönüllü ve karşılığında hiçbir ücret almadan yapmaktadırlar. Bu faaliyetlerin tamamını veya bir kısmını yapmak veya yapmamak tamamen UZMAN’ların takdirinde olup, söz konusu faaliyetlerde aksama veya durma olması SİTE’yi hiçbir şekilde bağlamaz.</p>
          <p>SİTE, Uzmanların Hasta/Danışanlar ya da Diğer Uzmanlar ile yaptığı görüşmelerde hakaret, iftira, huzur bozucu davranışlar, etik olmayan davranışlar vb. hallerde Uzman’ların siteyi kullanmasını kısıtlayabilir/ tamamen durdurabilir ve askıya alabilir.Uzmanlık statüsünü iptal edebilir.</p>
          <p>SİTE, Mevcut hizmetlerini teknik bakım ve gereklilikler için makul bir süre için durdurma hakkına sahiptir.</p>
          <p>SİTE, vermiş olduğu hizmetlere ilişkin kullanım kuralları ve politikalar koyabilir, tek taraflı olarak her zaman değiştirebilir. Uzmanlar iş bu kurallara ve politikalara uyacağını peşinen kabul eder.</p>
          <p>SİTE, kendi kontrolünde olmayan üçüncü kişilerin sahip olduğu ve işlettiği başka web sitelerine ve/veya portallara, dosyalara veya içeriklere 'link' verebilir. Bu 'linkler, sahibinden izin alınmak suretiyle referans kolaylığı nedeniyle sağlanmış olabilir ve SİTE link verilen web sitesini veya işleticilerini desteklemek amacı veya web sitesi içeriği bilgilere yönelik herhangi bir beyan veya garanti anlamına gelmez, söz konusu içerikle ilgili olarak SİTE’nin sorumluluğu bulunmamaktadır.</p>
          
          <h5>3- ÜYELERİN HAKLARI VE SORUMLULUK HALLERİ</h5>
          <p>Hasta/Danışanlar, SİTE’nin teşhis ve tedavi amaçlı olmadığını bilgilendirme amacı ile faaliyette bulunduğunu, UZMAN’ın vermiş olduğu görüşlerden ve bilgilerden SİTE’nin sorumlu tutulamayacağını kabul ve beyan eder.</p>
          <p>Hasta/Danışanlar, SİTE’ye randevu oluşturma sırasında vermiş olduğu bilgilerin doğruluğundan tek başına sorumludur. Hasta/Danışanlar, SİTE’nin kullanımı sırasında 3.şahısların haklarını ihlal etmeyeceğini, hakaret, iftira, sövmeve örf ve adetlere aykırı eylemlerde bulunmayacağını taahhüt eder.</p>
          <p>Hasta/Danışanlar, SİTE’den sadece kişisel amaçlar ile faydalanabileceğini, SİTE içerisinde bulunan hiç bir hizmeti ticari olsun olmasın başkalarına kullandıramayacağını beyan eder.</p>
          <p>Hasta/Danışanlar, Bedeli karşılığı yararlanan hizmetlerin anında ifa içeren hizmetlerden olduğunu, aylık / yıllık veya belirli dönemsel üyeliklerde hizmeti kullanmadığından bahis ile bedel iadesi, mahsup ve devir talep edemeyeceğini bildiğini kabul ve beyan eder.</p>
          <p>Hasta/Danışanlar, kullanılan sisteme erişim araçlarının (isim soyisim telefon vb.) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması ve izinsiz kullanılmasıyla ilgili hususların tamamen kendi sorumluluğunda olduğunu kabul eder. ÜYE’lerin sisteme giriş araçlarının güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması, kullanılması gibi hususlardaki tüm ihmal ve kusurlarından dolayı üyelerin ve/veya üçüncü kişilerin uğradığı veya uğrayabileceği zararlar dolayısıyla SİTE’nin, doğrudan veya dolaylı, herhangi bir sorumluluğu yoktur.</p>
          <p>Hasta/Danışanlar, SİTE’ye erişim sağlamak için kullanmakta olduğu donanımın ve erişim hizmetlerinin yeterliliğinden kendi sorumlu olup SİTE hizmetlerine işbu nedenler ile erişememekten/hizmetleri kullanamamaktan dolayı SİTE’yi sorumlu tutamaz.</p>

          <h5>4- FİKRİ VE SİNAİ HAKLAR</h5>
          <p>SİTE içerisinde yer alan bütün görseller, veri tabanı, bilgi, belge ve her türlü materyalin hak sahibi SİTE olup, Hasta/Danışanlar  sitede bulunan hizmetleri sadece görüntüleme hakkına sahiptir. UZMAN’lar tarafından yüklenen makaleler, videolar vb. materyallerin eser sahibi UZMAN olup, SİTE işbu materyallerinin SİTE’de yayınlanması için eser sahibine tanınan hakları kullanım hakkına sahiptir. İşbu durum Hasta/Danışanlar UZMAN’lara ait eserleri kişisel amaçları dışında kullanım hakkı vermez. Hasta/Danışanlar  her halükarda sitede bulunan hiçbir materyali kopyalamayacağını, Çoğaltmayacağını, yaymayacağını ticari olsun olmasın 3.Şahısların kullanımına sunmayacağını taahhüt eder.</p>
          <p>İşbu maddenin tek istisnası, verilen link ve bağlantı ve diğer tanımlayıcı verilere müdahale etmemek şartı ile kişisel kullanım kapsamında SİTE üzerinden paylaşım izni verilen sosyal mecra hesaplarıdır.</p>

          <h5>5- MÜCBİR NEDENLER</h5>
          <p>İşbu sözleşmenin imzalandığı tarihte var olmayan, öngörülmeyen ve tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen veya tamamen yerine getirmelerini imkânsızlaştıran Borçlar Kanunu’nda sayılan haller ve İnternet alt yapısında ortaya çıkabilecek arızalar kesilmeler, SİTE’nin hizmet aldığı 3.firmalar tarafından hizmet kesintisine neden olabilecek eylemler taraflarca mücbir sebep(ler) olarak kabul edilir. Mücbir sebeplerin 30 günden fazla sürmesi halinde taraflar tek taraflı ve tazminatsız olarak işbu sözleşmeyi fesih etme hakkına sahiptir.</p>

          <h5>6- KİŞİSEL BİLGİLER ve GİZLİLİK</h5>
          <p>Üyelik sırasında verilen e-posta, adres, isim, soy isim ve vb. içerikler kişisel bilgilerinizi oluşturur. SİTE hiçbir zaman bu bilgileri diğer ÜYE’ler ile paylaşmaz.</p>
          <p>SİTE vermiş olduğu hizmetin niteliği gereği UZMAN’ların kişisel bilgilerinize erişimine izin verebilir. SİTE, UZMAN’lar vasıtası ile ya da diğer İş ortakları aracılığı ile size tanıtım maillerini gönderebilir, sms atabilir veya tanıtım broşürleri gönderebilir.</p>
          <p>SİTE içerisinde UZMAN ile yapılan görüşmeler İşbu hükümlerin dışında olup hiçbir zaman site içerisinde sorulan özel sorular ve kişiye özel cevaplar üye bilgileri ile yayınlanmaz, paylaşılmaz. SİTE sadece bu bilgileri istatiksel veri toplama amacı ile tasnif edebilir, anonim olarak yayınlayabilir.</p>

          <h5>7- ÜYELİĞİN SONLANDIRILMASI</h5>
          <p>UZMANLAR dilediği zaman SİTE‘de bulunan üyeliğini sonlandırabilir. Bedeli karşılığı yararlanan hizmetlerin anında ifade edilen hizmetlerden olması nedeni ile ÜYELİK iptali halinde bedel iadesi talep edilemez.</p>
          <p>SİTE, UZMAN’ın İşbu sözleşmede yazılı kurallara uymaması halinde UZMAN Hesabını tek taraflı ve bildirimsiz olarak askıya alabilir/iptal edebilir ve UZMANIN tekrar siteden faydalanmasını sağlayacak teknik engellemelere başvurabilir.</p>

          <h5>8- UYUŞMAZLIKLAR</h5>
          <p>SİTE ve UZMANLAR arasında çıkabilecek uyuşmazlıklar öncelikle Sulh yolu ile çözümlenecektir. Taraflar, uzlaşma sağlanamaması halinde ADANA Merkez Mahkemeleri’nin ve İcra Müdürlükleri’nin yetkili olduğunu kabul ve beyan ederler.</p>
          
        </div>
    )
  }

  render(){
    return (
        <div>

            <ActionBar/>
            <ActionBarMobile/>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              <div id="content">
                <div className="container mt-5">
                  { this.content() }
                </div>
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(KullaniciSozlesmesi))
