import React, { Component } from 'react';

import ActionBar from '../Components/ActionBar';
import ActionBarMobile from '../Components/ActionBarMobile';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import PageLoading from '../Components/PageLoading';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class AydinlatmaMetni extends Component {

  state = {
    yuklendi : false,
  }


  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-12">
          <h3 className="text-center text-danger">Aydınlatma Metni</h3>

          <p>FİZYO-İYİ UZMAN DANIŞMANLIK HİZMETLERİ (“İYİ UZMAN” veya “Şirket”) olarak, işbu Aydınlatma Metni ile, Kişisel Verilerin Korunması Kanunu (“Kanun”) ve Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında aydınlatma yükümlüğümüzün yerine getirilmesi amaçlanmaktadır.</p>

          <p>Bu kapsamda bilgi vermekle yükümlü olduğumuz konular aşağıdaki gibidir:  </p>

          <h5>1. Veri sorumlusunun ve varsa temsilcisinin kimliği</h5>
          <p>Veri sorumlusu; Atatürk Cad. 64021 Sk. Mehmet Sümer Apartmanı 1 / 4 SEYHAN/ADANA adresinde mukim, Adana Ticaret Sicili Müdürlüğü’ne ( ) sicil numarası ile kayıtlı FİZYO-İYİ UZMAN DANIŞMANLIK HİZMETLERİ’dir.. </p>

          <h5>2. Kişisel verilerin hangi amaçla işleneceği</h5>
          <p>Ad, soyadı, telefon numarası, e-posta adresi, adres bilgileri, ödeme aracı bilgileri ve bunlarla sınırlı olmamak üzere varsa internet sitesi veya çağrı merkezi aracılığıyla iletmiş olduğunuz genel ve özel nitelikli kategorilerdeki kişisel verileriniz, internet sitesinde üyeliğinizin oluşturulması, İyi Uzman üyeliği sebebiyle aldığınız hizmetlerin sunumu, alınan hizmet ile ilgili sizinle iletişime geçilmesi, müşteri ilişkilerinde sağlıklı ve uzun süreli etkileşim kurulması, onay vermeniz halinde tarafınıza ticari elektronik ileti gönderilmesi, talep ve şikayetlerinizin takibi ile ilerde oluşabilecek uyuşmazlık ve sorunların çözülmesi ve mevzuattan kaynaklanan zamanaşımı süresi doğrultusunda bu kişisel verilerinizin İyi Uzman tarafından saklanması amacı ile işlenmektedir.</p>

          <p>Ayrıca, internet sitemizi ziyaretiniz ve kullanımınız sırasında internet sayfası sunucusu tarafından sabit sürücünüze iletilen küçük metin dosyaları (“Çerezler”) aracılığıyla elde edilen kullanılan tarayıcı, IP adresi, internet bağlantınız, site kullanımlarınız hakkındaki bilgiler, bilgisayarınızdaki işletim sistemi ve benzeri kategorilerdeki kişisel verileriniz, internet sitesinin düzgün bir şekilde çalışabilmesi, ziyaret edilebilmesi ve özelliklerinden faydalanılması, internet sitesinde sayfalar arasında bilgileri taşıyabilmek ve bilgileri tekrardan girmek zorunluluğunu ortadan kaldırmak amaçları ile işlenmektedir.</p>

          <h5>3. Şirket tarafından işlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği</h5>
          <p>Kişisel verileriniz 2. maddede belirtilen amaçların yerine getirilebilmesi için İyi Uzman hissedarları, iş ortakları, hizmet aldığı şirketler ile yetkili kamu kurum ve kuruluşlarına aktarılabilecektir.</p>

          <h5>4. Kişisel veri toplamanın yöntemi ve hukuki sebebi</h5>
          <p>Şirketimizin internet sitesi veya çağrı merkezi aracılığıyla, tamamen veya kısmen otomatik yollarla elde edilen kişisel verileriniz, kanunda açıkça öngörülmesi, İyi Uzman ile aranızda kurulabilecek hukuki ilişkinin devamı için kişisel verilerinizin işlenmesinin gerekli olması, iletişim hakkının tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması ve açık rızanız hukuki sebepleri ile toplanmaktadır.</p>

          <h5>5. Kişisel verileriniz ile ilgili Kanun kapsamındaki haklarınız aşağıdaki şekildedir:</h5>
          <p>(a) Kişisel verilerinizin işlenip işlenmediğini öğrenme, (b) Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme, (c) Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, (ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, (d) Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, (e) Kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerinizin silinmesini veya yok edilmesini isteme, (f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, (g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, (ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</p>
          <p>Bu haklarınızı yazılı olarak veya güvenli elektronik imza, mobil imza, kayıtlı elektronik posta (KEP) adresi ya da Şirket’in sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle (Bu kapsamda iletisim@iyiuzman.com e-posta adresi üzerinden Şirket’e ulaşabilirsiniz) veya başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla Şirket’e iletebilirsiniz.</p>
          <p>Bilginize sunarız.</p>

          <p>FİZYO - İYİ UZMAN DANIŞMANLIK HİZMETLERİ</p>
        </div>
    )
  }

  render(){
    return (
        <div>

            <ActionBar/>
            <ActionBarMobile/>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              <div id="content">
                <div className="container mt-5">
                  { this.content() }
                </div>
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AydinlatmaMetni))
