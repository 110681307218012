import React, { Component } from 'react'

import { ReactComponent as IkiliOkSol } from '../../assets/images/ikili-ok-sol.svg';
import { ReactComponent as OkSol } from '../../assets/images/ok-sol.svg';
import { ReactComponent as IkiliOkSag } from '../../assets/images/ikili-ok-sag.svg';
import { ReactComponent as OkSag } from '../../assets/images/ok-sag.svg';

export default class Pagination extends Component {
    render() {

        const { sayfa,toplam_sayfa, ileriClick,geriClick,ilkClick,sonClick } = this.props;
        
        var sayfalar = [ ];

        if(sayfa > 1){
            for(var i = sayfa - 1; i >= 1;i--){

                if(i < (sayfa-3)) break;

                var x = (sayfa + 3) - i;

                sayfalar.push(
                    <button id={ x } className="btn page-button">{ x }</button>
                )
            }
        }

        sayfalar.push(
            <button id={ sayfa } className="btn page-button active px-3 py-2">{ sayfa }</button>
        )

        
        for(var i = sayfa; i <= sayfa + 3; i++){
            
            if(i >= toplam_sayfa) break;

            sayfalar.push(
                <button id={ i } className="btn page-button">{ i }</button>
            )
        }

        return (
            <div>
                <button onClick={ ilkClick } className="btn page-button"><IkiliOkSol/></button>
                <button onClick={ geriClick } className="btn page-button"><OkSol/></button>

                { sayfalar }

                <button onClick={ ileriClick } className="btn page-button"><OkSag/></button>
                <button onClick={ sonClick } className="btn page-button"><IkiliOkSag/></button>
            </div>
        )

    }
}
