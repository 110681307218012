import { ReactComponent as Klinik } from '../../assets/images/klinikte-hizmet.svg';
import { ReactComponent as Ev } from '../../assets/images/evde-hizmet.svg';
import { ReactComponent as Online } from '../../assets/images/online-hizmet.svg';


import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';
import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

class RandevuTakvimi extends Component {

  state = {
      yuklendi          : false,
      randevu_takvimi   : [ ],
      modal1            : false,
      randevu_adim      : 1,
      uzman             : { },

      iller             : [ ],
      ilceler           : [ ],

      s_tarih           : "",
      s_saat            : "",
      s_hizmet          : 0,
      s_hizmet_yeri     : '',
      s_il              : '',
      s_ilce            : '',
      mahalle           : '',
      notlar            : '',
      ad                : '',
      soyad             : '',
      telefon           : '',
      dogrulama_kodu    : '',

      calisma_takvimi : []
  }

  componentDidMount = () => {
    document.title = "Randevu Takvimi - İyi Uzman"
    this.profil_bilgileri();

    this.randevu_takvimi();

    this.calisma_takvimi_getir2();
  };

  async profil_bilgileri(id){
    const { hata,mesaj,data } = await Api.profil_bilgileri();

    if(!hata){

      const iller = this.hizmetIlleri(data.hizmet_bolgeleri);

      this.setState({ yuklendi : true, uzman : data == null ? [ ] : data, iller })
     
    }else{
      Main.message(mesaj,hata);
    }

   
  }

  async randevu_takvimi(){

    this.setState({ yuklendi : false })

    const { hata,data } = await Api.randevu_takvimim();

    if(!hata){
      this.setState({ yuklendi : true,randevu_takvimi : data.randevular == null ? [ ] : data.randevular })
    }
    
  }

  async calisma_takvimi_getir2(){
      const { hata,mesaj,data } = await Api.calisma_takvimi_listesi(this.props.user.id);

      this.setState({
          calisma_takvimi : data == null ? [ ] : data.randevu_saatleri,
          yuklendi : true
      })
      console.log(data)
  }

  ozelRandevu(tarih,saat){

    if(saat.randevu.id != undefined){
      this.props.history.push("/uzman/randevu-detay/" + saat.randevu.id);
      return;
    }

    if(!saat.musait) return;

    this.setState({ s_tarih : tarih, s_saat : saat.saat,modal1 : true })
  }

  randevu_saatleri(){

    return (
      <tr>
        {
          this.state.randevu_takvimi.map( (takvim) => (
          <td>
            {
              takvim.saatler.map(saat => (
                <div data-ozel={ saat.randevu.durum == 'OZEL_RANDEVU' ? true : false } onClick={ () => this.ozelRandevu(takvim.tarih,saat) } className={ saat.musait ? 'empty m-1 p-2' : saat.gecmis ? 'lost m-1 p-2' : 'full m-1 p-2' }>
                    {
                      (!saat.gecmis && saat.randevu.randevu_turu != undefined) ?
                      <>
                        {
                          saat.randevu.randevu_turu == 'EVDE' ? <Ev/> : saat.randevu.randevu_turu == 'KLINIKTE' ? <Klinik/> : <Online/> 
                        }
                      </>
                      :
                      <></>
                    }
                    <span>{ saat.saat }</span>
                    <span className='rt-musteri-adi'>
                      {
                        saat.musait ? 'Müsait' :
                        saat.gecmis ? 'Geçmiş' :
                        saat.randevu.musteri_adi + ' ' + saat.randevu.musteri_soyadi
                      }
                    </span>
                </div>
              ))
            }
          <br />
          </td>))
        }
      </tr>
    )
  }

  geri(){

    if(this.state.randevu_adim <= 1) return;
    
    let adim = this.state.randevu_adim;

    adim--;
    
    if(adim == 2 && this.state.s_hizmet_yeri != 'evde_hizmet'){
      adim--;
    }

    this.setState({
      randevu_adim : adim
    })
  }

  adim1_validation(){
    if(this.state.s_hizmet < 1){
      Main.message("Lütfen bir hizmet seçimi yapın!",true);
      return false;
    }

    if(this.state.s_hizmet_yeri == ''){
      Main.message("Lütfen bir hizmet yeri seçin!",true);
      return false;
    }

    return true;
  }

  adim2_validation(){
    if(this.state.s_il == ''){
      Main.message("Lütfen hizmet verilecek ili seçin!",true);
      return false;
    }

    if(this.state.s_ilce == ''){
      Main.message("Lütfen hizmet verilecek ilçeyi seçin!",true);
      return false;
    }

    if(this.state.mahalle == ''){
      Main.message("Lütfen hizmet verilecek mahalleyi girin!",true);
      return false;
    }

    return true;
  }

  async adim3_validation(){
    if(this.state.ad == ''){
      Main.message("Lütfen müşteri adını girin!",true);
      return false;
    }

    if(this.state.soyad == ''){
      Main.message("Lütfen müşteri soyadını girin!",true);
      return false;
    }

    if(this.state.telefon == ''){
      Main.message("Lütfen müşteri telefon numarasını girin!",true);
      return false;
    }

    return true;
  }

  async devam_et(){

    switch(this.state.randevu_adim){
      case 1 : {
        if(!this.adim1_validation()){
          return;
        }
        break;
      }
      case 2 : {
        if(!this.adim2_validation()){
          return;
        }
        break;
      }
      case 3 : {
        const devam = await this.adim3_validation();
        if(!devam){
          return;
        }
      }
    }

    let adim = this.state.randevu_adim;

    adim++;
    
    if(adim == 2 && this.state.s_hizmet_yeri != 'evde_hizmet'){
      adim++;
    }

    this.setState({
      randevu_adim : adim
    })
  }

  async randevu_onayla(){

    let randevu_turu = 'EVDE'; // EVDE - KLINIKTE - ONLINE

    if(this.state.s_hizmet_yeri == 'evde_hizmet'){
      randevu_turu = 'EVDE';
    }else if(this.state.s_hizmet_yeri == 'klinikte_hizmet'){
      randevu_turu = 'KLINIKTE';
    }else if(this.state.s_hizmet_yeri == 'online_hizmet'){
      randevu_turu = 'ONLINE';
    }else{
      Main.message("Geçersiz hizmet yeri!",true);
      return;
    }

    const params = {
      kullanici_id    : this.state.uzman.id,
      hizmet_id       : this.state.s_hizmet,
      musteri_adi     : this.state.ad,
      musteri_soyadi  : this.state.soyad,
      musteri_telefon : this.state.telefon,
      il              : this.state.s_il,
      ilce            : this.state.s_ilce,
      mahalle         : this.state.mahalle,
      musteri_notlari : this.state.notlar,
      randevu_tarihi  : this.state.s_tarih,
      randevu_saati   : this.state.s_saat,
      randevu_turu    : randevu_turu,
      dogrulama_kodu  : this.state.dogrulama_kodu
    }

    const { hata,mesaj,data } = await Api.ozel_randevu_olustur(params);

    Main.message(mesaj,hata);

    if(!hata){

      this.randevu_takvimi();

      this.setState({
        randevu_adim : 1,
        modal1 : false,
        s_tarih : "",
        s_saat : "",
        s_hizmet : "",
        s_hizmet_yeri : "",
        s_il : "",
        s_ilce : "",
        mahalle : "",
        notlar : "",
        ad : "",
        soyad : "",
        telefon : "",
        dogrulama_kodu : "",
      })
    }

  }

  randevu_adim1(){

    if(!this.state.yuklendi) return null;

    return (
      <div>
        <div className="form-group mt-3">
              <p className="lead">Hizmet</p>
              <select value={ this.state.s_hizmet } onChange={ (e) => this.setHizmet(e.target.value) } id='hizmet' type="text" className="form-control" defaultValue="0">
                <option value="0" disabled>Seçim yapın</option>
                {
                  this.state.uzman.verilen_hizmetler != null ?
                  this.state.uzman.verilen_hizmetler.map(hizmet => (
                    <option value={ hizmet.hizmet_id }> { hizmet.hizmet_adi } </option>
                  ))
                  :
                  ''
                }
              </select>
            </div>

            <div>

              <p className="lead">Hizmet yeri</p>
                
                {
                  this.state.uzman.evde_hizmet ?
                  <>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input onClick={ () => this.setHizmetYeri('evde_hizmet') } type="radio" name="randevu_turu" id="evde-hizmet" className="custom-control-input" checked={ this.state.s_hizmet_yeri == 'evde_hizmet' ? true : false } />
                    <label className="custom-control-label" htmlFor="evde-hizmet">Evde Hizmet</label>
                  </div>
                  </> : ''
                }
                
                {
                  this.state.uzman.klinikte_hizmet ?
                  <>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input onClick={ () => this.setHizmetYeri('klinikte_hizmet') } type="radio" name="randevu_turu" id="klinikte-hizmet" className="custom-control-input" checked={ this.state.s_hizmet_yeri == 'klinikte_hizmet' ? true : false } />
                    <label className="custom-control-label" htmlFor="klinikte-hizmet">Klinikte Hizmet</label>
                  </div>
                  </>
                  : ''
                }

                {
                  this.state.uzman.online_hizmet ?
                  <>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input onClick={ () => this.setHizmetYeri('online_hizmet') } type="radio" name="randevu_turu" id="online-hizmet" className="custom-control-input" checked={ this.state.s_hizmet_yeri == 'online_hizmet' ? true : false } />
                    <label className="custom-control-label" htmlFor="online-hizmet">Online Hizmet</label>
                  </div>
                  </>
                  : ''
                }

            </div>

            <div className="text-right mt-3">
              <button onClick={ () => this.devam_et() } className="btn green-button" style={ { width:100 } }>
                Devam Et
              </button>
            </div>
      </div>
    )
  }

  randevu_adim2(){
    return (
      <div>

        <div className="form-group">
          <label htmlFor="il">Randevu Oluşturulacak İl</label>
          <select value={ this.state.s_il } id="il" defaultValue="" onChange={ (e) => this.setIl(e.target.value) } type="text" id="il" className="form-control">
            <option value="" disabled>Seçiniz</option>
            { this.state.iller.map(il => (
              <option value={ il.value }>{ il.label }</option>
            )) }
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="ilce">Randevu Oluşturulacak İlçe</label>
          <select defaultValue="" value={ this.state.s_ilce } disabled={ this.state.ilceler.length < 1 ? true : false } onChange={ (e) => this.setIlce(e.target.value) } type="text" id="ilce" className="form-control">
            <option value="">Seçiniz</option>
            { this.state.ilceler.map(ilce => (
              <option value={ ilce.value }>{ ilce.label }</option>
            )) }
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="mahalle">Randevu Oluşturulacak Mahalle</label>
          <input i value={ this.state.mahalle } onChange={ (e) => this.setState({ mahalle : e.target.value }) }d="mahalle" name="mahalle" type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label htmlFor="notlar">Danışan Notları</label>
          <textarea value={ this.state.notlar } onChange={ (e) => this.setState({ notlar : e.target.value }) } id="notlar" name="notlar" type="text" className="form-control"/>
        </div>
        
        <div className="row">
          <div className="col">
           <button onClick={ () => this.geri() } className="btn light-button2" style={ { width:100 } }>
                Geri
            </button>
          </div>
          <div className="col text-right">
            <button onClick={ () => this.devam_et() } className="btn green-button" style={ { width:100 } }>
                Devam Et
            </button>
          </div>
        </div>

      </div>
    )
  }

  randevu_adim3(){
    return (
      <div>
        
        <div className="form-group">
          <label htmlFor="ad">Müşteri Adı</label>
          <input value={ this.state.ad } onChange={ (e) => this.setState({ ad : e.target.value }) } id="ad" name="ad" type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label htmlFor="soyad">Müşteri Soyadı</label>
          <input value={ this.state.soyad } onChange={ (e) => this.setState({ soyad : e.target.value }) } id="soyad" name="soyad" type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label htmlFor="telefon">Müşteri Telefon Numarası</label>
          <input value={ this.state.telefon } onChange={ (e) => this.setState({ telefon : e.target.value }) } id="telefon" name="telefon" type="text" className="form-control" />
        </div>
        
        <div className="row">
          <div className="col">
           <button onClick={ () => this.geri() } className="btn light-button2" style={ { width:100 } }>
                Geri
            </button>
          </div>
          <div className="col text-right">
            <button onClick={ () => this.devam_et() } className="btn green-button" style={ { width:100 } }>
                Devam Et
            </button>
          </div>
        </div>

      </div>
    )
  }

  randevu_adim4(){
    return (
      <div>
        
        <table>
          <tr>
            <td>Ad</td>
            <td className="px-2">:</td>
            <td>{ this.state.ad }</td>
          </tr>
          <tr>
            <td>Soyad</td>
            <td className="px-2">:</td>
            <td>{ this.state.soyad }</td>
          </tr>
          <tr>
            <td>Telefon</td>
            <td className="px-2">:</td>
            <td>{ this.state.telefon }</td>
          </tr>
          <tr>
            <td>Alınacak Hizmet</td>
            <td className="px-2">:</td>
            <td>{ this.hizmetAdi() }</td>
          </tr>
          <tr>
            <td>Hizmet Türü</td>
            <td className="px-2">:</td>
            <td>
              {
                this.state.s_hizmet_yeri == 'evde_hizmet' ? "Evde Hizmet" :
                this.state.s_hizmet_yeri == 'klinikte_hizmet' ? "Klinikte Hizmet" : "Online Hizmet"
              }
            </td>
          </tr>
          {
            this.state.s_hizmet_yeri == 'evde_hizmet' ?
            <>
            <tr>
              <td>Randevu Adresi</td>
              <td className="px-2">:</td>
              <td>
                { Main.il_adi(this.state.s_il) + ' / ' + Main.ilce_adi(this.state.s_ilce) } { this.state.mahalle }
              </td>
            </tr>
            </>
            :
            <>
            </>
          }
          <tr>
            <td>Randevu Tarihi</td>
            <td className="px-2">:</td>
            <td>{ this.state.s_tarih }</td>
          </tr>
          <tr>
            <td>Randevu Saati</td>
            <td className="px-2">:</td>
            <td>{ this.state.s_saat }</td>
          </tr>
        </table>
        
        <div className="row mt-3">
          <div className="col">
           <button onClick={ () => this.geri() } className="btn light-button2" style={ { width:100 } }>
                Geri
            </button>
          </div>
          <div className="col text-right">
            <button onClick={ () => this.randevu_onayla() } className="btn green-button" style={ { width:200 } }>
                Randevuyu Onayla
            </button>
          </div>
        </div>

      </div>
    )
  }

  setHizmet(s_hizmet){
    this.setState({ s_hizmet })
  }

  setHizmetYeri(s_hizmet_yeri){
    this.setState({ s_hizmet_yeri })
  }

  hizmetAdi(){
    return this.state.uzman.verilen_hizmetler.filter(x => x.hizmet_id == this.state.s_hizmet)[0].hizmet_adi;
  }

  setIl(il){
    this.setState({ s_il : il, ilce : '', ilceler : this.hizmetIlceleri(il) });
  }

  setIlce(ilce){
    this.setState({ s_ilce : ilce });
  }

  content(){
      if(!this.state.yuklendi){
          return (
              <PageLoading/>
          )
      }

      return (
        <div className="row">
            <div className="col-md-10">
                <div className="content-card d-flex">
                    <div className="table-responsive">
                        <table id="tablo-randevu-takvimi" className="table table-borderless dash mb-0">
                            <thead>
                                <tr>
                                    {
                                        this.state.randevu_takvimi.map(takvim => (
                                        <th className="th-randevu-takvimi">{ takvim.tarih3 }</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                { this.randevu_saatleri() }
                            </tbody>
                        </table>
                    </div>
                
                </div>
            </div>
        </div>
      )
  }

  hizmetIlleri(hizmet_bolgeleri){

    let iller = [ ];

    hizmet_bolgeleri.map(x => {
      const il = x.split('-')[0];

      if( iller.filter( x=> x.value == il ).length < 1 ){
        iller.push({
          label : Main.il_adi(il),
          value : il
        })
      }

    });

    return iller;
  }

  hizmetIlceleri(il){
    let ilceler = [ ];
    this.state.uzman.hizmet_bolgeleri.map(x => {
      const x_il = x.split('-')[0];
      const x_ilce = x.split('-')[1];

      if(x_il == il && ilceler.filter(x => x.value == x_ilce).length < 1){
        ilceler.push({
          label : Main.ilce_adi(x_ilce),
          value : x_ilce
        });
      }
    });

    return ilceler;
  }

  randevuModal(){
    return (
        <Modal show={ this.state.modal1 } dialogClassName="modal-90w" centered>
          <Modal.Body className="px-5 py-4">

            <div className="text-right mr-2 mt-2">
                <i onClick={ () => this.setState({ modal1 : false }) } className="fas fa-times close-popup"></i>
            </div>

            <h3>Özel Randevu Oluştur</h3>

            <p className='bg-green p-3 mt-3'>iyiuzman.com haricinde aldığınız randevuları buradan girerek iyiuzman.com'dan takip edebilirsiniz.Bunun için sistem size ekstra bir ücretlendirme yapmaz.</p>
            
            {
              this.state.randevu_adim == 1 ? this.randevu_adim1()
              :
              this.state.randevu_adim == 2 ? this.randevu_adim2()
              :
              this.state.randevu_adim == 3 ? this.randevu_adim3()
              :
              this.randevu_adim4()
            }

          </Modal.Body>
        </Modal>
    )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>
              
              <div id="content">
                <h4 className="mb-3">Randevu Takvimi</h4>
             
                {
                this.state.calisma_takvimi.filter(x => x.saatler.length > 0).length > 0 ?
                <></>
                :
                <>
                  {
                    this.state.yuklendi ? 
                    <>
                    <div className="col-md-10 bg-light-info border-radius-10 pt-4 pb-2 px-4 mb-3">
                      <i className="fa fa-info-circle float-right text-info"></i>
                      <p className='text-green2'>Lütfen önce çalışma takvimi sayfasından takviminizi oluşturun.</p>
                    </div>
                    </>
                    :
                    <></>
                  }
                </>
                }
      
                { this.content() }

              </div>

            </div>

            <Footer/>

            { this.randevuModal() }
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user        : state.user,
        giris_pop   : state.giris_pop,
        sidebar     : state.sidebar
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(RandevuTakvimi))