import React, { Component } from 'react';

import ActionBar from '../Components/ActionBar';
import ActionBarMobile from '../Components/ActionBarMobile';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import PageLoading from '../Components/PageLoading';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class GizlilikSozlesmesi extends Component {

  state = {
    yuklendi : false,
  }


  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-12">
          <h3 className="text-center text-danger">Gizlilik Sözleşmesi</h3>

          <p>1.       FİZYO-İYİ UZMAN DANIŞMANLIK HİZMETLERİ (Bundan böyle İYİUZMAN.COM olarak anılacak), gizliliğinizi korumak ve kullanılmakta bulunan teknolojik altyapıdan en üst seviyede yararlanmanızı sağlayabilmek amacıyla; kişisel bilgi ve veri güvenliğiniz için çeşitli gizlilik ilkeleri benimsemiştir. Bu gizlilik ilkeleri dahilinde İYİUZMAN.COM internet sitesi ve tüm alt internet sitelerinde veri toplanması ve/veya kullanımı konusunda uygulanmak üzere belirlenmiş ve beyan edilmektedir.</p>

          <p>2.       İYİUZMAN.COM internet sitesini ziyaret etmekle ve/veya kullanmakla ve/veya üye olmakla belirtilen bu ilkelerin tümü Kullanıcı tarafından kabul edilmiş sayılır. İYİUZMAN.COM  sayfasında belirtilen iletişim adreslerinden birisi ile kendisine yapılacak geri bildirimler doğrultusunda, “Gizlilik Bildirimi” bölümünde düzeltme ve güncelleme gibi işlemleri, önceden bildirmeksizin her zaman yapabilir. İYİUZMAN.COM “Gizlilik Bildirimi” bölümünü belli aralıklarla güncelleyebilir ve kullanıcı belli aralıklarla bu bölümü ziyaret ederek yeni gelişmelerden haberdar olabilir.</p>

          <p>3.       İYİUZMAN.COM, ziyaretçileri ya da kullanıcıları tarafından www.iyiuzman.com adlı web sitesi üzerinden kendisine elektronik ortamdan iletilen her türden kişisel bilgileri ve verileri, hastalıklara ve sağlık sorularına ilişkin verileri üçüncü kişilere hiç bir şekilde açıklamayacaktır. İYİUZMAN.COM, sunmuş olduğu hizmetlerin ve servislerin daha efektif kullanılabilmesi amacıyla bir çok 3. Parti kurum ve kuruluşlarla çeşitli şekillerde işbirliği yapabilir. Bu işbirliği; reklam, sponsorluk, izinli pazarlama, veri paylaşımı ve yasal diğer ticari yöntemlerle olabilir. İYİUZMAN.COM, iletişim faaliyetlerinde, kanunların ve bilhassa Sağlık Mevzuatı’nın öngördüğü şekilde izinli iletişim / pazarlama yapacağını, kullanıcının isteği dışında iletişim yapmamayı, kullanıcının sistemden ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder. İYİUZMAN.COM, kendisine iletilen kişisel verileri ve bilgileri, bilgilerin toplanması ile ilgili açıklanan yukarıdaki amaçlar dışında üçüncü kişilerle kesinlikle paylaşmayacak, satışını yapmayacak ve hiç bir şart altında kullanılmasına izin vermeyecektir.</p>

          <p>4.       Sitedeki sistemle ilgili sorunların tanımlanabilmesi ve İYİUZMAN.COM sitesinde çıkabilecek muhtemel sorunların acil olarak giderilmesi için, İYİUZMAN.COM gerektiğinde kullanıcıların IP adresini kaydedebilir ve bu kayıtları anılan bu amaçlarla kullanabilir. Bu IP adresleri, İYİUZMAN.COM tarafından kullanıcılarını ve ziyaretçilerini genel anlamda tanımlamak ve kapsamlı şekilde demografik veri toplayabilmek amacıyla kullanılabilir. İYİUZMAN.COM sitesinin 5651 sayılı yasada belirtilen trafik verisi saklama yükümlülükleri ayrıca saklıdır.</p>

          <p>5.       İYİUZMAN.COM’a kayıt olarak üye sıfatının kazanılması için veya üye olmaksızın çeşitli servis ve içeriklerden faydalanabilmesi için, ziyaretçilerin kendileriyle ilgili bir takım kişisel bilgilerini (örnek olarak: isim ve soy isim, telefon numarası, posta adresi, e-posta adresi vb.) formlar aracılığıyla İYİUZMAN.COM’a vermeleri gerekmektedir. İYİUZMAN.COM’un, kayıt sırasında talep edebileceği bu bilgiler sistemde kayıtlı olarak tutulabilir. İletilen bu kişisel bilgiler, gerektiğinde kullanıcılarla iletişime geçmek amacıyla da kullanılabilir İYİUZMAN.COM  tarafından talep edilebilecek bilgiler veya kullanıcı tarafından aktarılan bilgiler veya Site üzerinden yapılan işlemlerde ortaya koyulan ilgili bilgiler İYİUZMAN.COM  ve işbirliği içinde olduğu diğer kişi ve kurumlar tarafından kullanıcının kimliği hiç bir şekilde açığa çıkarılmaksızın sadece çeşitli istatistiki değerlendirmeler, belirli aralıklar ile gönderilecek olan e-postalar aracılığı ile izinli iletişim ve pazarlama, veri tabanı oluşturma çabaları ve pazar araştırmaları yapma gibi durumlar dahilinde kullanılabilir. E-bülten gönderimlerini durdurmak isterseniz, dilediğiniz zaman gönderdiğimiz bültenlerin alt kısmında bulunan “Bülten listemizden çıkmak için tıklayınız” linkine tıklayarak e-bülten üyeliğinden tek tıkla, kolayca çıkabilirsiniz.</p>

          <p>6.       İYİUZMAN.COM, Site içerisinde başkaca sitelere link(bağlantı) sağlayabilir. Anlaşmalı olduğu 3. Partilerin reklamlarını ve/veya çeşitli hizmetlere ilişkin başvuru formlarını yayınlayabilir, Kullanıcıları bu formlar ve reklamlar aracılığıyla reklamveren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. İYİUZMAN.COM, bu bağlantı yoluyla erişilen diğer sitelerin gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.</p>

          <p>7.       Kullanıcıya ait kişisel bilgiler, ad ve soy ad, adres, telefon numarası, elektronik posta adresi ve kullanıcıyı tanımlamaya yönelik diğer her türlü bilgi olarak anlaşılır. İYİUZMAN.COM, işbu gizlilik bildiriminde aksi belirtilmedikçe kişisel bilgilerden herhangi birini İYİUZMAN.COM’un işbirliği içinde olmadığı şirketlere ve üçüncü kişilere hiç bir şekilde açıklamayacaktır. İYİUZMAN.COM, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar;</p>

          <p>7.1. Kanun, KHK, Yönetmelik vb. yetkili hukuki makamlar tarafından çıkarılan ve yürürlükte bulunan hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,</p>
          <p>7.2. Sağlık Bakanlığı’nın genel düzenleyici işlemleriyle, Bakanlıkla paylaşılması zorunlu hale getirilen bilgilerin istendiği haller,</p>
          <p>7.3. İYİUZMAN.COM’un kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,</p>
          <p>7.4. Yetkili idari ve/veya adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda kullanıcılarla ilgili bilgi talep edilmesi hallerinde,</p>
          <p>7.5. Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu hallerde.</p>

          <p>8.       İYİUZMAN.COM, kendisine verilen gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.</p>
          
          <p>9.       İYİUZMAN.COM, kullanıcılar ve kullanıcıların İYİUZMAN.COM sitesi kullanımı hakkındaki bilgileri, teknik bir iletişim dosyası olan çerezler (Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte saklanmak üzere bir internet sitesinin, kullanıcının tarayıcısına (browser) gönderdiği minik metin dosyalarından ibarettir. Teknik iletişim dosyası bir internet sitesi hakkında kullanıcının bilgisayarındaki ilgili durum ve basit tercih ayarlarını saklayarak internet kullanımını bu anlamda kolaylaştırır. Bu bahsedilen teknik iletişim dosyası, internet sitesini zamansal oranlamalı olarak kaç kişinin kullandığını, bir kişinin ilgili internet sitesini hangi amaçla, kaç kez ziyaret ettiği ve ne kadar kaldığı hakkında istatistiki bilgiler elde etmeye ve kullanıcılar için özel olarak tasarlanmış kullanıcı sayfalarından dinamik çeşitlilikle reklam ve içerik üretilmesine yardımcı olmak üzere tasarlanmış ve kullanılmaktadır. Teknik iletişim dosyası, ana bellekten başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek çoğu kurulum aşamasında bu teknik iletişim dosyasını kabul eder biçimde hazırlanmışlardır, ancak kullanıcılar dilerlerse teknik iletişim dosyasının bilgisayarlarına yerleştirilmemesi veya bu türden bir dosyasının gönderildiğinde ikaz verilmesini sağlayacak biçimde tarayıcılarının ayarlarını her zaman değiştirebilirler.</p>

          <p>10.   İYİUZMAN.COM  tarafından Site içerisinde düzenlenebilecek periyodik veya periyodik olmayan anketlere cevap veren kullanıcılardan talep edilebilecek bilgiler de, İYİUZMAN.COM  ve işbirliği içindeki kişi ya da kurumlar tarafından bu kullanıcılara doğrudan pazarlama yapmak, istatistiki analizler yapmak ve özel bir veri tabanı oluşturmak amacıyla da kullanılabilmektedir.</p>

          <p>11.   İYİUZMAN.COM, işbu gizlilik bildiriminde geçen hükümleri gerekli gördüğü anlarda İYİUZMAN.COM  sitesinde sayfasında yayınlamak şartıyla değiştirebilir. İYİUZMAN.COM’nin değişiklik yaptığı gizlilik bildirimi hükümleri, ilgili sayfada yayınlandığı tarihte yürürlüğe girmiş kabul edilirler.</p>

          <p>Kurumsal Firma Bilgileri</p>

          <table>

              <tr>
                  <td>Firma Ünvanı</td>
                  <td>FİZYO-İYİ UZMAN DANIŞMANLIK HİZMETLERİ</td>
              </tr>

              <tr>
                  <td>Adres</td>
                  <td>Atatürk Cad. 64021 Sk. Mehmet Sümer Apt. 1 / 4 SEYHAN/ADANA</td>
              </tr>

              <tr>
                  <td>E-Posta Adresi</td>
                  <td>iletisim@iyiuzman.com</td>
              </tr>

              <tr>
                  <td>İletişim Bilgileri</td>
                  <td>Tel : +90 850 305 29 80 , Gsm : +90 545 179 29 80</td>
              </tr>

          </table>
          
        </div>
    )
  }

  render(){
    return (
        <div>

            <ActionBar/>
            <ActionBarMobile/>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              <div id="content">
                <div className="container mt-5">
                  { this.content() }
                </div>
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(GizlilikSozlesmesi))
