import axios from 'axios';
import qs from 'qs';
import Co from './Co';

const client_data = {
    client_id       : "RZ01hB45TzGImGZS",
    client_secret   : "h3qRXwRvHz6QsMTtB4oZlPXcrawtggf8",
    grant_type      : "password",
}

const server = "https://api.iyiuzman.com";
const prefix = "/api/v1";

const endpoints = {
    token_al                    : "/token-al",
    dogrulama_kodu_gonder       : "/dogrulama-kodu-gonder",
    kodu_dogrula                : "/kodu-dogrula",
    kayit_ol                    : "/kayit-ol",
    il_olustur                  : "/il-olustur",
    iller                       : "/iller",
    il_sil                      : "/il-sil",
    ilceler                     : "/ilceler",
    ilce_olustur                : "/ilce-olustur",
    ilce_sil                    : "/ilce-sil",
    meslek_grubu_olustur        : "/meslek-grubu-olustur",
    meslek_gruplari             : "/meslek-gruplari",
    meslek_grubu_sil            : "/meslek-grubu-sil",
    meslek_hizmeti_olustur      : "/meslek-hizmet-olustur",
    meslek_hizmetleri           : "/meslek-hizmetleri",
    meslek_hizmeti_sil          : "/meslek-hizmet-sil",
    meslek_unvani_olustur       : "/meslek-unvani-olustur",
    meslek_unvanlari            : "/meslek-unvanlari",
    meslek_unvani_sil           : "/meslek-unvan-sil",
    randevu_saati_ekle          : "/randevu-saati-ekle",
    calisma_takvimi             : "/randevu-saatleri",
    randevu_saati_sil           : "/randevu-saati-sil",
    randevular                  : "/randevular",
    uzman_olustur               : "/uzman-olustur",
    uzmanlar                    : "/uzmanlar",
    uzman_sil                   : "/uzman-sil",
    profili_guncelle            : "/profili-guncelle",
    profil_resmi_degistir       : "/profil-resmi-degistir",
    profil_bilgileri            : "/profil-bilgileri",
    parola_degistir             : "/parola-degistir",
    diploma_yukle               : "/diploma-yukle",
    uzman_hizmet_ekle           : "/uzman-hizmet-ekle",
    uzman_hizmet_sil            : "/uzman-hizmet-sil",
    hizmet_bolgesi_ekle         : "/hizmet-bolgesi-ekle",
    hizmet_bolgesi_sil          : "/hizmet-bolgesi-sil",
    profili_onaya_gonder        : "/profili-onaya-gonder",
    uzman_profili_onayla        : "/uzman-profili-onayla",
    hizmet_ara                  : "/hizmet-ara",
    uzman_ara                   : "/uzman-ara",
    uzman_profil_bilgileri      : "/uzman-profil-bilgileri",
    randevu_takvimi             : "/randevu-takvimi",
    randevu_takvimim            : "/randevu-takvimim",
    randevu_dogrulama_kodu_gonder : "/randevu-dogrulama-kodu-gonder",
    randevu_olustur               : "/randevu-olustur",
    ozel_randevu_olustur          : "/ozel-randevu-olustur",
    randevu_onayla                : "/randevu-onayla",
    randevu_iptal                 : "/randevu-iptal-et",
    randevu                       : "/randevu",
    randevu_detaylari             : "/randevu-detaylari",
    musteri_randevu_iptal         : "/randevu-iptal",
    randevuya_arama_talebi_olustur : "/randevuya-arama-talebi-olustur",
    meslek_grubu_duzenle           : "/meslek-grubu-duzenle",
    sifremi_unuttum                : "/sifremi-unuttum",
    sifremi_sifirla                : "/sifremi-sifirla",
    faturalar                      : "/faturalar",
    randevu_degerlendir            : "/randevu-puanla",
    fatura                         : "/fatura",
    bildirimler                    : "/bildirimler",
    duyurular                      : "/duyurular",
    degerlendirmeler               : "/randevu-puanlari",
    dashboard                      : "/dashboard",
    degerlendirme                  : "/randevu-puani",
    randevuyu_tamamla              : "/randevuyu-tamamla",
    anasayfa                       : "/anasayfa",
    admin_randevular_listesi       : "/tum-randevular",
    iletisim_formu                 : "/iletisim-formu",
    admin_randevu_detay            : "/randevu-goruntule",
    admin_randevu_iptal            : "/sistem-randevu-iptal",
    cagri_kayitlari                : "/cagri-kayitlari",
    cagri_kaydi                    : "/cagri-kaydi",
    gorusler                       : "/degerlendirmeler",
    randevu_bilgileri_kaydet       : "/randevu-bilgileri-kaydet",
}

class Api {

    requestOptions(method,req_url,params = { },access_token = null){
        
        var headers = {
          'content-type' : 'application/x-www-form-urlencoded'
        };
    
        if(access_token != null){
            headers.Authorization = "Bearer : " + access_token;
        }

        const data = qs.stringify(params);

        if(method.toLowerCase() == 'get'){
            req_url += "?" + qs.stringify(params)
        }
        
        return {
            method:method,
            headers:headers,
            data : data,
            url : req_url
        };
    }

    async request(req_opt){
        const data = await axios(req_opt);

        if(data.data.kod != undefined){
            const { kod,mesaj } = data.data;

            if(kod < 1){
                window.location.href = "/";

                Co.logout();

                // const token_data = this.getItem('auth');
                // const now = new Date();

                // if( (now.getTime() / 1000)  > token_data.expiry){
                //     this.logout();
                //     window.location.href = "/";
                //     return false;
                // }
            }
        }

        return data;
    }
    
    async token_al(email,parola){
    
        const url = server + prefix + endpoints.token_al;

        const params = {
            client_id       : client_data.client_id,
            client_secret   : client_data.client_secret,
            grant_type      : client_data.grant_type,
            email           : email,
            password        : parola
        }
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async dogrulama_kodu_gonder(anahtar,tur = 'EMAIL'){
        const url = server + prefix + endpoints.dogrulama_kodu_gonder;

        const params = {
            tur,
            anahtar
        }
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async kodu_dogrula(kod,email,tur,access_token){
        const url = server + prefix + endpoints.kodu_dogrula;

        const params = {
            kod,
            tur,
            email
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async kayit_ol(ad,soyad,email,password,dogrulama_kodu){
        const url = server + prefix + endpoints.kayit_ol;

        const params = {
            client_id : client_data.client_id,
            client_secret : client_data.client_secret,
            grant_type : client_data.grant_type,
            ad,
            soyad,
            email,
            password,
            dogrulama_kodu
        }
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);

        // console.log(yanit);
  
        return yanit.data;
    }

    async il_olustur(il_adi,access_token){
        const url = server + prefix + endpoints.il_olustur;

        const params = {
            il_adi : il_adi,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async ilce_olustur(ilce_adi,il_id,access_token){
        const url = server + prefix + endpoints.ilce_olustur;

        const params = {
            il_id,
            ilce_adi
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    
    async il_listesi(access_token){
        const url = server + prefix + endpoints.iller;
  
        const req_opt = this.requestOptions('GET',url,{ },access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async il_sil(il_id,access_token){
        const url = server + prefix + endpoints.il_sil;

        const params = {
            il_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async ilce_sil(ilce_id,access_token){
        const url = server + prefix + endpoints.ilce_sil;

        const params = {
            ilce_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async ilce_listesi(il_id,access_token){
        const url = server + prefix + endpoints.ilceler;

        const params = {
            il_id
        };
  
        const req_opt = this.requestOptions('GET',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async meslek_grubu_olustur(meslek_adi,evde_komisyon_bedeli,klinikte_komisyon_bedeli,online_komisyon_bedeli,access_token){
        const url = server + prefix + endpoints.meslek_grubu_olustur;

        const params = {
            meslek_adi : meslek_adi,
            evde_komisyon_bedeli : evde_komisyon_bedeli,
            klinikte_komisyon_bedeli : klinikte_komisyon_bedeli,
            online_komisyon_bedeli : online_komisyon_bedeli,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async meslek_grubu_listesi(access_token = null){
        const url = server + prefix + endpoints.meslek_gruplari;
  
        const req_opt = this.requestOptions('GET',url,{ },access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async meslek_grubu_sil(meslek_id,access_token){
        const url = server + prefix + endpoints.meslek_grubu_sil;

        const params = {
            meslek_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async alt_meslek_grubu_olustur(ust_ktg_id,meslek_adi,evde_komisyon_bedeli,klinikte_komisyon_bedeli,online_komisyon_bedeli,access_token){
        const url = server + prefix + endpoints.meslek_grubu_olustur;

        const params = {
            ust_ktg_id : ust_ktg_id,
            meslek_adi : meslek_adi,
            evde_komisyon_bedeli : evde_komisyon_bedeli,
            klinikte_komisyon_bedeli : klinikte_komisyon_bedeli,
            online_komisyon_bedeli : online_komisyon_bedeli,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async alt_meslek_grubu_listesi(ust_ktg_id,access_token){
        const url = server + prefix + endpoints.meslek_gruplari;

        const params = {
            ust_ktg_id
        }
  
        const req_opt = this.requestOptions('GET',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async alt_meslek_grubu_sil(meslek_id,access_token){
        const url = server + prefix + endpoints.meslek_grubu_sil;

        const params = {
            meslek_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async meslek_hizmeti_olustur(meslek_id,hizmet_adi,access_token){
        const url = server + prefix + endpoints.meslek_hizmeti_olustur;

        const params = {
            meslek_id : meslek_id,
            hizmet_adi : hizmet_adi,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    
    async meslek_hizmetleri_listesi(meslek_id = null){
        const url = server + prefix + endpoints.meslek_hizmetleri;

        let params = {

        }

        if(meslek_id != null){
            params.meslek_id = meslek_id;
        }
  
        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async meslek_hizmeti_sil(hizmet_id,access_token){
        const url = server + prefix + endpoints.meslek_hizmeti_sil;

        const params = {
            hizmet_id : hizmet_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }


    async meslek_unvani_olustur(meslek_id,unvan,access_token){
        const url = server + prefix + endpoints.meslek_unvani_olustur;

        const params = {
            meslek_id : meslek_id,
            unvan : unvan,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    
    async meslek_unvanlari_listesi(meslek_id,access_token){
        const url = server + prefix + endpoints.meslek_unvanlari;

        const params = {
            meslek_id
        }
  
        const req_opt = this.requestOptions('GET',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async meslek_unvani_sil(unvan_id,access_token){
        const url = server + prefix + endpoints.meslek_unvani_sil;

        const params = {
            unvan_id : unvan_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async randevu_saati_ekle(gun,saat,access_token){
        const url = server + prefix + endpoints.randevu_saati_ekle;

        const params = {
            gun,
            saat
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async calisma_takvimi_listesi(id,access_token){

        const url = server + prefix + endpoints.calisma_takvimi;

        const params = {
            kullanici_id : id
        }
  
        const req_opt = this.requestOptions('GET',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async calisma_takvimi_listesi2(id){

        const url = server + prefix + endpoints.calisma_takvimi;

        const params = {
            kullanici_id : id
        }
  
        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async randevu_saati_sil(randevu_saati_id,access_token){
        const url = server + prefix + endpoints.randevu_saati_sil;

        const params = {
            randevu_saati_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async randevular_listesi(duruma_gore,tarihe_gore,sayfa){
        var url = server + prefix + endpoints.randevular;

        var params = { }

        if(duruma_gore != null) params.durum = duruma_gore;
        if(tarihe_gore != null) params.zaman = tarihe_gore;

        params.sayfa = sayfa;


        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async uzman_olustur(ad,soyad,email,password,access_token){
        const url = server + prefix + endpoints.uzman_olustur;

        const params = {
            client_id : client_data.client_id,
            client_secret : client_data.client_secret,
            grant_type : client_data.grant_type,
            ad,
            soyad,
            email,
            password,
            access_token
        }
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);

        // console.log(yanit);
  
        return yanit.data;
    }

    async uzman_listesi(params, access_token){
        var url = server + prefix + endpoints.uzmanlar;

        const req_opt = this.requestOptions('GET',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async uzman_sil(id,access_token){
        const url = server + prefix + endpoints.uzman_sil;

        const params = {
            id : id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async profili_guncelle(params,access_token){
        const url = server + prefix + endpoints.profili_guncelle;
  
        const req_opt = this.requestOptions('PUT',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async profil_bilgileri(params = {  }){

        const url = server + prefix + endpoints.profil_bilgileri;
        
        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async profil_resmi_degistir(resim,access_token){

        const url = server + prefix + endpoints.profil_resmi_degistir;

        const params = {
            resim
        }
  
        const req_opt = this.requestOptions('PUT',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async parola_degistir(suanki_parola,yeni_parola,access_token){
        const url = server + prefix + endpoints.parola_degistir;

        const params = {
            suanki_parola,
            yeni_parola
        }
  
        const req_opt = this.requestOptions('PUT',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    diploma_yukle(diploma,access_token){
        const url = server + prefix + endpoints.diploma_yukle;

        const headers = {
            Authorization : "Bearer : " + access_token
        }
        
        const data = new FormData() 

        data.append('diploma', diploma);

        return new Promise(resolve => {
            axios.post(url, data,{ headers }).then(res => {
                resolve(res.data)
            })
        });
 
    }

    async uzman_hizmet_ekle(hizmet_id,access_token){
        const url = server + prefix + endpoints.uzman_hizmet_ekle;

        const params = {
            hizmet_id,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async uzman_hizmet_sil(hizmet_id,access_token){
        const url = server + prefix + endpoints.uzman_hizmet_sil;

        const params = {
            hizmet_id
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async hizmet_bolgesi_ekle(bolge,access_token){
        const url = server + prefix + endpoints.hizmet_bolgesi_ekle;

        const params = {
            bolge,
        }
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async hizmet_bolgesi_sil(bolge,access_token){
        const url = server + prefix + endpoints.hizmet_bolgesi_sil;

        const params = {
            bolge
        }
  
        const req_opt = this.requestOptions('DELETE',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async profili_onaya_gonder(access_token){
        const url = server + prefix + endpoints.profili_onaya_gonder;

        const params = {};
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);

        //console.log(yanit);
  
        return yanit.data;
    }

    async uzmani_onayla(kullanici_id,onaylandi,aciklama,access_token){
        const url = server + prefix + endpoints.uzman_profili_onayla;

        const params = {
            kullanici_id,
            onaylandi,
            aciklama
        };
  
        const req_opt = this.requestOptions('POST',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async hizmet_ara(q){
        const url = server + prefix + endpoints.hizmet_ara;

        const params = {
            q
        }
  
        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async uzman_ara(params){
        const url = server + prefix + endpoints.uzman_ara;
  
        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async uzman_profil_bilgileri(id){
        const url = server + prefix + endpoints.uzman_profil_bilgileri;

        const params = {
            id
        }
  
        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_takvimi(id){
        const url = server + prefix + endpoints.randevu_takvimi;

        const params = {
            kullanici_id : id
        }
  
        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_takvimim(){
        const url = server + prefix + endpoints.randevu_takvimim;

        const params = {
        }
  
        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_dogrulama_kodu_gonder(telefon){
        const url = server + prefix + endpoints.randevu_dogrulama_kodu_gonder;

        const params = {
            telefon
        }
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_olustur(params){
        const url = server + prefix + endpoints.randevu_olustur;
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async ozel_randevu_olustur(params){
        const url = server + prefix + endpoints.ozel_randevu_olustur;
  
        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevular(params){
        var url = server + prefix + endpoints.randevular;

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_onayla(id){
        var url = server + prefix + endpoints.randevu_onayla;

        const params = {
            randevu_id : id
        }

        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_iptal(id,not){
        var url = server + prefix + endpoints.randevu_iptal;

        const params = {
            randevu_id  : id,
            iptal_notu  : not
        }

        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu(id){
        var url = server + prefix + endpoints.randevu;

        const params = {
            randevu_id  : id,
        }

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_detaylari(id){
        var url = server + prefix + endpoints.randevu_detaylari;

        const params = {
            hash_id  : id,
        }

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async musteri_randevu_iptal(id,iptal_notu){
        var url = server + prefix + endpoints.musteri_randevu_iptal;

        const params = {
            hash_id  : id,
            iptal_notu
        }

        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevuya_arama_talebi_olustur(randevu_id,notlar){
        var url = server + prefix + endpoints.randevuya_arama_talebi_olustur;

        const params = {
            randevu_id,
            notlar
        }

        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async meslek_grubu_duzenle(params){
        var url = server + prefix + endpoints.meslek_grubu_duzenle;

        const req_opt = this.requestOptions('PUT',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async sifremi_unuttum(email){
        var url = server + prefix + endpoints.sifremi_unuttum;

        const params = {
            email,
        }

        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async sifremi_sifirla(sifre,tekrar_sifre,kod){
        var url = server + prefix + endpoints.sifremi_sifirla;

        const params = {
            sifre,
            tekrar_sifre,
            kod,
        }

        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async faturalar_listesi(duruma_gore,tarihe_gore,sayfa){
        var url = server + prefix + endpoints.faturalar;

        var params = { }

        if(duruma_gore != null) params.durum = duruma_gore;
        if(tarihe_gore != null) params.zaman = tarihe_gore;

        params.sayfa = sayfa;


        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_degerlendir(hash_id,puan,yorum){
        var url = server + prefix + endpoints.randevu_degerlendir;

        const params = {
            hash_id,
            puan,
            yorum,
        }

        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async fatura(id){
        var url = server + prefix + endpoints.fatura;

        const params = {
            fatura_id  : id,
        }

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async bildirimler_listesi(sayfa){
        var url = server + prefix + endpoints.bildirimler;

        var params = { }

        params.sayfa = sayfa;


        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async duyurular_listesi(sayfa){
        var url = server + prefix + endpoints.duyurular;

        var params = { }

        params.sayfa = sayfa;


        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async degerlendirmeler_listesi(kullanici_id,sayfa){
        var url = server + prefix + endpoints.degerlendirmeler;

        var params = { }

        params.sayfa = sayfa;


        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async degerlendirme(id){
        var url = server + prefix + endpoints.degerlendirme;

        const params = {
            id,
        }

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async dashboard(){
        var url = server + prefix + endpoints.dashboard;

        var params = { };

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevuyu_tamamla(id){
        var url = server + prefix + endpoints.randevuyu_tamamla;

        const params = {
            id : id
        }

        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async anasayfa(){
        var url = server + prefix + endpoints.anasayfa;

        var params = { }

        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async admin_randevular_listesi(duruma_gore,tarihe_gore,sayfa,ara){
        var url = server + prefix + endpoints.admin_randevular_listesi;

        var params = { }

        if(duruma_gore != null) params.durum = duruma_gore;
        if(tarihe_gore != null) params.zaman = tarihe_gore;

        params.sayfa = sayfa;

        params.ara = ara;


        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async iletisim_formu(ad,soyad,telefon,email,mesaj){
        const url = server + prefix + endpoints.iletisim_formu;

        const params = {
            ad,
            soyad,
            telefon,
            email,
            mesaj,
        }
  
        const req_opt = this.requestOptions('POST',url,params);

        const yanit = await this.request(req_opt);

        // console.log(yanit);
  
        return yanit.data;
    }

    async admin_randevu_detay(id){
        var url = server + prefix + endpoints.admin_randevu_detay;

        const params = {
            randevu_id  : id,
        }

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async admin_randevu_iptal(id,not){
        var url = server + prefix + endpoints.admin_randevu_iptal;

        const params = {
            randevu_id  : id,
            iptal_notu  : not
        }

        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async cagri_kayitlari(params, access_token){
        var url = server + prefix + endpoints.cagri_kayitlari;

        const req_opt = this.requestOptions('GET',url,params,access_token);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async cagri_kaydi(id){
        
        var url = server + prefix + endpoints.cagri_kaydi;

        const params = {
            cagri_kaydi_id  : id,
        }

        const req_opt = this.requestOptions('GET',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async gorusler(id){

        var url = server + prefix + endpoints.gorusler;

        const params = {
            id  : id,
        }

        const req_opt = this.requestOptions('GET',url,params);

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

    async randevu_bilgileri_kaydet(randevu_id,uzman_notlari){
        var url = server + prefix + endpoints.randevu_bilgileri_kaydet;

        const params = {
            randevu_id,
            uzman_notlari
        }

        const req_opt = this.requestOptions('POST',url,params,Co.access_token());

        const yanit = await this.request(req_opt);
  
        return yanit.data;
    }

}

export default new Api();