import { ReactComponent as Basarili } from '../../assets/images/dashboard/basarili.svg';
import { ReactComponent as Basarisiz } from '../../assets/images/dashboard/basarisiz.svg';
import { ReactComponent as BasariOrani } from '../../assets/images/dashboard/basariorani.svg';
import { ReactComponent as Blog } from '../../assets/images/dashboard/blog.svg';
import { ReactComponent as RandevuTalepleri } from '../../assets/images/dashboard/randevu-talepleri.svg';
import { ReactComponent as YaklasanRandevular } from '../../assets/images/dashboard/yaklasan-randevular.svg';


import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import { Link,withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import * as actionTypes from '../../Redux/actionTypes';

import swal from '@sweetalert/with-react';

import Api from '../../Functions/Api';

import Main from '../../Functions/Main';

import Moment from 'react-moment';

class Dashbard extends Component {

  state = {
    yuklendi : false,
    dashboard : []
  }

  componentDidMount = () => {
    document.title = "Dashboard - İyi Uzman"
    document.querySelector('meta[name="description"]').setAttribute("content", "Dashboard, İyi Uzman, 0850 305 29 80");
    const ctx = this;
    setTimeout(function(){
       ctx.dashboard();
    },500);
    
  };

  async dashboard(){

    const { hata,mesaj,data } = await Api.dashboard();

    if(hata){
        swal("HATA",mesaj,"error");
    }

    this.setState({
      dashboard : data == null ? [ ] : data,
      yuklendi : true,
    })
    console.log(data)
  }

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
          <div className="row">

            <div className="col-md-4 mb-3">
              <div className="stat-card">
                <RandevuTalepleri/>
                <div className="stat">
                  <p>Randevu Talepleri</p>
                  <h3>{ this.state.dashboard.randevu_talepleri }</h3>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="stat-card">
                <YaklasanRandevular/>
                <div className="stat">
                  <p>Yaklaşan Randevular</p>
                  <h3>{ this.state.dashboard.yaklasan_randevular }</h3>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="stat-card">
                <Basarili/>
                <div className="stat">
                  <p>Başarılı Randevular</p>
                  <h3>{ this.state.dashboard.basarili_randevular }</h3>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="stat-card">
                <Basarisiz/>
                <div className="stat">
                  <p>Başarısız Randevular</p>
                  <h3>{ this.state.dashboard.basarisiz_randevular }</h3>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="stat-card">
                <BasariOrani/>
                <div className="stat">
                  <p>Başarı Oranı</p>
                  <h3>%{ this.state.dashboard.basari_orani }</h3>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="stat-card">
                <Blog/>
                <div className="stat">
                  <p>Blog Sayısı</p>
                  <h3>{ this.state.dashboard.blog_sayisi }</h3>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="content-card mt-4">
              <h4 className="title m-4">Faturalar</h4>
              {
                this.state.dashboard.faturalar.length > 0 ?
                <>
                  <div className="table-responsive">
                    <table className="table table-borderless dash mb-0">
                      <thead>
                        <tr>
                          <th>Tarih - Saat</th>
                          <th>Son Ödeme Tarihi</th>
                          <th>Miktar</th>
                          <th>Durum</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        this.state.dashboard.faturalar.map( (fatura) => (
                        <tr>
                          <td><Moment format="DD.MM.YYYY HH:mm">{ fatura.olusturulma_tarihi }</Moment></td>
                          <td><Moment add={{ days: 10}} format="DD.MM.YYYY HH:mm">{ fatura.olusturulma_tarihi }</Moment></td>
                          <td>{ fatura.tutar } ₺</td>
                          {
                            fatura.durum == 'ODENMEDI' ?
                            <>
                            <td className='text-danger'>{ Main.fatura_durum(fatura.durum) }</td>
                            </>
                            :
                            <td>{ Main.fatura_durum(fatura.durum) }</td>
                          }
                      
                          <td>
                              <Link to={ '/uzman/fatura-detay/' + fatura.id } className="btn light-button2">
                                  Detayları Görüntüle
                              </Link>
                          </td>
                        </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="text-center p-4">
                      <Link to={'/uzman/faturalar'} className="btn green-button">
                        Tümünü Görüntüle
                      </Link>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-md-12 p-5 text-center">
                      <h5>Hiç fatura yok.</h5>
                  </div>
                </>
              }
              
            </div>
            
            <nav id="dash-nav" className="mt-4">
                <div class="nav nav-tabs" role="tablist">
                  <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home">
                    <span>Değerlendirmeler</span>
                  </a>
                  <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile">
                    <span>Bildirimler</span>
                  </a>
                  <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact">
                    <span>Duyurular</span>
                  </a>
                </div>
            </nav>
            
            <div className="content-card mt-2">
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home">
                        
                  {
                    this.state.dashboard.degerlendirmeler.length > 0 ?
                    <>
                      <div className="table-responsive">
                        <table className="table table-borderless dash mb-0">
                          <thead>
                            <tr>
                              <th>Yorum</th>
                              <th>Puan</th>
                              <th>Değerlendirme Tarihi</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                            this.state.dashboard.degerlendirmeler.map( (degerlendirme) => (
                            <tr>
                              <td>{ degerlendirme.yorum }</td>
                              <td>{ degerlendirme.puan }</td>
                              <td><Moment format="DD.MM.YYYY HH:mm">{ degerlendirme.olusturulma_tarihi }</Moment></td>
                              <td>
                                <Link to={ '/uzman/degerlendirme-detay/' + degerlendirme.id } className="btn light-button2 btn-detay">Detayları Görüntüle</Link>
                              </td>
                            </tr>
                            ))
                            }
                          </tbody>
                        </table>
                      </div>

                      <div className="text-center p-4">
                        <Link to={'/uzman/degerlendirmeler'} className="btn green-button">
                          Tümünü Görüntüle
                        </Link>
                      </div>
                    </>
                    :
                    <>
                    <div className="col-md-12 p-5 text-center">
                        <h5>Hiç değerlendirme yok.</h5>
                    </div>
                    </>
                  }

                </div>

                <div class="tab-pane fade" id="nav-profile">
                  {
                    this.state.dashboard.bildirimler.length > 0 ?
                    <>
                      <div className="table-responsive">
                        <table className="table table-borderless dash mb-0">
                          <thead>
                            <tr>
                              <th>Bildirim</th>
                              <th>Tarih-Saat</th>
                              <th style={ { width:350 } }></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                            this.state.dashboard.bildirimler.map( (bildirim) => (
                            <tr>
                              <td>{ bildirim.baslik }</td>
                              <td>{ <Moment format="DD.MM.YYYY HH:mm">{ bildirim.olusturulma_tarihi }</Moment> }</td>
                              <td>
                                <Link 
                                to={
                                    bildirim.tur.toLowerCase() == 'randevu' ?
                                    '/uzman/randevu-detay/' + bildirim.data_id
                                    : bildirim.tur.toLowerCase() == 'fatura' ?
                                    '/uzman/fatura-detay/' + bildirim.data_id
                                    : bildirim.tur.toLowerCase() == 'link' ?
                                    bildirim.link
                                    :
                                    '#'
                                }
                                >
                                    <button className="btn light-button2">Git</button>
                                </Link>
                              </td>
                            </tr>
                            ))
                            }
                          </tbody>
                        </table>
                      </div>
                    
                      <div className="text-center p-4">
                        <Link to={ '/uzman/bildirimler' } className="btn green-button">
                          Tümünü Görüntüle
                        </Link>
                      </div>
                    </>
                    :
                    <>
                      <div className="col-md-12 p-5 text-center">
                          <h5>Hiç bildirim yok.</h5>
                      </div>
                    </>
                  }
                </div>
                
                <div class="tab-pane fade" id="nav-contact">
                      
                      {
                        this.state.dashboard.duyurular.length > 0 ?
                        <>
                          <div className="table-responsive">
                            <table className="table table-borderless dash mb-0">
                              <thead>
                                <tr>
                                  <th style={ { width:250 } }>Başlık</th>
                                  <th style={ { width:150 } }>Tarih</th>
                                  <th>Duyuru</th>
                                  <th style={ { width:250 } }></th>
                                </tr>
                              </thead>
                              
                              <tbody>
                                {
                                this.state.dashboard.duyurular.map( (duyuru) => (
                                <tr>
                                  <td>{ duyuru.baslik }</td>
                                  <td>{ <Moment format="DD.MM.YYYY HH:mm">{ duyuru.olusturulma_tarihi }</Moment> }</td>
                                  <td>{ duyuru.metin }</td>
                                  <td>
                                    <Link to={ '/uzman/duyuru-detay/' + duyuru.id } className="btn light-button2">
                                        Detayları Görüntüle
                                    </Link>
                                  </td>
                                </tr>
                                ))
                                }
                              </tbody>
                            </table>
                          </div>
                          
                          <div className="text-center p-4">
                            <Link to={ '/uzman/duyurular' } className="btn green-button">
                              Tümünü Görüntüle
                            </Link>
                          </div>
                        </>
                        :
                        <>
                        <div className="col-md-12 p-5 text-center">
                            <h5>Hiç duyuru yok.</h5>
                        </div>
                        </>
                      }

                </div>

              </div>
            </div>
            </div>

          </div>
        
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">
                
                {
                  !this.props.user.onaylandi ?
                  <>
                  <div class="alert alert-danger text-center" role="alert">
                    <h5 className="text-light">Size müşteri yönlendirebilmemiz için hesabınızı doğrulamanız gerekmektedir.  <Link to={ '/uzman/profili-duzenle/kisisel-bilgiler' } className="text-white"> <u>Hesabınızı doğrulamak için buraya tıklayınız.</u> </Link></h5>
                  </div>
                  </>
                  :
                  <>
                  </>
                }

                <h4>Randevu Bilgileri</h4>
                { this.content() }
              </div>

            </div>

            <Footer/>
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar : state.sidebar,
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Dashbard))