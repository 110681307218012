import React, { Component } from 'react';

import Spinner from "react-loader-spinner";

export default class Loader extends Component {
  render() {

    let { size } = this.props;

    if(size == null || size == undefined){
        size = 80;
    }

    return (
        <Spinner type="TailSpin" color="#71D4D4" height={ size } width={ size } />
    );
  }
}
