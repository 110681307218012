import { ReactComponent as Telefon } from '../assets/images/call2.svg';
import { ReactComponent as Mail } from '../assets/images/mail.svg';
import { ReactComponent as Konum } from '../assets/images/konum.svg';

import React, { Component } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import PageLoading from './Components/PageLoading';

import * as actionTypes from '../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../Functions/Api';

class Iletisim extends Component {

  state = {
    yuklendi : false,
    btn_gonder: false,
    ad : '',
    soyad : '',
    telefon : '',
    email : '',
    mesaj : '',
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    document.title = "İletişim - İyi Uzman"
    document.querySelector('meta[name="description"]').setAttribute("content", "İletişim, İyi Uzman, 0850 305 29 80");
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  setAd(ad){
      this.setState({
        ad
      });
  }
  setSoyad(soyad){
      this.setState({
        soyad
      });
  }
  setTelefon(telefon){
      this.setState({
        telefon
      });
  }
  setEmail(email){
      this.setState({
        email
      });
  }
  setMesaj(mesaj){
      this.setState({
        mesaj
      });
  }

  async gonder(){

    this.setState({
        btn_gonder : true
    });

    const { hata,mesaj } = await Api.iletisim_formu(
        this.state.ad,
        this.state.soyad,
        this.state.telefon,
        this.state.email,
        this.state.mesaj,
    );

    this.setState({
        btn_gonder : false
    });
    
    if(hata){
        swal("Oops!",mesaj,'error');
        return;
    }

    this.setState({
        ad: '',
        soyad: '',
        telefon: '',
        email: '',
        mesaj : '',
    });

    swal("Harika!",mesaj,"success");

  }

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="row">
          <div className="col-md-6">
            <div className="row mb-2">
              <div className="col-1"> 
                <Telefon /> 
              </div>
              <div className="col-10">
                0850 305 29 80
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-1">
              <Mail />
              </div>
              <div className="col-10">
                iletisim@iyiuzman.com
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-1">
              <Konum />
              </div>
              <div className="col-10">
                Kurtuluş Mahallesi Atatürk Caddesi 64021 Sokak Mehmet Sümer Apartmanı Kat 1 No 4
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12">
                <p>
                  <h5>YASAL BİLGİLER</h5>
                  İyi Uzman Danışmanlık Hizmetleri <br />
                  Vergi No: 7540517723 <br />
                  Vergi Dairesi: ZİYAPAŞA V.D.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d669.8429103597691!2d35.3224300569226!3d37.00197499968188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x273521f03422cda!2zMzfCsDAwJzA3LjciTiAzNcKwMTknMjIuMyJF!5e0!3m2!1str!2str!4v1644844466216!5m2!1str!2str" width="100%" height="450" style={{border: "0"}} allowFullScreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
          <div id="yeniBlogContainer" className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="ad">Ad <span className='text-danger font-weight-bold'>*</span></label>
                  <input type="text" onChange={ (e) => this.setAd(e.target.value) } value={ this.state.ad } id="ad" className="form-control" placeholder="Ad" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="soyad">Soyad <span className='text-danger font-weight-bold'>*</span></label>
                  <input type="text" onChange={ (e) => this.setSoyad(e.target.value) } value={ this.state.soyad } id="soyad" className="form-control" placeholder="Soyad" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="soyad">Telefon</label>
                  <input type="text" onChange={ (e) => this.setTelefon(e.target.value) } value={ this.state.telefon } id="telefon" className="form-control" placeholder="5xx - xxx xx xx" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="mail">E-mail</label>
                  <input type="text" onChange={ (e) => this.setEmail(e.target.value) } value={ this.state.email } id="email" className="form-control" placeholder="E-mail" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="mesaj">Mesaj <span className='text-danger font-weight-bold'>*</span></label>
                  <textarea id="mesaj" rows="8" onChange={ (e) => this.setMesaj(e.target.value) } value={ this.state.mesaj } id="mesaj" className="form-control" placeholder="Mesajınızı yazınız."></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <button disabled={ this.state.btn_gonder } onClick={ () => this.gonder() } className="btn green-button btn-block py-2">
                        {
                            !this.state.btn_gonder ? 'Gönder' : 'Gönderiliyor...'
                        }
                    </button>
              </div>
            </div>
          </div>
        </div>
      )
  }

  render(){
    return (
        <div>
          <ActionBar/>
          <ActionBarMobile/>

            <NavBar className="bg-green"/>

            <div className="wrapper">
              <div id="content">
                <div className="container mt-5">
                  <h2 className="mb-4">İletişim</h2>
                  { this.content() }
                </div>
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Iletisim))