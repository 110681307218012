import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import { ReactComponent as Check } from '../assets/images/check.svg';
import { ReactComponent as Medal } from '../assets/images/medal.svg';
import { ReactComponent as Hospital } from '../assets/images/hospital.svg';
import { ReactComponent as Hospital2 } from '../assets/images/hospital2.svg';
import { ReactComponent as Hediye } from '../assets/images/hediye.svg';
import { ReactComponent as Konum } from '../assets/images/konum.svg';
import { ReactComponent as Tarih } from '../assets/images/tarih-beyaz.svg';
import { ReactComponent as Online } from '../assets/images/online.svg';

import React, { Component } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';

import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import PageLoading from './Components/PageLoading';
import Loader from './Components/Loader';

import * as actionTypes from '../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../Functions/Api';
import Co from '../Functions/Co';
import Main from '../Functions/Main';
import qs from 'qs';

import queryString from 'query-string';
import Viewer from 'react-viewer';

class Ara extends Component {

    state = {
        yuklendi        : false,
        params          : { },
        iller           : Main.iller(),
        ilceler         : [ ],
        il              : '',
        ilce            : '',
        evde            : false,
        klinikte        : false,
        online          : false,
        kadin           : false,
        erkek           : false,
        mid             : 0, // meslek id
        hid             : 0, // hizmet id
        ucretli         : false,
        ucretsiz        : false,
        en_fazla        : '',

        meslek_gruplari : [ ],
        hizmetler       : [ ],
        uzmanlar        : [ ],

        meslek_adi      : 'meslek',

        araniyor        : true,
        tiklanan_avatar : '',
        secilen_avatar  : false
    }

    componentDidMount = async () => {

        const params = queryString.parse(this.props.location.search);

        this.setIl(this.props.match.params.il);
        this.setIlce(this.props.match.params.ilce);

        this.setState({ meslek_adi : this.props.match.params.meslek });

        document.title = "Arama Sonuçları - " + this.props.match.params.meslek.charAt(0).toUpperCase() + this.props.match.params.meslek.slice(1) + "- İyi Uzman";
        document.querySelector('meta[name="description"]').setAttribute("content", "Arama Sonuçları, " + this.props.match.params.meslek.charAt(0).toUpperCase() + this.props.match.params.meslek.slice(1) + ", İyi Uzman, 0850 305 29 80");

        this.setState({
            params,
            evde        : (params.evde == 'true' || params.evde == 1) ? true : false,
            klinikte    : (params.klinikte == 'true' || params.klinikte == 1) ? true : false,
            online      : (params.online == 'true' || params.online == 1) ? true : false,
            kadin       : (params.kadin == 'true' || params.kadin == 1) ? true : false,
            erkek       : (params.erkek == 'true' || params.erkek == 1) ? true : false,
            ucretli     : (params.ucretli == 'true' || params.ucretli == 1) ? true : false,
            ucretsiz    : (params.ucretsiz == 'true' || params.ucretsiz == 1) ? true : false,
            en_fazla    : params.en_fazla,
        })

        if(params.mid != null){
            this.setState({ mid : params.mid });
        }

        if(params.hid != null){
            this.setState({ hid : params.hid });
        }

        this.meslek_listesini_getir();

        const ctx = this;

        setTimeout(() => {
            ctx.ara();
        }, 500);

    };

    async ara(){

        if(!this.state.online){
            if(this.state.il == 'tum'){
                Main.message("İl seçimi yapılmadan arama yapamazsınız!",true);
                this.setState({ araniyor : false });
                return;
            }
        }

        const ctx = this;
        let params = {
            evde        : this.state.evde ? 1 : 0,
            klinikte    : this.state.klinikte ? 1 : 0,
            online      : this.state.online ? 1 : 0,
            kadin       : this.state.kadin ? 1 : 0,
            erkek       : this.state.erkek ? 1 : 0,
            mid         : this.state.mid,
            hid         : this.state.hid,
            ucretli     : this.state.ucretli ? 1 : 0,
            ucretsiz    : this.state.ucretsiz ? 1 : 0,
            en_fazla    : this.state.en_fazla,
        }

        const q = qs.stringify(params);

        let il = this.state.il;
        let ilce = this.state.ilce;

        if(this.state.online){
            il = "tum";
            ilce = "ilceler";
        }

        // this.setState({
        //     il,
        //     ilce
        // });

        params.il = il;
        params.ilce = ilce;

        const meslek_adi = Main.slugify(this.state.meslek_adi);

        this.props.history.push('/ara/' + this.state.il + '-' + this.state.ilce + '/' + meslek_adi + '?' + q);

        if(!params.online && this.state.il.length < 1){
            this.setState({ araniyor : false })
            return;
        }

        this.setState({ uzmanlar : [ ],araniyor : true });

        const { hata,mesaj,data} = await Api.uzman_ara(params);

        if(!hata){
            setTimeout(() => {
                ctx.setState({ uzmanlar : data.uzmanlar, araniyor : false });
            }, 800);
        }else{
            ctx.setState({ araniyor : false });
            Main.message(mesaj,hata);
        }
    }

    async meslek_listesini_getir(){

        this.setState({ meslek_gruplari : [ ] });

        const { hata,data } = await Api.meslek_grubu_listesi();
 
        if(!hata){

            this.meslek_hizmetleri();

            this.setState({
                meslek_gruplari : data,
                yuklendi        : true
            })
        }
    }

    async meslek_hizmetleri(){
        const { hata,data } = await Api.meslek_hizmetleri_listesi(this.state.mid == 0 ? null : this.state.mid);
    
        if(!hata){
          this.setState({
              hizmetler : data.hizmetler
          })
        }
    }

    setIl(il){
        this.setState({ il, ilce : "hepsi", ilceler : Main.ilceler(il) });
    }
    
    setIlce(ilce){
        this.setState({ ilce });
    }
    
    setEvde(evde){
        this.setState( { evde } )
    }
    
    setKlinikte(klinikte){
        this.setState( { klinikte } )
    }
    
    setOnline(online){
        this.setState( { online } )
    }
    
    setKadin(kadin){
        this.setState( { kadin } )
    }
    
    setErkek(erkek){
        this.setState( { erkek } )
    }

    setUcretli(ucretli){
        this.setState( { ucretli } )
    }

    setUcretsiz(ucretsiz){
        this.setState( { ucretsiz } )
    }

    
    

    setMid(mid){
        this.setState({ mid,hid : 0 });

        let meslek = this.state.meslek_gruplari.map(x => {
            if(x.ana_kategori.id == mid){
                this.setState({ meslek_adi : x.ana_kategori.meslek_adi })
            }else{
                x.alt_kategoriler.map(y => {
                    if(y.id == mid){
                        this.setState({ meslek_adi : y.meslek_adi })
                    }
                })
            }
        });

        const ctx = this;

        setTimeout(() => {
            ctx.meslek_hizmetleri();
        }, 250);
    }

    setHid(hid){
        this.setState({ hid })
    }

    setEnFazla(en_fazla){
        this.setState({ en_fazla })
    }

    uzmanPuan(puan,checked){
        return (
            Array.from(Array(puan)).map(x => (
                <span className={ checked ? 'fa fa-star gray checked' : 'fa fa-star gray' }></span>
            ))
        )
    }

    randevuAl(uzman,tarih,saat){
        this.props.history.push(Main.uzman_url(uzman),{
            tarih,
            saat
        });
    }

    randevu_saatleri(uzman){

            return (
                <tr>
                  {
       
                    uzman.randevu_takvimi.map( (takvim) => (
                    <td>
                      {
                        takvim.saatler.map(saat => (
                          saat.musait ? <button onClick={ () => this.randevuAl(uzman,takvim.tarih,saat.saat) } className='btn light-button2 bcs'>{ saat.saat }</button> :<button className='btn light-button2 bcs button-disabled'>{ saat.saat }</button>
                        ))
                      }
                    <br />
                    </td>))
                    
                  }
                </tr>
              )
    }

    uzman(data){
        return (
            <div className="card border-radius-10 py-5 px-3">
                <Link to={ Main.uzman_url(data) }> 
                    <button className="btn green-button btn-randevu-al">
                        <Tarih style={ { marginRight:8,marginBottom:4 } }/>
                        Randevu Al
                    </button>
                </Link>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row mb-4">
                            <div className="col-md-4 text-center">
                                <img onClick={ () => this.setState({ secilen_avatar : Main.resimURL(data.fotograf) ,showAvatar : true }) } className="uzman-ara-fotograf pointer" src={ Main.resimURL(data.fotograf) } data-holder-rendered="true"/>
                            </div>
                            <div className="col-md-8 m-center">
                                <h5>
                                    <span>{ data.meslek_unvani }</span>
                                    <p>{data.ad + ' ' + data.soyad } <Check/></p>
                                    {/* <Medal/> */}
                                </h5>
                                <p className="gray">{ data.meslek_adi }</p>
                                { 'Değerlendirme ' + data.puan + '/5' } <br />
                                { this.uzmanPuan(data.puan,true) }
                                { this.uzmanPuan((5 - data.puan),false) }
                                {/* <p>
                                    <span className=''>
                                    { 
                                        data.basari_orani == -1 ? 
                                        <>
                                            Bu uzmandan daha önce hiç randevu alınmamış.
                                        </>
                                        :
                                        <>%{data.basari_orani} başarı oranı</>
                                    }
                                    </span>
                                </p> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 m-center">
                                <p>{ data.ozgecmis }</p>
                            </div>
                            <div className="col-md-12 mb-3 pl-3 m-center">
                                
                                {
                                    data.evde_hizmet ?
                                    <>
                                    <Hospital2 width={ 24 } height={ 24 } className="mb-2"/>
                                    <span className="text-dark ml-1 mr-1">Evde Hizmet</span>
                                    <span> { data.evde_hizmet_taban_fiyat + '₺ - ' + data.evde_hizmet_tavan_fiyat + '₺' } </span>
                                    <small className="gray"> / Seans</small>
                                    <br/>
                                    </> : ''
                                }


                                {
                                    data.klinikte_hizmet ?
                                    <>
                                    <Hospital width={ 24 } height={ 24 } className="mb-2"/>
                                    <span className="text-dark ml-1 mr-1">Klinikte Hizmet</span>
                                    <span> { data.klinikte_hizmet_taban_fiyat + '₺ - ' + data.klinikte_hizmet_tavan_fiyat + '₺' } </span>
                                    <small className="gray"> / Seans</small>
                                    <br />
                                    </> : ''
                                }

                                {
                                    data.online_hizmet ?
                                    <>
                                    <Online width={ 24 } height={ 24 } className="mb-2"/>
                                    <span className="text-dark ml-1 mr-1">Online Hizmet</span>
                                    <span> { data.online_hizmet_taban_fiyat + '₺ - ' + data.online_hizmet_tavan_fiyat + '₺' } </span>
                                    <small className="gray"> / Seans</small>
                                    </> : ''
                                }
                            </div>

                            <div className="col-md-12 mb-4 m-center">
                                <span>
                                    { data.degerlendirme_seansi_ucretli ? 'Değerlendirme seansı ücretlidir.' : 'Değerlendirme seansı ücretsizdir.' }
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 m-center">
                                <Konum className='mr-1'/>
                                Hizmet Bölgeleri
                                { this.hizmet_bolgeleri(data) }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="table-responsive">
                            
                            {
                                data.randevu_takvimi.filter(x => x.saatler.length > 0).length > 0 ?
                                <>
                                <table className="table table-sm table-borderless text-center">
                                    <thead>
                                        <tr>
                                        {
                                            data.randevu_takvimi.map(takvim => (
                                                <th className="th-randevu-takvimi">{ takvim.tarih3 }</th>
                                            ))
                                        }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.randevu_saatleri(data) }
                                    </tbody>
                                </table>
                                </>
                                :
                                <>
                                    <h5 className='text-center'>Bu uzman şu anda randevu kabul etmiyor.</h5>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    searchBar(){
        return (
            <div id="uzman-ara-bar">
                <select onChange={ (e) => this.setMid(e.target.value) } className="form-control bg-light select-border-none" id="meslek" defaultValue={ 0 } value={ this.state.mid }>
                    <option value={ 0 } disabled>Seçiniz</option>
                    {
                        this.state.meslek_gruplari.map(mg => (
                            mg.alt_kategoriler.length > 0 ?
                            <>
                                <optgroup label={ mg.ana_kategori.meslek_adi }>
                                    { mg.alt_kategoriler.map(alt_mg => (
                                        <option value={ alt_mg.id }> { alt_mg.meslek_adi } </option>
                                    )) }
                                </optgroup>
                            </>
                            :
                            <>
                                <option value={ mg.ana_kategori.id }> { mg.ana_kategori.meslek_adi } </option>
                            </>
                        ))
                    }
                </select>
                            
                <select onChange={ (e) => this.setHid(e.target.value) } className="form-control bg-light select-border-none" value={ this.state.hid } defaultValue={ 0 } id="hizmet">
                    <option value={ 0 }>Tüm Hizmetler</option>
                    {
                        this.state.hizmetler.map(hizmet => (
                            <option value={ hizmet.id }> { hizmet.hizmet_adi } </option>
                        ))
                    }
                </select>

                <button onClick={ () => this.ara() } id="btn-uzman-ara" className="btn green-button px-4 py-3 ml-2">
                    <SearchIcon /> Ara
                </button>
            </div>
        )
    }

    filterBar(){
        return (
            <div id="uzman-ara-filter-bar" className="card">
                <div className="form-group">
                    <label htmlFor="il">İl</label>        
                    <select disabled={ this.state.online } id="il" value={ this.state.il == 'tum' ? '' : this.state.il } defaultValue={ '' } onChange={ (e) => this.setIl(e.target.value) } type="text" id="il" className="form-control">
                    <option value='' disabled>Seçiniz</option>
                    { this.state.iller.map(il => (
                        <option value={ il.value }>{ il.label }</option>
                    )) }
                    </select>            
                </div>
                
                <div className="form-group">
                    <label htmlFor="ilce">İlçe</label>
                    <select defaultValue="hepsi" value={ this.state.ilce } disabled={ (this.state.ilceler.length < 1 || this.state.online) ? true : false } onChange={ (e) => this.setIlce(e.target.value) } type="text" id="ilce" className="form-control">
                        <option value={ 'hepsi' }>Tüm İlçeler</option>
                        { this.state.ilceler.map(ilce => (
                            <option value={ ilce.value }>{ ilce.label }</option>
                        )) }
                    </select>
                </div>

                <strong>Hizmet Yeri</strong>
                <div className="mb-4 gray">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input disabled={ this.state.online } onClick={ (e) => this.setEvde(e.target.checked) } type="checkbox" name="hizmet_turu" id="evde" value="EVDE" defaultChecked={ this.state.evde ? true : false } className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="evde">Evde</label>
                    </div>
                    <br/>
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input disabled={ this.state.online } onClick={ (e) => this.setKlinikte(e.target.checked) } type="checkbox" name="hizmet_turu" id="klinikte" value="KLINIKTE" defaultChecked={ this.state.klinikte ? true : false } className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="klinikte">Klinikte</label>
                    </div>
                    <br/>
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setOnline(e.target.checked) } type="checkbox" name="hizmet_turu" id="online" value="ONLINE" defaultChecked={ this.state.online ? true : false } className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="online">Online</label>
                    </div>
                    {
                        this.state.online ?
                        <>
                            <div className="alert alert-danger mt-2">
                                <p className="p-1">Online hizmet seçeneği tüm dünya çapındaki uzmanları getirir.</p>
                            </div>
                        </> : <></>
                    }
                </div>

                <strong>Uzman Cinsiyeti</strong>
                <div className="mb-4 gray">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setKadin(e.target.checked) } type="checkbox" id="kadin" value="KADIN" className="custom-control-input" defaultChecked={ this.state.kadin ? true : false }/>
                        <label className="custom-control-label" htmlFor="kadin">Kadın</label>
                    </div>
                    <br/>
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setErkek(e.target.checked) } type="checkbox" id="erkek" value="ERKEK" className="custom-control-input" defaultChecked={ this.state.erkek ? true : false }/>
                        <label className="custom-control-label" htmlFor="erkek">Erkek</label>
                    </div>
                </div>
                
                <strong>Değerlendirme Seansı</strong>
                <div className="mb-4 gray">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setUcretli(e.target.checked) } type="checkbox" id="ucretli" className="custom-control-input" defaultChecked={ this.state.ucretli ? true : false }/>
                        <label className="custom-control-label" htmlFor="ucretli">Ücretli</label>
                    </div>
                    <br/>
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setUcretsiz(e.target.checked) } type="checkbox" id="ucretsiz" className="custom-control-input" defaultChecked={ this.state.ucretsiz ? true : false }/>
                        <label className="custom-control-label" htmlFor="ucretsiz">Ücretsiz</label>
                    </div>
                </div>

                <strong>Ücret (EN FAZLA)</strong>
                <div className="mb-2 gray">
                    <input value={ this.state.en_fazla } id="en_fazla" onChange={ (e) => this.setEnFazla(e.target.value)  } pattern="[0-9]*" placeholder="100" type="number" className="form-control mt-2" />
                </div>
                <button onClick={ () => this.ara() } id="btn-uzman-ara2" className="btn green-button">
                    <SearchIcon /> Ara
                </button>
            </div>
        )
    }

    hizmet_bolgeleri(uzman){

        let bolgeler = uzman.hizmet_bolgeleri;

        if(bolgeler.length > 15 && !this.state[uzman.id]){
          bolgeler = uzman.hizmet_bolgeleri.slice(0,15);
        }
    
        return (
          <div className="mt-2">
            {
              bolgeler.map(bolge => (
                <span class="badge badge-pill badge-success mr-1">{ bolge.toUpperCase() }</span>
              ))
            }
    
            {
              uzman.hizmet_bolgeleri.length > 15 ?
              <>
              <br />
                <span class="badge badge-pill badge-dark pointer mr-1"
                onClick={ () => this.setState({ [uzman.id] : !this.state[uzman.id] }) }
                >
                    { !this.state[uzman.id] ? '...devamını göster' : ' daha az göster' }
                </span>
              </>
              :
              <>
              </>
            }
    
          </div>
        )
      }

    content() {

        if (!this.state.yuklendi) {
            return (
                <PageLoading />
            )
        }

        return (
            <div className="row">
                
                <div id="uzman-ara-l" className="col-md-9">
                    { this.searchBar() }

                    {
                        !this.state.araniyor ?
                        <>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <h3>Arama Sonuçları</h3>
                            </div>
                            {
                                this.state.uzmanlar.map(uzman =>(
                                    <div className="col-md-12 mt-1 mb-2">
                                        { this.uzman(uzman) }
                                    </div>
                                ))
                            }
                        </div>
                        </>
                        :
                        <>
                            <div className="uzman-araniyor">
                                <Loader/>
                                <h4 className="text-center mt-5">Arama yapılıyor lütfen bekleyin...</h4>
                            </div>
                        </>
                    }

                </div>
                <div id="uzman-ara-r" className="col-md-3">
                    { this.filterBar() }
                </div>

            </div>
        )
    }

    render() {
        return (
            <div>
                <ActionBar/>
                <ActionBarMobile/>
                
                <NavBar className="bg-green" />

                <div className="wrapper">
                    <div id="content">
                        <div className="container mt-2">

                            <Viewer
                            onClose={ () => this.setState({ showAvatar : false }) }
                            disableMouseZoom={ true }
                            rotatable={ false }
                            scalable={ false }
                            zoomable={ false }
                            noFooter={ true }
                            noImgDetails={ true }
                            noNavbar={ true }
                            drag={ false }
                            noToolbar={ true }
                            visible={ this.state.showAvatar }
                            images={ [ {src: this.state.secilen_avatar, alt: 'avatar'} ] }
                            />

                            { this.content() }

                        </div>
                    </div>

                </div>

                <Footer />

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Ara))