import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';

class SidebarItem extends Component {

  render() {

    const { icon,baslik } = this.props;
    var active = false;
    if('/sistem/yeni-uzman' == this.props.location.pathname || '/sistem/uzmanlar' == this.props.location.pathname){
         active = true;    
    }

    return (
        <div>
            <li className="list-group-item" data-active={ active }>
                <a data-toggle="collapse" href="#profil" role="button" aria-expanded="false" aria-controls="profil">
                    { icon }
                    <span>{ baslik }</span>
                </a>
            </li>
            <ul className="list-group collapse pl-3" id="profil">
                <li onMouseUp={ () => this.props.sidebar_toggle(false) } className="list-group-item">
                    <Link to='/sistem/yeni-uzman'>
                        
                        <span> Yeni Uzman</span>
                    </Link>
                </li>
                <li onMouseUp={ () => this.props.sidebar_toggle(false) } className="list-group-item">
                    <Link to='/sistem/uzmanlar'>
                        
                        <span>Uzmanlar</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
  }
}


function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar   : state.sidebar,
        user      : state.user,
        fotograf  : state.fotograf
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value }),
        set_user : ( value ) => dispatch({ type : actionTypes.SET_USER_DATA, payload : value })
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SidebarItem))