import { ReactComponent as UploadIcon } from '../../assets/images/upload.svg';
import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';

class YeniBlog extends Component {

  state = {
    yuklendi : false,
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    const ctx = this;
    setTimeout(function(){
        ctx.setState({
            yuklendi : true
        })
    },500);
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div id="yeniBlogContainer" className="content-card p-3">
            <div className="form-group">
                <label htmlFor="baslik"><h4>Başlık</h4></label>
                <input type="text" className="form-control" />
            </div>
            <div className="form-group">
                <label htmlFor="blog_metni"><h4>Blog Metni</h4></label>
                <textarea name="blog_metni" id="blog_metni" rows="15" className="form-control"></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="gorsel"><h4>Görsel</h4></label>
                <div className="col-md-12 border-dashed border-radius-10 border-light2 p-5 text-center mb-2">
                  <UploadIcon className="mb-3" />
                  <h4>Sürükle bırak</h4>
                  <h4>veya</h4>
                  <h4 className="text-green">Dosya ekle</h4>
                </div>
                <span>Önerilen: .jpeg .png uzantı, max. 2 mb görsel</span>
            </div>
            <div className="form-group mb-4">
                <label htmlFor="etiketler"><h4>Etiketler (max 5)</h4></label>
                <input type="text" className="form-control mb-2" placeholder="Etiket ekle..."/>
                <span>Örnek: beslenme,diyet,sağlık</span>
            </div>
            <button className="btn btn green-button btn-block p-3">Yükle</button>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Yeni Blog</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(YeniBlog))