import * as actionTypes from './actionTypes'

const initialState = {
    domain          : "https://iyiuzman.com",
    fotograf        : "",
    giris_pop       : false,
    sidebar         : false,
    user            : { ad : '', soyad : '' }
}

export default ( state = initialState, action ) => {

    switch(action.type){
        case actionTypes.GIRIS_POP_TOGGLER : {
            return {
                ...state,
                giris_pop : action.payload
            }
        }
        case actionTypes.SIDEBAR_STATE : {
            return {
                ...state,
                sidebar : action.payload
            }
        }
        case actionTypes.SET_USER_DATA : {
            return {
                ...state,
                user : action.payload
            }
        }
        case actionTypes.SET_FOTOGRAF : {
            return {
                ...state,
                fotograf : action.payload
            }
        }
        default : {
            return state;
        }
    }
    
}