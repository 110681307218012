import { ReactComponent as Onay } from '../../assets/images/onay.svg';
import { ReactComponent as Saat } from '../../assets/images/saat.svg';
import { ReactComponent as Musteri } from '../../assets/images/musteri.svg';
import { ReactComponent as Tarih } from '../../assets/images/tarih.svg';
import { ReactComponent as IlIlce } from '../../assets/images/ililce.svg';
import { ReactComponent as Adres } from '../../assets/images/adres.svg';
import { ReactComponent as RandevuTuru } from '../../assets/images/randevu-turu.svg';
import { ReactComponent as HizmetKonusu } from '../../assets/images/almak-istenilen-hizmet.svg';
import { ReactComponent as MusteriNotlari } from '../../assets/images/randevu-notlari.svg';
import { ReactComponent as RandevuDurumu } from '../../assets/images/randevu-durumu.svg';
import { ReactComponent as Call } from '../../assets/images/call.svg';
import { ReactComponent as Not } from '../../assets/images/randevu-notlari.svg';
import { ReactComponent as Commision } from '../../assets/images/commision.svg';
import Co from '../../Functions/Co';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';
import Main from '../../Functions/Main';

import Moment from 'react-moment';

import SearchBar from '../Components/SearchBar';

class RandevuDetay extends Component {

  state = {
    modal1      : false,
    modal2      : false,
    yuklendi    : false,
    randevu     : { },
    iptal_notu  : '',
    iptal_secim : '',
    cagri_kayitlari : [],
  }

  componentDidMount() {
    document.title = "Randevu Detayları - İyi Uzman";
    this.randevu_detaylari();
  };

  async randevu_detaylari(){
    const { id } = this.props.match.params;
    
    let randevu_id = id;

    const { hata,mesaj,data } = await Api.admin_randevu_detay(randevu_id);

    if(!hata){
        this.setState({ yuklendi : true,randevu : data });
    }else{
        Main.message(mesaj,hata);
    }
  }

  async onayla(){
    const { hata, mesaj } = await Api.randevu_onayla(this.state.randevu.id);

    if(!hata){

        this.setState({ modal1 : true })

        this.randevu_detaylari();
    }else{
        Main.message(mesaj,hata);
    }
  }

  async reddet(){

    if(this.state.iptal_secim == ''){
        Main.message('Lütfen iptal nedeninizi seçin',true);
        return;
    }

    const iptal_notu = this.state.iptal_secim == 'diger' ? this.state.iptal_notu : this.state.iptal_secim;

    const { hata, mesaj } = await Api.admin_randevu_iptal(this.state.randevu.id,iptal_notu);

    this.setState({ modal2 : false })

    if(!hata){
        this.randevu_detaylari();
    }

    Main.message(mesaj,hata);
  }


  redModal(){
      this.setState({ modal2 : true,iptal_secim : '' });
  }

  async aramaTalebi(){

    swal({
        title : 'Bir dakika...',
        text : '0850 305 29 80 Numaralı telefondan aranacaksınız telefonunuzu hazır bulundurun. Görüşmelerde kişisel telefon numarası paylaşılması yasaktır. Görüşmelerimiz kalite standartları gereği kayıt altındadır.',
        buttons : {
            iptal : {
                text : 'Vazgeç',
                className : 'red-button'
            },
            devam : {
                text : 'Onaylıyorum',
                className : "green-button"
            }
        },
    }).then( async (btn) => {

        if(btn == 'devam'){
            const { hata, mesaj } = await Api.randevuya_arama_talebi_olustur(this.state.randevu.id,"Uzman tarafından oluşturulmuştur.");
            Main.message(mesaj,hata);
        }

    })
  }

  kayitlar(){
     return ( // Map = Foreach
        this.state.cagri_kayitlari.map( (kayit,index) => {
            return (
                <tr>
                    <td> { index+1 } </td>
                    <td>{ kayit.musteri }</td>
                    <td>{ kayit.uzman }</td>
                    <td>
                        <Link to={ {
                            pathname: '/sistem/cagri-kaydi/' + kayit.id,
                        } }>
                        <button className="btn light-button2">Görüntüle</button>
                        </Link>    
                    </td>
                </tr>
            )
        })
     )
  }

  ileri(son = false){

      if(!son && this.state.sayfa >= this.state.toplam_sayfa) return;

      this.setState({
          sayfa : son ? this.state.toplam_sayfa : this.state.sayfa + 1
      })

      this.kayitlari_getir()
  }

  geri(ilk = false){

    if(!ilk && this.state.sayfa <= 1) return;

    this.setState({
            sayfa : ilk ? 1 : this.state.sayfa - 1
    })

    this.kayitlari_getir()
  }

  async kayitlari_getir(){ 

      this.setState({
          yuklendi : true
      })
      return;


      const { hata,mesaj,data } = await Api.cagri_kayitlari({ ara : this.state.ara, sayfa : this.state.sayfa },Co.access_token());

      if(hata){
          swal("HATA",mesaj,"error");
      }
      
      this.setState({
          cagri_kayitlari   : data == null ? [ ] : data.cagri_kayitlari,
          toplam_sayfa      : data == null ? 0 : data.toplam_sayfa,
          yuklendi          : true
      })
  }

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="content-card p-5 col-md-10">
            <div className="row">
                <div className="col-md-8 offset-md-3 col-12">
                    <div className="table-responsive pb-3">
                        
                        <table id="tablo-randevu-detaylari">
                            <tr>
                                <td> <Tarih className="mr-2" /> </td>
                                <td> Randevu Tarih - Saat </td>
                                <td className='px-3'> : </td>
                                <td>
                                    <Moment format="DD.MM.YYYY HH:mm">{ this.state.randevu.tam_randevu_tarihi }</Moment>
                                </td>
                            </tr>

                            <tr>
                                <td> <Musteri className="mr-2" /> </td>
                                <td> Uzman</td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.randevu.uzman_adi }
                                </td>
                            </tr>

                            <tr>
                                <td> <Musteri className="mr-2" /> </td>
                                <td> Müşteri Bilgileri </td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.randevu.musteri_adi + ' ' + this.state.randevu.musteri_soyadi }
                                </td>
                            </tr>

                            {
                                this.state.randevu.randevu_turu == 'EVDE' ?
                                <>
                                <tr>
                                    <td> <IlIlce className="mr-2" /> </td>
                                    <td> Randevu İl ve ilçesi </td>
                                    <td className='px-3'> : </td>
                                    <td>
                                        { Main.il_adi(this.state.randevu.il) + ' / ' + Main.ilce_adi(this.state.randevu.ilce)  }
                                    </td>
                                </tr>
                                </> : <></>
                            }

                            {
                            this.state.randevu.randevu_turu != 'ONLINE' ?
                            <>
                            <tr>
                                <td> <Adres className="mr-2" /> </td>
                                <td> { this.state.randevu.randevu_turu == 'KLINIKTE' ? 'Klinik Adresi' : 'Adres' } </td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.randevu.randevu_turu == 'EVDE' ? this.state.randevu.mahalle : this.state.randevu.klinik_adresi }
                                </td>
                            </tr>
                            </>
                            :
                            <></>
                            }
                            <tr>
                                <td> <RandevuTuru className="mr-2" /> </td>
                                <td> Hizmet Yeri </td>
                                <td className='px-3'> : </td>
                                <td>
                                    { Main.hizmet_yeri(this.state.randevu.randevu_turu) } Hizmet
                                </td>
                            </tr>

                            <tr>
                                <td> <HizmetKonusu className="mr-2" /> </td>
                                <td> Hizmet Konusu</td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.randevu.hizmet_data.hizmet.hizmet_adi }
                                </td>
                            </tr>

                            <tr>
                                <td> <MusteriNotlari className="mr-2" /> </td>
                                <td> Müşteri Notları</td>
                                <td className='px-3'> : </td>
                                <td>
                                    { this.state.randevu.musteri_notlari }
                                </td>
                            </tr>

                            <tr>
                                <td> <RandevuDurumu className="mr-2" /> </td>
                                <td> Randevu Durumu</td>
                                <td className='px-3'> : </td>
                                <td>
                                    <span className="text-warning">
                                        { Main.randevu_durum(this.state.randevu.durum) }
                                    </span>
                                </td>
                            </tr>

                            {
                            (this.state.randevu.durum == 'UZMAN_IPTAL' || this.state.randevu.durum == 'MUSTERI_IPTAL'  || this.state.randevu.durum == 'SISTEM_IPTAL') ?
                                <>
                                    <tr>
                                        <td> <Not className="mr-2" /> </td>
                                        <td> İptal Notu</td>
                                        <td className='px-3'> : </td>
                                        <td>
                                            <span className="text-danger">
                                                { Main.iptal_notlari(this.state.randevu.iptal_notu) }
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <Tarih className="mr-2" /> </td>
                                        <td> İptal Edilme Tarihi - Saati</td>
                                        <td className='px-3'> : </td>
                                        <td>
                                            <Moment format="DD.MM.YYYY HH:mm">{ this.state.randevu.iptal_tarihi }</Moment>
                                        </td>
                                    </tr>
                                </>
                                :
                                <></>
                            }

                            {
                            this.state.randevu.durum != 'OZEL_RANDEVU' && this.state.randevu.durum != 'OZEL_RANDEVU_IPTAL' && this.state.randevu.durum != 'OZEL_RANDEVU_TAMAMLANDI' && this.state.randevu.durum != 'MUSTERI_IPTAL' && this.state.randevu.iptal_notu != 'musteri_talebi_uzerine_iptal' ?
                            <>
                            <tr>
                                <td> <Commision className="mr-2" /> </td>
                                <td> Komisyon Tutarı </td>
                                <td className='px-3'> : </td>
                                <td>
                                    <span className='text-dark'>
                                        { Main.randevu_durum(this.state.randevu.komisyon_tutari) }₺
                                    </span>
                                </td>
                            </tr>
                            </>
                            :
                            ''
                            }
                        </table>

                        <div className="text-center mt-3">
                            {
                                this.state.randevu.durum == 'OZEL_RANDEVU' ?
                                <>
                                <button onClick={ () => this.redModal() } className="btn red-button px-5 py-3 mb-2">
                                    İptal et
                                </button>
                                </>
                                : <> </>
                            }
                            {
                                this.state.randevu.durum == 'RANDEVU_SAATI_BEKLENIYOR' ? 
                                <>
                                    <button onClick={ () => this.redModal() } className="btn red-button px-5 py-3 mb-2">
                                        İptal et
                                    </button>
                                </>
                                :
                                this.state.randevu.durum == 'ONAY_BEKLIYOR' ?
                                <>
                                    <button onClick={ () => this.redModal() } className="btn red-button ml-2">İptal Et</button>
                                </>
                                :
                                <></>
                            }
                        </div>

                        {
                            this.state.randevu.tamamlanabilir ? 
                            <>
                            <div className="text-center mt-3">
                                <button onClick={ () => this.randevuyu_tamamla() } className="btn green-button ml-2">Randevuyu Şimdi Tamamla</button>
                            </div>
                            </>
                            :
                            <></>
                        }

                        {/* {
                            this.state.randevu.fatura_olusturuldu ? 
                            <>
                            <div className="text-center mt-3">
                                <Link to={'/uzman/fatura-detay/' + this.state.randevu.fatura_id}> <button className="btn green-light-button ml-2">Faturayı Görüntüle</button> </Link>
                            </div>
                            </>
                            :
                            <></>
                        } */}

                    </div>
                </div>
                <h4>Çağrı Kayıtları</h4>
                <div className="table-responsive mt-3">
                    <table className="table table-borderless dash">
                        <thead>
                            <tr>
                                <th style={ { width:100 } }>No</th>
                                <th>Müşteri</th>
                                <th>Uzman</th>
                                <th>Görüntüle</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            { this.kayitlar() }
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">
                <h4 className="mb-3">Randevu Detayları</h4>
                { this.content() }
              </div>

            </div>

            <Footer/>

            <Modal show={ this.state.modal2 } dialogClassName="modal-90w" centered>
                <Modal.Body>
                    <div className="text-right mr-2 mt-2">
                        <i onClick={ () => this.setState({ modal2 : false }) } className="fas fa-times close-popup"></i>
                    </div>
                        <center>
                            <h4 className="mt-2">Randevuyu İptal Et</h4>

                            <p className="gray">Randevu Detayları</p>

                            <div className="table-responsive">
                            <table>
                                <tr>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <div className="vertical">
                                            <Tarih/>
                                            <strong className="mt-1 mb-1">Tarih</strong>
                                            <small class="gray"><Moment format="DD.MM.YYYY">{ this.state.randevu.randevu_tarihi }</Moment></small>
                                            {/* <small class="gray">Pazartesi</small> */}
                                        </div>
                                    </td>

                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <div className="vertical ml-5 mr-5">
                                            <Saat/>
                                            <strong className="mt-1 mb-1">Saat</strong>
                                            <small class="gray">{ this.state.randevu.randevu_saati }</small>
                                        </div>
                                    </td>

                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <div className="vertical">
                                            <Musteri/>
                                            <strong className="mt-1 mb-1">Uzman</strong>
                                            <small class="gray">
                                                { this.state.randevu.uzman_adi }
                                            </small>
                                        </div>
                                    </td>

                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <div className="vertical ml-5">
                                            <Musteri/>
                                            <strong className="mt-1 mb-1">Müşteri</strong>
                                            <small class="gray">
                                                { this.state.randevu.musteri_adi } { this.state.randevu.musteri_soyadi }
                                            </small>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            </div>

                            <div className='form-group'>
                                <label className='text-danger' htmlFor='iptal_notlari'>İptal Nedeniniz nedir?</label>
                                <select value={ this.state.iptal_secim } onChange={ (e) => this.setState({ iptal_secim : e.target.value }) } defaultValue='' className='form-control'>
                                    <option value="" disabled>Seçiniz</option>
                                    <option value="uzman_talebi_uzerine_iptal">Uzman Talebi Üzerine İptal</option>
                                    <option value="musteri_talebi_uzerine_iptal">Müşteri Talebi Üzerine İptal</option>
                                </select>
                            </div>

                            {/* {
                                this.state.iptal_secim == 'diger' ?
                                <>
                                <div className="form-group">
                                    <label htmlFor="iptal_notu" className="text-danger">Randevuyu neden iptal etmek istediğinizi açıklar mısınız? </label>
                                    <textarea value={ this.state.iptal_notu } onChange={ (e) => this.setState({ iptal_notu : e.target.value }) } id="iptal_notu" rows="3" className="form-control"></textarea>
                                </div>
                                </>
                                : ''
                            } */}

                            <div className="text-right">
                                <button onClick={ () => this.setState({ modal2 : false }) } className="btn green-button">
                                    Vazgeç
                                </button>
                                <button onClick={ () => this.reddet() } className="btn red-button ml-2">
                                    Gönder
                                </button>
                            </div>

                        </center>

                </Modal.Body>
            </Modal>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(RandevuDetay))