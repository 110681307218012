import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import { ReactComponent as Yildiz } from '../assets/images/yildiz.svg';

import React, { Component } from 'react';

import ActionBar from './Components/ActionBar';
import ActionBarMobile from './Components/ActionBarMobile';
import Footer from './Components/Footer';

import Hero from './Components/Anasayfa/Hero';
import HizmetTurleri from './Components/Anasayfa/HizmetTurleri';
import NasilCalisir from './Components/Anasayfa/NasilCalisir';

import SonDegerlendirmeler from './Components/Anasayfa/SonDegerlendirmeler';
import EnIyiUzmanlar from './Components/Anasayfa/EnIyiUzmanlar';
import Bloglar from './Components/Anasayfa/Bloglar';

import { Modal } from 'react-bootstrap';

import * as actionTypes from '../Redux/actionTypes';
import { connect } from 'react-redux';

import { Link,withRouter } from 'react-router-dom';

import Api from '../Functions/Api';
import Main from '../Functions/Main';
import qs from 'qs';
import swal from '@sweetalert/with-react';

import Select, { components } from 'react-select';
import { AramaSonucu,aramaSonucuStyle } from './Components/Anasayfa/AramaSonucu';

class Anasayfa extends Component {

  state = {
    modal1            : false,
    tur               : '',
    hid               : 0, // hizmet id
    mid               : 0, // meslek id
    il                : '',
    ilce              : 'hepsi',
    evde              : false,
    klinikte          : false,
    online            : false,
    kadin             : false,
    erkek             : false,

    iller             : Main.iller(),
    ilceler           : [ ],
    arama_sonuclari   : [ ],
    arama_hata        : '',

    meslek_adi        : 'meslek',
    degerlendirmeler  : [],
    en_iyi_uzmanlar   : [],
    bloglar           : [],
    oneriler          : [],
    ara               : ''
  }

  constructor(props){
    super(props);
    this.timeout =  0;
  }

  componentDidMount = () => {
    document.title = "Şehrinin En İyi Uzmanını Bul ve Ücretsiz Randevu Oluştur - İyi Uzman";
    document.querySelector('meta[name="description"]').setAttribute("content", "İyi Uzman, Uzman Hesabı Oluştur & En İyi Uzmanlardan Randevu Al, 0850 305 29 80");
    const ctx = this;
    setTimeout(function(){
      ctx.props.giris_pop_toggle(false);
      ctx.aramaSonuclari("");
      ctx.anasayfa();
    },500);
  };


  async anasayfa(){
        
      const { hata,mesaj,data } = await Api.anasayfa();

      if(hata){
          swal("HATA",mesaj,"error");
      }

      this.setState({
        degerlendirmeler : data.degerlendirmeler,
        en_iyi_uzmanlar : data.en_iyi_uzmanlar,
        bloglar : data.bloglar,
        oneriler : data.meslekler,
      })


  }

  puan(puan,checked){
    return (
        Array.from(Array(puan)).map(x => (
            <span className={ checked ? 'fa fa-star gray checked' : 'fa fa-star gray' }></span>
        ))
    )
  }

  toggleModal(){
    this.setState({
      modal1 : !this.state.modal1
    })
  }

  ara(){
    if(this.state.hid == 0 && this.state.mid == 0){
      alert('Lütfen önce almak istediğiniz hizmeti arayıp seçin!');
      return;
    }
    this.toggleModal();
  }

  onKeyDown(e){
      if(e.key == 'Enter'){
        this.toggleModal()
      }
  }

  setIl(il){
    this.setState({ il, ilce : 'hepsi', ilceler : Main.ilceler(il) });
  }

  setIlce(ilce){
    this.setState({ ilce });
  }

  setEvde(evde){
    this.setState( { evde } )
  }

  setKlinikte(klinikte){
    this.setState( { klinikte } )
  }

  setOnline(online){
    this.setState( { online } )
  }

  setKadin(kadin){
    this.setState( { kadin } )
  }

  setErkek(erkek){
    this.setState( { erkek } )
  }

  uzmanAra(){
    this.setState({ arama_hata : '' })

    if(this.state.hid == 0 && this.state.mid == 0){
      this.setState({ arama_hata : 'Hizmet seçimi yapılmadı!' });
      return;
    }

    if(!this.state.online && this.state.il.length < 1){
      this.setState({ arama_hata : 'İl seçimi yapılmadı!' });
      return;
    }

    if(!this.state.online && this.state.ilce.length < 1){
      this.setState({ arama_hata : 'İlçe seçimi yapılmadı!' });
      return;
    }

    if( !this.state.evde && !this.state.klinikte && !this.state.online){
      this.setState({ arama_hata : 'En az bir adet hizmet yeri seçilmelisiniz.' });
      return;
    }

    let params = {
      il              : this.state.il,
      ilce            : this.state.ilce,
      kadin           : this.state.kadin,
      erkek           : this.state.erkek,
      evde            : this.state.evde,
      klinikte        : this.state.klinikte,
      online          : this.state.online,
    }

    if(this.state.mid > 0) params.mid = this.state.mid;
    if(this.state.hid > 0) params.hid = this.state.hid;

    const q = qs.stringify(params);

    let il = this.state.il;
    let ilce = this.state.ilce;

    if(this.state.online){
      il = "tum";
      ilce = "ilceler";
    }

    this.props.history.push('/ara/' + il + '-' + ilce + '/' + Main.slugify(this.state.meslek_adi) + '?' + q);

  }

  aramaSonucSecim (e){
    
    this.setState({ tur : e.tur });

    if(e.tur == 'uzman'){
      this.props.history.push(Main.uzman_url(e));
      return;
    }else if (e.tur == 'hizmet'){
      this.setState({ hid : e.value,mid : e.mid, meslek_adi : e.meslek_adi })
    }else if (e.tur == 'meslek'){
      this.setState({ mid : e.value,meslek_adi : e.meslek_adi })
    }

  }

  hizmetAra(value){
    this.setState({ ara : value })
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.aramaSonuclari(value)
    }, 500);
    
  }

  async aramaSonuclari(q){
    const { hata, data : { meslekler,uzmanlar,hizmetler } } = await Api.hizmet_ara(q);

    if(!hata){
      
      let arama_sonuclari = [ ];

      meslekler.map(meslek => {
        arama_sonuclari.push({
          tur   : 'meslek',
          label : meslek.meslek_adi,
          value : meslek.id,
          resim : "",
          meslek_adi : meslek.meslek_adi
        });
      });

      hizmetler.map(hizmet => {
        arama_sonuclari.push({
          tur   : 'hizmet',
          label : hizmet.hizmet_adi,
          value : hizmet.id,
          mid   : hizmet.meslek_id,
          resim : "",
          meslek_adi : hizmet.meslek_adi
        });
      });

      uzmanlar.map(uzman => {
        arama_sonuclari.push({
          tur   : 'uzman',
          label : `${uzman.ad} ${uzman.soyad} (${uzman.meslek_adi})`,
          value : uzman.id,
          resim : Main.resimURL(uzman.fotograf),
          ad    : uzman.ad,
          soyad : uzman.soyad,
          id    : uzman.id,
          meslek_adi: uzman.meslek_adi,
          il    : uzman.il,
        });
      });

      this.setState({ arama_sonuclari });
    }
  }

  puan(puan,checked) {
    return (
        Array.from(Array(puan)).map(x => (
            <span className={ checked ? 'fa fa-star gray checked' : 'fa fa-star gray' }></span>
        ))
    )
  }

  oneriSecim(oneri){
    this.aramaSonucSecim({
      tur : 'meslek',
      value : oneri.id,
      meslek_adi : oneri.meslek_adi
    });

    this.setState({ ara : oneri.meslek_adi,modal1 : true })
  }
  
  render(){

    return (
        <div>
            
            <ActionBar/>

            <ActionBarMobile/>

            <Hero>
              <div id="searchbar">
                <Select ref={ (e) => this.searchSelect = e } inputValue={ this.state.ara } noOptionsMessage={() => 'Hiç sonuç bulunamadı.'} onInputChange={ (value) => this.hizmetAra(value) } onChange={ (e) =>  this.aramaSonucSecim(e) } styles={ aramaSonucuStyle('1') } components={{ Option : AramaSonucu }} placeholder="Meslek & Hizmet veya Doktor Adı giriniz..." defaultValue="" options={ this.state.arama_sonuclari } id="hizmet-ara"/>
                <button id="btn-hizmet-ara" onClick={ (e) => this.ara(e) } className="btn green-button">
                    <SearchIcon /> Ara
                </button>
              </div>
              
              <div className="d-flex flex-wrap">
                {
                  this.state.oneriler.map(oneri => (
                    <button onClick={ () => this.oneriSecim(oneri) } className="btn bordered-green-light-button mr-1 mt-1">{ oneri.meslek_adi }</button>
                  ))
                }
              </div>
 
            </Hero>
            
            <div className="container">
                <HizmetTurleri/>
                <NasilCalisir/>
                {/* <SonDegerlendirmeler/> */}
                <div className="row mt-5">
                    <div className="col-md-12 text-center">
                        <h3>Son Değerlendirmeler</h3>
                    </div>

                    {
                    this.state.degerlendirmeler.map( (d) => (
                    <div className="col-md-6 mt-3">
                        <div className="card degerlendirme">
                            <div className="card-body">
                                <div className="dhead">
                                    <div className="img"><img src={ Main.resimURL(d.fotograf,true) } className="uzman-avatar" /></div>
                                    <div className="uzman-bilgi">
                                        <span className="uzman-adi">{ d.meslek_unvani + ' ' + d.ad + ' ' + d.soyad  }</span>
                                        <span className="uzman-meslek">{ d.meslek_adi }</span>
                                        <div className="yildizlar">
                                          { this.puan(d.puan,true) }
                                          { this.puan(5-d.puan,false) }
                                        </div>
                                    </div>
                                </div>
                                <p className="yorum text-center">
                                   <i>{ d.yorum }</i>
                                </p>
                                <Link to={ Main.uzman_url({ id : d.id, ad : d.ad, soyad : d.soyad, il : d.il, meslek_adi : d.meslek_adi }) }> <button className="btn green-button float-right">Randevu Al</button> </Link>
                            </div>
                        </div>
                    </div>
                    ))  
                    }
                </div>

                <div className="row mt-5">
                    <div className="col-md-6 text-center offset-md-3">
                        <h3>En İyi Uzmanlar</h3>
                    </div>
                    {
                        this.state.en_iyi_uzmanlar.map(e => (
                            <div className="col-md-6 mt-3">
                                <div className="card uzman-vitrin h-100">
                                    <div className="card-body">
                                        <div className="dhead">
                                            <div className="img"><img src={ Main.resimURL(e.fotograf,true) } className="uzman-avatar" /></div>
                                            <div className="uzman-bilgi">
                                                <span className="uzman-adi">{ e.meslek_unvani + ' ' + e.ad + ' ' + e.soyad  }</span>
                                                <span className="uzman-meslek">{ e.meslek_adi }</span>
                                                <div className="yildizlar">
                                                    { this.puan(e.puan,true) }
                                                    { this.puan(5-e.puan,false) }
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                        { 
                                          e.ozgecmis.length > 150 ? 
                                          <>
                                            {e.ozgecmis.substr(0,150)}...
                                          </>
                                          :
                                          <>
                                            {e.ozgecmis}
                                          </>
                                        }
                                        </p>
                                        
                                        <p>
                                        {
                                          e.hizmet_illeri != null && e.hizmet_illeri.length > 0 ?
                                          <>
                                          {
                                            e.hizmet_illeri.map(il=>(
                                              <span class="badge badge-pill badge-success mr-1">{ il.toUpperCase() }</span>
                                            ))
                                          }
                                          </>
                                          :
                                          <></>
                                        }
                                        </p>
                                        <div className="text-center col-md-12">
                                          <Link to={ Main.uzman_url(e) } style={{bottom:'15px'}}> <button className="btn green-button btn-randevu-al">Randevu Al</button> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>

            <div id="anasayfa-bloglar" className='d-none'>
                {/* <Bloglar/> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 text-center">
                            <h3>Bloglar</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                        </div>

                        <div className="col-md-12"></div>

                        {
                            this.state.bloglar.map(b => (
                                <div className="col-md-6 mt-3">
                                    <div className="card blog">
                                        <div className="card-body">

                                            <div className="blog-resim">
                                                <img src={ Main.resimURL(b.kapak_resmi,true) } />
                                            </div>

                                            <div className="blog-detay">
                                                <p className="baslik">{ b.baslik }</p>
                                                <p className="kisa-bilgi">{ b.metin.substr(0,200) }...</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            <Footer/>

            <Modal show={ this.state.modal1 } dialogClassName="modal-90w" centered>
              <Modal.Body className="px-5 py-4">
                <div className="text-right mr-2 mt-2">
                    <i onClick={ () => this.setState({ modal1 : false }) } className="fas fa-times close-popup"></i>
                </div>

                <h3 className="text-center">Uzman Ara</h3>
                
                <div className="row mt-4 mb-4">
                  <div className="form-group col-md-6">
                    <label htmlFor="il">İl</label>
                    
                    <select disabled={ this.state.online } id="il" defaultValue={ this.state.il } onChange={ (e) => this.setIl(e.target.value) } type="text" className="form-control">
                      <option value='' disabled>Seçiniz</option>
                      { this.state.iller.map(il => (
                        <option value={ il.value }>{ il.label }</option>
                      )) }
                    </select>
                    
                  </div>
                  <div className="form-group col-md-6">
                      <label htmlFor="ilce">İlçe</label>
                      <select defaultValue="hepsi" value={ this.state.ilce } disabled={ (this.state.ilceler.length < 1 || this.state.online) ? true : false } onChange={ (e) => this.setIlce(e.target.value) } type="text" id="ilce" className="form-control">
                        <option value='hepsi'>Tüm İlçeler</option>
                        { this.state.ilceler.map(ilce => (
                          <option value={ ilce.value }>{ ilce.label }</option>
                        )) }
                      </select>
                  </div>
                </div>

                <div className="row mb-4">

                  <div className="col-md-12"> 
                    <h4>Hizmet Yeri</h4> 
                  </div>

                  <div className="form-check col-md-4">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input disabled={ this.state.online } onClick={ (e) => this.setEvde(e.target.checked) } type="checkbox" id="evde" value="EVDE" className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="evde">Evde</label>
                    </div>
                  </div>
                  
                  <div className="form-check col-md-4">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input disabled={ this.state.online } onClick={ (e) => this.setKlinikte(e.target.checked) } type="checkbox" id="klinikte" value="KLINIKTE" className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="klinikte">Klinikte</label>
                    </div>
                  </div>

                  <div className="form-check col-md-4">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setOnline(e.target.checked) } type="checkbox" id="online" value="ONLINE" className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="online">Online</label>
                    </div>
                  </div>

                  {
                    this.state.online ?
                    <>
                        <div className="alert alert-danger mt-2">
                            <p className="p-1">Online hizmet seçeneği tüm dünya çapındaki uzmanları getirir.</p>
                        </div>
                    </> : <></>
                  }

                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <h4>Uzman Cinsiyeti</h4>
                  </div>
                  <div className="form-check col-md-4">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setKadin(e.target.checked) } type="checkbox" id="kadin" value="KADIN" className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="kadin">Kadın</label>
                    </div>
                  </div>
                  <div className="form-check col-md-4">
                    <div className="custom-control custom-checkbox custom-control-inline mt-2">
                        <input onClick={ (e) => this.setErkek(e.target.checked) } type="checkbox" id="erkek" value="ERKEK" className="custom-control-input"/>
                        <label className="custom-control-label" htmlFor="erkek">Erkek</label>
                    </div>
                  </div>
                </div>

                {
                  this.state.arama_hata.length > 0 ?
                  <>
                  <div className="alert alert-danger">
                    <p className="lead mb-0">{ this.state.arama_hata } </p>
                  </div>
                  </>
                  : <> </>
                }

                <div className="row">
                  <div className="col-md-12 text-right">
                      <button onClick={ () => this.uzmanAra() } className="btn green-button" style={ { width:200 } }>
                        <SearchIcon /> Uzman Ara
                      </button>
                  </div>
                </div>
                
              </Modal.Body>
            </Modal>
        </div>
    );
  }
}

function mapStateToProps(state){
  return {
    giris_pop : state.giris_pop
  }
}

function mapDispatchToProps(dispatch){
  return {
    giris_pop_toggle : (value) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value })
  }
}


export default  connect(mapStateToProps,mapDispatchToProps)(withRouter(Anasayfa));