import { ReactComponent as Tarih } from '../../assets/images/blog-tarih.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import * as actionTypes from '../../Redux/actionTypes';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import Api from '../../Functions/Api';

import Main from '../../Functions/Main';

import Moment from 'react-moment';

class BildirimDetay extends Component {

  state = {
    yuklendi : false,
    degerlendirme      : { },
  }


  async degerlendirme_detaylari(){
    const { id } = this.props.match.params;
    
    const { hata,mesaj,data } = await Api.degerlendirme(id);

    if(!hata){
        this.setState({ yuklendi : true, degerlendirme : data });
        console.log(data)
        document.title = "Müşteri Yorumu" + " - İyi Uzman"
    }else{
        Main.message(mesaj,hata);
    }
  }

  // sayfa yüklendiğinde çalışan fonksiyon
  componentDidMount = async () => {
    this.degerlendirme_detaylari();
  };

  // içerik aşağıdaki fonksiyonda kodlanacak
  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div className="col-md-10">
            <div class="card bildirim-detay-card">
                <div class="card-header bildirim-detay-header text-green px-5 py-3">
                    <div className="row">
                        <div className="col-6"><Tarih className="mr-1"/> <Moment format="DD.MM.YYYY HH:mm">{ this.state.degerlendirme.olusturulma_tarihi }</Moment></div>
                        <div className="col-3 offset-3"></div>
                    </div>
                </div>
                <div class="card-body px-5 py-4">
                    <p class="card-text">
                        { this.state.degerlendirme.yorum }
                    </p>
                    <hr />
                    <span>
                        Puan: { this.state.degerlendirme.puan }
                    </span>
                </div>
            </div>
        </div>
    )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>

              <div id="content">

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(BildirimDetay))