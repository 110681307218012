import { ReactComponent as IkiliOkSol } from '../../assets/images/ikili-ok-sol.svg';
import { ReactComponent as OkSol } from '../../assets/images/ok-sol.svg';
import { ReactComponent as IkiliOkSag } from '../../assets/images/ikili-ok-sag.svg';
import { ReactComponent as OkSag } from '../../assets/images/ok-sag.svg';

import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import { Modal } from 'react-bootstrap';

import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import Api from '../../Functions/Api';

import Moment from 'react-moment';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';

class Bildirimler extends Component {

  state = {
      yuklendi        : false,
      sayfa           : 1,
      toplam_sayfa    : 1,
      bildirimler     : [],
  }

  componentDidMount = () => {
    document.title = "Bildirimler - İyi Uzman";
    const ctx = this;
    setTimeout(function(){
       ctx.bildirimleri_getir();
    },500);
  };

  sayfalandirma(){

    let sayfalar = [ ];

    const { sayfa,toplam_sayfa } = this.state;

    sayfalar.push({ label : 'ilk',sayfa : 'ilk' });
    
    if(sayfa > 1){
        sayfalar.push({ label : 'geri',sayfa : sayfa - 1 });
    }

    let baslangic = (sayfa - 1);
    let bitis = (baslangic - 3);
    
    for(var i = baslangic; i > bitis; i--){

        let x = ((baslangic + bitis) - i)+1;

        if(x < 1) continue;

        sayfalar.push({ label : x, sayfa : x });
    }

    sayfalar.push({ label : sayfa, sayfa });

    baslangic = (sayfa + 1);
    bitis = (baslangic + 3);

    for(i = baslangic; i < bitis; i++){
        
        if(i > toplam_sayfa) break;

        sayfalar.push({ label : i, sayfa : i });
    }

    if(sayfa < toplam_sayfa){
        sayfalar.push({ label : 'sonraki',sayfa : sayfa + 1 });
    }
    sayfalar.push({ label : 'son',sayfa : 'son' });

    const click = (sayfa) => {
        let git = sayfa.sayfa;

        if(git == 'son'){
            git = this.state.toplam_sayfa;
        }else if (git == 'ilk'){
            git = 1;
        }

        this.setState({ sayfa : git });
        const ctx = this;
        setTimeout(() => {
            ctx.bildirimleri_getir();
        }, 500);
    }

      return(
        sayfalar.map(x => (
            <button onClick={ () =>  click(x) } className="btn page-button" data-active={ this.state.sayfa == x.sayfa ? true : false }>
               {
                   x.label == 'geri' ? <OkSol/> :
                   x.label == 'ilk' ? <IkiliOkSol/> :
                   x.label == 'sonraki' ? <OkSag/> :
                   x.label == 'son' ? <IkiliOkSag/> : x.label
               }
            </button>
        ))
      )
  }

    async bildirimleri_getir(){

        this.setState({
            yuklendi : false,
        })

        const { hata,mesaj,data } = await Api.bildirimler_listesi(this.state.sayfa);

        if(hata){
            swal("HATA",mesaj,"error");
        }

        this.setState({
            bildirimler : data == null ? [ ] : data.bildirimler,
            yuklendi : true,
            sayfa : data.sayfa,
            toplam_sayfa : data.toplam_sayfa,
        })
  
    }
  

  content(){
      if(!this.state.yuklendi){
          return (
              <PageLoading/>
          )
      }

      return (
        <div className="row">
            <div className="col-md-12">

                {
                    this.state.bildirimler.length > 0 ?
                    <>
                        <div className="content-card">
                            <div className="table-responsive">
                                <table className="table table-borderless dash mb-0">
                                    <thead>
                                        <tr>
                                            <th>Bildirim</th>
                                            <th>Tarih</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {
                                        this.state.bildirimler.map( (bildirim) => (
                                        <tr>
                                            <td>{ bildirim.baslik }</td>
                                            <td><Moment format="DD.MM.YYYY HH:mm">{ bildirim.olusturulma_tarihi }</Moment></td>
                                            <td>
                                                
                                                <Link 
                                                to={
                                                    bildirim.tur.toLowerCase() == 'randevu' ?
                                                    '/uzman/randevu-detay/' + bildirim.data_id
                                                    : bildirim.tur.toLowerCase() == 'fatura' ?
                                                    '/uzman/fatura-detay/' + bildirim.data_id
                                                    : bildirim.tur.toLowerCase() == 'link' ?
                                                    bildirim.link
                                                    :
                                                    '#'
                                                }
                                                >

                                                    <button className="btn light-button2">Git</button>
                                                </Link>
                                            </td>
                                        </tr>
                                        ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className="text-center">
                            { this.sayfalandirma() }
                        </div>
                    </>
                    :
                    <>
                        <div className="content-card py-5">
                            <h5 className='text-center'>Henüz bir bildirim yok.</h5>
                        </div>
                    </>
                }

            </div>
        </div>
      )
  }

  render(){


    return (
        <div>
            <NavBar className="bg-green"/>

            <div class="wrapper">
              
              <Sidebar/>
              
              <div id="content">
                <h3 className="mb-3">Bildirimler</h3>

                { this.content() }

              </div>

            </div>

            <Footer/>

        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar : state.sidebar
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Bildirimler))