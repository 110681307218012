import NavBar from '../NavBar';
import React, { Component } from 'react'

export default class Hero extends Component {

    render() {

        const { onClick,onKeyDown, placeHolder,children } = this.props;

        return (
            <div id="hero">
                <NavBar/>
                <div id="HeroContainer" className="container">
                    <h2 className='text-light'>Şehrinin En İyi Uzmanını Bul</h2>
                    { children }
                </div>
            </div>
        )
    }
}
