import React, { Component } from 'react';

import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Sidebar from '../Components/Sidebar';
import PageLoading from '../Components/PageLoading';

import { Modal } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actionTypes from '../../Redux/actionTypes';

import swal from '@sweetalert/with-react';
import Api from '../../Functions/Api';
import Co from '../../Functions/Co';
import Main from '../../Functions/Main';

class CalismaTakvimi extends Component {

  state = {
      modal : false,
      yuklendi : false,
      btn_ekle : true,
      gun : '',
      saat : '09',
      dakika: '00',
      takvim: [],
      bugun : new Date().getDay(),
  }

  componentDidMount = async () => {
    document.title = "Çalışma Takvimi - İyi Uzman"
    const ctx = this;
    setTimeout(function(){
        ctx.calisma_takvimi_getir();
    },500);
  };
  

  btn_ekle(gun){
      this.setState({ modal : true })
      this.setState({ gun })
  }

  ekleme_butonlari(){
      const gunler = [ 1,2,3,4,5,6,7 ];

      return (
        gunler.map(gun => (
            <td>
                <button onClick={ () =>  this.btn_ekle(gun) } className="btn light-button text-green">Ekle</button>
            </td>
        ))
      )
  }

  setSaat(saat){
      this.setState({ saat })
  }
  setDakika(dakika){
    this.setState({ dakika })
  }

  async calisma_takvimi_getir(){
    
      const { hata,mesaj,data } = await Api.calisma_takvimi_listesi(this.props.user.id,Co.access_token());

      this.setState({
          takvim : data == null ? [ ] : data.randevu_saatleri,
          yuklendi : true
      })
    
  }

  async randevu_saati_ekle(){

    this.setState({ btn_ekle : false });
    var saat = this.state.saat + ":" + this.state.dakika;
    const { hata,mesaj,data } = await Api.randevu_saati_ekle(this.state.gun,saat,Co.access_token());    
    this.calisma_takvimi_getir();

    Main.message(mesaj,hata);

    this.setState({
        modal : false,
        btn_ekle: true,
        saat : '09',
        dakika: '00'
    })
   }

   async randevu_saati_sil(id){

    swal({
      title :'Randevu Saati Siliniyor',
      content : <div><span>Randevu saatini silmek için sil butonuna basın</span></div>,
      buttons : {
        b1 : {
          text:'Vazgeç',
          className:'green-button'
        },
        b2 : {
          text:'Sil',
          className: 'red-button',
        }
      }
    }).then( async (result) => {
        if(result == "b2"){
            const { hata,mesaj,data } = await Api.randevu_saati_sil(id,Co.access_token());

            if(hata){
                Main.message(mesaj,hata);
            }
        }
        await this.calisma_takvimi_getir();
    });

   
   }

   saatler(){
    return (
       <tr>
           {
               this.state.takvim.map( (gun) => (
                <td>
                    {
                        gun.saatler.map(saat => (
                            <button onClick={ () => this.randevu_saati_sil(saat.id)} className='btn light-button2 bcs btn-block'> x { saat.saat }</button>
                        ))
                    }
                   <br />
                </td>
               ))
           }
       </tr>
    )
   }

  content(){

      if(!this.state.yuklendi){
        return (
          <PageLoading/>
        )
      }
      
      return (
        <div className="row">
            <div className="col-md-10">
                <div className="content-card">

                <div className="table-responsive">
                    <table className="table table-borderless calismaTakvimi mb-0">
                        <thead>
                            <tr>
                                <th className={ this.state.bugun == 1 ? 'active' : '' }>Pzt</th>
                                <th className={ this.state.bugun == 2 ? 'active' : '' }>Salı</th>
                                <th className={ this.state.bugun == 3 ? 'active' : '' }>Çarş</th>
                                <th className={ this.state.bugun == 4 ? 'active' : '' }>Perş</th>
                                <th className={ this.state.bugun == 5 ? 'active' : '' }>Cum</th>
                                <th className={ this.state.bugun == 6 ? 'active' : '' }>Ctesi</th>
                                <th className={ this.state.bugun == 0 ? 'active' : '' }>Paz</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>{ this.ekleme_butonlari() }</tr>                
                            { this.saatler() }
                        </tbody>
                    </table>
                </div>

                </div>
            </div>
        </div>
      )
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>
              
              <div id="content">
                <h4 className="mb-3">Çalışma Takvimi</h4>

                { this.content() }
                
              </div>

            </div>

            <Footer/>

            <Modal show={ this.state.modal } dialogClassName="modal-90w" centered>
                <Modal.Body>
                    <div className="text-right mr-2 mt-2">
                        <i onClick={ () => this.setState({ modal : false }) } className="fas fa-times close-popup"></i>
                    </div>
                    <div id="calisma-saati-ekle" className="text-center p-4">
                        <h4>Çalışma Takvimi Ekle</h4>
                        <small className="gray">Çalışma takvimine eklemek için saat ve dakika giriniz.</small>

                        <center>
                            <table className="text-center">
                                <tr>
                                    <th>Saat</th>
                                    <th></th>
                                    <th>Dakika</th>
                                </tr>
                                <tr>
                                    <td>
                                        <select onChange={ (e) => this.setSaat(e.target.value) } className="form-control" id="saat">
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="00">00</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                        </select>
                                    </td>
                                    <th>:</th>
                                    <td>
                                        <select onChange={ (e) => this.setDakika(e.target.value) } className="form-control" id="dakika">
                                            <option value="00">00</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                        </select>
                                    </td>
                                </tr>
                            </table>

                            <button disabled={ !this.state.btn_ekle } onClick={ () => this.randevu_saati_ekle() } className="btn green-button btn-block btn-lg mt-4 w-75">
                                {
                                    this.state.btn_ekle ?
                                    <>
                                        Ekle
                                    </>
                                    :
                                    <>
                                        <div className="spinner-border mr-3" role="status"></div>
                                        Ekleniyor...
                                    </>
                                }
                            </button>
                        </center>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
        giris_pop : state.giris_pop,
        sidebar : state.sidebar,
        user : state.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        giris_pop_toggle : ( value ) => dispatch({ type : actionTypes.GIRIS_POP_TOGGLER, payload : value }),
        sidebar_toggle : ( value ) => dispatch({ type : actionTypes.SIDEBAR_STATE, payload : value })
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CalismaTakvimi))