import { ReactComponent as UploadIcon } from '../../../assets/images/upload.svg';
import React, { Component } from 'react';
import Select from 'react-select';

import Footer from '../../Components/Footer';
import NavBar from '../../Components/NavBar';
import Sidebar from '../../Components/Sidebar';
import PageLoading from '../../Components/PageLoading';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import Api from '../../../Functions/Api';
import Co from '../../../Functions/Co';

import Main from '../../../Functions/Main';


import { domain } from '../../../Config';
import Stepper from './Stepper';
import { SET_USER_DATA } from '../../../Redux/actionTypes';


class KisiselBilgiler extends Component {

  state = {

    user                        : { },

    yuklendi                    : false,
    btn_onay                    : true,
    meslek_degisti              : 0,
    onay_bekliyor               : 0,
    email_dogrulandi            : 0,
    kimlik_onaylandi            : 0,

    diploma                     : '',
    yeni_diploma                : '',
    diploma_yukleniyor          : false,

    ust_meslek_id               : 0,
    alt_meslek_id               : 0,

    // Uzman profil bilgileri
    meslek_id                   : 0,
    meslek_unvani               : 0,
    ozgecmis                    : '',
    evde_hizmet                 : false,
    klinikte_hizmet             : false,
    online_hizmet               : false,
    evde_hizmet_taban_fiyat     : 0,
    evde_hizmet_tavan_fiyat     : 0,
    klinikte_hizmet_taban_fiyat : 0,
    klinikte_hizmet_tavan_fiyat : 0,
    online_hizmet_taban_fiyat   : 0,
    online_hizmet_tavan_fiyat   : 0,
    profilde_fiyat_goster       : false,
    nakit_odeme                 : false,
    kart_ile_odeme              : false,
    okul                        : '',
    tecrubeler                  : '',
    klinik_adresi               : '',
    degerlendirme_seansi_ucretli : false,

    meslek_gruplari             : [ ],
    alt_meslekler               : [ ],
    meslek_unvanlari            : [ ],

    verilen_hizmetler           : [ ],
    meslek_hizmetleri           : [ ],

    hizmet_bolgeleri            : Main.sehirListesi(),
    hizmet_bolgelerim           : [ ],

    kayit_ediliyor              : false


  }

  componentDidMount = async () => {
    document.title = "Uzman Profili - İyi Uzman";
    this.profilBilgileriniGetir();
  }

  setOzgecmis(ozgecmis){
    this.setState({ ozgecmis })
  }

  setEvdeHizmet(evde_hizmet){
    this.setState({ evde_hizmet })
  }

  setKlinikteHizmet(klinikte_hizmet){
    this.setState({ klinikte_hizmet })
  }

  setOnlineHizmet(online_hizmet){
    this.setState({ online_hizmet })
  }

  setEvdeTabanFiyat(evde_hizmet_taban_fiyat){
    this.setState({ evde_hizmet_taban_fiyat })
  }
  setEvdeTavanFiyat(evde_hizmet_tavan_fiyat){
      this.setState({ evde_hizmet_tavan_fiyat })
  }
  setKlinikteTabanFiyat(klinikte_hizmet_taban_fiyat){
      this.setState({ klinikte_hizmet_taban_fiyat })
  }
  setKlinikteTavanFiyat(klinikte_hizmet_tavan_fiyat){
      this.setState({ klinikte_hizmet_tavan_fiyat })
  }
  setOnlineTabanFiyat(online_hizmet_taban_fiyat){
      this.setState({ online_hizmet_taban_fiyat })
  }
  setOnlineTavanFiyat(online_hizmet_tavan_fiyat){
      this.setState({ online_hizmet_tavan_fiyat })
  }

  setProfildeFiyatGoster(profilde_fiyat_goster){
    this.setState({ profilde_fiyat_goster })
  }

  setNakitOdeme(nakit_odeme){
    this.setState({ nakit_odeme })
  }

  setKartIleOdeme(kart_ile_odeme){
    this.setState({ kart_ile_odeme })
  }

  setOkul(okul){
    this.setState({ okul })
  }

  setTecrubeler(tecrubeler){
    this.setState({ tecrubeler })
  }

  setKlinikAdresi(klinik_adresi){
    this.setState({ klinik_adresi })
  }

  setUcretli(degerlendirme_seansi_ucretli){
    this.setState({ degerlendirme_seansi_ucretli })
  }

  async profilBilgileriniGetir(){
    this.setState({ yuklendi : false })

    const { hata,data } = await Api.profil_bilgileri( { }, Co.access_token() );
    
    if(!hata){
      this.setState({
        ust_meslek_id : data.ust_meslek_id,
        alt_meslek_id : data.meslek_id,
        meslek_id : data.meslek_id,
        meslek_unvani : data.meslek_unvani,
        ozgecmis : data.ozgecmis,
        
        evde_hizmet : data.evde_hizmet,
        klinikte_hizmet : data.klinikte_hizmet,
        online_hizmet : data.online_hizmet,

        evde_hizmet_taban_fiyat : data.evde_hizmet_taban_fiyat,
        evde_hizmet_tavan_fiyat : data.evde_hizmet_tavan_fiyat,
        klinikte_hizmet_taban_fiyat : data.klinikte_hizmet_taban_fiyat,
        klinikte_hizmet_tavan_fiyat : data.klinikte_hizmet_tavan_fiyat,
        online_hizmet_taban_fiyat : data.online_hizmet_taban_fiyat,
        online_hizmet_tavan_fiyat : data.online_hizmet_tavan_fiyat,

        profilde_fiyat_goster : data.profilde_fiyat_goster,
        nakit_odeme : data.nakit_odeme,
        kart_ile_odeme : data.kart_ile_odeme,
        okul : data.okul,
        tecrubeler : data.tecrubeler,
        diploma : data.diploma,
        klinik_adresi : data.klinik_adresi,
        onay_bekliyor : data.onay_bekliyor,
        email_dogrulandi : data.email_dogrulandi,
        kimlik_onaylandi : data.kimlik_onaylandi,

        degerlendirme_seansi_ucretli : data.degerlendirme_seansi_ucretli
      });

      this.setState({ user : data });

      let hizmet_bolgelerim = Main.hizmetBolgeleri(data.hizmet_bolgeleri);
      
      let hizmetler = [ ];

      data.verilen_hizmetler.map(hizmet => {
        hizmetler.push({
          label : hizmet.hizmet_adi,
          value : hizmet.hizmet_id
        });
      });

      this.setState({
        verilen_hizmetler : hizmetler,
        hizmet_bolgelerim : hizmet_bolgelerim
      })
      await this.meslekleriGetir();

      await this.meslekHizmetleriGetir();

      if(data.ust_meslek_id > 0){
        this.meslekSecim(data.ust_meslek_id,true);
      }else{
        this.meslekSecim(data.meslek_id);
      }

      this.setState({ yuklendi : true })
    }
  }

  async meslekleriGetir(){
    const { hata,data } = await Api.meslek_grubu_listesi( Co.access_token() );
    
    if(!hata){
      this.setState({
        meslek_gruplari : data
      });
    }
  }

  async meslekUnvanlariGetir(meslek_id){

    this.setState({ yuklendi : false })

    if(meslek_id < 1) return;

    const { hata,data } = await Api.meslek_unvanlari_listesi(meslek_id,Co.access_token());

    if(!hata){
      this.setState({
        yuklendi : true,
        meslek_unvanlari : data.unvanlar
      })
    }
  }

  async meslekHizmetleriGetir(){
    
    if(this.state.meslek_id < 1) return;

    const meslek_id = this.state.meslek_id;
    

    const { hata,data} = await Api.meslek_hizmetleri_listesi(meslek_id);

    if(!hata){
      
      var hizmetler = [ ];

      data.hizmetler.map(hizmet => {
        hizmetler.push({
          label : hizmet.hizmet_adi,
          value : hizmet.id
        });
      });

      this.setState({
        meslek_hizmetleri : hizmetler
      })

    }

  }

  meslekSecim(id,initial = false) {
    if(!initial) {
      
      this.setState({ meslek_id : id });
      this.setState({ alt_meslek_id : 0 });

      if(id != this.state.meslek_id ){
        this.setState({ meslek_degisti : 1,meslek_unvani : '' });
      }

    }
    this.setState({ ust_meslek_id : id });

    this.meslekUnvanlariGetir(id);

    var ctx= this;
    setTimeout(() => {
      ctx.meslekHizmetleriGetir();
    }, 500);

    var ana_kategori = this.state.meslek_gruplari.filter(x => x.ana_kategori.id == id);

    if(ana_kategori.length > 0){
      ana_kategori = ana_kategori[0];

      this.setState({ alt_meslekler : ana_kategori.alt_kategoriler });
    }
    
  }

  altMeslekSecim(id){
    
    if(id != this.state.user.alt_meslek_id){
      // alert("Meslek değişti. Önce kaydet.")
      this.setState({
        meslek_degisti : 1,
        meslek_unvani : '',
      });
    }
  
    this.setState({ alt_meslek_id : id, meslek_id : id })
    var ctx = this;
    setTimeout(() => {
      ctx.meslekHizmetleriGetir();
    }, 500);
  }

  unvanSecim(unvan){
    this.setState({ meslek_unvani : unvan })
  }

  async kaydet(){

    if( !this.zorunlu_alanlar() ) return false;

    const params = {
      meslek_id                     : this.state.meslek_id,
      meslek_unvani                 : this.state.meslek_unvani,
      ozgecmis                      : this.state.ozgecmis,

      evde_hizmet                   : this.state.evde_hizmet ? 1 : 0,
      klinikte_hizmet               : this.state.klinikte_hizmet ? 1 : 0,
      online_hizmet                 : this.state.online_hizmet ? 1 : 0,

      evde_hizmet_taban_fiyat       : this.state.evde_hizmet_taban_fiyat,
      evde_hizmet_tavan_fiyat       : this.state.evde_hizmet_tavan_fiyat,

      klinikte_hizmet_taban_fiyat   : this.state.klinikte_hizmet_taban_fiyat,
      klinikte_hizmet_tavan_fiyat   : this.state.klinikte_hizmet_tavan_fiyat,

      online_hizmet_taban_fiyat     : this.state.online_hizmet_taban_fiyat,
      online_hizmet_tavan_fiyat     : this.state.online_hizmet_tavan_fiyat,

      profilde_fiyat_goster         : this.state.profilde_fiyat_goster ? 1 : 0,
      kart_ile_odeme                : this.state.kart_ile_odeme ? 1 : 0,
      nakit_odeme                   : this.state.nakit_odeme ? 1 : 0,
      okul                          : this.state.okul,
      tecrubeler                    : this.state.tecrubeler,

      klinik_adresi                 : this.state.klinik_adresi,
      degerlendirme_seansi_ucretli  : this.state.degerlendirme_seansi_ucretli ? 1 : 0
    }

    this.setState({ kayit_ediliyor : true })

    var { hata,mesaj,data } = await Api.profili_guncelle(params,Co.access_token());

    this.setState({ kayit_ediliyor : false })

    if(hata){
      Main.message(mesaj,hata);
      return;
    }

    setTimeout( () => {
      window.location.reload();
    },1500);

    if(this.state.meslek_degisti == 1){
      this.setState({
        verilen_hizmetler : []
      });
    }

    this.setState({
      meslek_degisti : 0
    });

    this.profilBilgileriniGetir();

    swal("Harika!", mesaj, 'success');
  }

  zorunlu_alanlar(){

    if(this.state.meslek_id < 1){
      Main.message("Lütfen mesleğinizi seçin!",true);
      return false;
    }

    if(this.state.meslek_unvani.length < 1){
      Main.message("Lütfen meslek ünvanınızı seçin!",true);
      return false;
    }

    if(this.state.diploma.length < 1){
      Main.message("Lütfen diplomanızı yükleyin!",true);
      return false;
    }

    if(!this.state.meslek_degisti && this.state.verilen_hizmetler.length < 1){
      Main.message("Lütfen vermek istediğiniz hizmetleri ekleyin!",true);
      return false;
    }

    if(this.state.okul.length < 1){
      Main.message("Lütfen okul ve eğitim bilgilerinizi doldurun!",true);
      return false;
    }

    if(this.state.tecrubeler.length < 1){
      Main.message("Lütfen tecrübelerinizi doldurun!",true);
      return false;
    }

    return true;
  }

  content(){

    if(!this.state.yuklendi){
      return (
        <PageLoading/>
      )
    }

    return (
        <div id="profilimContainer" className="content-card p-5">
            <h4><span>Uzman Profilim</span></h4>
            
            <div className="form-group mt-3">
              <label htmlFor="meslek"><h5>Meslek Seçimi</h5></label>
              <select onChange={ (e) => this.meslekSecim(e.target.value) } name="meslek" id="meslek" className="form-control" defaultValue={ this.state.ust_meslek_id }>
                <option value="0" disabled>Seçiniz...</option>
                {
                  this.state.meslek_gruplari.map(mg => (
                    // <option value={ mg.ana_kategori.id } selected={ mg.ana_kategori.id == this.state.ust_meslek_id ? true : false }> { mg.ana_kategori.meslek_adi } </option>
                    <option value={ mg.ana_kategori.id }> { mg.ana_kategori.meslek_adi } </option>
                  ))
                }
              </select>
            </div>

            {
              this.state.alt_meslekler.length > 0 ?
              <>
              <div className="form-group mt-3">
                <label htmlFor="alt_meslek"><h5>Alt Dal Seçimi</h5></label>
                <select onChange={ (e) => this.altMeslekSecim(e.target.value) } name="alt_meslek" id="alt_meslek" className="form-control" defaultValue={ this.state.alt_meslek_id }>
                  <option value="0" disabled>Seçiniz...</option>
                  {
                    this.state.alt_meslekler.map(am => (
                      // <option value={ am.id } selected={ am.id == this.state.alt_meslek_id ? true : false }> { am.meslek_adi } </option>
                      <option value={ am.id }> { am.meslek_adi } </option>
                    ))
                  }
                </select>
              </div>
              </>
              : ''
            }

            <div className="form-group">
                <label htmlFor="unvan"><h5>Unvan Seçimi</h5></label>
                <select onChange={ (e) => this.unvanSecim(e.target.value) } type="text" id="unvan" className="form-control" defaultValue={ this.state.meslek_unvani }>
                  <option value="" disabled>Seçiniz...</option>
                  { this.state.meslek_unvanlari.map(mu =>(
                    <option value={ mu.unvan }> { mu.unvan } </option>
                  ))}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="diploma"><h4>Diploma Belgesi</h4></label>
                {
                  this.state.diploma.length > 0 ?

                      this.state.diploma.toLowerCase().endsWith('.pdf') ?
                      <>
                        <br />
                        <a target="_blank" href={ 'https://api.iyiuzman.com' + this.state.diploma } className="btn green-button">Diplomamı Görüntüle</a>
                        <br /> <br />
                      </>
                      :
                      <>
                      <br />
                      <a target="_blank" href={ 'https://api.iyiuzman.com' + this.state.diploma }>
                        <img className="diploma" src={ 'https://api.iyiuzman.com' + this.state.diploma } alt="diploma" />
                      </a>
                      <br /> <br />
                      </>
                  :
                  <>
                    <br />
                    <span className="text-danger">
                      Yüklenmiş bir diplomanız yok
                    </span>
                    <br /><br />
                  </>
                }
                <div className="border-dashed border-radius-10 border-light2 p-5 text-center mb-2 ">
                  <UploadIcon className="mb-3" />
                  <br />
                  <button disabled={ this.state.diploma_yukleniyor } onClick={ () => this.fdiploma.click()  } className="btn light-button2">
                    {
                      this.state.diploma_yukleniyor ? 
                      <>
                      <div className="spinner-border text-dark" role="status"></div>
                      <span className="ml-3 dark">Yükleniyor...</span>
                      </>
                      : 'Dosya Ekle'
                    }
                  </button>
                </div>

                <input className="d-none" onChange={ this.diplomaSecim } type="file" accept="image/png,image/jpg,image/jpeg,application/pdf" ref={ (e) => this.fdiploma = e }/>

                <small className="gray">E-Devletten alınmış barkodlu mezuniyet belgesi</small>
            </div>

            <div className="form-group">
                <label htmlFor="verilen_hizmetler"><h5>Verilecek Hizmetler</h5></label>
                <Select value={ this.state.verilen_hizmetler } onChange={ this.hizmetChange } id="verilen_hizmetler" placeholder="Seçiniz..." styles={ customStyles } isMulti options={ this.state.meslek_hizmetleri } isDisabled={this.state.meslek_degisti} />
            </div>
            {
              this.state.meslek_degisti ? 
              <>
                <div class="alert alert-danger" role="alert">
                  <h5 className="text-white">Meslek değişti. Verilen hizmetleri değiştirebilmek için önce kaydetmelisiniz.</h5>
                </div>
              </>
              :
              ''
            }

            <div className="alert alert-danger">
              <p className="lead">Sadece <strong>Online Hizmet</strong> verecekseniz hizmet bölgesi seçmenize gerek yok. Ancak evde yada klinikte hizmet verecekseniz eğer hizmet bölgelerinizi seçmelisiniz.</p>
            </div>
            
            <div className="form-group">
                <label htmlFor="hizmet_bolgeleri"><h5>Hizmet Verilecek Bölgeler</h5></label>
                <Select value={ this.state.hizmet_bolgelerim } onChange={ this.hizmetBolgesiChange } id="hizmet_bolgeleri" placeholder="Seçiniz..." styles={ customStyles } isMulti options={ this.state.hizmet_bolgeleri } />
            </div>
            <div className="form-group mb-5">
                <label htmlFor="ozgecmis"><h5>Özgeçmiş</h5></label>
                <textarea onChange={ (e) => this.setOzgecmis(e.target.value) } defaultValue={ this.state.ozgecmis } id="ozgecmis" rows="8" className="form-control"></textarea>
            </div>
            
            <h5>Hizmet Verme Seçenekleri</h5>
            <div className="d-flex">
              <div className="custom-control custom-checkbox">
                <input onChange={ (e) => this.setEvdeHizmet(e.target.checked) } type="checkbox" className="custom-control-input" id="evde" checked={ this.state.evde_hizmet }/>
                <label className="custom-control-label" htmlFor="evde">Evde Hizmet</label>
              </div>
              <div className="custom-control custom-checkbox ml-3">
                  <input onChange={ (e) => this.setKlinikteHizmet(e.target.checked) } type="checkbox" className="custom-control-input" id="klinikte" checked={ this.state.klinikte_hizmet }/>
                  <label className="custom-control-label" htmlFor="klinikte">Klinikte Hizmet</label>
              </div>
              <div className="custom-control custom-checkbox ml-3">
                  <input onChange={ (e) => this.setOnlineHizmet(e.target.checked) } type="checkbox" className="custom-control-input" id="online" checked={ this.state.online_hizmet }/>
                  <label className="custom-control-label" htmlFor="online">Online Hizmet</label>
              </div>
            </div>
            <span>En az bir hizmet verme şekli seçmeniz gerekli.</span>

            <br />
            <br />

            {
              this.state.klinikte_hizmet ?
              <>
              <div className="form-group">
                <label htmlFor="klinik_adresi">Klinik Adresi</label>
                <textarea onChange={ (e) => this.setKlinikAdresi(e.target.value) } defaultValue={ this.state.klinik_adresi } id="klinik_adresi" rows="2" className="form-control"></textarea>
              </div>
              </>
              : ''
            }

            {
              this.state.evde_hizmet ?
              <>
              <h5>Evde Hizmet Ücret Aralığı</h5>
              <div className="row">
                
                <div className="form-group col mb-2">
                  <label htmlFor="evde_hizmet_taban_fiyat">Taban Fiyat</label>
                  <input defaultValue={ this.state.evde_hizmet_taban_fiyat } onChange={ (e) => this.setEvdeTabanFiyat(e.target.value) } id="evde_hizmet_taban_fiyat" type="text" className="form-control" />
                </div>

                <div className="form-group col mb-2">
                  <label htmlFor="evde_hizmet_tavan_fiyat">Tavan Fiyat</label>
                  <input defaultValue={ this.state.evde_hizmet_tavan_fiyat } onChange={ (e) => this.setEvdeTavanFiyat(e.target.value) } id="evde_hizmet_tavan_fiyat" type="text" className="form-control" />
                </div>
              </div>
              <br/>
              </>
              : ''
            }

            {
              this.state.klinikte_hizmet ?
              <>
              <h5>Klinikte Hizmet Ücret Aralığı</h5>
              <div className="row">
                
                <div className="form-group col mb-2">
                  <label htmlFor="klinikte_hizmet_taban_fiyat">Taban Fiyat</label>
                  <input defaultValue={ this.state.klinikte_hizmet_taban_fiyat } onChange={ (e) => this.setKlinikteTabanFiyat(e.target.value) } id="klinikte_hizmet_taban_fiyat" type="text" className="form-control" />
                </div>

                <div className="form-group col mb-2">
                  <label htmlFor="klinikte_hizmet_tavan_fiyat">Tavan Fiyat</label>
                  <input defaultValue={ this.state.klinikte_hizmet_tavan_fiyat } onChange={ (e) => this.setKlinikteTavanFiyat(e.target.value) } id="klinikte_hizmet_tavan_fiyat" type="text" className="form-control" />
                </div>
              </div>
              <br />
              </>
              : ''
            }

            {
              this.state.online_hizmet ?
              <>
              <h5>Online Hizmet Ücret Aralığı</h5>
              <div className="row">
                
                <div className="form-group col mb-2">
                  <label htmlFor="online_hizmet_taban_fiyat">Taban Fiyat</label>
                  <input defaultValue={ this.state.online_hizmet_taban_fiyat } onChange={ (e) => this.setOnlineTabanFiyat(e.target.value) } id="online_hizmet_taban_fiyat" type="text" className="form-control" />
                </div>

                <div className="form-group col mb-2">
                  <label htmlFor="online_hizmet_tavan_fiyat">Tavan Fiyat</label>
                  <input defaultValue={ this.state.online_hizmet_tavan_fiyat } onChange={ (e) => this.setOnlineTavanFiyat(e.target.value) } id="online_hizmet_tavan_fiyat" type="text" className="form-control" />
                </div>
              </div>
              </>
              : ''
            }


            {
              (!this.state.evde_hizmet && !this.state.klinikte_hizmet && !this.state.online_hizmet) ? '' :
              <>
              <div className="custom-control custom-checkbox col-md-12 mb-5">
                <input onChange={ (e) => this.setProfildeFiyatGoster(e.target.checked) } type="checkbox" className="custom-control-input" id="profilde_fiyat_goster" checked={ this.state.profilde_fiyat_goster }/>
                <label className="custom-control-label" htmlFor="profilde_fiyat_goster">Fiyatlandırma profilimde gözüksün</label>
              </div>
              </>
            }

            {/* <h5>Değerlendirme Seansı</h5>
            <div className="custom-control custom-radio custom-control-inline mt-2">
                <input name="ucretli" type="radio" id="0" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="0">Ücretsiz</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline mt-2 mb-5">
                <input name="ucretli" type="radio" id="1" className="custom-control-input"/>
                <label className="custom-control-label" htmlFor="1">Ücretli</label>
            </div> */}

            <h5>Kabul Edilen Ödeme Yöntemi</h5>
            <div className="col-md-12">
                <div className="row my-3">
                    <div className="custom-control custom-checkbox col-md-2">
                        <input onChange={ (e) => this.setNakitOdeme(e.target.checked) } type="checkbox" className="custom-control-input" id="nakit" checked={ this.state.nakit_odeme }/>
                        <label className="custom-control-label" htmlFor="nakit">Nakit</label>
                    </div>
                    <div className="custom-control custom-checkbox col-md-2">
                        <input onChange={ (e) => this.setKartIleOdeme(e.target.checked) } type="checkbox" className="custom-control-input" id="kart" checked={ this.state.kart_ile_odeme }/>
                        <label className="custom-control-label" htmlFor="kart">Kredi Kartı</label>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mb-5">
                <span>En az bir ödeme yöntemi seçmeniz gerekli.</span>
            </div>

            <h5 className="mt-5">Değerlendirme Seansı</h5>
            <div className="col-md-12">
                <div className="row my-3">
                    <div className="custom-control custom-checkbox col-md-2">
                        <input onChange={ (e) => this.setUcretli(e.target.checked) } type="checkbox" className="custom-control-input" id="ds-ucretli" checked={ this.state.degerlendirme_seansi_ucretli }/>
                        <label className="custom-control-label" htmlFor="ds-ucretli">Ücretli</label>
                    </div>
                </div>
            </div>

            

            <div className="form-group mb-5">
                <label htmlFor="okullar"><h5>Okullar/Eğitimler</h5></label>
                <textarea onChange={ (e) => this.setOkul(e.target.value) } defaultValue={ this.state.okul } id="okullar" rows="8" className="form-control"></textarea>
            </div>

            <div className="form-group mb-5">
                <label htmlFor="tecrubeler"><h5>Deneyimler/Tecrübeler</h5></label>
                <textarea onChange={ (e) => this.setTecrubeler(e.target.value) } defaultValue={ this.state.tecrubeler } id="tecrubeler" rows="8" className="form-control"></textarea>
            </div>

            <div className="custom-control custom-checkbox col-md-12 mb-3">
                <input type="checkbox" className="custom-control-input" id="kabul" checked/>
                <Link to={ '/kullanici-sozlesmesi' } className="custom-control-label" htmlFor="kabul">Site Kullanım Şartnamesini ve Kişisel Veri Gizlilik Politikası ve Aydınlatma Metnini <strong>sisteme üye olarak</strong> kabul ettiğimi biliyorum. </Link>
            </div>


            <button disabled={ this.state.kayit_ediliyor } onClick={ () => this.kaydet() } className="btn btn green-button btn-block p-3">
              {
                this.state.kayit_ediliyor ? 'Kaydediliyor...' : 'Kaydet'
              }
            </button>
        </div>
      )
  }

  hizmetKontrol(value,hizmetler){
    return hizmetler.filter(x => x.value == value).length > 0 ? true : false;
  }

  hizmetChange = async (e) => {

    // if(this.state.meslek_id != this.state.user.meslek_id){
    //   // alert("Meslek değişti. Önce kaydet.")
    //   this.setState({
    //     meslek_degisti : 1
    //   });
    //   this.setState({
    //     verilen_hizmetler : []
    //   });
    //   return;
    // }
    // else{
    //   this.setState({
    //     meslek_degisti : 0
    //   })
    // }

    if(this.state.verilen_hizmetler.length > e.length){
      
      let silinen = this.state.verilen_hizmetler.filter(x => !this.hizmetKontrol(x.value,e))[0];
      this.hizmetSil(silinen.value)

    }else{

      let eklenen = e.filter(x => !this.hizmetKontrol(x.value,this.state.verilen_hizmetler))[0];
      this.hizmetEkle(eklenen.value)
    }

    this.setState({ verilen_hizmetler : e })
  }

  async hizmetEkle(hizmet_id){
    var { hata,mesaj,data } = await Api.uzman_hizmet_ekle(hizmet_id,Co.access_token());

    return hata;
  }

  async hizmetSil(hizmet_id){

    var { hata,mesaj,data } = await Api.uzman_hizmet_sil(hizmet_id,Co.access_token());

    return hata;
  }



  hizmetBolgesiKontrol(value,hizmet_bolgeleri){
    return hizmet_bolgeleri.filter(x => x.value == value).length > 0 ? true : false;
  }

  hizmetBolgesiChange = async (e) => {

    if(this.state.hizmet_bolgelerim.length > e.length){
      
      let silinen = this.state.hizmet_bolgelerim.filter(x => !this.hizmetBolgesiKontrol(x.value,e))[0];
      this.hizmetBolgesiSil(silinen.value)

    }else{

      let eklenen = e.filter(x => !this.hizmetBolgesiKontrol(x.value,this.state.hizmet_bolgelerim))[0];
      this.hizmetBolgesiEkle(eklenen.value)
    }

    this.setState({ hizmet_bolgelerim : e })
  }

  async hizmetBolgesiEkle(bolge){
    var { hata,mesaj,data } = await Api.hizmet_bolgesi_ekle(bolge,Co.access_token());

    return hata;
  }

  async hizmetBolgesiSil(bolge){

    var { hata,mesaj,data } = await Api.hizmet_bolgesi_sil(bolge,Co.access_token());

    return hata;
  }


  diplomaSecim = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({ yeni_diploma : e.target.files[0] });

      this.setState({ diploma_yukleniyor : true })

      Api.diploma_yukle(e.target.files[0],Co.access_token()).then(yanit => {
        const { hata,mesaj,data } = yanit;

        if(hata){
          swal('Oops',mesaj,'error');
        }else{
          swal('Harika!',mesaj,'success');
          this.setState({ diploma : data.diploma });
        }

        e.target.value = null;

        this.setState({ diploma_yukleniyor : false })
      })
    }
  }

  render(){
    return (
        <div>
            <NavBar className="bg-green"/>

            <div className="wrapper">
              
              <Sidebar/>

              <div id="content">

                { this.state.yuklendi ? <Stepper/> : '' }

                <h4 className="mb-3">Profilim</h4>

                { this.content() }
              </div>

            </div>

            <Footer/>
            
        </div>
    )
  }
}

function mapStateToProps(state){
    return {
    }
}

function mapDispatchToProps(dispatch){
    return {
      set_user : (value) => dispatch({ type : SET_USER_DATA,payload : value })
    }
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#EFEFEF',
    minHeight: '150px',
    padding:8,
    // height: '150px',
    boxShadow: state.isFocused ? null : null,
    alignItems:'flex-start'
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: '150px',
    padding:8,
  }),
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(KisiselBilgiler))